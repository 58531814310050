import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor() { }

  title: string;

  setTitle(title:string)
  {
    this.title = title;
  }
}
