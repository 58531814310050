<div *ngIf="loaded">
<mat-sidenav-container class="example-container" (backdropClick)="closeSides()" >
  <mat-sidenav #sidenav (keydown.escape)="sidenav.close()" disableClose position="end">
    <app-event [value]="event"></app-event>

  </mat-sidenav>

  <mat-sidenav #sitenav (keydown.escape)="sitenav.close()" disableClose position="start">
    <app-site [site_id]="site_id"></app-site>

  </mat-sidenav>

  <mat-sidenav-content>
     <!-- (scroll)="scrolling($event)"> -->



<div class="header">
  <div class="left">
    <img src="assets/img/logoweb.png" height="20px">
  </div>


<ng-container *ngIf="logedIn">
  <button mat-button [matMenuTriggerFor]="menuServices">Services</button>
  <mat-menu #menuServices="matMenu">
    <button mat-menu-item (click)="newService()">New Service</button>
    <button mat-menu-item (click)="services()">Services</button>
  </mat-menu>

  <button mat-button [matMenuTriggerFor]="menuQuotes">Quotes</button>
  <mat-menu #menuQuotes="matMenu">
    <button mat-menu-item (click)="newQuote()">New Quote</button>
    <button mat-menu-item (click)="quotes()">Quotes</button>
  </mat-menu>

  <ng-container *ngIf="basic.privilegios['Sales N1'] || basic.privilegios.Master">
    <button mat-button [matMenuTriggerFor]="menuSales">Sales</button>
    <mat-menu #menuSales="matMenu">
      <button mat-menu-item (click)="newProspect()">New Prospect</button>
      <button mat-menu-item (click)="prospects()">Prospects</button>
    </mat-menu>
  </ng-container>

  <ng-container *ngIf="basic.privilegios['Accounting'] || basic.privilegios.Master">
    <button mat-button [matMenuTriggerFor]="menuAccounting">Accounting</button>
    <mat-menu #menuAccounting="matMenu">
      <button mat-menu-item (click)="factoring()">Factoring</button>
      <button mat-menu-item (click)="invoices()">Invoices</button>
    </mat-menu>
  </ng-container>
</ng-container>

  <button *ngIf="logedIn" mat-button (click)="logout()" class="float_right">Log Out</button>
  <button *ngIf="!logedIn" mat-button (click)="login()" class="float_right">Log In</button>


</div>



<!-- <div class='content'>
  <div class="toolbar-wrapper">
  <mat-toolbar  class="example-toolbar" #stickyMenu [class.sticky] ="sticky">
    <div>
    <button *ngIf="mobileQuery.matches" mat-icon-button ><mat-icon>menu</mat-icon></button>
    </div>
  </mat-toolbar>
  </div>



 -->
      <div class ="nav_container" [class.is-mobile]="mobileQuery.matches">
      <router-outlet></router-outlet>
      </div>
      <!-- <app-messages></app-messages> -->


<!-- </div> -->


</mat-sidenav-content>
</mat-sidenav-container>
</div>
<div *ngIf="!loaded">
  Loading...
</div>
