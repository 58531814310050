<mat-dialog-content class="mat-typography">

  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="{{ 'interface.ex_name' | translate}}"  #input>
  </mat-form-field>

  <!-- <div class="example-container mat-elevation-z8"> -->
    <div class="example-table-container">

      <table mat-table [dataSource]="dataSource" class="example-table" matSort>
             <!-- matSort matSortActive="created" matSortDisableClear matSortDirection="desc"> -->
        <!-- Number Column -->

        <ng-container matColumnDef="select" >
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row"> <button mat-stroked-button  [mat-dialog-close]="row" >{{ 'interface.select' | translate }}</button> </td>
        </ng-container>

        <ng-container *ngFor="let col of displayedColumns | slice:1" matColumnDef="{{col}}">

            <th mat-header-cell *matHeaderCellDef> {{'interface.'+col | translate}} </th>
            <td mat-cell *matCellDef="let element">
              {{element[col]}}
            </td>
        </ng-container>

        <!-- <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>{{'interface.name' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{row.name}}</td>
        </ng-container>

        <ng-container matColumnDef="tax_number">
          <th mat-header-cell *matHeaderCellDef>{{ 'interface.tax_number' | translate}}</th>
          <td mat-cell *matCellDef="let row">{{row.tax_number}}</td>
        </ng-container>

        <ng-container matColumnDef="city">
          <th mat-header-cell *matHeaderCellDef>{{ 'interface.city' | translate}}</th>
          <td mat-cell *matCellDef="let row" >{{row.city}}</td>
        </ng-container>

        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef>{{ 'interface.state' | translate}}</th>
          <td mat-cell *matCellDef="let row">{{row.state}}</td>
        </ng-container>

        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef>{{ 'interface.address' | translate}}</th>
          <td mat-cell *matCellDef="let row">{{row.address}}</td>
        </ng-container> -->

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length">
              {{ 'interface.not_matching' | translate}} "{{input.value}}"
              <button mat-stroked-button [mat-dialog-close]="{create_new:'true',name: input.value}" >{{ 'interface.create' | translate }}</button>
          </td>
        </tr>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  <mat-paginator [pageSize]="10"></mat-paginator>
<!-- </div> -->


</mat-dialog-content>
