<h2 mat-dialog-title>{{ 'interface.new_vendor' | translate }}</h2>
<mat-dialog-content class="mat-typography">

  <mat-vertical-stepper linear="false" #stepper>
    <mat-step [editable]="!formSubmitted" [stepControl]="MCFormGroup">
      <form [formGroup]="MCFormGroup">
        <ng-template matStepLabel>Fill out your company information</ng-template>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
            <mat-label>MC Number</mat-label>
            <input matInput placeholder="MC-XXXXXXX" formControlName="mc_number">
          </mat-form-field>
        </div>
        <!-- <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">

          <mat-form-field fxFlex>
            <input matInput placeholder="SCAC Code" formControlName="scac_code">
          </mat-form-field>


          <mat-form-field fxFlex>
            <input matInput placeholder="Federal ID Number" formControlName="federal_id_number">
          </mat-form-field>
        </div> -->
        <div>
          <button mat-raised-button matStepperNext color="primary">Next</button>
        </div>
      </form>
    </mat-step>

    <mat-step [editable]="!formSubmitted" [stepControl]="companyFormGroup">
      <form [formGroup]="companyFormGroup">
        <ng-template matStepLabel>Fill out your company information</ng-template>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
            <input matInput placeholder="Legal Name" formControlName="legal_name">
          </mat-form-field>

          <mat-form-field fxFlex>
            <input matInput placeholder="DBA Name" formControlName="dba">
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
            <input matInput placeholder="Street Address" formControlName="street_address">
          </mat-form-field>

          <mat-form-field fxFlex>
            <input type="text" placeholder="{{ 'interface.zip' | translate }}" aria-label="Number" matInput formControlName="zip" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option.name">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <label>Maililng Address (if different)</label>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
            <input matInput placeholder="Street Address" formControlName="street_address_mailing">
          </mat-form-field>
          <mat-form-field fxFlex>
            <input matInput placeholder="City, State, Postal code" formControlName="zip_mailing">
          </mat-form-field>
        </div>


        <!-- <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">

          <mat-form-field fxFlex>
            <input matInput placeholder="SCAC Code" formControlName="scac_code">
          </mat-form-field>


          <mat-form-field fxFlex>
            <input matInput placeholder="Federal ID Number" formControlName="federal_id_number">
          </mat-form-field>
        </div> -->
        <div>
          <button mat-raised-button matStepperPrevious>Back</button>
          <button mat-raised-button matStepperNext color="primary">Next</button>
        </div>
      </form>
    </mat-step>

    <mat-step [editable]="!formSubmitted" [stepControl]="contactsFormGroup" label="Fill out your contact information">
      <form [formGroup]="contactsFormGroup">

        <label>Dispatch Contact</label>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
            <input matInput placeholder="Name" formControlName="name_dispatch">
          </mat-form-field>
          <mat-form-field fxFlex>
            <input matInput placeholder="Phone" formControlName="phone_dispatch">
          </mat-form-field>
          <mat-form-field fxFlex>
            <input matInput placeholder="Email" formControlName="email_dispatch">
          </mat-form-field>
        </div>


        <!-- afterhours -->
        <label>Afterhours Contact</label>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
            <input matInput placeholder="Name" formControlName="name_afterhours">
          </mat-form-field>
          <mat-form-field fxFlex>
            <input matInput placeholder="Phone" formControlName="phone_afterhours">
          </mat-form-field>
          <mat-form-field fxFlex>
            <input matInput placeholder="Email" formControlName="email_afterhours">
          </mat-form-field>
        </div>

        <!-- accounting -->
        <label>Accounting Contact</label>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
            <input matInput placeholder="Name" formControlName="name_accounting">
          </mat-form-field>
          <mat-form-field fxFlex>
            <input matInput placeholder="Phone" formControlName="phone_accounting">
          </mat-form-field>
          <mat-form-field fxFlex>
            <input matInput placeholder="Email" formControlName="email_accounting">
          </mat-form-field>
        </div>

        <div>
          <button mat-raised-button matStepperPrevious>Back</button>
          <button mat-raised-button matStepperNext color="primary">Next</button>
        </div>
      </form>
    </mat-step>


    <mat-step [editable]="!formSubmitted" [stepControl]="insuranceFormGroup">
      <form [formGroup]="insuranceFormGroup">
          <ng-template matStepLabel>Fill out your insurance information</ng-template>

        <label>Commercial General Liability</label>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">

          <mat-form-field fxFlex>
            <mat-label>Amount of Insurance</mat-label>
            <input matInput placeholder="2,000,000" formControlName="commercial_liability">
          </mat-form-field>

          <mat-form-field fxFlex>
            <mat-label>Expiry Date</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="commercial_expiry">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

        <label>Automobile Liability</label>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">

          <mat-form-field fxFlex>
            <mat-label>Amount of Insurance</mat-label>
            <input matInput placeholder="2,000,000" formControlName="automobile_liability">
          </mat-form-field>

          <mat-form-field fxFlex>
            <mat-label>Expiry Date</mat-label>
            <input matInput [matDatepicker]="picker2" formControlName="automobile_expiry">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>

        <label>Cargo Liability</label>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">

          <mat-form-field fxFlex>
            <mat-label>Amount of Insurance</mat-label>
            <input matInput placeholder="250,000" formControlName="cargo_liability">
          </mat-form-field>

          <mat-form-field fxFlex>
            <mat-label>Expiry Date</mat-label>
            <input matInput [matDatepicker]="picker3" formControlName="cargo_expiry">
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
          </mat-form-field>
        </div>


        <div>
          <button mat-raised-button matStepperPrevious>Back</button>
          <button mat-raised-button matStepperNext color="primary">Next</button>
        </div>
      </form>
    </mat-step>


    <mat-step [editable]="!formSubmitted" [stepControl]="subscriberFormGroup">
      <form [formGroup]="subscriberFormGroup">
        <ng-template matStepLabel>Broker Carrier Agreement</ng-template>
        <label>Subscriber</label>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
            <input matInput placeholder="Name" formControlName="name_subscriber">
          </mat-form-field>
          <mat-form-field fxFlex>
            <input matInput placeholder="Title" formControlName="title_subscriber">
          </mat-form-field>
        </div>

        <div>

          <h3 class="accent-color">Agreement must be emailed to carriers@venkor.ca</h3>
          <button mat-raised-button color="accent"(click)="generatePDF()">Print Agreement
            <mat-icon aria-hidden="false" aria-label="">print</mat-icon>
          </button>

        </div>


        <div>
          <button mat-raised-button matStepperPrevious>Back</button>
          <button mat-raised-button matStepperNext color="primary">Next</button>
        </div>
      </form>
    </mat-step>

    <mat-step [editable]="!formSubmitted">
      <ng-template matStepLabel>Submit</ng-template>

      <div *ngIf="!formSubmitted">
        <button mat-raised-button matStepperPrevious>Back</button>
        <button mat-raised-button (click)="submit()" color="primary">Submit</button>
      </div>
      <div *ngIf="formSubmitted">
        <h1>Thank you!</h1>
        <p>To complete your registration please email the following documents to <a href="mailto:carriers@venkor.ca">carriers@venkor.ca</a>:</p>
        <p>1) Broker Carrier Agreement.</p>
        <p>2) Your Carrier Package including W8/W9 Form.</p>
        <p>3) COI emailed by your insurance company directly.</p>
      </div>
    </mat-step>
  </mat-vertical-stepper>







  <!--
  <div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">

    <mat-card class="box">

        <mat-card-title><h1>{{'interface.new_vendor' | translate }}</h1></mat-card-title>


      <h2>{{'interface.company_information' | translate}}</h2>
      <form [formGroup]="form" fxLayout="column">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
            <input matInput placeholder="{{ 'interface.legal_name' | translate }}" formControlName="legal_name">
          </mat-form-field>
          <mat-form-field fxFlex>
            <input matInput placeholder="{{ 'interface.dba' | translate }}" formControlName="dba">
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
            <input matInput placeholder="{{ 'interface.mc_number' | translate }}" formControlName="mc_number">
          </mat-form-field>
          <mat-form-field fxFlex>
            <input matInput placeholder="{{ 'interface.scac_code' | translate }}" formControlName="scac_code">
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
            <input matInput placeholder="{{ 'interface.us_dot' | translate }}" formControlName="us_dot">
          </mat-form-field>
          <mat-form-field fxFlex>
            <input matInput placeholder="{{ 'interface.federal_id_number' | translate }}" formControlName="federal_id_number">
          </mat-form-field>
        </div>

        <h2>{{'interface.address' | translate}}</h2>
        <div class="input-row">
          <mat-form-field fxFlex>
            <input matInput placeholder="{{ 'interface.street_address' | translate }}" formControlName="street_address">
          </mat-form-field>
        </div>

        <div class="input-row">
          <mat-form-field fxFlex>
            <input matInput placeholder="{{ 'interface.zip_address' | translate }}" formControlName="zip">
          </mat-form-field>
        </div>

        <h2>{{'interface.mailing_address' | translate}}</h2>
        <div class="input-row">
          <mat-form-field fxFlex>
            <input matInput placeholder="{{ 'interface.street_address' | translate }}" formControlName="street_address_mailing">
          </mat-form-field>
        </div>

        <div class="input-row">
          <mat-form-field fxFlex>
            <input matInput placeholder="{{ 'interface.zip_address' | translate }}" formControlName="zip_mailing">
          </mat-form-field>
        </div>


        <h2>{{'interface.contacts' | translate}}</h2>
        <!-- dispatch
        <h3>{{'interface.dispatch_contact' | translate}}</h3>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
            <input matInput placeholder="{{ 'interface.name' | translate }}" formControlName="name_dispatch">
          </mat-form-field>
          <mat-form-field fxFlex>
            <input matInput placeholder="{{ 'interface.phone' | translate }}" formControlName="phone_dispatch">
          </mat-form-field>
          <mat-form-field fxFlex>
            <input matInput placeholder="{{ 'interface.email' | translate }}" formControlName="email_dispatch">
          </mat-form-field>
        </div>


        <!-- afterhours
        <h3>{{'interface.afterhours_contact' | translate}}</h3>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
            <input matInput placeholder="{{ 'interface.name' | translate }}" formControlName="name_afterhours">
          </mat-form-field>
          <mat-form-field fxFlex>
            <input matInput placeholder="{{ 'interface.phone' | translate }}" formControlName="phone_afterhours">
          </mat-form-field>
          <mat-form-field fxFlex>
            <input matInput placeholder="{{ 'interface.email' | translate }}" formControlName="email_afterhours">
          </mat-form-field>
        </div>

        <!-- accounting
        <h3>{{'interface.accounting_contact' | translate}}</h3>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
            <input matInput placeholder="{{ 'interface.name' | translate }}" formControlName="name_accounting">
          </mat-form-field>
          <mat-form-field fxFlex>
            <input matInput placeholder="{{ 'interface.phone' | translate }}" formControlName="phone_accounting">
          </mat-form-field>
          <mat-form-field fxFlex>
            <input matInput placeholder="{{ 'interface.email' | translate }}" formControlName="email_accounting">
          </mat-form-field>
        </div>

          <button mat-stroked-button class="btn-block" type="submit" [disabled]="!form.valid">{{ 'interface.assign_vendor' | translate}}</button>
      </form>
    </mat-card>

  </div> -->



</mat-dialog-content>
