import {Component, Input,ElementRef, ViewChild, OnInit} from '@angular/core';
import { Observable, of, Subscription, empty } from 'rxjs';
import { tap, startWith, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
//import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { MatTable } from '@angular/material/table';
//import { MatTable } from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import { AppConstants } from '../structures/appconstants';
import { DatePipe } from '@angular/common'
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-editable-table',
  templateUrl: './editableTable.component.html',
  styleUrls: ['./editableTable.component.css']
})

export class EditableTableComponent implements OnInit {
  @ViewChild('templateForm') templateForm: NgForm;
  filteredOptions: Observable<any[]>;
  @Input() headers;
  @Input() data: any;
  // @Input() selectList: string[];
  @Input() updateUrl: string =null;
  @Input() newUrl: string =null;
  @Input() removeUrl: string =null;
  @Input() blockEdit: string = "";
  //@Input() checkMarkCallback: (args: any) => void = function(){ alert('not defined');};
  @Input() fieldChangeCallback: (args: any) => void = function(){ alert('not defined');};
  // @Input() required: string[];
  // @Input() number: string[];
  // @Input() checkList: string[];
  // @Input() date: string[] = [];
  // @Input() static_field: string[] = [];

  @Input() popup: (args: any) => void = function(){ alert('not defined');};

  headers_array:string[] =[ ];
  //@ViewChild('editable_table') table: MatTable<any>;
  @ViewChild('editable_table') table: MatTable<any>;

  constructor(
    private apiService: ApiService,
    public datepipe: DatePipe
  )
  {

    this.backup = new Array();


  }

  log( l)
  {
    console.log(l);
  }
  // selectRow()
  // {
  //
  //   this.checkMarkCallback( this.data );
  // }

  ngOnInit() {

    // if(this.selectList)
    //   for( let s of this.selectList)
    //   {
    //     console.log(s);
    //     this.data[s]['select'] = true;
    //   }
    for(let h of this.headers)
    {
      this.headers_array.push(h.name);
    }

    if( this.newUrl || this.updateUrl)
      this.headers_array.push('edit-save');
    if( this.newUrl || this.removeUrl)
      this.headers_array.push('remove-cancel');



  }


  updateDependents( d , a ) {
    let bool_first = false;
    for(let i = 0; i<this.data.length; i++)
    {
      if(this.data[i].editable && (!bool_first) )
      {

        for( let j=0; j< d.length ; j++)
          this.data[i][d[j].name] = d[j].value;
        this.data[i]['additional']=a;
        bool_first = true;
      }
      else
        this.data[i].additional = null;
    }

  }






  backup : any[];
  makeEditable( element)
  {
    element.editable = true;
    for(let e of this.headers)
    {
      if( e.type == 'selectList')
      {

        //element[e.name] = '' +element[e.name];
        //console.log('selectList:' + +element[e.name]);
      }
      this.backup[e.name] = element[e.name];
    }
  }

  cancelEditable( element)
  {
    if(!element.id)
    {
        this.data.splice(this.data.indexOf(element),1);
        this.table.renderRows();

    }
    else
    {


      for(let e of this.headers)
      {
        element[e.name] = this.backup[e.name];

      }
      element.editable = false;
    }

  }

  newEditable()
  {
    //this.newLine = true;

    let new_element = {};
    for(let h of this.headers)
    {
      switch(h.type)
      {
         case 'date': {
            new_element[h.name] = new Date();
            break;
         }
         case 'autocomplete': {
            console.log(h.default);
            new_element[h.name] = h.default;
            break;
         }
         case 'dialog': {
            if(h.default)
              new_element[h.name] = h.default;
            else
              new_element[h.name] = {name:'',id:''};
            break;
         }
         default: {
           if(h.default)
              new_element[h.name] = h.default;
           else
              new_element[h.name] = "";

           //if(h.lineChange)

            break;
         }
      }


      //if( this.myValidation.indexOf(e) != -1)
       //new_element[e]
    }
    new_element['editable'] = true;
    //new_element.new_line = true;

    this.data.push(new_element);
    //this.data = cloned;
    this.table.renderRows();

    //this.data.data = this.data.data;
    // console.log(this.myValidation);
    // console.log(this.data);
    // console.log(this.headers);
  }

  fieldChange(element,header)
  {
    if(header.fieldChange)
      this.fieldChangeCallback(element);
  }




  public templateToFormData( object ) {
    const formData = new FormData();
    for ( const key of Object.keys(object) )
    {
      if (Array.isArray(object[key]))
      {
        for ( let a of object[key] )
          formData.append(key+'[]', a);
      }
      else
      {
        if( object[key]=== null)
          formData.append(key, '');
        else if( object[key]=== false)
          formData.append(key, '0');
        // else if( key == 'date')
        // {
        //     const  latest_date =this.datepipe.transform(value, 'yyyy-MM-dd');
        //     formData.append(key, latest_date);
        // }
        // else if( key =='aut')
        //   formData.append(key, object[key]);
        else
          formData.append(key, object[key]);
      }
    }
    return formData;
  }

  saveEditable( element)
  {
    element.updating = true;

    for(let h of this.headers)
    {
      if(h.type == 'date')
        element[h.name] = this.datepipe.transform(element[h.name], 'yyyy-MM-dd');
      else if((h.type == 'autocomplete') || h.type == 'dialog')
      {
          if( element[h.name] )
            element[h.name+'_id'] = element[h.name].id;
          else
            element[h.name+'_id'] = null;
      }

    }

    const formData = this.templateToFormData(element);

    if(element.id)
    {
      this.apiService.getData( this.updateUrl, formData )
        .subscribe(
          result => {
            if(!result['success'])
            {
              this.cancelEditable(element);

            }

            if(result['file_id'])
              element.file = result['file_id'];
            element.updating = false;
            element.editable = false;
          });
    }
    else
    {
      this.apiService.getData( this.newUrl, formData )
        .subscribe(
          result => {
            if(!result['success'])
            {

            }
            else
            {
              if(result['file_id'])
                element.file = result['file_id'];
              element.id = result['id'];

              for(let h of this.headers)
              {
                if(h.update)
                  element[h.name] = result[h.name];
              }
              element.editable = false;
            }
            element.updating = false;


          });

    }
  }

  rowValid(index)
  {
    for(let h of this.headers)
    {
      if( h.required )
      {
        if( this.data[index][h.name] === '' || this.data[index][h.name] === null )
        {
        //  console.log('not valid'+ this.data[index][h.name] + 'field' +h.name)
          return false;
        }
      }
      if( h.number)
      {
        if(this.data[index][h.name])
          if (isNaN(+this.data[index][h.name]))
            return false;

      }
    }

    return true;

  }
  removeEditable( element)
  {
    if(! confirm("Are you sure to delete"))
      return;
    element.updating = true;

    if(element.id)
    {
      this.apiService.getData( this.removeUrl, element )
        .subscribe(
          result => {
            if(result['success'])
            {
              this.data.splice(this.data.indexOf(element),1);
              this.table.renderRows();

              element.updating = false;
              element.editable = false;
            }
          });
    }
  }

  getStructure( name )
  {
    if(name)
    return AppConstants[name];
    //else
    //console.log('error getStructure name:' + name);
  }


  getElementValue(list, id)
  {
    if(list && id)
    {
      return list.filter(x => x.value == id)[0]?.viewValue;
    }
      //console.log('error getStructureValue name:' + name + ' id:'+id );
  }
  getStructureValue( name, id )
  {
    if(name && id)
    {
      return AppConstants[name].filter(x => x.value == id)[0]?.viewValue;
      // if(AppConstants[name][id-1])
      //   return AppConstants[name][id-1].viewValue;
      // else
      //   console.log('error getStructureValue name:' + name + ' id:'+id );
    }
  //  else
    //console.log('error getStructureValue name:' + name + ' id:'+id );
  }

  autocompleteOnFocus(header_name, h_i) :void
  {
    this.filteredOptions = this.templateForm.control.get(header_name).valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(val  =>
          this.filter(val || '',h_i)
      )
    );
  }

  filter(val, h_i): Observable<any[]>
  {
    return this.apiService.getData(this.headers[h_i].url,{query: val})
      .pipe(
      )
  }

  displayProperty(value) {
    if (value) {
      return value.name;
    }
  }
  local_popup(element)
  {
    //console.log('debug here');
    this.popup(element);
  }

  optionSelected(event)
  {
    event.option.value.id;
  }
}
