<div class="panel_div" *ngIf="loaded">
  <mat-sidenav-container class="example-container" (backdropClick)="sidenav.close()" >
    <mat-sidenav #sidenav (keydown.escape)="sidenav.close()" disableClose position="end" class="side_bar">
      <app-operationm [id]="selected_row"></app-operationm>
      &nbsp;
    </mat-sidenav>

    <mat-sidenav-content>

<mat-horizontal-stepper linear="true" #stepper>
  <mat-step [editable]="true" [stepControl]="facturasFormGroup">
    <!-- <form [formGroup]="facturasFormGroup"> -->
      <ng-template matStepLabel>{{'interface.facturas' | translate}}</ng-template>


      <mat-button-toggle-group name="fontStyle" aria-label="Font Style" multiple (change)="toggleChangeStatus($event)" value="pending">
          <mat-button-toggle value="pending">Pending</mat-button-toggle>
          <mat-button-toggle value="paid">Paid</mat-button-toggle>

      </mat-button-toggle-group>


<div class="table-responsive">
  <table mat-table [dataSource]="dataSource" class='operations_table' matSort #matsort>
    <ng-container matColumnDef="select" >
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row"> <button mat-stroked-button  (click)="rowClick(row, $event)" >{{ 'interface.go' | translate }}</button> </td>
    </ng-container>

    <ng-container matColumnDef="check" >
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row; let i = index">

        <mat-checkbox *ngIf="(row.total_books - row.amount_paid_books) > 0" color="primary"  value="{{row.id}}" (change)="onCheckboxChange(i,row.id,$event)" [(ngModel)]="row.isChecked"></mat-checkbox>

      </td>
    </ng-container>



    <ng-container matColumnDef="file" >
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <button mat-icon-button (click)="file(row)">
          <mat-icon>insert_drive_file</mat-icon>
        </button>
      </td>
    </ng-container>


    <ng-container matColumnDef="factoring" >
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['factoring']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
        {{element['factoring']['name']}}
      </td>
    </ng-container>







    <ng-container *ngFor="let col of displayedColumns | slice:3:-1" matColumnDef="{{col}}">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter" floatLabel="never">
                <input matInput [formControl]="colFilter[col]" >
            </mat-form-field>
          </th>
        <td mat-cell *matCellDef="let element">
          {{element[col]}}
        </td>
    </ng-container>


    <ng-container *ngFor="let col of displayedColumnsSort" matColumnDef="{{col}}">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'interface.' + col.slice(5) | translate}}
          </th>
    </ng-container>

    <!-- <ng-container matColumnDef="pickups">
      <th mat-header-cell *matHeaderCellDef> Pickups </th>
      <td mat-cell *matCellDef="let element">
        <mat-list role="list" *ngFor="let pickup of element.pickups">
          {{pickup.site_name}}<br/> {{pickup.city_name}},{{pickup.state}}
        </mat-list>
      </td>
    </ng-container>

    <ng-container matColumnDef="pickups_dates">
      <th mat-header-cell *matHeaderCellDef> Pickups Dates </th>
      <td mat-cell *matCellDef="let element">
        <mat-list role="list" *ngFor="let pickup of element.pickups">
          {{pickup.departure_date}}
        </mat-list>
      </td>
    </ng-container>

    <ng-container matColumnDef="deliveries">
      <th mat-header-cell *matHeaderCellDef> Deliveries </th>
      <td mat-cell *matCellDef="let element">
        <mat-list role="list" *ngFor="let delivery of element.deliveries">
          {{delivery.site_name}}<br/> {{delivery.city_name}},{{delivery.state}}
        </mat-list>
      </td>
    </ng-container>

    <ng-container matColumnDef="deliveries_dates">
      <th mat-header-cell *matHeaderCellDef> Pickups Dates </th>
      <td mat-cell *matCellDef="let element">
        <mat-list role="list" *ngFor="let delivery of element.deliveries">
          {{delivery.departure_date}}
        </mat-list>
      </td>
    </ng-container> -->


    <tr mat-header-row *matHeaderRowDef="displayedColumnsSort; sticky: true"></tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;  let ind = index "></tr>

  </table>
</div>

<button mat-raised-button matStepperNext color="primary">{{'interface.next' | translate}}</button>
<!-- </form> -->
  </mat-step>
  <mat-step [editable]="true">
    <ng-template matStepLabel>{{'interface.journal' | translate}}</ng-template>

    <div class="table-responsive">
      <table mat-table #tableSelected [dataSource]="dataSourceSelected" class='operations_table'>

        <ng-container matColumnDef="file" >
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button (click)="file(row)">
              <mat-icon>insert_drive_file</mat-icon>
            </button>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>



        <ng-container matColumnDef="factoring">
            <th mat-header-cell *matHeaderCellDef> {{'interface.contract_id' | translate}} </th>
            <td mat-cell *matCellDef="let element">
              {{element['factoring']['name']}}
            </td>
            <td mat-footer-cell *matFooterCellDef> <strong>{{totalCol('contract_id')}} </strong></td>
        </ng-container>


        <ng-container matColumnDef="contract_id">
            <th mat-header-cell *matHeaderCellDef> {{'interface.contract_id' | translate}} </th>
            <td mat-cell *matCellDef="let element">
              {{element['contract_id']}}
            </td>
            <td mat-footer-cell *matFooterCellDef> <strong>{{totalCol('contract_id')}} </strong></td>
        </ng-container>


        <ng-container matColumnDef="amount_paid_books">
            <th mat-header-cell *matHeaderCellDef> {{'interface.amount_paid_books' | translate}} </th>
            <td mat-cell *matCellDef="let element">
              {{element['amount_paid_books']}}
            </td>
            <td mat-footer-cell *matFooterCellDef> <strong>{{totalCol('amount_paid_books')}} </strong></td>
        </ng-container>

        <ng-container matColumnDef="fecha_emision">
            <th mat-header-cell *matHeaderCellDef> {{'interface.fecha_emision' | translate}} </th>
            <td mat-cell *matCellDef="let element">
              {{element['fecha_emision']}}
            </td>
            <td mat-footer-cell *matFooterCellDef> <strong>{{totalCol('fecha_emision')}} </strong></td>
        </ng-container>

        <ng-container matColumnDef="legal_name">
            <th mat-header-cell *matHeaderCellDef> {{'interface.legal_name' | translate}} </th>
            <td mat-cell *matCellDef="let element">
              {{element['legal_name']}} {{element['vendor_id']}}
              <button  mat-icon-button   matTooltip="Site"  (click)="editSite(element.site_id)">
                <mat-icon>web</mat-icon>
              </button>
            </td>
            <td mat-footer-cell *matFooterCellDef> <strong>{{totalCol('legal_name')}} </strong></td>
        </ng-container>

        <ng-container matColumnDef="invoice_number">
            <th mat-header-cell *matHeaderCellDef> {{'interface.invoice_number' | translate}} </th>
            <td mat-cell *matCellDef="let element">
              {{element['invoice_number']}}
            </td>
            <td mat-footer-cell *matFooterCellDef> <strong>{{totalCol('invoice_number')}} </strong></td>
        </ng-container>

        <ng-container matColumnDef="subtotal_vendor">
            <th mat-header-cell *matHeaderCellDef> {{'interface.subtotal_vendor' | translate}} </th>
            <td mat-cell *matCellDef="let element">
              {{element['subtotal_vendor']}}
            </td>
            <td mat-footer-cell *matFooterCellDef> <strong>{{totalCol('subtotal_vendor')}} </strong></td>
        </ng-container>

        <ng-container matColumnDef="hst_vendor">
            <th mat-header-cell *matHeaderCellDef> {{'interface.hst_vendor' | translate}} </th>
            <td mat-cell *matCellDef="let element">
              {{element['hst_vendor']}}
            </td>
            <td mat-footer-cell *matFooterCellDef> <strong>{{totalCol('hst_vendor')}} </strong></td>
        </ng-container>

        <ng-container matColumnDef="total_vendor">
            <th mat-header-cell *matHeaderCellDef> {{'interface.total_vendor' | translate}} </th>
            <td mat-cell *matCellDef="let element">
              {{element['total_vendor']}}
            </td>
            <td mat-footer-cell *matFooterCellDef> <strong>{{totalCol('total_vendor')}} </strong></td>
        </ng-container>

        <ng-container matColumnDef="total_books">
            <th mat-header-cell *matHeaderCellDef> {{'interface.total_books' | translate}} </th>
            <td mat-cell *matCellDef="let element">
              {{element['total_books']}}
            </td>
            <td mat-footer-cell *matFooterCellDef> <strong>{{totalCol('total_books')}} </strong></td>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="displayedColumnsSelected"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsSelected;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumnsSelected"></tr>


      </table>
    </div>

    <form [formGroup]="formEntry" (submit)="recordPayment()">
      <div fxLayout="column">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
      <mat-form-field  fxFlex>
        <mat-label>Choose a date</mat-label>
        <input matInput [matDatepicker]="picker3" formControlName="date">
        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
        <mat-datepicker #picker3></mat-datepicker>
      </mat-form-field>
      <mat-form-field fxFlex>
        <input type="text" placeholder="{{ 'interface.account' | translate }}" matInput  [matAutocomplete]="auto" formControlName="account">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngFor="let option of accountFilteredOptions | async" [value]="option">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field fxFlex>
        <input type="text" placeholder="{{ 'interface.total' | translate }}" matInput formControlName="total" >
        <!-- value="{{totalFacturas()}}" -->
      </mat-form-field>

      <mat-form-field fxFlex>
        <mat-label >{{ 'interface.currency' | translate }}</mat-label>
        <mat-select formControlName="currency_id" (selectionChange)="updateCurrency()">
          <mat-option *ngFor="let currency of getCurrencies()" [value]="currency.value">
            {{currency.viewValue | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>



      <mat-form-field fxFlex>
        <input type="text" placeholder="{{ 'interface.total' | translate }}" matInput formControlName="total_books">
        <!-- value="{{totalBooks()}}" -->
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
      <mat-checkbox  formControlName="quick_pay" color="primary"  value="quick_pay" (change)="onQuickPay($event)" fxFlex></mat-checkbox>
      <mat-form-field fxFlex>
        <input type="text" placeholder="Quick Pay Disccount" matInput formControlName="quick_pay_discount">
      </mat-form-field>

      <mat-form-field fxFlex>
        <input type="text" placeholder="After Discount" matInput formControlName="after_quick_pay">
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
      <mat-form-field fxFlex>
        <input type="text" placeholder="{{ 'interface.payment_number' | translate }}" matInput formControlName="payment_number" >
      </mat-form-field>


      <mat-form-field fxFlex>
       <ngx-mat-file-input formControlName="file"  accept="true" color="primary">
       </ngx-mat-file-input>
     </mat-form-field>


    </div>
    </div>
<!-- [disabled]="!formEntry.valid" -->
    <button mat-raised-button class="btn-block" type="submit" >{{ 'interface.record_payment' | translate}}</button>


    </form>

  </mat-step>
</mat-horizontal-stepper>

</mat-sidenav-content>
</mat-sidenav-container>


</div>
<div *ngIf="!loaded">
  Loading...
</div>
