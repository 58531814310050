import {AfterViewInit, Component, ViewChild, OnInit} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-sitedialog',
  templateUrl: './sitedialog.component.html',
  styleUrls: ['./vendordialog.component.css']
})
export class SitedialogComponent implements AfterViewInit, OnInit {
    displayedColumns: string[] = ['select','name','address_line','city', 'state', 'zip_code'];
    dataSource : MatTableDataSource <any>;
    ready: boolean = false;
    filterValue = "";
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(
      private apiService: ApiService

    )
    {
      this.getRows();

    }

    ngAfterViewInit() {

      //this.dataSource.paginator = this.paginator;
      //this.dataSource.sort = this.sort;
    }
    applyFilter(event: Event) {

      this.filterValue = ((event.target as HTMLInputElement).value).trim().toLowerCase();
      if (!this.ready) return;
      const filterValue = this.filterValue;
      this.dataSource.filter = filterValue;
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }

    ngOnInit(): void {

    }



    getRows( ): void {
      this.apiService.getData('sites/list' )
        .subscribe(param =>
          {
            this.ready = true;
            console.log('getrow');
            this.dataSource = new MatTableDataSource<any>(param.data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

            const filterValue = this.filterValue;
            this.dataSource.filter = filterValue;
            // if (this.dataSource.paginator) {
            //   this.dataSource.paginator.firstPage();
            // }


            //this.dataSource.paginator.firstPage();

          });
    }

}
