import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { MatTableDataSource } from '@angular/material/table';
import {Router} from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-ledgers',
  templateUrl: './ledgers.component.html',
  styleUrls: ['../shared/panel.component.css']
})

export class LedgersComponent implements OnInit {

  loaded: boolean

  filterFormGroup :FormGroup;
  filter = {};


  displayedColumns: string[] = ['select','id','code','name','saldo'];
  dataSource : MatTableDataSource<any>;

  ngOnInit(): void {


    this.getRows();
  }

  constructor( private apiService: ApiService, private router: Router, fb: FormBuilder)
  {
    this.loaded = false;

    this.filterFormGroup = fb.group({
      pickup_start: ['',[]],
      pickup_end: ['',[]],
      delivery_start: ['',[]],
      delivery_end: ['',[]]
    });
  }

  applyFilter(): void {
    this.filter = this.filterFormGroup.value;
    this.getRows();
  }

  resetFilter(): void {
    this.filter = {};
    this.filterFormGroup.reset();
    this.getRows();
  }

  rowClick( row, event ) {
  if (( event.metaKey ) || (event.ctrlKey))
  {
    var win = window.open('/ledger_detail/'+ row.id , '_blank');
    if (win) {
        win.focus();
    } else {
        alert('Please allow popups for this website');
    }
  }
    else
//    OperationComponent.set_row(row.id);//.selected_row = row.id;
    this.router.navigate(['/ledger_detail/' + row.id  ]);

  }

  getRows(): void {
    this.apiService.getData( 'ledgers/list',this.filter )
      .subscribe(param =>
        {
          if(this.dataSource)
            this.dataSource.data  = param.data;
          else
            this.dataSource = new MatTableDataSource<any>(param.data)
          this.loaded = true;
        });

  }
}
