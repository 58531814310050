import {AfterViewInit, Component, ViewChild, OnInit} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-carrierdialog',
  templateUrl: './carrierdialog.component.html',
  styleUrls: ['./vendordialog.component.css']
})
export class CarrierdialogComponent implements AfterViewInit, OnInit {
    displayedColumns: string[] = ['select','name','emails','phone','edit'];
    dataSource : MatTableDataSource <any>;
    ready: boolean = false;
    filterValue = "";
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(
      private apiService: ApiService

    )
    {
      this.getRows();

    }

    ngAfterViewInit() {

      //this.dataSource.paginator = this.paginator;
      //this.dataSource.sort = this.sort;
    }
    applyFilter(event: Event) {

      this.filterValue = ((event.target as HTMLInputElement).value).trim().toLowerCase();
      if (!this.ready) return;
      const filterValue = this.filterValue;
      this.dataSource.filter = filterValue;
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }

    ngOnInit(): void {

    }



    getRows( ): void {
      this.apiService.getData('carrier/list' )
        .subscribe(param =>
          {
            this.ready = true;
            console.log('getrow');
            this.dataSource = new MatTableDataSource<any>(param.data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

            const filterValue = this.filterValue;
            this.dataSource.filter = filterValue;
            // if (this.dataSource.paginator) {
            //   this.dataSource.paginator.firstPage();
            // }


            //this.dataSource.paginator.firstPage();

          });
    }

    // editCarrier(row) {
    //
    //   const dialogRef = this.dialog.open(CarrierdialogComponent,this.dialogParams);
    //
    //   dialogRef.afterClosed().subscribe(result => {
    //     if(result)
    //     {
    //       if(result['create_new'])
    //       {
    //         this.openNewCarrier(result['name'],element);
    //       }
    //       else
    //       {
    //
    //         // element.site.name = result.name;
    //         // element.site.id = result.id;
    //         // element.zip_name = result.city +', '+result.state +', '+result.zip_code;
    //
    //
    //       }
    //     }
    //   });
    //   console.log(element);
    //   return (element);
    // }


}
