<div class="panel_div" *ngIf="loaded">
  <mat-sidenav-container class="example-container" (backdropClick)="sidenav.close()" >
    <mat-sidenav #sidenav (keydown.escape)="sidenav.close()" disableClose position="end" class="side_bar">
      <app-operationm [id]="selected_row"></app-operationm>
      &nbsp;
    </mat-sidenav>

    <mat-sidenav-content>

<mat-horizontal-stepper linear="true" #stepper>
  <mat-step [editable]="true" [stepControl]="commissionsFormGroup">
      <ng-template matStepLabel>Operations</ng-template>

      <mat-button-toggle-group name="fontStyle" aria-label="Font Style" multiple (change)="toggleChangeStatus($event)" value="pending">
          <mat-button-toggle value="pending">Pending</mat-button-toggle>
          <mat-button-toggle value="paid">Paid</mat-button-toggle>
      </mat-button-toggle-group>

<div class="table-responsive">
  <table mat-table [dataSource]="dataSource" class='operations_table' matSort #matsort>
    <ng-container matColumnDef="select" >
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row"> <button mat-stroked-button  (click)="rowClick(row, $event)" >{{ 'interface.go' | translate }}</button> </td>
    </ng-container>

    <ng-container matColumnDef="check" >
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row; let i = index">

        <mat-checkbox color="primary"  value="{{row.id}}" (change)="onCheckboxChange(i,row.id,$event)" [(ngModel)]="row.isChecked"></mat-checkbox>

      </td>
    </ng-container>


    <ng-container matColumnDef="service_id" >
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['service_id']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
        {{element['service_id']}}
      </td>
    </ng-container>


    <!-- <ng-container *ngFor="let col of displayedColumns | slice:2:0" matColumnDef="{{col}}">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter" floatLabel="never">
                <input matInput [formControl]="colFilter[col]" >
            </mat-form-field>
          </th>
        <td mat-cell *matCellDef="let element">
          {{element[col]}}
        </td>
    </ng-container> -->

    <ng-container *ngFor="let col of displayedColumnsSort" matColumnDef="{{col}}">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'interface.' + col.slice(5) | translate}}
          </th>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsSort; sticky: true"></tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;  let ind = index "></tr>

  </table>
</div>

<button mat-raised-button matStepperNext color="primary">{{'interface.next' | translate}}</button>
<!-- </form> -->
  </mat-step>
  <mat-step [editable]="true">
    <ng-template matStepLabel>{{'interface.journal' | translate}}</ng-template>

    <div class="table-responsive">
      <table mat-table #tableSelected [dataSource]="dataSourceSelected" class='operations_table'>


        <ng-container matColumnDef="service_id">
            <th mat-header-cell *matHeaderCellDef> {{'interface.contract_id' | translate}} </th>
            <td mat-cell *matCellDef="let element">
              {{element['contract_id']}}
            </td>
            <td mat-footer-cell *matFooterCellDef> <strong> </strong></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsSelected"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsSelected;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumnsSelected"></tr>


      </table>
    </div>
  </mat-step>
</mat-horizontal-stepper>

</mat-sidenav-content>
</mat-sidenav-container>


</div>
<div *ngIf="!loaded">
  Loading...
</div>
