<div class="wrapper">
  <header>
    <mat-form-field appearance="standard">
        <mat-label>Filter...</mat-label>
        <input matInput type="search" #input>
    </mat-form-field>
  </header>
  <main #scrollMe>

      <mat-card *ngFor="let estado of data">
        <mat-card-subtitle fxLayout>
          {{estado.date + 'Z' | date:"MMMM d, y, h:mm"}} <span class="spacer"></span> {{estado.user_name}} <span class="spacer"></span>
          <button class="title_button" mat-icon-button matTooltip="Remove"  (click)="removeNote(estado)">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-card-subtitle>
        <mat-card-content [innerText]="estado.status">
        </mat-card-content>
      </mat-card>


  </main>
  <footer>
    <mat-form-field class="bottom_textarea">
      <mat-label>Insert new note</mat-label>
      <textarea matInput (keydown.meta.enter)="newNote($event)"  (keydown.control.enter)="newNote($event)" [(ngModel)]="text" rows="4"></textarea>
    </mat-form-field>
  </footer>
</div>


<!-- <mat-form-field>
  <mat-label>Autosize textarea</mat-label>
  <textarea matInput Rows="5"></textarea>
</mat-form-field> -->
