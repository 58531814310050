import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ApiService } from '../services/api.service';
import { DetailedOperation } from '../structures/detailed_operation';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppConstants } from '../structures/appconstants';
import { AppComponent } from '../app.component';
import {CdkDragDrop, moveItemInArray, transferArrayItem, CdkDropList} from '@angular/cdk/drag-drop';
//import { MatTable, MatTableDataSource } from '@angular/material';
import { MatTableDataSource } from '@angular/material/table';
//import { EditableTableComponent } from '../editableTable/EditableTable.component';
import { EditableTableComponent } from '../editableTable/editableTable.component';
import {TranslateService} from '@ngx-translate/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { SitedialogComponent } from '../vendordialog/sitedialog.component';
import { CarrierdialogComponent } from '../vendordialog/carrierdialog.component';
import { NewcarrierdialogComponent } from '../vendordialog/newcarrierdialog.component';
import { EditcarrierdialogComponent } from '../vendordialog/editcarrierdialog.component';
import { NewsitedialogComponent } from '../vendordialog/newsitedialog.component';


@Component({
  selector: 'app-servicenm',
  templateUrl: './servicenm.component.html',
  styleUrls: ['./servicenm.component.css']
})
export class ServicenmComponent implements OnInit {
  @Input() id: number;

  @ViewChild('invoicesEditableTableId') invoicesEditableTable:EditableTableComponent;
  @ViewChild('budgetsEditableTableId') budgetsEditableTable:EditableTableComponent;

  data : any;
  sub_total=0;
  privilegios : any;
  loaded: boolean;
  is_editting_details :boolean;
  descriptionFormGroup: FormGroup;
  //invoice_headers = [{name:'date',required:true,type:'date'},{name:'invoice_number',type:'static_field'},{name:'sub_total_books',required:true,readonly:true},{name:'hst_books',required:true,readonly:true},{name:'total_books',required:true,readonly:true},{name:'file',type:'button',icon:'insert_drive_file',callback:this.fileCallback}];

  displayedColumnsStops: string[] = ['stop_type_name', 'site_name', 'city_name', 'state'];
  //dataSourceStops = ELEMENT_DATA;


  constructor(
    private apiService: ApiService,
    private sanitizer: DomSanitizer,
    private app: AppComponent,
    private location: Location,
    public translate: TranslateService,
    public dialog: MatDialog,
    fb: FormBuilder,

  ) {


    this.is_editting_details = false;
    this.loaded = false;
    this.descriptionFormGroup = fb.group({
      description: [
        '',
        []
      ],
      client_ref: [
        '',
        []
      ],
      temperature: [
        '',
        []
      ],
      mode_id: [
        '',
        []
      ]
    });


  }

  private dialogParams = {
    width: 'calc( 100% - 50px )',
    height: 'calc( 100% - 50px )',
    maxWidth: '100vw',
    maxHeight: '100vh',
  };

  ngOnInit(): void {

  }

  ngOnChanges(changes) {
    this.getOperation();
  }


  send_completed()
  {
    const id = +this.id;
    if(!this.id) return;
    this.apiService.getData('services/status/update/'+ id ,{param:'send_completed'})
      .subscribe(results =>
        {

          if(results['success'] )
          {
            this.data.status_name = 'q_completed';
            this.data.status = -11;
            console.log('completed');
          }
        });

  }

  send_pending()
  {
    const id = +this.id;
    if(!this.id) return;
    this.apiService.getData('services/status/update/'+ id ,{param:'send_pending'})
      .subscribe(results =>
        {

          if(results['success'] )
          {
            this.data.status_name = 'q_pending';
            this.data.status = -10;
            console.log('completed');
          }
        });

  }

  send_customer()
  {
    const id = +this.id;
    if(!this.id) return;
    this.apiService.getData('services/status/update/'+ id ,{param:'send_customer'})
      .subscribe(results =>
        {

          if(results['success'] )
          {
            this.data.status_name = 'q_sent';
            this.data.status = -12;
          }
        });

  }

  send_new_op()
  {
    const id = +this.id;
    if(!this.id) return;
    this.apiService.getData('services/status/update/'+ id ,{param:'send_new_op'})
      .subscribe(results =>
        {

          if(results['success'] )
          {
            this.data.status_name = 'pending';
            this.data.status = 1;
          }
        });

  }

  back_completed()
  {
    const id = +this.id;
    if(!this.id) return;
    this.apiService.getData('services/status/update/'+ id ,{param:'back_completed'})
      .subscribe(results =>
        {

          if(results['success'] )
          {
            this.data.status_name = 'q_completed';
            this.data.status = -11;
          }
        });

  }


  cancel()
  {
    if(confirm(this.translate.instant('Cancel Operation?')))
    {
      const id = +this.id;
      if(!this.id) return;
      this.apiService.getData('services/service/remove/'+ id )
        .subscribe(results =>
          {
            if(results['success'] == "true")
            {
              this.data.status_name = 'cancelled';
              this.data.status = 9;
            }
          });

    }
  }


  hold()
  {
    if(confirm(this.translate.instant('Place on hold')))
    {
      const id = +this.id;
      if(!this.id) return;
      this.apiService.getData('services/status/update/'+ id ,{param:'on_hold'})
        .subscribe(results =>
          {
            if(results['success'])
            {
              this.data.status_name = 'onhold';
              this.data.status = 7;
            }
          });

    }
  }


  getOperation(): void {
    this.loaded = false;
    const id = +this.id;
    if(!this.id) return;
    this.apiService.getData('services/service_detail/view/'+ id )
      .subscribe(detailed_operation =>
        {

          // for(let contract of detailed_operation.data.contracts)
          // {
          //   for(let stop of contract.stops)
          //   {
          //     stop.service_stop_id_list.push({value:0,viewValue:'New Stop'});
          //   }
          // }

          this.data = detailed_operation.data;
          this.privilegios = detailed_operation.privilegios;




          this.loaded = true;

        });
  }
  // makeEditable( element)
  // {
  //   element.editable = true;
  //   element.name_bk = element.name;
  //   element.description_bk = element.description;
  //   element.quantity_bk = element.quantity;
  //   element.weight_bk = element.weight;
  //   element.units_bk = element.units;
  //   element.length_bk = element.length;
  //   element.width_bk = element.width;
  //   element.height_bk = element.height;
  // }
  //
  // cancelEditable( element)
  // {
  //   element.name = element.name_bk;
  //   element.description = element.description_bk;
  //   element.quantity = element.quantity_bk;
  //   element.weight = element.weight_bk;
  //   element.units = element.units_bk;
  //   element.length = element.length_bk;
  //   element.width = element.width_bk;
  //   element.height = element.height_bk;
  //
  //   element.editable = false;
  //
  // }

  getModes()
  {
    return AppConstants.modes;
  }

  editSite(id) :void
  {
    this.app.setSiteSide(id);
    this.app.getSiteSide().open();
  }

  fileCallback = ( element ): void =>
  {
  //https://tms.venkor.ca/pages/invoice/pdf/49/
    this.apiService.getBlob( 'pages/invoices/pdf/'+element.id )
      .subscribe(response =>
        {
          var blob = response;
          var fileURL = URL.createObjectURL(blob);
          var documentData = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);

          let url = 'pages/invoices/email/' + element.id;
          let filename = 'V'+ this.data.service_id +'.pdf';
          let subject = 'Invoice V'+ this.data.service_id;

          let message = ""
          let preview = ""

          let c = [];

          for(var i= 0; i <this.data.client_contact.length; i++)
          {
            if( (this.data.client_contact[i].accounting) && (this.data.client_contact[i].email) )
              c.push(this.data.client_contact[i]);
          }


          this.app.setSidenavD( documentData, "");
          this.app.getSidenav().open();
        });

  }



  getStructureValue( name, id )
  {
    if(name && id)
    {
      return AppConstants[name].filter(x => x.value == id)[0]?.viewValue;
      // if(AppConstants[name][id-1])
      //   return AppConstants[name][id-1].viewValue;
      // else
      //   console.log('error getStructureValue name:' + name + ' id:'+id );
    }
  //  else
    console.log('error getStructureValue name:' + name + ' id:'+id );
  }

  fileCallbackDocuments = ( element ): void =>
  {
      this.apiService.getBlob( 'documents/contract_documents/'+element.id )
      .subscribe(response =>
        {
          var blob = response;
          var fileURL = URL.createObjectURL(blob);
          var documentData = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);

          let url = null;//'pages/invoices/email/' + element.id;
          let filename = null;//'filename';
          let subject = null;//'Invoice V';

          let message = this.translate.instant('dialogs.lc_email');
          let preview = ""

          let c = [];

          //this.app.setSidenav(this.contract, c, subject ,message, documentData, filename, url);
          this.app.setSidenavD(documentData,"");
          this.app.getSidenav().open();
        });

  }


}
