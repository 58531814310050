import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';



import { TrackingComponent } from './access/tracking.component';
import { BolComponent } from './access/bol.component';


import { ServicesComponent } from './operations/services.component';
import { OperationsComponent } from './operations/operations.component';
import { QuotesComponent } from './operations/quotes.component';
import { LedgersComponent } from './ledgers/ledgers.component';
import { FacturasComponent } from './invoices/facturas.component';
import { FactoringsComponent } from './invoices/factorings.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { CommissionsComponent } from './commissions/commissions.component';
import { PaymentsComponent } from './payments/payments.component';
import { LedgerComponent } from './ledger/ledger.component';
import { NewquoteComponent } from './operations/newquote.component';
import { NewoperationComponent } from './operations/newoperation.component';
import { LoginComponent } from './login/login.component';
import { NewVendorComponent } from './newvendor/newvendor.component';
import { OperationComponent } from './operation/operation.component';
import { GifiComponent } from './statements/gifi.component';
import { BalanceComponent } from './statements/balance.component';
import { VoucherComponent } from './statements/voucher.component';
import { RevisionComponent } from './statements/revision.component';
import { GeneralledgerComponent } from './statements/generalledger.component';
import { JournalComponent } from './statements/journal.component';
import { ProfitandlossComponent } from './statements/profitandloss.component';
import { AccountsComponent } from './accounts/accounts.component';
import { VendorsComponent } from './vendors/vendors.component';
import { VendorComponent } from './vendor/vendor.component';


import { ProspectsComponent } from './prospects/prospects.component';
import { NewprospectComponent } from './prospects/newprospect.component';



import { ClientsComponent } from './clients/clients.component';
import { ClientComponent } from './client/client.component';
import { ContractsComponent } from './contracts/contracts.component';
import { ContractComponent } from './contract/contract.component';

import { GuestGuardService } from './services/guest-guard.service';
import { GuestService } from './services/guest.service';
import { AuthGuardService } from './services/auth-guard.service';


// const routes: Routes = [
//   // {
//   //   path: '',
//   //   loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
//   // },
//   // {
//   //   path: 'login',
//   //   loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
//   // }
// ];

const routes: Routes = [
  { path: '', component: OperationsComponent, canActivate: [AuthGuardService] },
  { path: 'login', component: LoginComponent, canActivate: [ GuestGuardService ] },
  { path: 'newvendor', component: NewVendorComponent, canActivate: [ GuestService ] },
  { path: 'tracking/:id', component: TrackingComponent, canActivate: [ GuestService ] },
  //{ path: 'detail/:id', component: HeroDetailComponent },
  { path: 'operations', component: OperationsComponent, canActivate: [AuthGuardService] },
  { path: 'services', component: ServicesComponent, canActivate: [AuthGuardService] },
  { path: 'clients', component: ClientsComponent, canActivate: [AuthGuardService] },
  { path: 'vendors', component: VendorsComponent, canActivate: [AuthGuardService] },
  { path: 'vendor_detail/:id', component: VendorComponent, canActivate: [AuthGuardService] },
  { path: 'quotes', component: QuotesComponent, canActivate: [AuthGuardService] },
  { path: 'ledgers', component: LedgersComponent, canActivate: [AuthGuardService] },
  { path: 'ledger_detail/:id', component: LedgerComponent, canActivate: [AuthGuardService] },
  { path: 'accounting/invoices', component: InvoicesComponent, canActivate: [AuthGuardService] },
  { path: 'accounting/factorings', component: FactoringsComponent, canActivate: [AuthGuardService] },
  { path: 'accounting/payments', component: PaymentsComponent, canActivate: [AuthGuardService] },
  { path: 'accounting/facturas', component: FacturasComponent, canActivate: [AuthGuardService] },
  { path: 'statements/balance', component: BalanceComponent, canActivate: [AuthGuardService] },
  { path: 'statements/gifi', component: GifiComponent, canActivate: [AuthGuardService] },
  { path: 'statements/journal', component: JournalComponent, canActivate: [AuthGuardService] },
  { path: 'statements/profitandloss', component: ProfitandlossComponent, canActivate: [AuthGuardService] },
  { path: 'statements/generalledger', component: GeneralledgerComponent, canActivate: [AuthGuardService] },
  { path: 'accounting/voucher/:id', component: VoucherComponent, canActivate: [AuthGuardService] },
  { path: 'statements/revision', component: RevisionComponent, canActivate: [AuthGuardService] },
  { path: 'accounting/accounts', component: AccountsComponent, canActivate: [AuthGuardService] },
  { path: 'accounting/commissions', component: CommissionsComponent, canActivate: [AuthGuardService] },
  { path: 'newquote', component: NewquoteComponent, canActivate: [AuthGuardService] },
  { path: 'newoperation', component: NewoperationComponent, canActivate: [AuthGuardService] },
  { path: 'contracts', component: ContractsComponent, canActivate: [AuthGuardService] },
  { path: 'operation_detail/:id', component: OperationComponent, canActivate: [AuthGuardService] },
  { path: 'client_detail/:id', component: ClientComponent, canActivate: [AuthGuardService] },
  { path: 'contract_detail/:id', component: ContractComponent, canActivate: [AuthGuardService] },
  { path: 'prospects', component: ProspectsComponent, canActivate: [AuthGuardService] },
  //{ path: 'prospect_detail/:id', component: ProspectComponent, canActivate: [AuthGuardService] },
  { path: 'access/tracking/:id', component: TrackingComponent, canActivate: [GuestGuardService] },
  { path: 'access/bol/:id', component: BolComponent, canActivate: [GuestGuardService] },
  { path: 'newprospect', component: NewprospectComponent, canActivate: [AuthGuardService] }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
