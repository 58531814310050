import {HttpClient} from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef, Optional, Inject} from '@angular/core';
import { ApiService } from '../services/api.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { AppConstants } from '../structures/appconstants';
import { Observable, of, Subscription, empty } from 'rxjs';
import { tap, startWith, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { asyncValidator } from '../helpers/custom-async-validator';
import { AngularMaterialModule } from '../angular-material.module';


@Component({
  selector: 'app-newcarrierdialog',
  templateUrl: './editcarrierdialog.component.html',
  styleUrls: ['./vendordialog.component.css']
})
export class EditcarrierdialogComponent implements OnInit {

  public name: string;
  filteredOptions: Observable<any[]>;
  formSubmitted = false;
  // Variables
  loading: boolean;
  errors: boolean;
  siteFormGroup: FormGroup;


  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<EditcarrierdialogComponent >,
    private apiService : ApiService
  )
  {


    this.siteFormGroup = fb.group({
      id: [
        data.id,
        [Validators.required]
      ],
      name: [
        data.name,
        [Validators.required]
      ],
      phone: [
        data.phone,
      ],
      emails: [
        data.emails,
      ],
    });
  }

  submit()
  {
    this.formSubmitted = true;

    let data = {  ...this.siteFormGroup.value };

//    data['zip_id'] = data['zip'].id;

    this.apiService.getData('carriers/carrier_detail/store', data ).subscribe(res =>
    {
        if ( res['success'] )
        {
            //this.router.navigate(['/operation_detail/' + res['id']  ]);
            //let results = [ ...this.results, ...res.ids];
            this.dialogRef.close(res);
        }


    });
   }

    cancel()
    {
      this.dialogRef.close();
    }




  
  ngOnInit(): void { }


}
