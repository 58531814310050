<mat-card class="example-card">
  <mat-card-header>
    <mat-card-title>Revision</mat-card-title>
    <mat-card-subtitle>Options</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="filterFormGroup" (submit)="doChecks()">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fx-layout-align="end">
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fx-layout-align="end">
        <button mat-raised-button color="primary" type="submit">Do Checks</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<mat-card class="example-card" *ngIf="loaded">

  <mat-card-subtitle>Unbalanced</mat-card-subtitle>
  <mat-card-content>
      <ul>
        <li *ngFor="let item of data.unbalanced">
        <a (click)="openVoucher(item)">{{item}}</a>
        </li>
      </ul>
  </mat-card-content>

  <mat-card-subtitle>Zero</mat-card-subtitle>
  <mat-card-content>
      <ul>
        <li *ngFor="let item of data.zero">
        <a (click)="openVoucher(item)">{{item}}</a>
        </li>
      </ul>
  </mat-card-content>

  <mat-card-subtitle>Pass</mat-card-subtitle>
  <mat-card-content>
      <ul>
        <li *ngFor="let item of data.ok">
          <a (click)="openVoucher(item)">{{item}}</a>
        </li>
      </ul>
  </mat-card-content>


</mat-card>
