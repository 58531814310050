import {Component, Input,ElementRef, ViewChild, OnInit, AfterViewInit} from '@angular/core';
import { ApiService } from '../services/api.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { AppConstants } from '../structures/appconstants';

@Component({
  selector: 'app-journal',
  templateUrl: './journal.component.html',
  styleUrls: ['./journal.component.css']
})

export class JournalComponent implements OnInit
{
  test;
  loaded:boolean = false;
  data;
  filterFormGroup :FormGroup;
  filter = {};

  ngOnInit()
  {

  }

  constructor( private apiService: ApiService, fb: FormBuilder,public datepipe: DatePipe)
  {
    this.filterFormGroup = fb.group({
      date_from: [
        '',
        []
      ],
      date_to: [
        '',
        []
      ]
    });

  }

  public templateToFormData( object ) {
    console.log(object);
    const formData = new FormData();
    for ( const key of Object.keys(object) )
    {
      if (Array.isArray(object[key]))
      {
        for ( let a of object[key] )
          formData.append(key+'[]', a);
      }
      else
      {
        if( object[key]=== null)
          formData.append(key, '');
        else if( object[key]=== false)
          formData.append(key, '0');
        else if( (key == 'date_from') || (key == 'date_to'))
        {
          const  latest_date =this.datepipe.transform(object[key], 'yyyy-MM-dd');
          formData.append(key, latest_date);
        }
        else
          formData.append(key, object[key]);
      }
    }
    return formData;
  }


  getJournal()
  {
    const formData = this.templateToFormData(this.filterFormGroup.value);
    this.apiService.getData( 'statements/journal', formData)
      .subscribe(param =>
        {
          this.loaded = true;
          this.data = param;
        });

  }
  totalDebit(voucher)
  {
    let total = 0;
    for( let entry of voucher.entries)
    {
      total+= +(entry.debit??0);
    }
    return total;
  }
  totalCredit(voucher)
  {
    let total = 0;
    for( let entry of voucher.entries)
    {
      total+= +(entry.credit??0);
    }
    return total;
  }
}
