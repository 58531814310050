import { Injectable, Injector } from '@angular/core';
import {
 HttpEvent,
 HttpInterceptor,
 HttpHandler,
 HttpRequest,
 HttpResponse,
 HttpErrorResponse,
 HttpClient,
 HttpHeaders
} from '@angular/common/http';

import {MatSnackBar, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';



import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })

export class HttpErrorInterceptor implements HttpInterceptor {



  verticalPosition: MatSnackBarVerticalPosition = 'top';

 intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


   return next.handle(request)

     .pipe(
       catchError((error: HttpErrorResponse) => {
         console.log('error has been catched');
         let errorMessage = '';

         // if( (error.error instanceof ErrorEvent) || (error.error.message)) {
         //   // client-side error
         //   errorMessage = `Error: ${error.error.message}`;
         // } else {
         //   // server-side error
         //   errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
         //   console.log(error);
         // }
         //
         // // var error_msg = this.translate.instant('interface.error') +'</br>'+
         // //   error.message;


         if(error.error.message == 'Unauthenticated.')
         {
           localStorage.removeItem('access_token');
         }

        // return true;
        //console.log(error);
        errorMessage = error.error.message;

        this._snackBar.open(errorMessage , 'OK', {
          //duration: 2000,
          verticalPosition: this.verticalPosition
        });
        return throwError(errorMessage);

       })

     )

 }

 constructor(
   private injector: Injector,
   public _snackBar: MatSnackBar,
   public translate: TranslateService
 ) {

 }

}
