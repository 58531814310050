<mat-card class="example-card">
  <mat-card-header>
    <mat-card-title>General Ledger</mat-card-title>
    <mat-card-subtitle>Options</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="filterFormGroup" (submit)="getGeneralledger()">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fx-layout-align="end">
        <mat-form-field>
          <mat-label>Up to date</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate placeholder="Start date" formControlName="date_from">
            <input matEndDate placeholder="End date" formControlName="date_to">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>



      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fx-layout-align="end">
        <button mat-raised-button color="primary" type="submit">Generate Report</button>
      </div>

      <button mat-raised-button color="accent"(click)="generatePDF()">Print Report
        <mat-icon aria-hidden="false" aria-label="">print</mat-icon>
      </button>
    </form>
  </mat-card-content>
</mat-card>

<mat-card class="example-card" *ngIf="loaded" >
  <div  #print_content>



  <table data-pdfmake="{&quot;layout&quot;:&quot;noBorders&quot;}">
    <tr><td colspan="6" class='text_center'>
        Venkor Inc
    </td></tr><tr><td colspan="6" class='text_center'>
        GENERAL LEDGER
    </td></tr><tr><td colspan="6" class='text_center'>
        {{data.date_from | date:'MMM d, y'}} - {{data.date_to | date:'MMM d, y'}}
    </td></tr>

    <tr>
      <th>DATE</th><th>TYPE</th><th>MEMO/DESCRIPTION</th><th>SPLIT</th><th>AMOUNT</th><th>BALANCE</th>
    </tr>
    <ng-container *ngFor="let account of data.accounts">
      <ng-container *ngIf="account.entries.length">
        <tr><td colspan="5"><strong>{{account.name}}</strong></td>
          <td><strong>{{starting_balance(account) | number : '1.2-2'}}</strong></td>
        </tr>
          <ng-container *ngFor="let entry of account.entries; let first = first;">
            <tr>
                <td style="white-space: nowrap;padding-left:5px;">
                <a href="/accounting/voucher/{{entry.voucher_id}}">{{entry.date}}</a>
                </td>
                <td>{{entry.type_name}}</td>
                <td style="width:20px;">{{entry.description}}</td>
                <td>{{entry.split}}</td>
                <td>{{entry.amount | number :'1.2-2'}}</td>
                <td>{{entry.balance | number :'1.2-2'}}</td>
          </tr>
        </ng-container>
          <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td><strong>{{account.total | number  : '1.2-2'}}</strong></td>
              <td><strong></strong></td>
          </tr>
      </ng-container>
    </ng-container>

  </table>
  </div>
  </mat-card>
