import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { DetailedOperation } from '../structures/detailed_operation';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppConstants } from '../structures/appconstants';
import { AppComponent } from '../app.component';
import {CdkDragDrop, moveItemInArray, transferArrayItem, CdkDropList} from '@angular/cdk/drag-drop';
//import { MatTable, MatTableDataSource } from '@angular/material';
import { MatTableDataSource } from '@angular/material/table';
import {TranslateService} from '@ngx-translate/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';


@Component({
  selector: 'app-ledger',
  templateUrl: './ledger.component.html',
  styleUrls: ['./ledger.component.css']
})
export class LedgerComponent implements OnInit {

  saldo: number;
  data : any;
  loaded: boolean;
  is_editting_details :boolean;
  descriptionFormGroup: FormGroup;

  displayedColumns: string[] = ['date','debit','credit','saldo', 'details'];
  // dataSourceStops = ELEMENT_DATA;


  constructor(
    private apiService: ApiService,
    private sanitizer: DomSanitizer,
    private app: AppComponent,
    private route: ActivatedRoute,
    private location: Location,
    public translate: TranslateService,
    fb: FormBuilder,

  ) {


    this.is_editting_details = false;
    this.loaded = false;

  }

  rowClick(row, $event)
  {

  }
  ngOnInit(): void {
    this.getLedger();
    this.saldo = 0;
  }


  getLedger(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.apiService.getData('ledgers/ledger_detail/view/'+ id )
      .subscribe(details =>
        {
          var saldo :number = 0;
          for(let i=0;i<details.data.entries.length;i++)
          {
            if( details.data.entries[i].entry_type == 'debit')
              saldo += parseFloat(details.data.entries[i].amount);
            else
              saldo -= parseFloat(details.data.entries[i].amount);

            details.data.entries[i].saldo = saldo;
          }
          this.data = details.data;
          this.loaded = true;

        });
  }
}
