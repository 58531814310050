import { Injectable , ViewChild} from '@angular/core';



import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from "html-to-pdfmake";

@Injectable({
  providedIn: 'root'
})
export class PdfService {

//  @ViewChild('sidenav') sidenav: MatSidenav;

  constructor( ) {
    this.visible = false;



  }

  visible:boolean;



  showPdf()
  {
    //this.app.showDocumentSlider();
    //this.visible=true;
    //sidenav.open();


  }
  createPdf()
  {
    let docDefinition = {
        header: 'C#Corner PDF Header',
        content: htmlToPdfmake("<div> <h1>My title</h1><p>This is a sentence with a <strong>bold word</strong>, <em>one in italic</em>,and <u>one with underline</u>. And finally <a href>a link</a>.</p></div>")
      };

      //pdfMake.createPdf(dd).open();
      return pdfMake.createPdf(docDefinition);
  }

}
