import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { DetailedOperation } from '../structures/detailed_operation';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppConstants } from '../structures/appconstants';
import { AppComponent } from '../app.component';
import {CdkDragDrop, moveItemInArray, transferArrayItem, CdkDropList} from '@angular/cdk/drag-drop';
import { tap, startWith, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import {Observable} from 'rxjs';
//import { MatTable, MatTableDataSource } from '@angular/material';
import { MatTableDataSource } from '@angular/material/table';
import {TranslateService} from '@ngx-translate/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';


@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.css']
})
export class VendorComponent implements OnInit {

  data : any;
  loaded: boolean;
  is_editting_details :boolean;
  descriptionFormGroup: FormGroup;
  filteredOptions: Observable<any[]>;
  constructor(

    private apiService: ApiService,
    private sanitizer: DomSanitizer,
    private app: AppComponent,
    private route: ActivatedRoute,
    private location: Location,
    public translate: TranslateService,
    fb: FormBuilder,

  ) {




    this.loaded = false;
    this.is_editting_details = false;
    this.descriptionFormGroup = fb.group({
      name: [
        '',
        []
      ],
      address: [
        '',
        []
      ],
      zip: ['', [Validators.required]]
    });

    this.filteredOptions = this.descriptionFormGroup.get("zip").valueChanges.pipe(
       startWith(''),
       debounceTime(400),
       distinctUntilChanged(),
       switchMap(val  =>
          this.filter(val || this.data.detail.site.zip.city + ', '+  this.data.detail.site.zip.state + ', '+  this.data.detail.site.zip.code))
    );
  }

  ngOnInit(): void {
    this.getVendor();
  }

  filter(val :string ): Observable<any[]> {
    // call the service which makes the http-request
    return this.apiService.getData('shared/zip',{query: val})
      .pipe(
      //   map(response => response.filter(option => {
      //     return option.name.toLowerCase().indexOf(val.toLowerCase()) === 0
    //     }))
      )
  }




  getVendor(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.apiService.getData('vendors/vendor_detail/view/'+ id )
      .subscribe(detailed_operation =>
        {
          this.data = detailed_operation.data;
          this.descriptionFormGroup.get('name').setValue(this.data.name);
          this.descriptionFormGroup.get('address').setValue(this.data.address);
          //this.descriptionFormGroup.get('zip').setValue(this.data.detail.city +', ' + this.data.detail.state + ', '+ this.data.detail.zip);
          this.descriptionFormGroup.get('zip').setValue(this.data.zip_code);

          //this.filter = this.data.detail.site.zip.city + ', '+ this.data.detail.site.zip.state + ', '+ this.data.detail.site.zip.code;
          this.loaded = true;
        });
  }
  displayFn( option )
  {
    console.log(option);
    return option.city + ', ' + option.state + ', ' + option.code;
  }
  resetDescription(){
      this.descriptionFormGroup.reset( this.data.detail );
      this.is_editting_details = false;
  }

  saveDescription()
  {
    const id = +this.route.snapshot.paramMap.get('id');
    const data = this.descriptionFormGroup.value;
    data['zip'] = data.zip.id;
    this.apiService.getData('vendors/vendor_detail/modify/'+ id, data ).subscribe(detailed_operation =>
    {
        for(var i in detailed_operation){
          this.data.detail[i]= detailed_operation[i];
        }
        this.is_editting_details = false;
    });
    console.log(this.descriptionFormGroup.value);
  }
}
