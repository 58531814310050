import { MediaMatcher} from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, OnDestroy, AfterViewInit,ViewChild, ElementRef, HostListener } from '@angular/core';
import { MatSliderModule } from '@angular/material/slider';
import {MatSidenav} from '@angular/material/sidenav';


import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import { AppService } from './services/app.service';
import { PdfService } from './services/pdf.service';
import { AuthService } from './services/auth.service';
import { ApiService } from './services/api.service';
import { EventComponent } from './event/event.component';
import {Router} from '@angular/router';
import { AppConstants } from './structures/appconstants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent  implements OnInit, OnDestroy{
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('sitenav') sitenav: MatSidenav;

  site_id;// = 3;
  loaded = false;
  title = 'tms2';
  basic:any = null;
  logedIn = false;
  mobileQuery: MediaQueryList;
   private _mobileQueryListener: () => void;

  constructor(
    //slideNav
    private router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public appService : AppService,
    public pdfService : PdfService,
    public translate: TranslateService,
    private apiService: ApiService,
    private authService: AuthService
  ) {
    if(localStorage.getItem('access_token'))
      this.logedIn = true;
    //slideNav
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    //translate
    translate.addLangs(['en', 'sp']);
    translate.setDefaultLang('en');
    //const browserLang = translate.getBrowserLang();
    //translate.use(browserLang.match(/en|sp/) ? browserLang : 'sp');

  }


  event =
  {
    contacts :null,
    subject:null,
    message:null,
    tryDoctype:null,
    url:null,
    filename:null,
    sidenav:null,
    type:null,
    callback:null,
  };

  setSidenav( callback ,contacts, subject:string, message:string, tryDoctype, filename, url)
  {
    this.event = {
      contacts : contacts,
      subject: subject,
      message : message,
      tryDoctype: tryDoctype,
      url: url,
      filename: filename,
      sidenav : this.sidenav,
      type: 'email',
      callback: callback
    };
    //this.event.vendor_contacts = detail.vendor_contacts;
  }

  setSidenavD(tryDoctype, message)
  {
    this.event = {
      contacts : null,
      subject: null,
      message : message,
      tryDoctype: tryDoctype,
      url: null,
      filename: null,
      sidenav : this.sidenav,
      type: 'document',
      callback: null
    };

  }
  getSidenav() {
    return this.sidenav;
  }


  setSideBlob( src )
  {
    this.sideBlob = src;
  }

  sideBlob: any;
//STICKY


  getSiteSide() {
    return this.sitenav;
  }
  setSiteSide(id) {
    this.site_id = id;
  }

  @ViewChild('stickyMenu',{read: ElementRef}) menuElement: ElementRef;


  sticky: boolean = false;
  elementPosition: any;

  ngAfterViewInit(){
//    this.elementPosition = this.menuElement.nativeElement.offsetTop;
  }

  ngOnInit() {
      //  window.addEventListener('scroll', this.scroll, true); //third parameter
      if(this.logedIn)
        this.loadBasic();
      else
        this.loaded = true;


  }

  loadBasic(): void {
    this.apiService.getData('basic/basic').subscribe(basic => {
      AppConstants['owner_id'] = basic.sales_team;

      AppConstants['account_manager_id'] = basic.account_managers;
      AppConstants['carrier_coordinator_id'] = basic.carrier_coordinators;
      this.basic = basic;
      this.loaded = true;
    });
  }

  getBasic() {
    return this.basic;
  }

  ngOnDestroy() {
      //window.removeEventListener('scroll', this.scroll, true);
       //window.addEventListener('scroll', this.scrolling, true)
      this.mobileQuery.removeListener(this._mobileQueryListener);
  }




  // @HostListener('window:scroll', ['$event'])
  //     handleScroll(){

  // scrolling=(s)=>{
  //
  //     //console.log(s);
  //         const windowScroll = s.target.scrollTop;//window.pageYOffset;
  //         if(!this.sticky)
  //           this.elementPosition = this.menuElement.nativeElement.offsetTop;
  //       //  console.log( windowScroll + ' ' + this.elementPosition)
  //         if(windowScroll >= this.elementPosition){
  //           this.sticky = true;
  //     //      console.log('sticky true');
  //         } else {
  //         this.sticky = false;
  //     //    console.log('sticky false');
  //         }
  //
  //
  //
  //     }
  //
  //


  closeSides()
  {
    this.sidenav.close();
    this.sitenav.close();
  }
  setLoggedIn()
  {
    this.loaded = false;
    this.logedIn = true;
    this.loadBasic();

  }


  newService()
  {
    this.router.navigate(['/newoperation']);
  }

  newQuote()
  {
    this.router.navigate(['/newquote']);
  }

  services()
  {
    this.router.navigate(['/operations']);
  }

  quotes()
  {
    this.router.navigate(['/quotes']);
  }


  newProspect()
  {
    this.router.navigate(['/newprospect']);
  }

  prospects()
  {
    this.router.navigate(['/prospects']);
  }

  factoring()
  {
    this.router.navigate(['/accounting/factorings']);
  }

  invoices()
  {
    this.router.navigate(['/accounting/invoices']);
  }


  logout(): void {
    this.authService.logout()
      .subscribe(() => {
        localStorage.removeItem('access_token');
        this.logedIn=false;
        this.router.navigate(['/login']);
      });
  }

  login(): void {
      this.router.navigate(['/login']);
  }

}
