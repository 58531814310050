<mat-card class="example-card" *ngIf="loaded">




  <div style="text-align: center;">Voucher</div>
  <div style="text-align: center;">As of {{data.date | date:'MMM d, y'}}</div>


  <app-editable-form
         [headers]="[{name:'date',type:'date'},{name:'bank_date',type:'date'},{name:'bank_order'},{name:'description'}]"
         [data]='data'
         updateUrl="{{'vouchers/voucher_detail/update/'+data.id}}">
  </app-editable-form>





  <app-editable-table [headers]="[{name:'account', type:'autocomplete', url:'accounts/filter_list'},{name:'debit'},{name:'credit'}]"
  [data]='data.entries'
  updateUrl="{{'vouchers/entry/update/'+data.id}}"
  newUrl="{{'vouchers/entry/store/'+data.id}}"
  removeUrl="{{'vouchers/entry/remove/'+data.id}}"
  >
  </app-editable-table>








  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fx-layout-align="end">
    <button mat-raised-button color="primary" (click)="deleteVoucher()">Delete</button>
  </div>


</mat-card>
