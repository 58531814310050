import {Component, Input,ElementRef, ViewChild, OnInit, AfterViewInit} from '@angular/core';
import { ApiService } from '../services/api.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { AppConstants } from '../structures/appconstants';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.css']
})

export class AccountsComponent implements OnInit
{
  data;

  ngOnInit(): void
  {
    this.getAccounts();
  }

  constructor( private apiService: ApiService, fb: FormBuilder,public datepipe: DatePipe)
  {

  }

  getAccounts()
  {
    this.apiService.getData( 'accounts/list', {})
      .subscribe(param =>
        {
          this.data = param.data;
        });

  }

}
