<mat-card>
  <mat-card class="mat_card_contract">
    <mat-card-title fxLayout>
      <span> {{contract.vendor_name}}
      </span>
      <button  mat-icon-button class="title_button" matTooltip="Detail"  (click)="editSite(contract.site_id)">
        <mat-icon>web</mat-icon>
      </button>

      <span class="spacer"></span>
      <span>
        {{contract.costs}}
      </span>
      <span class="spacer"></span>


      <span>{{'interface.' + contract.status_name | translate}}</span>
    </mat-card-title>
  </mat-card>

  <mat-card-content>
      <div class='budgets-title'>
        Budgets
      </div>
      <div class="scrollable">
      <app-editable-table #budgetsContractEditableTable
      [headers]="[ {name:'invoice',type:'checkMarkEdit',callback:this.budgetSelected},{name:'expense_type_id',type:'selectList',default:1}, {name:'unit_price',fieldChange:true},{name:'unit_type_id',type:'selectList',default:1},{name:'quantity',fieldChange:true},{name:'rate', readonly:true}]"
      [data]='contract.contract_budgets'
      updateUrl="{{'contracts/budgets/update/'+contract.id}}"
      newUrl="{{'contracts/budgets/store/'+contract.id}}"
      removeUrl="{{'contracts/budgets/remove/'+contract.id}}"
      blockEdit="invoice"
      [fieldChangeCallback]="lineChange"
      >
      </app-editable-table>
      </div>
      <div class='budgets-title'>
        Invoices
      </div>
      <div class="scrollable">
      <app-editable-table #invoicesContractEditableTableId
       [headers]="[{name:'payment',type:'checkMarkEdit',callback:this.invoiceSelected},{name:'factoring',type:'autocomplete',url:'accounting/factoring/filter_list'},{name:'date',required:true,type:'date'},{name:'invoice_number',required:true},{name:'sub_total_books',required:true,readonly:true},{name:'hst_books',required:true,readonly:true},{name:'total_books',required:true,readonly:true},{name:'file',type:'file',icon:'insert_drive_file',callback:this.fileCallback,required:true}]"
       [data]='contract.invoices'
       updateUrl="{{'contracts/invoices/update/'+contract.id}}"
       newUrl="{{'contracts/invoices/store/'+contract.id}}"
       removeUrl="{{'contracts/invoices/remove/'+contract.id}}"></app-editable-table>
       </div>
       <div class='budgets-title'>
        Payments
       </div>

       <div class="scrollable">
      <app-editable-table #paymentsContractEditableTable
      [headers]="[ {name:'date',type:'date'},{name:'payment_type_id',type:'selectList',default:1}, {name:'source_id',type:'autocomplete',url:'accounts/filter_list',default:{id: 68, name: 'RBC Chequing CAD'}},{name:'nro'},{name:'total_books'}]"
      [data]='contract.payments'
      updateUrl="{{'contracts/payments/update/'+contract.id}}"
      newUrl="{{'contracts/payments/store/'+contract.id}}"
      removeUrl="{{'contracts/payments/remove/'+contract.id}}"
      blockEdit="invoice"
      [fieldChangeCallback]="lineChange"
      >
      </app-editable-table>
      </div>
  </mat-card-content>
</mat-card>
