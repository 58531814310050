<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">

  <mat-card class="box">
    <mat-card-header>
      <mat-card-title>Log in</mat-card-title>
    </mat-card-header>

    <form class="example-form" [formGroup]="form" (submit)="login()">
      <mat-card-content>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Username" formControlName="email">
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input matInput placeholder="Password" type="password" formControlName="password">
        </mat-form-field>
      </mat-card-content>
      <button mat-stroked-button color="accent" class="btn-block" type="submit" [disabled]="loading">Log in</button>
    </form>
  </mat-card>

</div>
