<div *ngIf="loaded" fxLayout="column" class="test">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fx-layout-align="end">
    <div fxFlex>
      <h1>{{ 'interface.service' | translate | uppercase }} {{ data.service_id }}</h1>
    </div>
    <div fxFlex >
      <h1>{{data.client_name}}</h1>
      <h1>{{data.client_ref}}</h1>
      <h3>
        <ng-container *ngFor="let stop of data.stops; last as isLast">
          {{stop.city_name}}, {{stop.state}}
          <span *ngIf="!isLast"> | </span>
        </ng-container>
      </h3>
    </div>
  </div>















  <mat-toolbar>
  <div *ngIf="data.status_name=='booked'">

    <button  *ngIf="privilegios.eliminar" mat-icon-button aria-label="cancel" matTooltip="{{'interface.cancel' | translate}}" >
      <mat-icon>cancel</mat-icon>
    </button>

    <button  *ngIf="privilegios.modificar" mat-icon-button  aria-label="dispatch" matTooltip="{{'interface.dispatch' | translate}}">
      <mat-icon>send</mat-icon>
    </button>
  </div>

    <span class="spacer"></span>
    <span>{{'interface.' + data.status_name | translate}}</span>

  </mat-toolbar>


    <mat-tab-group mat-align-tabs="start">
      <mat-tab label="{{ 'interface.contracts' | translate }}">
        <mat-card *ngFor="let contract of data.contracts">




              <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fx-layout-align="end">
                <div fxFlex>
                  <div><strong>{{ contract.vendor_name | translate | uppercase }}</strong></div>
                  <div>{{ contract.driver_name | translate}} | {{ contract.placa | translate}}</div>

                </div>
                <div fxFlex >
                  <div>{{ 'interface.contract' | translate | uppercase }} {{ contract.contract_id }}</div>
                  <div class='grayed'>
                    <ng-container *ngFor="let stop of contract.stops; last as isLast">
                      {{stop.city_name}}, {{stop.state}}
                      <span *ngIf="!isLast"> | </span>
                    </ng-container>
                  </div>
                </div>

                <div fxFlex >

                    <ng-container *ngFor="let budget of contract.contract_budgets; last as isLast">
                      {{budget.rate}}
                      <span *ngIf="!isLast"> | </span>
                    </ng-container>

                </div>

              </div>

              <mat-card-content>
              <!-- CONTRACT TABS   -->
              <!-- <app-contracttoolstabs [views]="data.views" [contract]="contract" [documents]="data.views.contracts.models.documents[contract.id] "></app-contracttoolstabs> -->
              <app-contracttoolstabs [contract]="contract"  [service_id]="data.service_id" [views]="data.views"></app-contracttoolstabs>
              <!-- END CONTRACT TABS -->
              </mat-card-content>


        </mat-card>
        <button mat-raised-button color="primary">{{'interface.createLeg' | translate }}</button>

            <!-- <mat-icon matListIcon>truck</mat-icon>
            <h3 matLine> {{contract.sub_unit}} | {{contract.vendor_name }} | {{contract.driver_name }} </h3>
            <p matLine>
              <span> {{contract.id}} </span>
              <span class="demo-2"> -- {{contract.id}} </span>
            </p> -->


      </mat-tab>


      <mat-tab label="POs">





        <app-editable-table [headers]="[ 'name', 'description', 'number','po_units_id','weight','po_weight_id','length','width','height']" [data]='data.pos' [required]="['number','po_units_id','weight','po_weight_id']" [number]="['number','po_units_id','weight','po_weight_id','length','width','height']" [selectList]="['po_units_id','po_weight_id']" updateUrl="{{'services/pos/update/'+data.id}}" newUrl="{{'services/pos/store/'+data.id}}" removeUrl="{{'services/pos/remove/'+data.id}}">
        </app-editable-table>
      </mat-tab>


      <mat-tab label="{{ 'interface.service' | translate }}">

                <!-- Service Data -->

              <dl *ngIf="!is_editting_details; else details_edit" class="editable_form">
                <div class="edit_icon" (click)="is_editting_details=true">
                  <mat-icon appearance="outline">edit</mat-icon>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
                  <div fxFlex>
                    <dt>{{ 'interface.client_ref' | translate }}</dt>
                    <dd>{{data.client_ref}}</dd>

                    <dt>{{ 'interface.description' | translate }}</dt>
                    <dd>{{data.description}}</dd>
                  </div>
                  <div fxFlex>
                    <dt>{{ 'interface.temperature' | translate }}</dt>
                    <dd>{{data.temperature?data.temperature:('interface.not_set' | translate) }}</dd>

                    <dt>{{ 'interface.mode' | translate }}</dt>
                    <dd>{{data.mode_name}}</dd>
                  </div>

                </div>

              </dl>


              <ng-template #details_edit>
              <dl>
              <form [formGroup]="descriptionFormGroup">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">

                  <div fxFlex fxLayout="column">
                  <mat-form-field >
                    <mat-label >{{ 'interface.client_ref' | translate }}</mat-label>
                    <input matInput formControlName="client_ref" [ngModel]="data.client_ref">
                  </mat-form-field>

                  <mat-form-field fxFlex>
                    <mat-label >{{ 'interface.description' | translate }}</mat-label>
                    <input matInput formControlName="description" [ngModel]="data.description">
                  </mat-form-field>
                </div>
                <div fxFlex fxLayout="column">
                  <mat-form-field fxFlex>
                    <mat-label >{{ 'interface.temperature' | translate }}</mat-label>
                    <input matInput formControlName="temperature" [ngModel]="data.temperature">
                  </mat-form-field>

                  <mat-form-field fxFlex>
                    <mat-label >{{ 'interface.mode' | translate }}</mat-label>
                    <mat-select formControlName="mode_id" [ngModel]="data.mode_id">
                      <mat-option *ngFor="let mode of getModes()" [value]="mode.value">
                        {{mode.viewValue | translate}}
                      </mat-option>
                    </mat-select>

                  </mat-form-field>



                </div>
              </div>
              <div>
                <button mat-raised-button color="primary" (click)="saveDescription()">{{'interface.save' | translate }}</button>
                <button mat-raised-button (click)="resetDescription()">{{'interface.cancel' |translate }}</button>
              </div>
              </form>
              </dl>
              </ng-template>


      </mat-tab>

      <mat-tab label="{{ 'interface.stops' | translate }}">
        <table mat-table [dataSource]="data.stops" class="mat-elevation-z8">
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="stop_type_name">
          <th mat-header-cell *matHeaderCellDef> {{'interface.type' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{'interface.' + element.stop_type_name | translate }} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="site_name">
          <th mat-header-cell *matHeaderCellDef> {{ 'interface.site' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{element.site_name}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="city_name">
          <th mat-header-cell *matHeaderCellDef> {{ 'interface.city' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{element.city_name}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef> {{ 'interface.state' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{element.state}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsStops"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsStops;"></tr>
      </table>

      </mat-tab>


      <mat-tab label="{{ 'interface.notes' | translate }}"></mat-tab>
      <mat-tab label="{{ 'interface.accounting' | translate }}"></mat-tab>

      <!-- <mat-tab *ngIf="data.views.documentos" label="{{ 'interface.documents' | translate }}"> -->

        <!-- <dl fxLayout="column">
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">

            <div fxFlex *ngFor="let documenttype of data.views.documentos.models">
              <dt>{{ documenttype.name | translate }}</dt>

              <dd>
                <button mat-stroked-button *ngFor="let doc of documenttype.documents" (click)="openBlog(doc.id,$event)">{{ doc.filename}}</button>
              </dd>
            </div>

          </div>
        </dl>

        <iframe *ngIf="tryDoctype"  width="950" height="600" [src]="tryDoctype"></iframe>
 -->

      <!-- </mat-tab> -->

    </mat-tab-group>
</div>
