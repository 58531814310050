<div *ngIf="loaded" fxLayout="column" class="test">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fx-layout-align="end">
    <div fxFlex>
      <h1>{{ 'interface.ledger' | translate | uppercase }} {{ data.name }}</h1>
    </div>
    <div fxFlex >
      <h1>h1</h1>
      <h1>h1B</h1>
      <h3>
        h3
      </h3>
    </div>
  </div>


  <mat-toolbar>
      

    <span class="spacer"></span>
    <span></span>

  </mat-toolbar>




  <table mat-table [dataSource]="data.entries" class='operations_table'>


    <ng-container matColumnDef="date" >
      <th mat-header-cell *matHeaderCellDef> {{'interface.date' | translate}} </th>
      <td mat-cell *matCellDef="let element">
        {{element.date}}
      </td>
    </ng-container>

    <ng-container matColumnDef="debit" >
      <th mat-header-cell *matHeaderCellDef> {{'interface.debit' | translate}} </th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.entry_type == 'debit'">
         {{element.amount  | number:'1.2-2' }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="credit" >
      <th mat-header-cell *matHeaderCellDef> {{'interface.credit' | translate}} </th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.entry_type == 'credit'">
         {{element.amount  | number:'1.2-2' }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="saldo" >
      <th mat-header-cell *matHeaderCellDef> {{'interface.saldo' | translate}} </th>
      <td mat-cell *matCellDef="let element">
         {{element.saldo | number:'1.2-2' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="details" >
      <th mat-header-cell *matHeaderCellDef> {{'interface.details' | translate}} </th>
      <td mat-cell *matCellDef="let element">
         {{element.details}}
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>


</div>
