<mat-card>
  <mat-card-header>
    <mat-card-subtitle>Description</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>

      <app-editable-form
       [headers]="[{name:'name', required:true},{name:'tabs_prospects_id',type:'selectList',required:true},{name:'description'},{name:'website'},{name:'goal',type:'date'},{name:'account_manager_id',type:'selectList'}]"
       [data]='data'
       newUrl="prospects/prospect/store"
       [submitOnly]="true"
       >
     </app-editable-form>
</mat-card-content>
</mat-card>
