export class AppConstants {

  public static owner_id = [];
  public static carrier_coordinator_id = [];
  public static account_manager_id = [];

  public static currency_id = [
    {value: 1, viewValue: 'Bolivianos'},
    {value: 2, viewValue: 'US Dollars'},
    {value: 3, viewValue: 'Canadian Dollars'}
  ];

  public static contract_document_type_id = [
    {value: 1, viewValue: 'Shipping Documents'},
    {value: 2, viewValue: 'Proof of Delivery'}
  ];

  public static modes = [
    {value: 1, viewValue: 'OTR FTL'},
    {value: 2, viewValue: 'Intermodal FCL'},
    {value: 3, viewValue: 'Multimodal FCL'},
    {value: 4, viewValue: 'Ocean FCL'},
    {value: 5, viewValue: 'OTR LTL'},
    {value: 6, viewValue: 'Intermodal LCL'},
    {value: 7, viewValue: 'Multimodal LCL'},
    {value: 8, viewValue: 'Ocean LCL'}
  ];

  public static mode_id = [
    {value: 1, viewValue: 'OTR FTL'},
    {value: 2, viewValue: 'Intermodal FCL'},
    {value: 3, viewValue: 'Multimodal FCL'},
    {value: 4, viewValue: 'Ocean FCL'},
    {value: 5, viewValue: 'OTR LTL'},
    {value: 6, viewValue: 'Intermodal LCL'},
    {value: 7, viewValue: 'Multimodal LCL'},
    {value: 8, viewValue: 'Ocean LCL'}
  ];

  public static stop_type_id = [
    {value: 1, viewValue: 'Pickup'},
    {value: 2, viewValue: 'Delivery'}
  ];

  public static pos_units = [
    {value: 1, viewValue: 'skids/kgs'},
    {value: 2, viewValue: 'skids/lbs'},
    {value: 3, viewValue: 'FTL/lbs'},
    {value: 4, viewValue: 'feet/lbs'}
  ];

  public static unit_type_id = [
    {value: 1, viewValue: 'Flat rate'},
    {value: 2, viewValue: 'Percentage'}
  ];

  public static payment_type_id = [
    {value: 1, viewValue: 'Transfer'},
    {value: 2, viewValue: 'Cheque'},
    {value: 3, viewValue: 'Cash'},
    {value: 4, viewValue: 'Short Pay'}
  ];

  public static expense_type_id = [
    {value: 1, viewValue: 'Freight'},
    {value: 2, viewValue: 'FSC'},
    {value: 3, viewValue: 'ASPB'},
    {value: 4, viewValue: 'Extra Stop'},
    {value: 5, viewValue: 'Insurance'},
    {value: 6, viewValue: 'Redelivery'},
    {value: 7, viewValue: 'Storage'},
    {value: 8, viewValue: 'Waiting Time'},
    {value: 9, viewValue: 'Missed Pickup'},
    {value: 10, viewValue: 'Driver Assist'},
    {value: 11, viewValue: 'Pallet Jack'},
    {value: 12, viewValue: 'Lumper Fee'},
  ];

  public static equipment_types = [
    {value: 1, viewValue: '53 DV'},
    {value: 2, viewValue: '53 HEAT'},
    {value: 3, viewValue: '53 REEFER'},
    //{value: '4', viewValue: '40 DV'},
    //{value: '5', viewValue: '40 HV'},
    //{value: '6', viewValue: '40 RF'},
    {value: 10, viewValue: 'Flatbed'},
  ];

  public static equipment_type_id = [
    {value: 1, viewValue: '53 DV'},
    {value: 2, viewValue: '53 HEAT'},
    {value: 3, viewValue: '53 REEFER'},
    //{value: '4', viewValue: '40 DV'},
    //{value: '5', viewValue: '40 HV'},
    //{value: '6', viewValue: '40 RF'},
    {value: 10, viewValue: 'Flatbed'},
  ];

  public static po_units_id = [
    {value: 1, viewValue: 'skids'},
    {value: 2, viewValue: 'feet'},
    {value: 3, viewValue: 'FTL'},
    {value: 4, viewValue: 'pieces'},
    {value: 5, viewValue: 'boxes'}
  ];

  public static call_outcome_id = [
    {value: 1, viewValue: 'No Answer'},
    {value: 2, viewValue: 'No Change'},
    {value: 3, viewValue: 'Failure'},
    {value: 4, viewValue: 'Success'}

  ];

  public static po_weight_id = [
    {value: 1, viewValue: 'lbs'},
    {value: 2, viewValue: 'kgs'},
    // {value: '3', viewValue: ''},
    // {value: '4', viewValue: ''},
    // {value: '5', viewValue: ''}
  ];


  public static tabs_quotes = [
    {value: -10, name: 'q_pending'},
    {value: -11, name: 'q_completed'},
    {value: -12, name: 'q_sent'},
    {value: 50, name: 'template'}

  ];

  public static tabs_service = [
    {value: 1, name: 'pending'},
    {value: 2, name: 'booked'},
    {value: 3, name: 'dispatched'},
    {value: 4, name: 'intransit'},
    {value: 5, name: 'delivered'},
    {value: 6, name: 'billed'},
    {value: 7, name: 'onhold'},
    {value: 9, name: 'cancelled'}

  ];

  public static tabs_prospects_id = [
    {value: 1, viewValue: 'action'},
    {value: 2, viewValue: 'data_needed'},
    {value: 3, viewValue: 'dropped_lead'}
  ];

  public static tabs_contract = [
    {value: 1, name: 'pending'},
    {value: 2, name: 'booked'},
    {value: 3, name: 'dispatched'},
    {value: 4, name: 'intransit'},
    {value: 5, name: 'delivered'},
    {value: 6, name: 'documents_received'},
    {value: 7, name: 'paid'}
  ];
}
