<h2 mat-dialog-title>New Carrier</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="siteFormGroup">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
      <mat-form-field fxFlex>
        <input matInput placeholder="Name" formControlName="name">
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
      <mat-form-field fxFlex>
        <input matInput placeholder="Emails" formControlName="emails">
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
      <mat-form-field fxFlex>
        <input matInput placeholder="Phone" formControlName="phone">
      </mat-form-field>
    </div>

    <div *ngIf="!formSubmitted">
        <button mat-raised-button (click)="submit()" color="primary">Submit</button>
    </div>
  </form>

</mat-dialog-content>
