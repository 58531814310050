<form #templateForm="ngForm">
<table mat-table [dataSource]="data" class="mat-elevation-z8" #editable_table>
  <ng-container *ngFor="let header of headers; index as h_i" matColumnDef="{{header.name}}">
    <th mat-header-cell *matHeaderCellDef> {{header.name | translate }} </th>
    <td mat-cell *matCellDef="let element let index=index">
      <ng-container *ngIf="element.editable; else elseNotEditable" >
        <container-element [ngSwitch]="header.type|| '_undefined_'">

          <some-element *ngSwitchCase="'selectList'">
            <mat-form-field class="editable_td">
              <mat-select [(value)]="element[header.name]" name="{{header.name}}[{{index}}]" (selectionChange)="fieldChange(element,header)">
                <mat-option *ngFor="let option of getStructure(header.name)" [value]="option.value">
                  {{option.viewValue}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </some-element>

          <some-element *ngSwitchCase="'selectListCustom'">
            <mat-form-field class="editable_td">
              <mat-select [(value)]="element[header.name]" name="{{header.name}}[{{index}}]" [multiple]="header.multiple">
                <mat-option *ngFor="let option of header.list" [value]="option.value">
                  {{option.viewValue}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </some-element>

          <some-element *ngSwitchCase="'checkList'">
            <mat-checkbox [(ngModel)]="element[header.name]" name="{{header.name}}[{{index}}]" [required]="header.required" [readonly]="header.readonly" ></mat-checkbox>
          </some-element>

          <some-element *ngSwitchCase="'date'">
            <mat-form-field>
             <input matInput [matDatepicker]="picker" [(ngModel)]="element[header.name]" name="{{header.name}}[{{index}}]"  [required]="header.required" [readonly]="header.readonly" >
             <!-- ng-model-options="{timezone: 'utc'}" format="yyyy-MM-dd" type="date"> -->
             <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
             <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </some-element>

          <some-element *ngSwitchCase="'static_field'">
            {{element[header.name]}}
          </some-element>

          <some-element *ngSwitchCase="'autocomplete'">
            <mat-form-field>
              <input type="text" matInput [matAutocomplete]="auto" (focus)="autocompleteOnFocus(header.name,h_i)"  name="{{header.name}}" #fname="ngModel" [(ngModel)]="element[header.name]" [readonly]="header.readonly">
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayProperty">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                  {{option.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </some-element>


          <some-element *ngSwitchCase="'checkMarkEdit'">
          </some-element>

          <some-element *ngSwitchCase="'button'">
          </some-element>

          <some-element *ngSwitchCase="'file'">
            <mat-form-field>
              <ngx-mat-file-input [(ngModel)]="element[header.name]"  accept="true" color="primary" name="{{header.name}}[{{index}}]"  [required]="header.required" [readonly]="header.readonly" (change)="fieldChange(element,header)">
              </ngx-mat-file-input>
            </mat-form-field>
          </some-element>

          <some-element *ngSwitchCase="'dialog'">
            <mat-form-field>
              <input matInput name="{{header.name}}[{{index}}]"  [required]="header.required" [(ngModel)]="element[header.name].name" (click)="header.popup(element)" [readonly]="true">

              <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="header.popup_clear(element)">
                <mat-icon>close</mat-icon>
              </button>

            </mat-form-field>
          </some-element>

          <some-element *ngSwitchCase="'sub'">
            <mat-form-field class="editable_td">
              <input matInput [(ngModel)]="element[header.object][header.name]" name="{{header.name}}[{{index}}]"  [required]="header.required" [readonly]="header.readonly">
            </mat-form-field>
          </some-element>


          <some-element *ngSwitchDefault>
            <mat-form-field class="editable_td">
              <input matInput [(ngModel)]="element[header.name]" name="{{header.name}}[{{index}}]"  [required]="header.required" [readonly]="header.readonly" (change)="fieldChange(element,header)">
            </mat-form-field>
          </some-element>




        </container-element>
      </ng-container>

      <ng-template #elseNotEditable>

        <container-element [ngSwitch]="header.type|| '_undefined_'">
          <some-element *ngSwitchCase="'selectList'">
              {{getStructureValue(header.name,element[header.name])}}
          </some-element>

          <some-element *ngSwitchCase="'selectListCustom'">
            {{getElementValue(element[header.name+'_list'],element[header.name])}}

          </some-element>

          <some-element *ngSwitchCase="'checkList'">
            <mat-checkbox disabled="true" [(ngModel)]="element[header.name]" name="{{header.name}}[{{index}}]"></mat-checkbox>
          </some-element>

          <some-element *ngSwitchCase="'date'">
            {{element[header.name] | date : 'M/d/yyyy'}}
          </some-element>

          <some-element *ngSwitchCase="'checkMarkEdit'">

            <ng-container *ngIf="element[header.name] ;else elseCheckBox">
              {{element[header.name]}}
            </ng-container>
            <ng-template #elseCheckBox>
             <mat-checkbox name="{{header.name}}[{{index}}]" (change)="header.callback(data)" [(ngModel)]="element.marked"></mat-checkbox>
            </ng-template>
          </some-element>

          <some-element *ngSwitchCase="'sub'">
            {{element[header.object][header.name]}}
          </some-element>

          <some-element *ngSwitchCase="'button'">
            <button mat-icon-button (click)="header.callback(element)">
              <mat-icon>{{header.icon}}</mat-icon>
            </button>
          </some-element>

          <some-element *ngSwitchCase="'file'">
            <!-- *ngIf="element.file" -->
            <button  mat-icon-button (click)="header.callback(element)">
              <mat-icon>{{header.icon}}</mat-icon>
            </button>
          </some-element>

          <some-element *ngSwitchCase="'autocomplete'">
            {{element[header.name]?element[header.name].name:''}}
          </some-element>
          <some-element *ngSwitchCase="'dialog'">
            {{element[header.name]?.name}}
          </some-element>



          <some-element *ngSwitchDefault>
            {{element[header.name]}}
          </some-element>
        </container-element>
      </ng-template>
    </td>

  </ng-container>

  <ng-container matColumnDef="edit-save" *ngIf="newUrl || updateUrl">
    <th mat-header-cell *matHeaderCellDef class="min_td"></th>
    <td mat-cell *matCellDef="let element let index=index" class="min_td">
      <ng-container *ngIf="element.editable && !element.updating" >
        <button  mat-icon-button (click)='saveEditable(element)' [disabled]="!rowValid(index)">
          <mat-icon>save</mat-icon>
        </button>
      </ng-container>

      <ng-container *ngIf="!element.editable && (blockEdit?!element[blockEdit]:true)" >
        <button  mat-icon-button   (click)='makeEditable(element)'>
          <mat-icon>edit</mat-icon>
        </button>
      </ng-container>
    </td>

  </ng-container>
  <ng-container matColumnDef="remove-cancel" *ngIf="newUrl || removeUrl">
    <th mat-header-cell *matHeaderCellDef class="min_td">
      <button *ngIf="newUrl" mat-icon-button (click)='newEditable()'>
        <mat-icon>add</mat-icon>
      </button>
    </th>
    <td mat-cell *matCellDef="let element" class="min_td">
      <ng-container *ngIf="element.editable && !element.updating" >
        <button  mat-icon-button  (click)='cancelEditable(element)'>
          <mat-icon>cancel</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="!element.editable && (blockEdit?!element[blockEdit]:true) && (removeUrl)" >
        <button  mat-icon-button (click)='removeEditable(element)'>
          <mat-icon>delete</mat-icon>
        </button>
      </ng-container>



    </td>

  </ng-container>
  <tr mat-header-row *matHeaderRowDef="headers_array"></tr>
  <!-- <tr mat-row *matRowDef="let row; columns: headers.concat(['edit-save','remove-cancel'])"></tr> -->

  <tr mat-row *matRowDef="let row; columns: headers_array"></tr>


</table>
</form>
