<div *ngIf="loaded" fxLayout="column" class="test">
  <div ngClass="left_panel" ngClass.lt-md="">
    <mat-card class="mat_card_service">
      <mat-card-title fxLayout>
        <span>{{data.quote_id}} {{data.client_name}}</span>

        <span class="spacer"></span>
        <span>
          {{data.revenue}} | {{data.costs}} | {{data.revenue-data.costs | number: '0.2'}}
        </span>
        <span class="spacer"></span>



        <button class="title_button"  mat-icon-button matTooltip="{{'interface.cancel' | translate}}"  (click)="cancel()">
          <mat-icon color="warn">delete</mat-icon>
        </button>

        <button  class="title_button"  mat-icon-button  aria-label="dispatch" matTooltip="Refresh" (click)="getOperation()">
          <mat-icon>update</mat-icon>
        </button>

        <span>{{'interface.' + data.status_name | translate}}</span>
      </mat-card-title>
    </mat-card>



    <mat-tab-group mat-align-tabs="start">

      <mat-tab label="Quotes">
        <mat-toolbar>

          <button mat-icon-button aria-label="Find carriers" matTooltip="Find Carriers" (click)="find_carriers()">
            <mat-icon>search</mat-icon>
          </button>

          <div *ngIf="data.status_name=='q_pending'">
            <button mat-icon-button aria-label="send to completed" matTooltip="Send to Completed" (click)="send_completed()">
              <mat-icon>forward</mat-icon>
            </button>


            </div>

          <div *ngIf="data.status_name=='q_completed'">
            <button mat-icon-button aria-label="send to pending" matTooltip="Send to Pending" (click)="send_pending()">
              <mat-icon>undo</mat-icon>
            </button>

            <button mat-icon-button aria-label="send to customer" matTooltip="Send to Customer" (click)="send_customer()">
              <mat-icon>send</mat-icon>
            </button>

            <button mat-icon-button aria-label="send to customer direct" matTooltip="Send to Customer Direct" (click)="send_customer22()">
              <mat-icon>redo</mat-icon>
            </button>


          </div>

          <div *ngIf="data.status_name=='q_sent' || data.status_name=='template'">
            <button mat-icon-button aria-label="Back to Completed" matTooltip="Back to Completed" (click)="back_completed($event)" [disabled]="creating_op">
              <mat-icon>undo</mat-icon>
            </button>
            <button mat-icon-button aria-label="Create new Service" matTooltip="Create New Service" (click)="send_new_op($event)" [disabled]="creating_op">
              <mat-icon>create_new_folder</mat-icon>
            </button>
          </div>
        <span class="spacer"></span>
        <span></span>

      </mat-toolbar>


      <mat-card>
        <mat-card-header>
          <mat-card-subtitle>Budgets</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <app-editable-table
          [headers]="[ {name:'expense_type_id',type:'selectList',default:1}, {name:'unit_price',fieldChange:true},{name:'unit_type_id',type:'selectList',default:1},{name:'quantity',fieldChange:true},{name:'rate', readonly:true}]"
          [data]='data.quotes_budgets'
          updateUrl="{{'quotes/budgets/update/'+data.id}}"
          newUrl="{{'quotes/budgets/store/'+data.id}}"
          removeUrl="{{'quotes/budgets/remove/'+data.id}}"
          [fieldChangeCallback]="lineChange"
          >
          </app-editable-table>
        </mat-card-content>
      </mat-card>



        <mat-card>
          <mat-card-header>

          </mat-card-header>
          <mat-card-content>

              <app-editable-form
               [headers]="[{name:'target_rate'},{name:'carrier_coordinator_id',type:'selectList'},{name:'account_manager_id',type:'selectList'}]"
               [data]='data.details'
               updateUrl="{{'quotes/quote/modify/'+data.id}}">
             </app-editable-form>
        </mat-card-content>
        </mat-card>

        <mat-card>
          <mat-card-header>
            <mat-card-subtitle>Quotes</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <app-editable-table-filter
            [headers]="[
            {
              name:'edit',
              type:'button',
              icon:'edit',
              callback: EditCarrierRow.bind(this)
            },

            {
              name:'send',
              type:'button',
              icon:'send',
              callback: sendEmailCarrier.bind(this)
            },

            {
              name:'carrier',
              type:'dialog',
              readonly:'true',
              callback: openCarrierDialog.bind(this)
            },{name:'emails', type:'sub',object:'carrier', readonly:'true'},{name:'phone', type:'sub',object:'carrier', readonly:'true'},{name:'rate'},{name:'notes'},{name:'updated_at'}]"
            [data]='data.quotes_carrier'
            updateUrl="{{'quote2_carriers/quote/update/'+data.id}}"
            newUrl="{{'quote2_carriers/quote/store/'+data.id}}"
            removeUrl="{{'quote2_carriers/quote/remove/'+data.id}}"
            [popup]="openCarrierDialog.bind(this)">
            </app-editable-table-filter>
          </mat-card-content>
        </mat-card>


      </mat-tab>


      <mat-tab label="POs">
        <app-editable-table
        [headers]="[
          {name:'name'},
          {name:'description'},
          {name:'number',required:true,fieldChange:true},
          {name:'po_units_id',required:true,type:'selectList',fieldChange:true},
          {name:'weight',fieldChange:true},
          {name:'po_weight_id',required:true,type:'selectList',fieldChange:true},
          {name:'stackable',type:'checkList'},
          {name:'length',fieldChange:true},
          {name:'width',fieldChange:true},
          {name:'height',fieldChange:true},
          {name:'nmfc'},
          {name:'class'},
          {name:'subclass'}
        ]"
        [data]='data.pos'
        updateUrl="{{'quotes/pos/update/'+data.id}}" newUrl="{{'quotes/pos/store/'+data.id}}" removeUrl="{{'quotes/pos/remove/'+data.id}}"
        [fieldChangeCallback]="lineChangePO"
        >
        </app-editable-table>
      </mat-tab>

      <mat-tab label="Description">
            <app-editable-form
             [headers]="[{name:'client_ref'},{name:'description'},{name:'mode_id',type:'selectList'},{name:'temperature'}]"
             [data]='data'
             updateUrl="{{'quotes/quote_detail/modify/'+data.id}}">
           </app-editable-form>
      </mat-tab>



      <mat-tab label="{{ 'interface.stops' | translate }}">

        <app-editable-table
        [headers]="[ {name:'stop_type_id',type:'selectList'}, {name:'site', type:'dialog',popup: openSiteDialog.bind(this),popup_clear: site_clear.bind(this) }, {name:'details',type:'button',icon:'web',callback:this.openSiteCallback}, {name:'blind_site', type:'dialog',popup: openBlindSiteDialog.bind(this), popup_clear: blind_site_clear.bind(this) },{name:'details2',type:'button',icon:'web',callback:this.openBlindSiteCallback},{name:'zip_name',readonly:'true'}]"
        [data]='data.stops'
        updateUrl="{{'quotes/stops/update/'+data.id}}"
        newUrl="{{'quotes/stops/store/'+data.id}}"
        removeUrl="{{'quotes/stops/remove/'+data.id}}"
        [popup]="openSiteDialog.bind(this)">
        >
        </app-editable-table>



      </mat-tab>


    </mat-tab-group>






  </div>
  <div ngClass="right_panel" ngClass.lt-md="">
    <mat-card class="notes_class">

        <app-notes
        [data]="data.notes"
        newUrl="{{'quotes/notes/store/'+data.id}}"
        removeUrl="{{'quotes/notes/remove/'+data.id}}"
        >
        </app-notes>

    </mat-card>
  </div>
</div>
<div *ngIf="!loaded">
  Loading...
</div>
