import { Component } from '@angular/core';
import { Observable, of, Subscription, empty } from 'rxjs';
import { tap, startWith, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { asyncValidator } from '../helpers/custom-async-validator';
import { ApiService } from '../services/api.service';
import { MatTableDataSource } from '@angular/material/table';
import {Router} from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AppConstants } from '../structures/appconstants';
import { AppComponent } from '../app.component';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
//import { formatDate } from "@angular/common";
import { DatePipe } from '@angular/common'



//private app: AppComponent
@Component({
  selector: 'app-factorings',
  templateUrl: './factorings.component.html',
  styleUrls: ['../shared/panel.component.css']
})

export class FactoringsComponent {

  loaded: boolean =false;
  data:any;

  constructor(
    private apiService: ApiService,
    private sanitizer: DomSanitizer,
    private app: AppComponent
  )
  {
    this.getRows();
  }

  getRows(): void {
    this.apiService.getData( 'accounting/factoring/list',{} )
      .subscribe(param =>
      {
        this.data= param.data;
        this.loaded = true;
      });
  }

  fileCallback = ( element ): void =>
  {
  //https://tms.venkor.ca/pages/invoice/pdf/49/
    this.apiService.getBlob( 'documents/factoring_image/'+element.id )
      .subscribe(response =>
        {
          var blob = response;
          var fileURL = URL.createObjectURL(blob);
          var documentData = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);

          //let url = 'pages/invoices/email/' + element.id;
          //let filename = 'filename';
          //let subject = 'Invoice V';

          let message = 'Name: ' + element.name+ ' Bank: '+ element.bank + ' Transit: ' + element.transit + ' Account: ' +element.account;
          //let preview = ""

          //let c = [];

          this.app.setSidenavD(documentData, message);
          this.app.getSidenav().open();
        });

  }

}
