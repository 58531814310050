<mat-card class="example-card hide-print">
  <mat-card-header>
    <mat-card-title>Gifi Report</mat-card-title>
    <mat-card-subtitle>Options</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="filterFormGroup" (submit)="getGifi()">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fx-layout-align="end">
        <mat-form-field>
          <mat-label>Up to date</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="date">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fx-layout-align="end">
        <button mat-raised-button color="primary" type="submit">Generate Report</button>
      </div>




    </form>
  </mat-card-content>
</mat-card>



<div  #print_content class="print_content" *ngIf="loaded">
  <div style="text-align: center;">Venkor Inc</div>
  <div style="text-align: center;">GIFI REPORT</div>
  <div style="text-align: center;">As of {{data.date | date:'MMM d, y'}}</div>



  <ng-template #treeView let-name="name" let-gifi="gifi" let-children="children" let-saldo="saldo">
    <ng-container *ngIf="saldo !=0 && gifi" >
    <tr>
      <td>"{{gifi}}",</td>
      <td>{{(saldo | number :'1.2-2')}}</td>
    </tr>
    </ng-container>
    <ng-container *ngFor="let child of children">
      <!-- <ng-container *ngIf="child.saldo !=0 && child.gifi" > -->
        <ng-container *ngTemplateOutlet="treeView;  context:child"></ng-container>
      <!-- </ng-container> -->

    </ng-container>
  </ng-template>

<table>
<ng-container *ngTemplateOutlet="treeView;context:data.assets"></ng-container>
<ng-container *ngTemplateOutlet="treeView;context:data.liabilities"></ng-container>
<ng-container *ngTemplateOutlet="treeView;context:data.equity"></ng-container>
<ng-container *ngTemplateOutlet="treeView;context:data.revenues"></ng-container>
<ng-container *ngTemplateOutlet="treeView;context:data.expenses"></ng-container>
<ng-container *ngTemplateOutlet="treeView;context:data.expenses_after_taxes"></ng-container>


<tr><td>"3640",</td><td>{{(data.liabilities.saldo + data.equity.saldo | number : '1.2-2')}}</td></tr>
<tr><td>"9970",</td><td>{{(data.revenues.saldo - data.expenses.saldo | number : '1.2-2')}}</td></tr>
<tr><td>"9999",</td><td>{{(data.revenues.saldo - data.expenses.saldo -data.expenses_after_taxes.saldo | number : '1.2-2')}}</td></tr>
</table>

</div>
