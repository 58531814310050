
  <dl *ngIf="!is_editting_details; else details_edit" class="editable_form">


    <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap.lt-md="0px">
      <ng-container *ngFor="let header of headers; index as h_i" matColumnDef="{{header.name}}">
        <div fxFlex="50%" fxFlex.xs="100%">

          <dt *ngIf="header.type!='checkList'">{{header.name | translate}}</dt>
          <dd>
            <container-element [ngSwitch]="header.type|| '_undefined_'">
              <some-element *ngSwitchCase="'selectList'">
                  {{getStructureValue(header.name,data[header.name])}}
              </some-element>

              <some-element *ngSwitchCase="'autocomplete'">
                  {{data[header.name].name}}
              </some-element>

              <some-element *ngSwitchCase="'date'">
                 {{data[header.name] | date : 'M/d/yyyy'}}
              </some-element>

              <some-element *ngSwitchCase="'checkList'">
                <mat-checkbox disabled="true" [(ngModel)]="data[header.name]">{{header.name}}</mat-checkbox>
              </some-element>

              <some-element *ngSwitchDefault>
                {{data[header.name]}}
              </some-element>




            </container-element>
          </dd>

        </div>

      </ng-container>
    </div>

    <div>
      <button *ngIf="!submitOnly" mat-raised-button color="primary" (click)="makeEditable()">Edit</button>
      <div *ngIf="submitOnly">
        Submitted</div>
    </div>

  </dl>
  <ng-template #details_edit>
    <form #templateForm="ngForm">
    <dl>
      <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="10px grid" fxLayoutGap.lt-md="0px">
        <ng-container *ngFor="let header of headers; index as h_i" matColumnDef="{{header.name}}">
          <div fxFlex="calc(50%-10px)" fxFlex.lt-md="100%">

            <container-element [ngSwitch]="header.type|| '_undefined_'">
              <some-element *ngSwitchCase="'selectList'">
                <mat-form-field class="editable_td">
                  <mat-label>{{header.name | translate}}</mat-label>
                  <mat-select [(ngModel)]="data[header.name]" name="{{header.name}}" [required]="header.required">
                    <mat-option *ngFor="let option of getStructure(header.name)" [value]="option.value">
                      {{option.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </some-element>

              <some-element *ngSwitchCase="'date'">
                <mat-form-field class="editable_td">
                 <mat-label>{{header.name  | translate}}</mat-label>
                 <input matInput [matDatepicker]="picker" [(ngModel)]="data[header.name]" name="{{header.name}}"  [required]="header.required" [readonly]="header.readonly" >
                 <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
                 <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </some-element>

              <some-element *ngSwitchCase="'autocomplete'">
                <mat-form-field class="editable_td">
                  <input type="text" matInput [matAutocomplete]="auto" name="{{header.name}}" #fname="ngModel" [(ngModel)]="data[header.name]" [readonly]="header.readonly" (ngModelChange)="onChangeAutocomplete(h_i,data[header.name])">
                  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayProperty">
                    <mat-option *ngFor="let option of filteredOptions[h_i]" [value]="option">
                      {{option.name}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </some-element>

              <some-element *ngSwitchCase="'checkList'">
                <div class="editable_td">
                  <mat-checkbox [(ngModel)]="data[header.name]" [required]="header.required" [readonly]="header.readonly" name="{{header.name}}">{{header.name}}</mat-checkbox>
                </div>
              </some-element>




              <some-element *ngSwitchDefault>
                <mat-form-field class="editable_td">
                  <mat-label>{{header.name | translate}}</mat-label>
                  <input matInput [(ngModel)]="data[header.name]" name="{{header.name}}"  [required]="header.required" [readonly]="header.readonly">
                </mat-form-field>
              </some-element>
            </container-element>



          </div>

        </ng-container>
      </div>

      <div>
        <button [disabled]="!templateForm.valid" mat-raised-button color="primary" (click)="saveDescription()">{{'interface.save' | translate }}</button>
        <button *ngIf="!submitOnly" mat-raised-button (click)="resetDescription()">{{'interface.cancel' |translate }}</button>
      </div>

    </dl>
    </form>
  </ng-template>
