<div *ngIf="loaded" fxLayout="column" class="test">
  <div ngClass="left_panel" ngClass.lt-md="">
    <mat-card class="mat_card_service">
      <mat-card-title fxLayout>
        <span>{{data.service_id}} {{data.client_name}}</span>

        <span class="spacer"></span>
        <span>
          {{getRevenueTotal()}} | {{getCostTotal()}} | {{ getRevenueTotal() - getCostTotal() | number: '0.2'}}
        </span>
        <span class="spacer"></span>

        <div *ngIf="data.contracts[0].status_name != 'pending'">
          <button  class="title_button"  mat-icon-button  aria-label="bol" matTooltip="BOL"  (click)="print_bol()">
            <mat-icon>web</mat-icon>
          </button>
        </div>

        <button class="title_button" mat-icon-button matTooltip="{{'interface.hold' | translate}}"  (click)="hold()">
          <mat-icon >pause_circle_outline</mat-icon>
        </button>

        <button class="title_button"  *ngIf="data.status_name=='on_hold'" mat-icon-button matTooltip="{{'interface.remove_hold' | translate}}"  (click)="remove_hold()">
          <mat-icon >play_circle_outline</mat-icon>
        </button>

        <button class="title_button"  mat-icon-button matTooltip="{{'interface.cancel' | translate}}"  (click)="cancel()">
          <mat-icon color="warn">delete</mat-icon>
        </button>

        <button  class="title_button"  mat-icon-button  aria-label="dispatch" matTooltip="Refresh" (click)="getOperation()">
          <mat-icon>update</mat-icon>
        </button>

        <span>{{'interface.' + data.status_name | translate}}</span>
      </mat-card-title>
    </mat-card>


    <mat-card>
      <mat-card-content>
        <mat-tab-group mat-align-tabs="start">
          <mat-tab label="{{ 'description' | translate }}">
            <app-editable-form
             [headers]="[{name:'mode_id',type:'selectList'},{name:'temperature'},{name:'equipment_type_id', type:'selectList'},{name:'description'},{name:'client_ref'},{name:'account_manager_id', type:'selectList'},{name:'carrier_coordinator_id', type:'selectList'}]"
             [data]='data.details'
             updateUrl="{{'services/service_detail/modify/'+data.id}}">
            </app-editable-form>
          </mat-tab>

          <mat-tab label="POs">
            <app-editable-table
            [headers]="[
              {name:'name'},
              {name:'description'},
              {name:'number',required:true,fieldChange:true},
              {name:'po_units_id',required:true,type:'selectList'},
              {name:'weight',fieldChange:true},
              {name:'po_weight_id',required:true,type:'selectList',fieldChange:true},
              {name:'stackable',type:'checkList'},
              {name:'length',fieldChange:true},
              {name:'width',fieldChange:true},
              {name:'height',fieldChange:true},
              {name:'nmfc'},
              {name:'class'},
              {name:'subclass'}
            ]"
            [data]='data.pos'
            updateUrl="{{'services/pos/update/'+data.id}}" newUrl="{{'services/pos/store/'+data.id}}" removeUrl="{{'services/pos/remove/'+data.id}}"
            [fieldChangeCallback]="lineChangePO"
            >
            </app-editable-table>
          </mat-tab>

          <mat-tab label="{{ 'interface.stops' | translate }}">
            <app-editable-table
            [headers]="[ {name:'stop_type_id',type:'selectList'}, {name:'site', type:'dialog',popup: openSiteDialog.bind(this),popup_clear: site_clear.bind(this) }, {name:'blind_site', type:'dialog',popup: openBlindSiteDialog.bind(this), popup_clear: blind_site_clear.bind(this) },{name:'zip_name',readonly:'true'}]"
            [data]='data.stops'
            updateUrl="{{'services/stops/update/'+data.id}}"
            newUrl="{{'services/stops/store/'+data.id}}"
            removeUrl="{{'services/stops/remove/'+data.id}}"
            [popup]="openSiteDialog.bind(this)">
            >
            </app-editable-table>
          </mat-tab>

          <mat-tab label="Quotes">

            <mat-card>
              <mat-card-header>
                <mat-card-subtitle>Quotes</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content class="scrollable">
                <app-editable-table-filter
                [headers]="[
                {
                  name:'edit',
                  type:'button',
                  icon:'edit',
                  callback: EditCarrierRow.bind(this)
                },

                {
                  name:'send',
                  type:'button',
                  icon:'send',
                  callback: sendEmailCarrier.bind(this)
                },

                {
                  name:'carrier',
                  type:'dialog',
                  readonly:'true',
                  callback: openCarrierDialog.bind(this)
                },{name:'emails', type:'sub',object:'carrier', readonly:'true'},{name:'phone', type:'sub',object:'carrier', readonly:'true'},{name:'rate'},{name:'notes'},{name:'updated_at'}]"
                [data]='data.quotes_carrier'
                updateUrl="{{'service_carriers/quote/update/'+data.id}}"
                newUrl="{{'service_carriers/quote/store/'+data.id}}"
                removeUrl="{{'service_carriers/quote/remove/'+data.id}}"
                [popup]="openCarrierDialog.bind(this)">
                </app-editable-table-filter>
              </mat-card-content>
            </mat-card>


          </mat-tab>

          <mat-tab label="Billing">
            <mat-card>
              <mat-card-content  class="scrollable">
                <div class='budgets-title'>
                  Budgets
                </div>

                <app-editable-table #budgetsEditableTable
                [headers]="[ {name:'invoice',type:'checkMarkEdit',callback:this.budgetSelected},{name:'expense_type_id',type:'selectList',default:1}, {name:'unit_price',fieldChange:true},{name:'unit_type_id',type:'selectList',default:1},{name:'quantity',fieldChange:true},{name:'rate', readonly:true}]"
                [data]='data.service_budgets'
                updateUrl="{{'services/budgets/update/'+data.id}}"
                newUrl="{{'services/budgets/store/'+data.id}}"
                removeUrl="{{'services/budgets/remove/'+data.id}}"
                blockEdit="invoice"
                [fieldChangeCallback]="lineChange"
                >
                </app-editable-table>



                <div class='budgets-title'>
                  Invoices
                </div>
                <app-editable-table *ngIf="data.status <= 6" #invoicesEditableTableId
                 [headers]="[{name:'date',required:true,type:'date'},{name:'invoice_number',type:'static_field'},{name: 'stops', type:'selectListCustom',list:data.stops, multiple:true},{name:'sub_total_books',required:true,readonly:true},{name:'hst_books',required:true,readonly:true},{name:'total_books',required:true,readonly:true},{name:'file',type:'button',icon:'insert_drive_file',callback:this.fileCallback}]"
                 [data]='data.invoices'
                 updateUrl="{{'services/invoices/update/'+data.id}}"
                 newUrl="{{'services/invoices/store/'+data.id}}"
                 removeUrl="{{'services/invoices/remove/'+data.id}}">
               </app-editable-table>

              </mat-card-content>
            </mat-card>
          </mat-tab>


          <!-- <mat-tab disabled>
            <ng-template mat-tab-label>

            </ng-template>
          </mat-tab> -->


        </mat-tab-group>
      </mat-card-content>
    </mat-card>


    <mat-tab-group mat-align-tabs="start">
      <mat-tab *ngFor="let contract of data.contracts; let i = index" label="{{ contract.vendor_name }}" >
        <app-contractnm [service_contacts]= "data.client_contact" [contract]="contract" [privilegios]="privilegios"></app-contractnm>
      </mat-tab>

      <mat-tab label="+" >

      </mat-tab>
    </mat-tab-group>

    <!-- <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="0px" fxLayoutGap.lt-md="0px">
      <div fxFlex="50%" fxFlex.lt-md="100%">
        <mat-card>
          <mat-card class="mat_card_service">
            <mat-card-title fxLayout>
              <span>{{data.service_id}} {{data.client_name}}</span>

              <span class="spacer"></span>
              <span>
                {{data.revenue}} | {{data.costs}} | {{data.revenue-data.costs | number: '0.2'}}
              </span>
              <span class="spacer"></span>
              <span>{{'interface.' + data.status_name | translate}}</span>
            </mat-card-title>
          </mat-card>

          <mat-card-content  class="scrollable">
            <div class='budgets-title'>
              Budgets
            </div>

            <app-editable-table #budgetsEditableTable
            [headers]="[ {name:'invoice',type:'checkMarkEdit',callback:this.budgetSelected},{name:'expense_type_id',type:'selectList',default:1}, {name:'unit_price',fieldChange:true},{name:'unit_type_id',type:'selectList',default:1},{name:'quantity',fieldChange:true},{name:'rate', readonly:true}]"
            [data]='data.service_budgets'
            updateUrl="{{'services/budgets/update/'+data.id}}"
            newUrl="{{'services/budgets/store/'+data.id}}"
            removeUrl="{{'services/budgets/remove/'+data.id}}"
            blockEdit="invoice"
            [fieldChangeCallback]="lineChange"
            >
            </app-editable-table>



            <div class='budgets-title'>
              Invoices
            </div>
            <app-editable-table *ngIf="data.status <= 6" #invoicesEditableTableId
             [headers]="[{name:'date',required:true,type:'date'},{name:'invoice_number',type:'static_field'},{name:'sub_total_books',required:true,readonly:true},{name:'hst_books',required:true,readonly:true},{name:'total_books',required:true,readonly:true},{name:'file',type:'button',icon:'insert_drive_file',callback:this.fileCallback}]"
             [data]='data.invoices'
             updateUrl="{{'services/invoices/update/'+data.id}}"
             newUrl="{{'services/invoices/store/'+data.id}}"
             removeUrl="{{'services/invoices/remove/'+data.id}}">
           </app-editable-table>

          </mat-card-content>
        </mat-card>
      </div>
      <div fxFlex="50%" fxFlex.lt-md="100%">
        <ng-container *ngFor="let contract of data.contracts">
            <app-contractnmBudgets [service_contacts]= "data.client_contact" [contract]="contract" [privilegios]="privilegios"></app-contractnmBudgets>
        </ng-container>
      </div>
    </div> -->

  </div>
  <div ngClass="right_panel" ngClass.lt-md="">
    <mat-card class="notes_class">

        <app-notes
        [data]="data.notes"
        newUrl="{{'services/notes/store/'+data.id}}"
        removeUrl="{{'services/notes/remove/'+data.id}}"
        >
        </app-notes>

    </mat-card>
  </div>
</div>
<div *ngIf="!loaded">
  Loading...
</div>
