<mat-sidenav-container class="example-container" (backdropClick)="sidenav.close()" >
  <mat-sidenav #sidenav (keydown.escape)="sidenav.close()" disableClose position="end" class="side_bar">
    <app-prospect [id]="selected_row"></app-prospect>
    &nbsp;
  </mat-sidenav>

  <mat-sidenav-content>

<div class="panel_div">

<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="0px" fxLayoutGap.lt-md="0px" fx-layout-align="end">
    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" multiple (change)="toggleChangeService($event)" [(ngModel)]="tabsSelected">
      <mat-button-toggle *ngFor="let tab of tabs" value="{{tab.viewValue}}">{{'interface.'+tab.viewValue | translate}}</mat-button-toggle>
    </mat-button-toggle-group>

    <span class="spacer"></span>

    <button mat-button (click)="getRows()">Update</button>
    <button mat-button (click)="resetFilter()">Reset Filter</button>
</div>


  <div class="example-table-container mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" class='operations_table example-table' matSort #matsort>

    <ng-container matColumnDef="select" >
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row"> <button mat-stroked-button  (click)="rowClick(row, $event)" >{{ 'interface.go' | translate }}</button> </td>
      <td mat-footer-cell *matFooterCellDef>  </td>
    </ng-container>


    <ng-container matColumnDef="status_name">
      <th mat-header-cell *matHeaderCellDef>
        <!-- <mat-form-field class="filter" floatLabel="never">
            <input matInput >
        </mat-form-field> -->
      </th>
      <td mat-cell *matCellDef="let element">
      {{'interface.'+ element['status_name'] | translate}}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="owner_name">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['owner_name']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
      {{ element['owner_name'] }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['name']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
      {{ element['name']}}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="next_objective">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['next_objective']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
      {{ element['next_objective']}}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="next_date">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
          <mat-date-range-input [rangePicker]="picker1">
            <input matStartDate [formControl]="colFilter['next_date_start']"  placeholder="Start date">
            <input matEndDate [formControl]="colFilter['next_date_end']"  placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-date-range-picker #picker1></mat-date-range-picker>
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
      {{ element['next_date']| date:"MMM d, y"}}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="goal">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
          <mat-date-range-input [rangePicker]="picker1">
            <input matStartDate [formControl]="colFilter['goal_start']"  placeholder="Start date">
            <input matEndDate [formControl]="colFilter['goal_end']"  placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-date-range-picker #picker1></mat-date-range-picker>
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
      {{ element['goal'] | date:"MMM d, y"}}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>




    <!-- sort -->

    <ng-container matColumnDef="sort_select" >
      <th mat-header-cell *matHeaderCellDef></th>
    </ng-container>

    <ng-container matColumnDef="sort_status_name">
      <th mat-header-cell *matHeaderCellDef> {{'interface.status'| translate}}
      </th>
    </ng-container>

    <ng-container matColumnDef="sort_owner_name">
      <th mat-header-cell *matHeaderCellDef> {{'interface.owner' | translate}}
      </th>
    </ng-container>

    <ng-container matColumnDef="sort_name">
      <th mat-header-cell *matHeaderCellDef> {{'interface.name' | translate}}
      </th>
    </ng-container>

    <ng-container matColumnDef="sort_next_objective">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'interface.next_objective' | translate}}
      </th>
    </ng-container>

    <ng-container matColumnDef="sort_next_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'interface.next_date' | translate}}
      </th>
    </ng-container>

    <ng-container matColumnDef="sort_goal">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'interface.goal' | translate}}
      </th>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumnsSort; sticky: true"></tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>

  </table>
  </div>

</div>

</mat-sidenav-content>
</mat-sidenav-container>
