import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { AngularMaterialModule } from './angular-material.module';
import { BrowserModule } from '@angular/platform-browser';
import { SlidenavComponent } from './slidenav/slidenav.component';
import { OperationComponent } from './operation/operation.component';
import { OperationmComponent } from './operationm/operationm.component';
import { ServicenmComponent } from './servicenm/servicenm.component';
import { OperationsComponent } from './operations/operations.component';
import { ServicesComponent } from './operations/services.component';
import { BalanceComponent } from './statements/balance.component';
import { GifiComponent } from './statements/gifi.component';
import { GeneralledgerComponent } from './statements/generalledger.component';
import { ProfitandlossComponent } from './statements/profitandloss.component';
import { VoucherComponent } from './statements/voucher.component';
import { RevisionComponent } from './statements/revision.component';
import { JournalComponent } from './statements/journal.component';
import { AccountsComponent } from './accounts/accounts.component';
import { ClientsComponent } from './clients/clients.component';
import { ClientComponent } from './client/client.component';

import { VendorsComponent } from './vendors/vendors.component';
import { VendorComponent } from './vendor/vendor.component';

import { ProspectsComponent } from './prospects/prospects.component';
import { ProspectComponent } from './prospects/prospect.component';
import { NewprospectComponent } from './prospects/newprospect.component';

import { QuotesComponent } from './operations/quotes.component';
import { QuotesnmComponent } from './operationm/quotesnm.component';
import { LedgersComponent } from './ledgers/ledgers.component';
import { LedgerComponent } from './ledger/ledger.component';

import { FactoringsComponent } from './invoices/factorings.component';
import { FacturasComponent } from './invoices/facturas.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { PaymentsComponent } from './payments/payments.component';
import { CommissionsComponent } from './commissions/commissions.component';

import { NewquoteComponent } from './operations/newquote.component';
import { NewoperationComponent } from './operations/newoperation.component';


import {
	MatMomentDateModule,
	MomentDateAdapter, MAT_MOMENT_DATE_FORMATS, MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MAT_DATE_LOCALE } from '@angular/material/core';
//import {DatePickerModule} from './DatePickerModule.module';



import { ContractsComponent } from './contracts/contracts.component';
import { VendordialogComponent } from './vendordialog/vendordialog.component';
import { CarrierdialogComponent } from './vendordialog/carrierdialog.component';
import { NewcarrierdialogComponent } from './vendordialog/newcarrierdialog.component';
import { EditcarrierdialogComponent } from './vendordialog/editcarrierdialog.component';
import { DriverdialogComponent } from './vendordialog/driverdialog.component';
import { SitedialogComponent } from './vendordialog/sitedialog.component';
import { NewsitedialogComponent } from './vendordialog/newsitedialog.component';
import { NewvendordialogComponent } from './vendordialog/newvendordialog.component';
import { ContractComponent } from './contract/contract.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
//import { HttpClientModule } from '@angular/common/http';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe } from '@angular/common';



import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';


import { HttpErrorInterceptor } from './http-error.interceptor';
import { LoginComponent } from './login/login.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { DemoMaterialModule } from './materials.module';
import { MessagesComponent } from './messages/messages.component';
import { NewVendorComponent } from './newvendor/newvendor.component';
import { EventComponent } from './event/event.component';
import { SiteComponent } from './site/site.component';
import { EditableTableComponent } from './editableTable/editableTable.component';
import { EditableNotesComponent } from './editableNotes/editableNotes.component';
import { EditableFormComponent } from './editableForm/editableForm.component';
import { EditableTableFilterComponent } from './editableTableFilter/editableTableFilter.component';
import { ContracttoolstabsComponent } from './contract/contracttoolstabs.component';
import { ContractnmComponent } from './contractnm/contractnm.component';
import { ContractnmBudgetsComponent } from './contractnm/contractnmBudgets.component';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
//HomeModule
// const routes: Routes = [
//   {
//     path: '',
//     component: HomeComponent,
//     canActivate: [ AuthGuardService ]
//   }
// ];

//trasnlation
// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}



@NgModule({
  declarations: [
    AppComponent,
    SlidenavComponent,
    OperationsComponent,OperationComponent,LoginComponent,ContractComponent,
		ServicesComponent,
		BalanceComponent,
		GifiComponent,
		GeneralledgerComponent,
		ProfitandlossComponent,
		VoucherComponent,
		RevisionComponent,
		JournalComponent,
		AccountsComponent,
    OperationmComponent,
		ServicenmComponent,
    ContractsComponent,
    VendordialogComponent,
		CarrierdialogComponent,
		NewcarrierdialogComponent,
		EditcarrierdialogComponent,
    DriverdialogComponent,
    ClientsComponent,
    VendorsComponent,
    VendorComponent,
		ProspectComponent,
		ProspectsComponent,
		NewprospectComponent,
    ClientComponent,
    SitedialogComponent,
		NewsitedialogComponent,
    NewvendordialogComponent,
    MessagesComponent,
    NewVendorComponent,
    EventComponent,
		SiteComponent,
    EditableTableComponent,
		EditableNotesComponent,
		EditableFormComponent,
		EditableTableFilterComponent,
    ContracttoolstabsComponent,
		ContractnmComponent,
		CommissionsComponent,
		ContractnmBudgetsComponent,
    QuotesComponent,
		QuotesnmComponent,
    LedgersComponent,
    LedgerComponent,
    FacturasComponent,
		FactoringsComponent,
    InvoicesComponent,
		PaymentsComponent,
    NewquoteComponent,
    NewoperationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    NgxMatFileInputModule,
    NgxMaterialTimepickerModule,

    //DatePickerModule,
    MatMomentDateModule,
		MatDatepickerModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

    //RouterModule.forChild(routes), //HOME
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    MatCardModule,
    FlexLayoutModule,
//    FormsModule, ReactiveFormsModule,

    HttpClientModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
   {
     provide: HTTP_INTERCEPTORS,
     useClass: HttpErrorInterceptor,
     multi: true
   },
   // {
		// 	provide: MAT_DATE_LOCALE,
		// 	useValue: 'en-GB'
		// },
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
		},
		{
			provide: MAT_DATE_FORMATS,
			useValue: MAT_MOMENT_DATE_FORMATS
		},
		{
			provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
			useValue: { strict: true }
		},


    DatePipe
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }
