import {HttpClient} from '@angular/common/http';
import {AfterViewInit, Component, ViewChild, OnInit} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-vendordialog',
  templateUrl: './vendordialog.component.html',
  styleUrls: ['./vendordialog.component.css']
})
export class VendordialogComponent implements AfterViewInit, OnInit {
    displayedColumns: string[] = ['select','name','tax_number', 'address', 'city','state'];
    dataSource : MatTableDataSource <any>;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(
      private apiService: ApiService
    )
    {}

    ngAfterViewInit() {
      this.getRows();
      //this.dataSource.paginator = this.paginator;
      //this.dataSource.sort = this.sort;
    }
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }

    ngOnInit(): void {

    }

    getRows( ): void {
      this.apiService.getData('vendors/list' )
        .subscribe(param =>
          {
            this.dataSource = new MatTableDataSource<any>(param.data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;


          });
    }
    clickSelectVendor(event: Event)
    {

    }

}
