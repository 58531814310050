import { Component, OnInit } from '@angular/core';
import { Hero } from './hero';
import { SlidenavService } from '../services/slidenav.service';

@Component({
  selector: 'app-slidenav',
  templateUrl: './slidenav.component.html',
  styleUrls: ['./slidenav.component.css']
})
export class SlidenavComponent implements OnInit {


  heroes: Hero[];

  constructor(private heroService: SlidenavService) { }

  ngOnInit(): void {
    this.getHeroes();
  }

  getHeroes(): void {
    this.heroService.getHeroes()
      .subscribe(heroes => this.heroes = heroes);
  }



}
