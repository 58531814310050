//import { Component, OnInit,ViewChild } from '@angular/core';
import {Component, Input,ElementRef, ViewChild, OnInit, AfterViewInit} from '@angular/core';
import {MatSort} from '@angular/material/sort';

import { ApiService } from '../services/api.service';
import { MatTableDataSource } from '@angular/material/table';
import {Router} from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import {MatSidenav} from '@angular/material/sidenav';
import { AppConstants } from '../structures/appconstants';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-prospects',
  templateUrl: './prospects.component.html',
  styleUrls: ['./prospects.component.css']
})

export class ProspectsComponent implements OnInit,AfterViewInit {

  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild(MatSort) sort: MatSort;

  tabsSelected: any;
  tabsSelectedContract: any;
  loaded:boolean;
  selected_row : number;
  filterFormGroup :FormGroup;
  filter = {};

  displayedColumns: string[] = ['select','status_name','owner_name','name','next_objective','next_date','goal'];
  displayedColumnsSort : string[] = ['sort_status_name','sort_select','sort_owner_name','sort_name','sort_next_objective','sort_next_date','sort_goal'];

  filterValues = {
    status_name: '',
    owner_name: '',
    name: '',
    next_objective: '',
    next_date_start: '',
    next_date_end: '',
    goal_start: '',
    goal_end: ''
  };

  displayedColumnsFooter: string[] = [];
  dataSource : MatTableDataSource<any>;
  colFilter;

  toggleChangeService(event){

    this.filterValues['status_name'] = event.value;
    this.dataSource.filter = JSON.stringify(this.filterValues);
  }

  resetFilter()
  {
    for (let i of Object.keys(this.colFilter))
    {
      this.colFilter[i].setValue('');
    }
    this.tabsSelected = [];
    //this.filterValues['status_name'] = [];
    this.dataSource.filter = JSON.stringify(this.filterValues);
  }

  ngOnInit(): void
  {
    for (let i of Object.keys(this.colFilter))
    {

      this.colFilter[i].valueChanges
       .pipe(
         debounceTime(1000), // Waiting for 1 sec while you are typing
          distinctUntilChanged() // Prevents the emitting if the 'start' value and the 'end' value are the same
       )
      .subscribe(
        value => {
        //  if((i == 'pickups_dates_start') || (i == 'pickups_dates_end') || (i == 'deliveries_dates_start') || (i == 'deliveries_dates_end'))
        //    this.filterValues[i] = this.datepipe.transform(value, 'yyyy-MM-dd');
      //    else
            this.filterValues[i] = value;
          this.dataSource.filter = JSON.stringify(this.filterValues);

        }
      )
    }
  }



  constructor( private apiService: ApiService, private router: Router, fb: FormBuilder,public datepipe: DatePipe)
  {
    this.loaded = false;
    this.getRows();
    this.colFilter = {};
    let properties = Object.keys(this.filterValues);
    for (let i of properties) {
      this.colFilter[i] = new FormControl('');
    }


  }


  createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function(data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      let properties = Object.keys(searchTerms);

      for (let i of properties) {

        if((searchTerms[i] =='')|| searchTerms[i] == null){
            continue;
        }
        else if( (i == 'status_name') )
        {
          if(searchTerms['status_name'].indexOf(data['status_name']) == -1)
            return false;
        }

        else if( i == 'next_date_start')
        {
          let result = false;
          if( data['next_date'] >= searchTerms['next_date_start'] ) result = true;

          if(!result) return false;
        }
        else if( i == 'next_date_end')
        {
          let result = false;
          if( data['next_date'] <= searchTerms['next_date_end'] ) result = true;
          if(!result) return false;
        }

        else if( i == 'goal_start')
        {
          let result = false;
          if( data['goal'] >= searchTerms['goal_start'] ) result = true;

          if(!result) return false;
        }
        else if( i == 'goal_end')
        {
          let result = false;
          if( data['goal'] <= searchTerms['goal_end'] ) result = true;
          if(!result) return false;
        }

        else
        {
          if( (!data[i]) || (data[i]?.toString().toLowerCase().indexOf(searchTerms[i].toLowerCase())  === -1 ))
          {
            return false;
          }
        }
      }
      return true;
    }
    return filterFunction;
  }

  rowClick( row, event )
  {
    if (( event.metaKey ) || (event.ctrlKey))
    {
      var win = window.open('/prospect_detail/'+ row.id , '_blank');
      if (win) {
          win.focus();
      } else {
          alert('Please allow popups for this website');
      }
    }
    else
    {
      this.selected_row = row.id;
  //  this.router.navigate(['/operation_detail/' + row.id  ]);
      this.sidenav.open();
    }

  }

  getRows(): void {
    this.apiService.getData( 'prospects/list',this.filter )
      .subscribe(param =>
        {
          if(this.dataSource)
            this.dataSource.data  = param.data;
          else
            this.dataSource = new MatTableDataSource<any>(param.data)
          this.loaded = true;

          this.dataSource.filterPredicate = this.createFilter();

          this.dataSource.sort = this.sort;

          this.dataSource.sortData = this.createSort();

        });
  }

  createSort(): (data: any[], sort: MatSort) => any[]
  {
      let sortData = function(data: any[], sort: MatSort) : any[]{
      var active = sort.active;
      const direction = sort.direction;
      if (!active || direction == '') { return data; }

      active = sort.active.slice(5);
      return data.sort((a, b) => {

        let valueA = null;// = this.sortingDataAccessor(a, active);
        let valueB = null;// = this.sortingDataAccessor(b, active);

        switch(active)
        {
          // case 'carriers':
          //   if(a.contracts.length)
          //     valueA = a.contracts[0].vendor_name;
          //   if(b.contracts.length)
          //     valueB = b.contracts[0].vendor_name;
          // break;

          default:
            valueA = this.sortingDataAccessor(a, active);
            valueB = this.sortingDataAccessor(b, active);
          break;

        }


        // If there are data in the column that can be converted to a number,
        // it must be ensured that the rest of the data
        // is of the same type so as not to order incorrectly.
        const valueAType = typeof valueA;
        const valueBType = typeof valueB;

        if (valueAType !== valueBType) {
          if (valueAType === 'number') { valueA += ''; }
          if (valueBType === 'number') { valueB += ''; }
        }

        // If both valueA and valueB exist (truthy), then compare the two. Otherwise, check if
        // one value exists while the other doesn't. In this case, existing value should come last.
        // This avoids inconsistent results when comparing values to undefined/null.
        // If neither value exists, return 0 (equal).
        let comparatorResult = 0;
        if (valueA != null && valueB != null) {
          // Check if one value is greater than the other; if equal, comparatorResult should remain 0.
          if (valueA > valueB) {
            comparatorResult = 1;
          } else if (valueA < valueB) {
            comparatorResult = -1;
          }
        } else if (valueA != null) {
          comparatorResult = 1;
        } else if (valueB != null) {
          comparatorResult = -1;
        }

        return comparatorResult * (direction == 'asc' ? 1 : -1);
      });
    }
    return sortData;
  }



  get tabs() {
        return AppConstants.tabs_prospects_id;
    }


  ngAfterViewInit() {
    //this.dataSource.sort = this.sort;
  }
}
