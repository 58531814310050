<mat-card class="example-card">
  <mat-card-header>
    <mat-card-title>Factoring</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <app-editable-table-filter
    [headers]="[ {name:'name'},{name:'description'},{name:'bank'},{name:'transit'},{name:'account'},{name:'file',type:'file',icon:'insert_drive_file',callback:this.fileCallback,required:true},{name:'email'}]"
    [data]='data'
    updateUrl="{{'accounting/factoring/update'}}"
    newUrl="{{'accounting/factoring/store'}}"
    removeUrl="{{'accounting/factoring/remove'}}">
    </app-editable-table-filter>

  </mat-card-content>
</mat-card>
