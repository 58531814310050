import {Component, Input,ElementRef, ViewChild, OnInit, AfterViewInit} from '@angular/core';
import { ApiService } from '../services/api.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { AppConstants } from '../structures/appconstants';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from "html-to-pdfmake";


@Component({
  selector: 'app-gifi',
  templateUrl: './gifi.component.html',
  styleUrls: ['./gifi.component.css']
})

export class GifiComponent implements OnInit
{
  @ViewChild('print_content') content: ElementRef;
  loaded:boolean = false;
  data;
  filterFormGroup :FormGroup;
  filter = {};

  ngOnInit(): void
  {

  }

  constructor( private apiService: ApiService, fb: FormBuilder,public datepipe: DatePipe)
  {

    this.filterFormGroup = fb.group({
      date: [
        new Date('7/31/2021'),
        []
      ]
    });

  }

  public templateToFormData( object ) {
    console.log(object);
    const formData = new FormData();
    for ( const key of Object.keys(object) )
    {
      if (Array.isArray(object[key]))
      {
        for ( let a of object[key] )
          formData.append(key+'[]', a);
      }
      else
      {
        if( object[key]=== null)
          formData.append(key, '');
        else if( object[key]=== false)
          formData.append(key, '0');
        else if( key == 'date')
        {
          const  latest_date =this.datepipe.transform(object[key], 'yyyy-MM-dd');
          formData.append(key, latest_date);
        }
        else
          formData.append(key, object[key]);
      }
    }
    return formData;
  }


  getGifi()
  {
    const formData = this.templateToFormData(this.filterFormGroup.value);
    this.apiService.getData( 'statements/gifi', formData)
      .subscribe(param =>
        {


          this.data = param;
          this.data.equity.children.push({ children: null, gifi: '3680', name:'Period Results', saldo: this.data.revenues.saldo - this.data.expenses.saldo});
          this.data.equity.saldo += this.data.revenues.saldo - this.data.expenses.saldo;
          this.loaded = true;
        });

  }

}
