<div *ngIf="loaded" fxLayout="column">

  <h1>V {{data.service_id}}</h1>


<ng-container *ngFor="let contract of data.contracts">
  <mat-card *ngFor="let stop of contract.stops">
  <mat-card-subtitle fxLayout>
    <span>{{stop.stop_type_name | uppercase}}</span>
    <span class="spacer"></span>
    <span>{{stop.ref_number}}</span>
  </mat-card-subtitle>
  <mat-card-title>{{stop.site_name}}</mat-card-title>
  <mat-card-subtitle>{{stop.address}}<br/>
  {{stop.city_name}}, {{stop.state}} {{stop.zip_code}}</mat-card-subtitle>

  <mat-card-content>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
      <div fxFlex>

      </div>
    </div>

    <div *ngIf="stop.departure_date; else elseScheduled">
      Completed on: {{stop.departure_date}}
    </div>

    <ng-template #elseScheduled>
      Scheduled for: {{stop.appointment_from_date}} {{stop.appointment_from_time}}
    </ng-template>

  </mat-card-content>
  <mat-divider inset></mat-divider>
  <mat-card-actions>
    <div class="table_div">
      <div class="row_div">
          <div class="th_div">
            Description
          </div>
          <div class="th_div">
            Quantity
          </div>
          <div class="th_div">
            Units
          </div>
          <div class="th_div">
            Weight
          </div>
          <div class="th_div">
            Units
          </div>
          <div class="th_div">
            Length
          </div>
          <div class="th_div">
            Width
          </div>
          <div class="th_div">
            Height
          </div>

      </div>
      <div class="row_div" *ngFor="let po of stop.stoppos; let j=index">
          <div class="cell_div">
            {{po.po.description}}
          </div>
          <div class="cell_div">
            {{po.po.number}}
          </div>

          <div class="cell_div">
            {{getStructureValue('po_units_id',po.po.po_units_id)}}
          </div>

          <div class="cell_div">
            {{po.po.weight}}
          </div>

          <div class="cell_div">
            {{getStructureValue('po_weight_id',po.po.po_weight_id)}}
          </div>


          <div class="cell_div">
            {{po.po.length}}
          </div>

          <div class="cell_div">
            {{po.po.width}}
          </div>


          <div class="cell_div">
            {{po.po.height}}
          </div>


      </div>
    </div>


  </mat-card-actions>
  <mat-card-footer>

  </mat-card-footer>
</mat-card>
</ng-container>



<mat-card>
<mat-card-header>
  <mat-card-subtitle>Budgets</mat-card-subtitle>
</mat-card-header>
<mat-card-content>
  <app-editable-table #budgetsEditableTable
  [headers]="[ {name:'invoice',type:'static_field'},{name:'expense_type_id',type:'selectList',default:1}, {name:'unit_price',fieldChange:true},{name:'unit_type_id',type:'selectList',default:1},{name:'quantity',fieldChange:true},{name:'rate', readonly:true}]"
  [data]='data.service_budgets'
  >
  </app-editable-table>
</mat-card-content>
</mat-card>



<mat-card *ngIf="data.status <= 6">
<mat-card-header>
  <mat-card-subtitle>Invoices</mat-card-subtitle>
</mat-card-header>
<mat-card-content>
  <app-editable-table #invoicesEditableTableId
   [headers]="[{name:'date',required:true,type:'date'},{name:'invoice_number',type:'static_field'},{name:'sub_total_books',required:true,readonly:true},{name:'hst_books',required:true,readonly:true},{name:'total_books',required:true,readonly:true},{name:'file',type:'button',icon:'insert_drive_file',callback:this.fileCallback}]"
   [data]='data.invoices'
   >
  </app-editable-table>
</mat-card-content>
</mat-card>







<ng-container *ngFor="let contract of data.contracts">

<mat-card>
  <mat-card-header>
    <mat-card-subtitle>Documents Visible to Customer</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <app-editable-table
    [headers]="[ {name:'contract_document_type_id',type:'selectList'}, {name:'file',type:'file',icon:'insert_drive_file',callback:this.fileCallbackDocuments,required:true}]"
    [data]='contract.contract_documents'
    >
    </app-editable-table>
  </mat-card-content>
</mat-card>
</ng-container>

</div>

<div *ngIf="!loaded">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
