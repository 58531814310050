import { Component, OnInit } from '@angular/core';
//import { Simple } from '../structures/simple';
import { ContractsStructure } from '../structures/contracts.structure';
import { MatTableDataSource } from '@angular/material/table';
import {Router} from '@angular/router';

import { ApiService } from '../services/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-contracts',
  templateUrl: '../shared/panel.component.html',
  styleUrls: ['../shared/panel.component.css']
})
export class ContractsComponent implements OnInit {

  //contracts : ContractsStructure;
  loaded: boolean
  tabs;
  clients;
  filterFormGroup :FormGroup;
  filter = {};


  displayedColumns: string[] = ['select','contract_id','vendor_name','placa','client_name','client_ref','pickups','pickups_dates','deliveries','deliveries_dates'];
  dataSource : MatTableDataSource <any>;

  totalGM()
  {
    return this.dataSource.data.reduce((datum, val) => datum+= val.cell('gm'), 0);
  }


  constructor(
    private router: Router,
    private apiService : ApiService,
    fb: FormBuilder
  ) {

    this.loaded = false;
  this.filterFormGroup = fb.group({
    client: [
      '',
      []
    ],
    pickup_start: [
      '',
      []
    ],
    pickup_end: [
      '',
      []
    ],
    delivery_start: [
      '',
      []
    ],
    delivery_end: [
      '',
      []
    ]
  });
}


  ngOnInit(): void {
      this.getClients();
      this.getTabs();
      this.getRows();

  }
  getTabs(): void {
    this.apiService.getData('contracts/tabs')
      .subscribe(tabs => this.tabs = tabs);
  }

  getClients(): void {
    this.apiService.getData('clients/list')
      .subscribe(clients => this.clients = clients);
  }

  rowClick( row, event ) {
  if (( event.metaKey ) || (event.ctrlKey))
  {
    var win = window.open('/contract_detail/'+ row.id , '_blank');
    if (win) {
        win.focus();
    } else {
        alert('Please allow popups for this website');
    }
  }
    else
//    OperationComponent.set_row(row.id);//.selected_row = row.id;
    this.router.navigate(['/contract_detail/' + row.id  ]);

  }

  resetFilter(): void {
    this.filter = {};
    this.filterFormGroup.reset();
    this.getRows();
  }

  applyFilter(): void {
    this.filter = this.filterFormGroup.value;
    this.getRows();
  }
  tabClick( event):void {
    if(this.dataSource)
    {
      this.dataSource.filterPredicate = (data: any, filter: string) => {
      if( filter == 'all')
        return true;
      return data.status_name == filter;
     };
    this.dataSource.filter = this.tabs[event.index].name;
    }

  }
  getRows(): void {
    console.log('getRows');
    this.apiService.getData( 'contracts/list',this.filter )
      .subscribe(param => {
        if(this.dataSource)
          this.dataSource.data  = param;
        else
          this.dataSource = new MatTableDataSource<any>(param)
        this.loaded = true;
      });
  }
}
