<div *ngIf="loaded">
<mat-sidenav-container class="example-container" (backdropClick)="sidenav.close()" >
  <mat-sidenav #sidenav (keydown.escape)="sidenav.close()" disableClose position="end" class="side_bar">
    <app-servicenm [id]="selected_row"></app-servicenm>
    &nbsp;
  </mat-sidenav>

  <mat-sidenav-content>

<div class="panel_div">

<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="0px" fxLayoutGap.lt-md="0px" fx-layout-align="end">
    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" multiple (change)="toggleChangeService($event)" [(ngModel)]="tabsSelected">
      <mat-button-toggle *ngFor="let tab of tabs" value="{{tab.name}}">{{'interface.'+tab.name | translate}}</mat-button-toggle>
    </mat-button-toggle-group>

    <span class="spacer"></span>


    <span class="spacer"></span>

    <button mat-button (click)="getRows()">Update</button>
    <button mat-button (click)="resetFilter()">Reset Filter</button>
</div>


  <div class="example-table-container mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" class='operations_table example-table' matSort #matsort>

    <ng-container matColumnDef="select" >
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <button mat-icon-button mat-stroked-button color="primary" (click)="rowClick(row, $event)" >


            <mat-icon>menu</mat-icon>
        </button>
      </td>
      <td mat-footer-cell *matFooterCellDef>  </td>
    </ng-container>


    <ng-container matColumnDef="status_name">
      <th mat-header-cell *matHeaderCellDef>
        <!-- <mat-form-field class="filter" floatLabel="never">
            <input matInput >
        </mat-form-field> -->
      </th>
      <td mat-cell *matCellDef="let element">
      {{'interface.'+ element['status_name'] | translate}}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="service_id">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['service_id']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
          {{ element['service_id'] }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="carrier_coordinator_name">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['carrier_coordinator_name']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
      {{ element['carrier_coordinator_name'] }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>


    <ng-container matColumnDef="account_manger_name">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['account_manger_name']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
      {{ element['account_manger_name'] }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>



    <ng-container matColumnDef="client_name">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['client_name']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
      {{ element['client_name']}}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="carriers">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['carriers']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-list role="list" *ngFor="let carrier of element['contracts']">
          {{carrier.vendor_name}}
        </mat-list>
      </td>

      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>


    <ng-container matColumnDef="carriers_pro">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['carriers_pro']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-list role="list" *ngFor="let carrier of element['contracts']">
          {{carrier.pro}}
        </mat-list>
      </td>

      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>


    <ng-container matColumnDef="client_ref">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['client_ref']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
      {{ element['client_ref']}}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="pickups_ref">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['pickups_ref']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-list role="list" *ngFor="let carrier of element['contracts']">
          <mat-list role="list" *ngFor="let stop of carrier['pickups']">
            {{stop.ref_number}}
          </mat-list>
        </mat-list>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="pickups">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['pickups']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-list role="list" *ngFor="let carrier of element['contracts']">
          <mat-list role="list" *ngFor="let stop of carrier['pickups']">
            {{stop.city_name}} | {{stop.site_name}}
          </mat-list>
        </mat-list>


      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>


    <ng-container matColumnDef="deliveries_ref">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['deliveries_ref']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-list role="list" *ngFor="let carrier of element['contracts']">
          <mat-list role="list" *ngFor="let stop of carrier['deliveries']">
            {{stop.ref_number}}
          </mat-list>
        </mat-list>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="deliveries">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['deliveries']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-list role="list" *ngFor="let carrier of element['contracts']">
          <mat-list role="list" *ngFor="let stop of carrier['deliveries']">
            {{stop.city_name}} | {{stop.site_name}}
          </mat-list>
        </mat-list>


      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>


    <ng-container matColumnDef="pickups_dates">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
          <mat-date-range-input [rangePicker]="picker1">
            <input matStartDate [formControl]="colFilter['pickups_dates_start']"  placeholder="Start date">
            <input matEndDate [formControl]="colFilter['pickups_dates_end']"  placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-date-range-picker #picker1></mat-date-range-picker>
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-list role="list" *ngFor="let carrier of element['contracts']">
          <mat-list role="list" *ngFor="let stop of carrier['pickups']">
             <span [ngClass]="{'red': !stop.departure_date}">
            {{(stop.departure_date?stop.departure_date:stop.appointment_from_date) | date:"MMM d, y"}}
            {{(stop.departure_date?'':stop.appointment_from_time) | slice:0:-3}}
            </span>
          </mat-list>
        </mat-list>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="deliveries_dates">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
          <mat-date-range-input [rangePicker]="picker2">
            <input matStartDate [formControl]="colFilter['deliveries_dates_start']"  placeholder="Start date">
            <input matEndDate [formControl]="colFilter['deliveries_dates_end']"  placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-date-range-picker #picker2></mat-date-range-picker>
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-list role="list" *ngFor="let carrier of element['contracts']">
          <mat-list role="list" *ngFor="let stop of carrier['deliveries']">
             <span [ngClass]="{'red': !stop.departure_date}">
            {{(stop.departure_date?stop.departure_date:stop.appointment_from_date) | date:"MMM d, y"}}
            {{(stop.departure_date?'':stop.appointment_from_time) | slice:0:-3}}
            <!-- {{(stop.departure_date ?? stop.appointment_from_date) | date:"MMM d, y"}} -->
            </span>
          </mat-list>
        </mat-list>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="mode_name">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['mode_name']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
      {{ element['mode_name']}}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="skids">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['skids']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
      {{ element['skids']}}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="weight">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['weight']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
      {{ element['weight']}}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>



    <ng-container matColumnDef="revenue">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['revenue']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
      {{ element['revenue'] | number:'.2'}}
      </td>
      <td mat-footer-cell *matFooterCellDef> {{totalRev() | number:'.2'}} </td>
    </ng-container>

    <ng-container matColumnDef="costs">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['costs']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
      {{ element['costs'] | number:'.2'}}
      </td>
      <td mat-footer-cell *matFooterCellDef> {{totalCosts() | number:'.2'}} </td>
    </ng-container>

    <ng-container matColumnDef="gm">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['gm']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
        {{element['revenue']-element['costs'] | number:'.2'}}
      </td>
      <td mat-footer-cell *matFooterCellDef> {{totalGM() | number:'.2'}} </td>
    </ng-container>


    <!-- sort -->

    <ng-container matColumnDef="sort_select" >
      <th mat-header-cell *matHeaderCellDef></th>
    </ng-container>

    <ng-container matColumnDef="sort_status_name">
      <th mat-header-cell *matHeaderCellDef> {{'interface.status'| translate}}
      </th>
    </ng-container>

    <ng-container matColumnDef="sort_service_id">
      <th mat-header-cell *matHeaderCellDef> {{'interface.service' | translate}}
      </th>
    </ng-container>

    <ng-container matColumnDef="sort_carrier_coordinator_name">
      <th mat-header-cell *matHeaderCellDef>Carrier Coordinator
      </th>
    </ng-container>

    <ng-container matColumnDef="sort_account_manger_name">
      <th mat-header-cell *matHeaderCellDef>Account Manager
      </th>
    </ng-container>

    <ng-container matColumnDef="sort_client_name">
      <th mat-header-cell *matHeaderCellDef> {{'interface.client' | translate}}
      </th>
    </ng-container>


    <ng-container matColumnDef="sort_carriers_pro">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'interface.carriers' | translate}}
      </th>
    </ng-container>

    <ng-container matColumnDef="sort_carriers">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'interface.carriers' | translate}}
      </th>
    </ng-container>

    <ng-container matColumnDef="sort_client_ref">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'interface.client_ref' | translate}}
      </th>
    </ng-container>

    <ng-container matColumnDef="sort_pickups_ref">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'interface.pickups_ref' | translate}}
      </th>
    </ng-container>

    <ng-container matColumnDef="sort_pickups">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'interface.pickups' | translate}}
      </th>
    </ng-container>

    <ng-container matColumnDef="sort_deliveries_ref">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'interface.deliveries_ref' | translate}}
      </th>
    </ng-container>

    <ng-container matColumnDef="sort_deliveries">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'interface.deliveries' | translate}}
      </th>
    </ng-container>

    <ng-container matColumnDef="sort_pickups_dates">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'interface.pickups_dates' | translate}}
      </th>
    </ng-container>

    <ng-container matColumnDef="sort_deliveries_dates">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'interface.deliveries_dates' | translate}}
      </th>
    </ng-container>

    <ng-container matColumnDef="sort_mode_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'interface.mode_name' | translate}}
      </th>
    </ng-container>

    <ng-container matColumnDef="sort_skids">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'interface.skids' | translate}}
      </th>
    </ng-container>

    <ng-container matColumnDef="sort_weight">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'interface.mode_name' | translate}}
      </th>
    </ng-container>

    <ng-container matColumnDef="sort_revenue">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'interface.revenue' | translate}}
      </th>
    </ng-container>

    <ng-container matColumnDef="sort_costs">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'interface.costs' | translate}}
      </th>
    </ng-container>

    <ng-container matColumnDef="sort_gm">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'interface.gm' | translate}}
      </th>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumnsSort; sticky: true"></tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>

  </table>
  </div>

</div>

</mat-sidenav-content>
</mat-sidenav-container>
</div>
<div *ngIf="!loaded">
  Loading...
</div>
