import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ApiService } from '../services/api.service';
import { PdfService } from '../services/pdf.service';
import { AppComponent } from '../app.component';
//private app: AppComponent
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { VendordialogComponent } from '../vendordialog/vendordialog.component';
import { DriverdialogComponent } from '../vendordialog/driverdialog.component';
import { NewvendordialogComponent } from '../vendordialog/newvendordialog.component';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from "html-to-pdfmake";

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.css']
})
export class ContractComponent implements OnInit {

    public data : any;

    form: FormGroup;
    loaded: boolean;
    errors: boolean;
    tryDoctype: any;
  constructor(
    fb: FormBuilder,
    private apiService: ApiService,
    private pdfService: PdfService,
    private route: ActivatedRoute,
    private location: Location,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public translate: TranslateService,
    private app: AppComponent

  ) {
    this.loaded = false;
    this.form = fb.group({
      vendor_id: [
        '',
        [Validators.required]
      ],
      vendor: [
        '',
        [Validators.required]
      ],
      driver_id: [
        '',
        [Validators.required]
      ],
      driver: [
        '',
        [Validators.required]
      ],
      placa: [
        '',
        []
      ],
      rate: [
        '',
        []
      ],
      currency: [
        '',
        [Validators.required]
      ],
    });


  }

  private dialogParams = {
    width: 'calc( 100% - 50px )',
    height: 'calc( 100% - 50px )',
    maxWidth: '100vw',
    maxHeight: '100vh',
  };
  public vendor = { name: null, id :null};
  public driver = { name: null, id :null};

  public currencies = [
    {value: '1', viewValue: 'interface.bolivianos'},
    {value: '2', viewValue: 'interface.dolares_americanos'},
    {value: '3', viewValue: 'interface.dolares_canadienses'}
  ];

  openDialog() {

    const dialogRef = this.dialog.open(VendordialogComponent, this.dialogParams);

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        if(result == 'create_new')
        {
          this.openNewVendor();
        }
        else
          this.vendor = result;
      }

    });
  }
  openDialogDriver() {

    const dialogRef = this.dialog.open(DriverdialogComponent,this.dialogParams);

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        if(result == 'create_new')
        {
            alert('create_carrier');
        }
        else
          this.driver.name = result.name + ' ' + result.lastname;
          this.driver.id = result.id;
      }
    });
  }

  openNewVendor() {

    const dialogRef = this.dialog.open(NewvendordialogComponent, this.dialogParams);

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        this.vendor = result;
      }


    });
  }


  ngOnInit(): void {
    this.getContract();
  }

  get controls() {
    return this.form.controls;
  }


  // ASSIGN VENDOR
  assignvendor() :void{
    if (this.form.valid)
    {

    const id = +this.route.snapshot.paramMap.get('id');
    this.apiService.getData( 'contracts/vendor/store/'+id, this.form.value )
      .subscribe(
        result => {
        this.getContract();
      });
    }

  }


  //cancel
  cancel() :void{

    if(confirm(this.translate.instant('dialogs.cancel_contract')))
    {
      const id = +this.route.snapshot.paramMap.get('id');

      this.apiService.getData( 'contracts/vendor/remove/'+id, {} )
        .subscribe(
          result => {
          this.getContract();
        });
    }
  }








  //print contract
  print_contract() :void{
    const id = +this.route.snapshot.paramMap.get('id');
    this.apiService.getBlob( 'pages/contract_dispatch/pdf/'+id )
      .subscribe(response =>
        {
          var blob = response;
          var fileURL = URL.createObjectURL(blob);
          var documentData = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
        //  this.tryDoctype = documentData;

          console.log('id')
          const id = +this.route.snapshot.paramMap.get('id');
          let url = 'pages/contract_dispatch/email/' +id;
          let filename = 'LC'+ this.data.detail.contract_id +'.pdf';
          let subject = 'LC '+ this.data.detail.contract_id + ' ';
          for( var i = 0; i<this.data.detail.stops.length; i++)
          {
            subject = subject + this.data.detail.stops[i].state + '-';
          }
          subject = subject + this.data.detail.vendor_name;
          let message = this.translate.instant('dialogs.lc_email');


          let preview = ""
          //this.app.setSidenav(this.data.detail, subject ,message, documentData, filename, url);
          //this.app.getSidenav().open();

        });



    // let docDefinition = {
    //     header: 'C#Corner PDF Header',
    //     content: htmlToPdfmake("<div> <h1>My title</h1><p>This is a sentence with a <strong>bold word</strong>, <em>one in italic</em>,and <u>one with underline</u>. And finally <a href>a link</a>.</p></div>")
    //   };
    //
    // var blob = pdfMake.createPdf(docDefinition);
    // var callback = function(url) {
    //   var documentData = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    //
    // }
    // blob.getDataUrl(callback.bind(this));





    // var fileURL = URL.createObjectURL(blob);
    // var documentData = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);


    ///this.app.setSideBlob( this.pdfService.createPdf() );




  }

  getContract(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.apiService.getData( 'contracts/contract_detail/'+id )
      .subscribe(param => {
        this.data = param;
        this.loaded = true;
      });
  }



  openBlog( id:number, event)
  {
    //event.target.classList.add('active');
    this.apiService.getBlob( 'documents/view/'+id )
      .subscribe(response =>
        {
          var blob = response;
          //var contentType = response.headers["content-type"];
          //let blob = new Blob([res.error.text.blob()], { type: 'application/pdf' })

          //var blob2 = new Blob([blob], { type: 'application/pdf', title: 'my title ' })


          var fileURL = URL.createObjectURL(blob);
          var documentData = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
          this.tryDoctype = documentData;
          //var blob_iframe = document.querySelector('#blob-src-test');
          //blob_iframe.src = documentData;


          //this.tryDoctype = fileURL;
          //window.open(fileURL);
        });



  }
}
