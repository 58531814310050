<div *ngIf="loaded" fxLayout="column" class="test">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fx-layout-align="end">
    <div fxFlex>
      <h1>{{ data.name }}</h1>
    </div>
    <div fxFlex >
      <h1></h1>
      <h1></h1>
      <h3>

      </h3>
    </div>
  </div>

  <mat-toolbar>
  </mat-toolbar>
  <mat-tab-group mat-align-tabs="start">
    <mat-tab label="Info">
      <dl *ngIf="!is_editting_details; else details_edit" class="editable_form">
        <div class="edit_icon" (click)="is_editting_details=true">
          <mat-icon appearance="outline">edit</mat-icon>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <div fxFlex>
            <dt>Name</dt>
            <dd>{{data.name}}</dd>

            <dt>Address</dt>
            <dd>{{data.address}}</dd>

            <dt>City, State, Zip</dt>
            <dd>{{data.city}}, {{data.state}}, {{data.zip}}</dd>


          </div>
            <!-- <div fxFlex>
              <dt>{{ 'interface.temperature' | translate }}</dt>
              <dd>{{data.temperature?data.temperature:('interface.not_set' | translate) }}</dd>

              <dt>{{ 'interface.mode' | translate }}</dt>
              <dd>{{data.mode_name}}</dd>
            </div> -->

        </div>

      </dl>
      <ng-template #details_edit>
      <dl>
        <form [formGroup]="descriptionFormGroup">
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
            <div fxFlex fxLayout="column">
            <mat-form-field >
              <mat-label >Name</mat-label>
              <input matInput formControlName="name">
            </mat-form-field>

            <mat-form-field fxFlex>
              <mat-label >Address</mat-label>
              <input matInput formControlName="address">
            </mat-form-field>


            <mat-form-field fxFlex>
              <input type="text" placeholder="{{ 'interface.zip' | translate }}" aria-label="Number" matInput formControlName="zip" [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                  {{option.city}}, {{option.state}}, {{option.code}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>


          </div>
          <!-- <div fxFlex fxLayout="column">
            <mat-form-field fxFlex>
              <mat-label >{{ 'interface.temperature' | translate }}</mat-label>
              <input matInput formControlName="temperature" [ngModel]="data.temperature">
            </mat-form-field>

            <mat-form-field fxFlex>
              <mat-label >{{ 'interface.mode' | translate }}</mat-label>
              <mat-select formControlName="mode_id" [ngModel]="data.mode_id">
                <mat-option *ngFor="let mode of getModes()" [value]="mode.value">
                  {{mode.viewValue | translate}}
                </mat-option>
              </mat-select>

            </mat-form-field>



          </div> -->
        </div>
        <div>
          <button mat-raised-button color="primary" (click)="saveDescription()">{{'interface.save' | translate }}</button>
          <button mat-raised-button (click)="resetDescription()">{{'interface.cancel' |translate }}</button>
        </div>
        </form>
        </dl>
        </ng-template>


      </mat-tab>


      <mat-tab label="Contacts">
        <app-editable-table [headers]="[ {name:'name'},{name:'phone'},{name: 'email'},{name: 'shipper',type:'checkList'},{name: 'receiver',type:'checkList'},{name: 'customer',type:'checkList'},{name: 'accounting',type:'checkList'},{name: 'dispatch',type:'checkList'},{name: 'tracing',type:'checkList'},{name: 'bol',type:'checkList'}]"
        [data]='data.contacts'
        [checkList]="['shipper','receiver','customer','accounting','dispatch','tracing','bol']"
        updateUrl="{{'site/contact/update/'+data.site_id}}"
        newUrl="{{'site/contact/store/'+data.site_id}}" removeUrl="{{'site/contact/remove/'+data.site_id}}">
        </app-editable-table>
      </mat-tab>


      <mat-tab label="Accounts">
      </mat-tab>

    </mat-tab-group>
</div>
