//import { Component, OnInit,ViewChild } from '@angular/core';
import {Component, Input,ElementRef, ViewChild, OnInit, AfterViewInit} from '@angular/core';
import {MatSort} from '@angular/material/sort';

import { Operation } from './operation';
import { Tab } from './tab';
import { ApiService } from '../services/api.service';
import { MatTableDataSource } from '@angular/material/table';
//import { AngularMaterialModule } from '../angular-material.module'
import {Router} from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import {MatSidenav} from '@angular/material/sidenav';
import { AppConstants } from '../structures/appconstants';

@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.css']
})

export class OperationsComponent implements OnInit,AfterViewInit {

  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild(MatSort) sort: MatSort;

  tabsSelected: any;
  tabsSelectedContract: any;
  loaded:boolean;
  operations: Operation[];
  clients;
  selected_row : number;
  filterFormGroup :FormGroup;
  filter = {};

//  displayedColumns: string[] = [];//['select','status_name','service_id','client_name','client_ref','pickups_ref','deliveries_ref','carriers','pickups','pickups_dates','deliveries','deliveries_dates','mode_name','revenue','costs','gm','skids','weight'];
//  displayedColumnsSort : string[] = [];//['sort_select','sort_status_name','sort_service_id','sort_client_name','sort_client_ref','sort_pickups_ref','sort_deliveries_ref','sort_carriers','sort_pickups','sort_pickups_dates','sort_deliveries','sort_deliveries_dates','sort_mode_name','sort_revenue','sort_costs','sort_gm','sort_skids','sort_weight'];

displayedColumns: string[] = ['select','status_name','service_id','client_name','client_ref','pickups_ref','deliveries_ref','carriers', 'carriers_pro', 'pickups','pickups_dates','deliveries','deliveries_dates','mode_name','revenue','costs','gm','skids','weight'];
displayedColumnsSort : string[] = ['sort_select','sort_status_name','sort_service_id','sort_client_name','sort_client_ref','sort_pickups_ref','sort_deliveries_ref','sort_carriers', 'sort_carriers_pro','sort_pickups','sort_pickups_dates','sort_deliveries','sort_deliveries_dates','sort_mode_name','sort_revenue','sort_costs','sort_gm','sort_skids','sort_weight'];


  hiddenOps:string[] =[];

  filterValues = {
    service_id: '',
    hiddenOps: '',
    carrier_coordinator_name: '',
    account_manager_name: '',
    client_name: '',
    client_ref: '',
    carriers_pro: '',
    carriers: '',
    pickups_ref: '',
    pickups: '',
    pickups_dates_start: '',
    pickups_dates_end: '',
    deliveries_ref: '',
    deliveries: '',
    deliveries_dates_start: '',
    deliveries_dates_end: '',
    mode_name: '',
    revenue: '',
    costs: '',
    gm: '',
    skids: '',
    weight: ''
  };

  displayedColumnsFooter: string[] = ['revenue','costs','gm','skids','weight'];
  dataSource : MatTableDataSource<any>;

  toggleChangeService(event){

    this.filterValues['status_name'] = event.value;
    console.log(event.value);
    this.dataSource.filter = JSON.stringify(this.filterValues);
  }
  toggleChangeContract(event){
    this.filterValues['contract_status'] = event.value;
    this.dataSource.filter = JSON.stringify(this.filterValues);
  }

  hideOp(op)
  {
    this.filterValues['hiddenOps'] +=' ' + op;
    console.log(this.filterValues);
    this.dataSource.filter = JSON.stringify(this.filterValues);
  }




  resetFilter()
  {
    for (let i of Object.keys(this.colFilter))
    {
      this.colFilter[i].setValue('');
    }
    console.log('reset');
    this.tabsSelected = [];
    this.tabsSelectedContract = [];
    this.filterValues['hiddenOps'] = '';
    this.filterValues['status_name'] = [];
    this.filterValues['contract_status'] = [];
    this.dataSource.filter = JSON.stringify(this.filterValues);
  }

  ngOnInit(): void
  {
    this.loaded = true;//false;

    this.dataSource = new MatTableDataSource<any>();
    this.colFilter = {};
    let properties = Object.keys(this.filterValues);
    for (let i of properties) {
      this.colFilter[i] = new FormControl('');
    }


    for (let i of Object.keys(this.colFilter))
    {

      this.colFilter[i].valueChanges
       .pipe(
         debounceTime(1000), // Waiting for 1 sec while you are typing
          distinctUntilChanged() // Prevents the emitting if the 'start' value and the 'end' value are the same
       )
      .subscribe(
        value => {
          if((i == 'pickups_dates_start') || (i == 'pickups_dates_end') || (i == 'deliveries_dates_start') || (i == 'deliveries_dates_end'))
            this.filterValues[i] = this.datepipe.transform(value, 'yyyy-MM-dd');
          else
            this.filterValues[i] = value;
          this.dataSource.filter = JSON.stringify(this.filterValues);

        }
      )
    }

    this.getRows();
    console.log('init done');
    //this.dataSource.filter = JSON.stringify(this.filterValues);
  }
  createLeg(): void {
    console.log(this.sidenav);
    this.sidenav.open();
  }
  colFilter;

  constructor( private apiService: ApiService, private router: Router, fb: FormBuilder,public datepipe: DatePipe)
  {

  }

  totalCol(col)
  {

    if( this.displayedColumnsFooter.indexOf(col) != -1)
    {
      if( col == 'gm')
        return this.dataSource?.filteredData.reduce((accum, curr) => accum += curr.revenue - curr.costs, 0);
      else
      return this.dataSource?.filteredData.reduce((accum:number, curr) => accum += Number(curr[col]), 0);
    }
    else
      return null;
  }

  totalCosts()
  {
      return this.dataSource?.filteredData.reduce((accum:number, curr) => accum += Number(curr['costs']), 0);
  }

  totalRev()
  {
      return this.dataSource?.filteredData.reduce((accum:number, curr) => accum += Number(curr['revenue']), 0);
  }

  totalGM()
  {
      return this.dataSource?.filteredData.reduce((accum, curr) => accum += curr.revenue - curr.costs, 0);
  }


  createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function(data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      let properties = Object.keys(searchTerms);

      for (let i of properties) {
      //  searchTerms[i] = searchTerms[i].toLowerCase();
        if((searchTerms[i] =='')|| searchTerms[i] == null){
    //      console.log('continue');
          continue;

        }

        //

        //console.log(data[i]);
      //  console.log(i);
        //console.log(data);
        else if( (i == 'status_name') )
        {
            //if(data['status_name'] != searchTerms[i] )
            if(searchTerms['status_name'].indexOf(data['status_name']) == -1)
              return false;
        }
        else if( (i == 'hiddenOps') )
        {
            //if(data['status_name'] != searchTerms[i] )
            if(searchTerms['hiddenOps'].indexOf(data['id']) != -1)
              return false;
        }
        else if( (i == 'contract_status') )
        {
          let result = false;
          for(let contract of data['contracts'] )
          {
            if(searchTerms['contract_status'].indexOf(contract.status) !== -1) result = true;
          }
          if(!result) return false;
        }
        else if( (i == 'carriers') )
        {
          let result = false;
          for(let carrier of data['contracts'] )
          {
            if(carrier.vendor_name?.toString().toLowerCase().indexOf(searchTerms[i].toLowerCase())  !== -1 ) result = true;
          }
          if(!result) return false;
        }
        else if( (i == 'carriers_pro') )
        {
          let result = false;
          for(let carrier of data['contracts'] )
          {
            if(carrier.pro)
            if(carrier.pro.toString().toLowerCase().indexOf(searchTerms[i].toLowerCase())  !== -1 ) result = true;
          }
          if(!result) return false;
        }
        else if (i == 'pickups')
        {
          let result = false;
          for(let carrier of data['contracts'] )
          {
            for(let stop of carrier['pickups'] )
            {
              if(stop.city_name?.toString().toLowerCase().indexOf(searchTerms[i].toLowerCase())  !== -1 ) result = true;
              if(stop.site_name?.toString().toLowerCase().indexOf(searchTerms[i].toLowerCase())  !== -1 ) result = true;
              if(stop.state?.toString().toLowerCase().indexOf(searchTerms[i].toLowerCase())  !== -1 ) result = true;
            }
          }

          if(!result) return false;
        }
        else if (i == 'pickups_ref')
        {
          let result = false;
          for(let carrier of data['contracts'] )
          {
            for(let stop of carrier['pickups'] )
            {
              if(stop.ref_number)
                if(stop.ref_number?.toString().toLowerCase().indexOf(searchTerms[i].toLowerCase())  !== -1 ) result = true;
            }
          }

          if(!result) return false;
        }
        else if (i == 'deliveries_ref')
        {
          let result = false;
          for(let carrier of data['contracts'] )
          {
            for(let stop of carrier['deliveries'] )
            {
              if(stop.ref_number)
                if(stop.ref_number?.toString().toLowerCase().indexOf(searchTerms[i].toLowerCase())  !== -1 ) result = true;
            }
          }

          if(!result) return false;
        }
        else if (i == 'deliveries')
        {
          let result = false;
          for(let carrier of data['contracts'] )
          {
            for(let stop of carrier['deliveries'] )
            {
              if(stop.city_name?.toString().toLowerCase().indexOf(searchTerms[i].toLowerCase())  !== -1 ) result = true;
              if(stop.site_name?.toString().toLowerCase().indexOf(searchTerms[i].toLowerCase())  !== -1 ) result = true;
              if(stop.state?.toString().toLowerCase().indexOf(searchTerms[i].toLowerCase())  !== -1 ) result = true;
            }
          }

          if(!result) return false;

        }
        else if( i == 'pickups_dates_start')
        {
          // console.log(i);
          // console.log(data['pickups'][0].departure_date);
           console.log(searchTerms['pickups_dates_start']);
          let result = false;
          for(let carrier of data['contracts'] )
          {
            for(let stop of carrier['pickups'] )
              //if((stop.departure_date ?? stop.appointment_from_date) >= searchTerms['pickups_dates_start'] ) result = true;

              if( ((stop.departure_date ?? (stop.appointment_from_date ?? stop.eta_date)) >= searchTerms['pickups_dates_start'] ) &&
              ((stop.departure_date ?? (stop.appointment_from_date ?? stop.eta_date)) <= searchTerms['pickups_dates_end'] ) )
                 result = true;

          }
          if(!result) return false;
        }
        else if( i == 'pickups_dates_end')
        {
        //    console.log(searchTerms['pickups_dates_end']);
        //   let result = false;
        //   for(let carrier of data['contracts'] )
        //   {
        //     for(let stop of carrier['pickups'] )
        //       if((stop.departure_date ?? stop.appointment_from_date) <= searchTerms['pickups_dates_end'] ) result = true;
        //   }
        //   if(!result) return false;
        }
        else if( i == 'deliveries_dates_start')
        {
          // console.log(i);
          // console.log(data['pickups'][0].departure_date);
           console.log(searchTerms['deliveries_dates_start']);
          let result = false;
          for(let carrier of data['contracts'] )
          {
            for( let stop of carrier['deliveries'] )
              //if((stop.departure_date ?? stop.appointment_from_date) >= searchTerms['deliveries_dates_start'] ) result = true;

              if( ((stop.departure_date ?? (stop.appointment_from_date ?? stop.eta_date)) >= searchTerms['deliveries_dates_start'] ) &&
              ((stop.departure_date ?? (stop.appointment_from_date ?? stop.eta_date)) <= searchTerms['deliveries_dates_end'] ) )
                 result = true;

          }
          if(!result) return false;
        }
        else if( i == 'deliveries_dates_end')
        {
        //    console.log(searchTerms['deliveries_dates_end']);
        //   let result = false;
        //   for(let carrier of data['contracts'] )
        //   {
        //     for( let stop of carrier['deliveries'] )
        //     {
        //       if((stop.departure_date ?? stop.appointment_from_date) <= searchTerms['deliveries_dates_end'] ) result = true;
        //     }
        //
        //   }
        //   if(!result) return false;
        }
        else if (i=='gm')
        {}
        else
        {
        //console.log('test');
        if( (!data[i]) || (data[i]?.toString().toLowerCase().indexOf(searchTerms[i].toLowerCase())  === -1 ))
        {
          return false;
        }
        }
      }
      return true;
    }
    return filterFunction;
  }

  rowClick( row, event ) {
  // if (( event.metaKey ) || (event.ctrlKey))
  // {
  //   var win = window.open('/operation_detail/'+ row.id , '_blank');
  //   if (win) {
  //       win.focus();
  //   } else {
  //       alert('Please allow popups for this website');
  //   }
  // }
    //else
    {
      console.log(this.sidenav);
      this.selected_row = row.id;
      this.sidenav.open();
    }

  }



  // getRows(): void {
  //   this.apiService.getData( 'services/list',this.filter )
  //     .subscribe(param =>
  //       {
  //           this.dataSource.data  = param.data;
  //           this.dataSource.filterPredicate = this.createFilter();
  //           this.dataSource.sortData = this.createSort();
  //         //  this.dataSource.sort = this.sort;
  //           this.loaded = true;
  //
  //           console.log('after init');
  //           console.log(this.sort);
  //           //this.getRows();
  //
  //           this.dataSource.sort = this.sort;
  //       });
  //
  // }



  getRows(): void {
    this.apiService.getData( 'services/list',this.filter )
      .subscribe(param =>
        {
          // if(this.dataSource)
          // {
            this.dataSource.data  = param.data;
            console.log('data source');
          // }
          // else
          // {
          //   this.dataSource = new MatTableDataSource<any>(param.data)
          //   console.log('data source else');
          // }


            this.displayedColumns = ['select'];
            this.displayedColumnsSort = ['sort_select'];

          if( param.privilegios['ServicesList L1'] || param.privilegios['Master'])
          {
            this.displayedColumns = [... this.displayedColumns, 'status_name','service_id','client_name','client_ref','pickups_ref','deliveries_ref'];
            this.displayedColumnsSort = [... this.displayedColumnsSort, 'sort_status_name','sort_service_id','sort_client_name','sort_client_ref','sort_pickups_ref','sort_deliveries_ref'];
          }

          if( param.privilegios['ServicesList L2']|| param.privilegios['Master'])
          {
            this.displayedColumns = [... this.displayedColumns, 'carriers', 'carriers_pro'];
            this.displayedColumnsSort = [... this.displayedColumnsSort, 'sort_carriers','sort_carriers_pro'];
          }

          if( param.privilegios['ServicesList L3']|| param.privilegios['Master'])
          {
            this.displayedColumns = [... this.displayedColumns, 'carrier_coordinator_name','account_manager_name'];
            this.displayedColumnsSort = [... this.displayedColumnsSort, 'sort_carrier_coordinator_name','sort_account_manager_name'];
          }


          if( param.privilegios['ServicesList L1']|| param.privilegios['Master'])
          {
            this.displayedColumns = [... this.displayedColumns, 'pickups','pickups_dates','deliveries','deliveries_dates','mode_name','skids'];
            this.displayedColumnsSort = [... this.displayedColumnsSort, 'sort_pickups','sort_pickups_dates','sort_deliveries','sort_deliveries_dates','sort_mode_name','sort_skids'];
          }



          if( param.privilegios['ServicesList L3']|| param.privilegios['Master'])
          {
            this.displayedColumns = [... this.displayedColumns, 'revenue'];
            this.displayedColumnsSort = [... this.displayedColumnsSort, 'sort_revenue'];
          }

          if( param.privilegios['ServicesList L2']|| ['ServicesList L3']|| param.privilegios['Master'])
          {
            this.displayedColumns = [... this.displayedColumns, 'costs'];
            this.displayedColumnsSort = [... this.displayedColumnsSort, 'sort_costs'];
          }

          if( param.privilegios['ServicesList L3']|| param.privilegios['Master'])
          {
            this.displayedColumns = [... this.displayedColumns,'gm'];
            this.displayedColumnsSort = [... this.displayedColumnsSort, 'sort_gm'];
          }





          console.log('sortdata = createsort1');
          this.dataSource.filterPredicate = this.createFilter();


          console.log('sortdata = createsort2');
          this.dataSource.sortData = this.createSort();
          console.log('sortdata = createsort3');
          this.dataSource.sort = this.sort;


          this.loaded = true;

        });

  }




  // createSort(): (data: T[], sort: MatSort) => T[] {
  //   let sortData = function(data: T[], sort: MatSort) : T[]{

      createSort(): (data: any[], sort: MatSort) => any[] {
        let sortData = function(data: any[], sort: MatSort) : any[]{
       console.log('sorting');
       console.log(data);
       console.log(sort);
      var active = sort.active;
      const direction = sort.direction;
      if (!active || direction == '') { return data; }

      active = sort.active.slice(5);
      return data.sort((a, b) => {
        //console.log(a, ' vs ', b);

        let valueA = null;// = this.sortingDataAccessor(a, active);
        let valueB = null;// = this.sortingDataAccessor(b, active);

        switch( active)
        {
          case 'carriers':
            if(a.contracts.length)
              valueA = a.contracts[0].vendor_name;
            if(b.contracts.length)
              valueB = b.contracts[0].vendor_name;
          break;

          case 'pickups_dates':
            if(a.contracts.length)
              if(a.contracts[0].pickups.length)
                valueA = a.contracts[0].pickups[0].departure_date ?? (a.contracts[0].pickups[0].appointment_from_date ?? a.contracts[0].pickups[0].eta_date);
            if(b.contracts.length)
              if(b.contracts[0].pickups.length)
                valueB = b.contracts[0].pickups[0].departure_date ?? (b.contracts[0].pickups[0].appointment_from_date) ?? b.contracts[0].pickups[0].eta_date;
          break;

          case 'deliveries_dates':
            if(a.contracts.length)
              if(a.contracts[0].deliveries.length)
                valueA = a.contracts[0].deliveries[0].departure_date ?? (a.contracts[0].deliveries[0].appointment_from_date ?? a.contracts[0].deliveries[0].eta_date);
            if(b.contracts.length)
              if(b.contracts[0].deliveries.length)
                valueB = b.contracts[0].deliveries[0].departure_date ?? (b.contracts[0].deliveries[0].appointment_from_date ?? b.contracts[0].deliveries[0].eta_date);
          break;

          case 'gm':
            valueA = a.revenue- a.costs;
            valueB = b.revenue- b.costs;
          break;


          default:

            valueA = this.sortingDataAccessor(a, active);
            valueB = this.sortingDataAccessor(b, active);

          break;

        }


        // If there are data in the column that can be converted to a number,
        // it must be ensured that the rest of the data
        // is of the same type so as not to order incorrectly.
        const valueAType = typeof valueA;
        const valueBType = typeof valueB;

        if (valueAType !== valueBType) {
          if (valueAType === 'number') { valueA += ''; }
          if (valueBType === 'number') { valueB += ''; }
        }

        // If both valueA and valueB exist (truthy), then compare the two. Otherwise, check if
        // one value exists while the other doesn't. In this case, existing value should come last.
        // This avoids inconsistent results when comparing values to undefined/null.
        // If neither value exists, return 0 (equal).
        let comparatorResult = 0;
        if (valueA != null && valueB != null) {
          // Check if one value is greater than the other; if equal, comparatorResult should remain 0.
          if (valueA > valueB) {
            comparatorResult = 1;
          } else if (valueA < valueB) {
            comparatorResult = -1;
          }
        } else if (valueA != null) {
          comparatorResult = 1;
        } else if (valueB != null) {
          comparatorResult = -1;
        }

        return comparatorResult * (direction == 'asc' ? 1 : -1);
      });
    }
    return sortData;
  }



  get tabs() {
        return AppConstants.tabs_service;
    }

  get tabsContract() {
          return AppConstants.tabs_contract;
  }

  ngAfterViewInit() {

  }


}
