<mat-card class="example-card">
  <mat-card-header>
    <mat-card-title>Accounts</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <app-editable-table-filter #PaymentsEditableTable
    [headers]="[ {name:'id'},{name:'code'},{name:'name'},{name:'parent'},{name:'childof',type:'autocomplete',url:'accounts/filter_list2'},{name:'gifi'}]"
    [data]='data'
    updateUrl="{{'accounting/accounts/update'}}"
    newUrl="{{'accounting/accounts/store'}}"
    removeUrl="{{'accounting/accounts/remove'}}"
    >
    </app-editable-table-filter>

  </mat-card-content>
</mat-card>
