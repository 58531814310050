import {Component, Input,ElementRef, ViewChild} from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

import { ApiService } from '../services/api.service';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from "html-to-pdfmake";


@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})

export class EventComponent {
  @Input() value: any;
  loaded = false;
  sending = false;

  //tryDoctype:any;

  email_dialog;
  emailForm : FormGroup;
  subject;
  //emailPattern = /[a-zA-Z0-9_.+-,;]+@(?:(?:[a-zA-Z0-9-]+\.,;)?[a-zA-Z]+\.,;)?(gmail)\.com/;
  constructor(
    fb: FormBuilder,
    private route: ActivatedRoute,
    private apiService: ApiService,

  )
  {
    this.sending = false;
    this.email_dialog = false;
    this.emailForm = fb.group({
      emails: [
        '',
        [Validators.required, this.commaSepEmail]
      ]
    });

    this.rulesForm = fb.group({
      emails: ['', [this.validateArrayNotEmpty]],
      subject: ['', []],
      message: ['', []]
    });




  }

  private validateArrayNotEmpty(c: FormControl) {
    console.log('validator');
   if (c.value && c.value.length === 0) {
     return {
       validateArrayNotEmpty: { valid: false }
     };
   }
   return null;
 }

 private validateEmail(email) {
   var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   return re.test(String(email).toLowerCase());
 }
 emailDialogToggle()
 {
   this.email_dialog= !this.email_dialog;
 }

 print(){

   // const pdfTable = this.confirmation.nativeElement;
   //  //html to pdf format
   //  var html = htmlToPdfmake(pdfTable.innerHTML);
   //
   //  const documentDefinition = { content: html };
   // var blob = pdfMake.createPdf(documentDefinition).print();
 }



  ngOnChanges(changes) {
    console.log(this.value);
    this.loaded = false;
    this.loaded = true;
    this.emailList = [];
    if( this.value.contacts )
    for(let i=0; i<this.value.contacts.length; i++){
      this.emailList.push({value: this.value.contacts[i].email, invalid:false});
    }
    //this.subject = this.value.subject;
    this.rulesForm.get('subject').setValue(this.value.subject);
    this.rulesForm.get('message').setValue(this.value.message);
    this.loaded = true;
  }




  commaSepEmail = (control: AbstractControl): { [key: string]: any } | null => {
    const emails = control.value.split(',').map(e=>e.trim());

    const forbidden = emails.some(email => {
      //const matches = email.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
      const matches = email.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$");
      return matches ? null : true;

    });
    //const forbidden = false;

    return forbidden ? { 'toAddress': 'error' } : null;

  };




  separatorKeysCodes: number[] = [ENTER, COMMA];
  public emailList = [];
  removable = true;
  rulesForm: FormGroup;
  fb: FormBuilder = new FormBuilder();

  add(event): void {
    console.log(event.value)
    if (event.value) {
      if (this.validateEmail(event.value)) {
        if(this.emailList.length ==0) this.rulesForm.controls['emails'].setErrors(null);
        this.emailList.push({ value: event.value, invalid: false });

      } else {
        this.emailList.push({ value: event.value, invalid: true });
        this.rulesForm.controls['emails'].setErrors({'incorrectEmail': true});
      }
    }
    if (event.input) {
      event.input.value = '';
    }

  }


  removeEmail(data: any): void {
    console.log('Removing ' + data)
    if (this.emailList.indexOf(data) >= 0) {
      this.emailList.splice(this.emailList.indexOf(data), 1);
    }
    for(let i = 0; i<this.emailList.length;i++){
      if(this.emailList[i].invalid)
        return;
    }
    if(this.emailList.length == 0)
    {
      console.log('invalid');
      this.rulesForm.controls['emails'].setErrors({'emptyValue': true});

    }
    else
      this.rulesForm.controls['emails'].setErrors(null);
  }

  sendEmail()
  {
    this.sending = true;
    //console.log('id')
    //const id = +this.route.snapshot.paramMap.get('id');
    //var url ='pages/contract_dispatch/email/7' ;

    var email_array = [];
    for(let i=0;i<this.emailList.length;i++)
      email_array.push(this.emailList[i].value)
      //email_string = email_string + this.emailList[i].value

    this.rulesForm.value['to'] = email_array;//.toString();

    this.rulesForm.value['filename'] = this.value.filename;
    this.apiService.getData(this.value.url, this.rulesForm.value ).subscribe(res =>
    {

      console.log(res);
      if(res.success == true)
      {
        if(this.value.callback)
          this.value.callback();
        this.value.sidenav.close();
      }
      this.sending = false;
    });

  }

}
