import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../services/api.service';
import { DetailedOperation } from '../structures/detailed_operation';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppConstants } from '../structures/appconstants';
import { AppComponent } from '../app.component';
import {CdkDragDrop, moveItemInArray, transferArrayItem, CdkDropList} from '@angular/cdk/drag-drop';
import { tap, startWith, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import {Observable} from 'rxjs';
//import { MatTable, MatTableDataSource } from '@angular/material';
import { MatTableDataSource } from '@angular/material/table';
import {TranslateService} from '@ngx-translate/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';



@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.css']
})
export class SiteComponent implements OnInit {

  detail_disabled: boolean = false;
  @Input() site_id;
  tryDoctype: any;
  data : any;
  data_bk: any;
  loaded: boolean = false;
  is_editting_details :boolean;
  descriptionFormGroup: FormGroup;
  filteredOptions: Observable<any[]>;
  file = false;
  constructor(
    private sanitizer: DomSanitizer,
    private apiService: ApiService,
    private app: AppComponent,
    // private location: Location,
    // public translate: TranslateService,
    fb: FormBuilder,

  ) {




    this.loaded = false;
    this.is_editting_details = false;

  }

  ngOnInit(): void {
  //  this.getClient();
  }

  ngOnChanges(changes) {
    this.loaded = false;
    if(this.site_id)
    {
      this.getSite();
    }
  }


  getSite(): void {
    //const id = +this.route.snapshot.paramMap.get('id');
    const id = this.site_id;
    this.apiService.getData('sites/site_detail/view/'+ id )
      .subscribe(detailed_operation =>
        {
          this.data = detailed_operation.data;
          this.loaded = true;
        });
  }

  resetDescription(){
      // this.descriptionFormGroup.reset( this.data.detail );
      console.log('reset');
      this.data =  { ...this.data_bk };
      this.is_editting_details = false;
  }
  startEdit(){
    console.log('start');
    this.data_bk =  { ...this.data };
    this.is_editting_details = true;
  }

  saveDescription()
  {
    const id = this.site_id;//+this.route.snapshot.paramMap.get('id');
  //   const data = this.descriptionFormGroup.value;
  //   data['zip'] = data.zip.id;
    this.apiService.getData('sites/site_detail/modify/'+ id, this.data ).subscribe(detailed_operation =>
    {
        this.is_editting_details = false;
    });
  //   console.log(this.descriptionFormGroup.value);
   }



   fileCallback = ( element ): void =>
   {

     console.log(element);
     this.apiService.getBlob( 'vendors/document/'+element.file )
       .subscribe(response =>
         {
           var blob = response;
           //var contentType = response.headers["content-type"];
           //let blob = new Blob([res.error.text.blob()], { type: 'application/pdf' })

           //var blob2 = new Blob([blob], { type: 'application/pdf', title: 'my title ' })


           var fileURL = URL.createObjectURL(blob);
           var documentData = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
           this.tryDoctype = documentData;
           this.file = true;
         });

        //this.app.getSidenav().open();
   }


   pendingChange()
   {

     //event.target.classList.add('updating');
     this.detail_disabled = true;
     this.apiService.getData( 'sites/site_pending/modify/'+this.data.id, {pending:this.data.pending} )
       .subscribe(
         result => {
           if(result['success'])
           {
             this.detail_disabled = false;
             //this.cancelEditable(element);
             //event.target.classList.remove('updating');
           }


         });
   }
}
