import {Component, Input,ElementRef, ViewChild, OnInit, AfterViewInit} from '@angular/core';
import { ApiService } from '../services/api.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { AppConstants } from '../structures/appconstants';


import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from "html-to-pdfmake";



@Component({
  selector: 'app-generalledger',
  templateUrl: './generalledger.component.html',
  styleUrls: ['./generalledger.component.css']
})

export class GeneralledgerComponent implements OnInit
{

  @ViewChild('print_content') content: ElementRef;
  test;
  loaded:boolean = false;
  data;
  filterFormGroup :FormGroup;
  filter = {};

  ngOnInit()
  {

  }

  constructor( private apiService: ApiService, fb: FormBuilder,public datepipe: DatePipe)
  {
    this.filterFormGroup = fb.group({
      date_from: [
        new Date('8/1/2020'),
        []
      ],
      date_to: [
        new Date('7/31/2021'),
        []
      ]
    });

  }

  public templateToFormData( object ) {
    console.log(object);
    const formData = new FormData();
    for ( const key of Object.keys(object) )
    {
      if (Array.isArray(object[key]))
      {
        for ( let a of object[key] )
          formData.append(key+'[]', a);
      }
      else
      {
        if( object[key]=== null)
          formData.append(key, '');
        else if( object[key]=== false)
          formData.append(key, '0');
        else if( (key == 'date_from') || (key == 'date_to'))
        {
          const  latest_date =this.datepipe.transform(object[key], 'yyyy-MM-dd');
          formData.append(key, latest_date);
        }
        else
          formData.append(key, object[key]);
      }
    }
    return formData;
  }


  getGeneralledger()
  {
    const formData = this.templateToFormData(this.filterFormGroup.value);
    this.apiService.getData( 'statements/generalledger', formData)
      .subscribe(param =>
        {
          this.data = param;
          this.setTotals();
          this.loaded = true;

        });

  }


  starting_balance(account)
  {
    let code = (account.code.slice(0,1));
    let active = (code==1 || code == 5 )?true:false;
    if( active )
        return account.starting_balance;
    else
        return 0-account.starting_balance;
  }
  setTotals()
  {
    for(let account of this.data.accounts)
    {
      let code = (account.code.slice(0,1));
      let active = (code==1 || code == 5 )?true:false;
      let total = active?account.starting_balance:(0-account.starting_balance);
      for(let entry of account.entries)
      {
        if( active )
          entry.amount = entry.debit - entry.credit;
        else
          entry.amount = entry.credit-entry.debit;
        total = total + entry.amount
        entry.balance = total;
      }
      account.total = total;
    }

  }
  totalDebit(voucher)
  {
    let total = 0;
    for( let entry of voucher.entries)
    {
      total+= +(entry.debit??0);
    }
    return total;
  }
  totalCredit(voucher)
  {
    let total = 0;
    for( let entry of voucher.entries)
    {
      total+= +(entry.credit??0);
    }
    return total;
  }




  generatePDF()
  {
    const content = this.content.nativeElement;
    let rcontent = htmlToPdfmake(content.innerHTML);

    rcontent[0].table["headerRows"] = 4;
    rcontent[0].table["widths"] = ['auto', 'auto', 250, '*', 'auto', 'auto'];

    let dd = {
    footer: function (currentPage, pageCount) {
      if(pageCount == currentPage)
        return '';
      return {
                text: "Page " + currentPage.toString() + ' of ' + pageCount,
                alignment: 'right',
                style: 'text',
                margin: [0, 0, 40, 0]
      };
    },
    pageSize: 'LETTER',
    pageOrientation: 'landscape',
    content: rcontent,
    styles: {
      text_center:
      {
        alignment: 'center'
      },
      'html-td':
      {
        fontSize: 6.5,
      },
      'html-th':
      {
        fontSize: 6.5,
      },
      header: {
        fontSize: 12,
        bold: true,
        alignment: 'center'
      },
      text: {
        fontSize: 9,
        alignment: 'justify'
      },
      subheader: {
        fontSize: 9,
        bold: true,
        margin: [0, 5, 0, 0]
      },
      quote: {
        italics: true
      },
      small: {
        fontSize: 8
      }
    }

    };
    pdfMake.createPdf(dd).print();
  }

}
