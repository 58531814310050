import {Component, Input,ElementRef, ViewChild, OnInit} from '@angular/core';
import { Observable, Subject, of, Subscription, empty } from 'rxjs';
import { tap, startWith, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
//import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { MatTable } from '@angular/material/table';
//import { MatTable } from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import { AppConstants } from '../structures/appconstants';
import { DatePipe } from '@angular/common'
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-editable-form',
  templateUrl: './editableForm.component.html',
  styleUrls: ['./editableForm.component.css']
})

export class EditableFormComponent implements OnInit {
  @Input() headers;
  @Input() data: any;
  is_editting_details = false;
  updating = false;
  backup : any[];
  filteredOptions = new Array();

  @Input() submitOnly: boolean;
  @Input() updateUrl: string;
  @Input() newUrl: string;
  @Input() removeUrl: string;

  txtQueryChanged : Subject<string>[] = [];

  constructor(
    private apiService: ApiService,
    public datepipe: DatePipe
  )
  {
    this.backup = new Array();






  }




  ngOnInit() {

    if(this.submitOnly)
    {
      this.is_editting_details = true;
    }


    for(let i in this.headers)
    {

      if(this.submitOnly)
        this.data[this.headers[i].name] = null;
      if(this.headers[i].type == 'autocomplete')
      {
        this.txtQueryChanged[i] = new Subject<string>();//new Observable<any[]>();

        this.txtQueryChanged[i].pipe(
          debounceTime(400), // wait 1 sec after the last event before emitting last event
          distinctUntilChanged(), // only emit if value is different from previous value
          switchMap(val  =>
            {
              return this.apiService.getData(this.headers[i].url, {query: val || ''})
            })
          ).subscribe(response => {
           this.filteredOptions[i] = response;
        });

      }
    }
  }

  log(e)
  {
    console.log(e);
  }


  // updateDependents( d , a ) {
  //   let bool_first = false;
  //   for(let i = 0; i<this.data.length; i++)
  //   {
  //     if(this.data[i].editable && (!bool_first) )
  //     {
  //
  //       for( let j=0; j< d.length ; j++)
  //         this.data[i][d[j].name] = d[j].value;
  //       this.data[i]['additional']=a;
  //       bool_first = true;
  //     }
  //     else
  //       this.data[i].additional = null;
  //   }
  //
  // }

  makeEditable( )
  {
    this.is_editting_details=true;
    for(let h of this.headers)
    {
      this.backup[h.name] = this.data[h.name];
    }
  }

  public templateToFormData( object ) {
    const formData = new FormData();
    for ( const key of Object.keys(object) )
    {
      if (Array.isArray(object[key]))
      {
        for ( let a of object[key] )
          formData.append(key+'[]', a);
      }
      else
      {
        if( object[key]=== null)
          formData.append(key, '');
        else if( object[key]=== false)
          formData.append(key, '0');
        else
          formData.append(key, object[key]);
      }
    }
    return formData;
  }
  // rowValid(index)
  // {
  //   for(let h of this.headers)
  //   {
  //     if( h.required )
  //     {
  //       if( this.data[index][h.name] === '' || this.data[index][h.name] === null )
  //       {
  //       //  console.log('not valid'+ this.data[index][h.name] + 'field' +h.name)
  //         return false;
  //       }
  //     }
  //     if( h.number)
  //     {
  //       if(this.data[index][h.name])
  //         if (isNaN(+this.data[index][h.name]))
  //           return false;
  //
  //     }
  //   }
  //
  //   return true;
  //
  // }
  // removeEditable( element)
  // {
  //   if(! confirm("Are you sure to delete"))
  //     return;
  //   element.updating = true;
  //
  //   if(element.id)
  //   {
  //     this.apiService.getData( this.removeUrl, element )
  //       .subscribe(
  //         result => {
  //           if(result['success'])
  //           {
  //             this.data.splice(this.data.indexOf(element),1);
  //             this.table.renderRows();
  //
  //             element.updating = false;
  //             element.editable = false;
  //           }
  //         });
  //   }
  // }
  //
  // autocompleteOnFocus(header_name, h_i) :void
  // {
  //   this.filteredOptions = this.templateForm.control.get(header_name).valueChanges.pipe(
  //     startWith(''),
  //     debounceTime(400),
  //     distinctUntilChanged(),
  //     switchMap(val  =>
  //         this.filter(val || '',h_i)
  //     )
  //   );
  // }
  //
  // filter(val, h_i): Observable<any[]>
  // {
  //   return this.apiService.getData(this.headers[h_i].url,{query: val})
  //     .pipe(
  //     )
  // }
  //
  // displayProperty(value) {
  //   if (value) {
  //     return value.name;
  //   }
  // }
  //
  // optionSelected(event)
  // {
  //   event.option.value.id;
  // }

  resetDescription(){
      //this.descriptionFormGroup.reset( this.data.detail);
      this.updating = true;
      for(let h of this.headers)
      {
        this.data[h.name] = this.backup[h.name];
      }

      this.is_editting_details = false;
  }


  saveDescription()
  {
    console.log('here');
    this.updating = true;
    let data = [];
    for(let h of this.headers)
    {
      if(h.type == 'date')
        data[h.name] = this.datepipe.transform(this.data[h.name], 'yyyy-MM-dd');
      else if(h.type == 'autocomplete')
        data[h.name+'_id'] = this.data[h.name].id;
      else
        data[h.name] = this.data[h.name];
    }

    if( !this.submitOnly)
      data['id'] = this.data['id'];

    const formData = this.templateToFormData(data);

    this.apiService.getData( this.submitOnly?this.newUrl:this.updateUrl, formData )
      .subscribe(
        result => {
          if(!result['success'])
          {
            this.resetDescription();
          }


          this.updating = false;
          this.is_editting_details= false;
          // if(this.submitOnly && this.callAfterSubmit)
          //   return callAfterSubmit();
        }
      );
  }




  getStructure( name )
  {
    if(name)
    return AppConstants[name];

  }

  getStructureValue( name, id )
  {
    if(name && id)
    {
      return AppConstants[name].filter(x => x.value == id)[0]?.viewValue;
      // if(AppConstants[name][id-1])
      //   return AppConstants[name][id-1].viewValue;
      // else
      //   console.log('error getStructureValue name:' + name + ' id:'+id );
    }
    //else
    //console.log('error getStructureValue name:' + name + ' id:'+id );
  }

  filter(val,h_i){
    return this.apiService.getData(this.headers[h_i].url, {query: val})
      .subscribe(response => {
       this.filteredOptions[h_i] = response;
    })
    //return this.insDetails;
  }

  // startWith(''),
  // debounceTime(400),
  // distinctUntilChanged(),
  // switchMap(val  =>
  //     this.filter(val || '',h_i)
  // )



  displayProperty(value) {
    if (value) {
      return value.name;
    }
  }

  onChangeAutocomplete(h_i, value)
  {
    this.txtQueryChanged[h_i].next(value);
  }
}
