import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators,AbstractControl } from '@angular/forms';
//import { FormGroup, , FormBuilder, Validators, , EmailValidator,  }
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AngularMaterialModule } from '../angular-material.module';
import { ApiService } from '../services/api.service';
import { AppConstants } from '../structures/appconstants';

import { tap, startWith, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { asyncValidator } from '../helpers/custom-async-validator';
import { Observable, of, Subscription, empty } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import { SitedialogComponent } from '../vendordialog/sitedialog.component';
import { NewsitedialogComponent } from '../vendordialog/newsitedialog.component';

import { AppComponent } from '../app.component';

@Component({
  selector: 'app-newprospect',
  templateUrl: './newprospect.component.html',
  styleUrls: ['./newprospect.component.css']
})
export class NewprospectComponent implements OnInit {

  data =[];
  // Variables
  results = [];
  loading: boolean;
  errors: boolean;
  newquoteFormGroup: FormGroup;
  finalFormGroup: FormGroup;
  poFormGroup: FormGroup;
  stopsFormGroup: FormGroup;
  stopsCount = 0;
  //stopsFormGroup: FormArray;
  rateFormGroup: FormGroup;
  emailFormGroup: FormGroup;
  clients;
  emailList;
  formSubmitted: boolean;
  filteredOptions: Observable<any[]>;

  basic:any;
//  filteredOptions;

  private dialogParams = {
    width: 'calc( 100% - 50px )',
    height: 'calc( 100% - 50px )',
    maxWidth: '100vw',
    maxHeight: '100vh',
  };

 //emailThread: string;// = null;


  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private apiService : ApiService,
    public dialog: MatDialog,
    public translate: TranslateService,
    public app: AppComponent
  ) {

    this.formSubmitted = false;
    this.basic = app.getBasic();

    this.emailFormGroup = fb.group({
      emailThread:[
        '',
        []
      ]
    });
    this.stopsFormGroup = fb.group({
      stops: fb.array([
        this.fb.group({
            date:'',
            time:'',
            type: 1,
            site: ['', [Validators.required]],
            site_id: ['', [Validators.required]],
            zip: ['', [Validators.required]]
          })
          ,
          this.fb.group({
              date:'',
              time:'',
              type: 2,
              site: ['', [Validators.required]],
              site_id: ['', [Validators.required]],
              zip: ['', [Validators.required]]
            })])
    });
    this.stopsCount = 2;

    // console.log (this.stopsFormGroup.get("stops")['controls'][0].get("type").value);
    // this.stopsFormGroup.get("stops")['controls'][1].get("type").setValue(2);



    this.newquoteFormGroup = fb.group({
      client: [
        '',
        [Validators.required]
      ],
      client_id: [
        '',
        [Validators.required]
      ],

      mode: [
        1,
        [Validators.required]
      ],
      equipment_type: [
        '',
        [Validators.required]
      ],
      client_ref: [
        '',
        []
      ],
      description: [
        '',
        []
      ],
      temperature: [
        '',
        []
      ]
    });

    this.finalFormGroup = fb.group({
      number_of_copies: [
        '1',
        [Validators.required]
      ]
    });



    this.poFormGroup = fb.group({ // make a nested group
        pos: fb.array([this.addPOFormGroup()])
      });

      this.rateFormGroup  =fb.group({ // make a nested group
        rates: fb.array([this.addRateFormGroup()])
      });


  }

  //this.filteredOptions =



  // onChangeClient(value){
  //
  // }

  filter(val): Observable<any[]> {
    // call the service which makes the http-request
    return this.apiService.getData('shared/zip',{query: val})
      .pipe(
      //   map(response => response.filter(option => {
      //     return option.name.toLowerCase().indexOf(val.toLowerCase()) === 0
    //     }))
      )
  }

  get modes() {return AppConstants.modes;}
  get equipment_types() {return AppConstants.equipment_types;}

  get pos_units() { return AppConstants.pos_units;}

  get po_units_id() { return AppConstants.po_units_id;}
  get po_weight_id() { return AppConstants.po_weight_id;}



  get expense_types() { return AppConstants.expense_type_id ;}

  get unit_type() { return AppConstants.unit_type_id;}

  get stop_types() {return AppConstants.stop_type_id;}

  submit2()
  {
    // newquoteFormGroup: FormGroup;
    // poFormGroup: FormGroup;
    // stopsFormGroup: FormGroup;
    // rateFormGroup: FormGroup;
    // emailFormGroup: FormGroup;
    //
    let data = { ...this.newquoteFormGroup.value, ...this.poFormGroup.value, ...this.stopsFormGroup.value, ...this.rateFormGroup.value, ...this.emailFormGroup.value, ... this.finalFormGroup.value};
    //console.log(data);
    //const id = +this.route.snapshot.paramMap.get('id');

    this.apiService.getData('services/service/store', data ).subscribe(res =>
    {
        if ( res['success'] )
        {
            //this.router.navigate(['/operation_detail/' + res['id']  ]);
            this.results = [ ...this.results, ...res.ids];
        }
        console.log(res);
        console.log(res['success']);


    });
  }

  ngOnInit(): void {
    this.getClients();
    this.updateEmailList();

    // this.newquoteFormGroup.controls.clients.valueChanges
    // .subscribe(
    //   value => {
    //     this.newquote
    //
    //   });
    //
    //
  }

  changeClient()
  {
    //console.log('change_client');
    //console.log(this.rateFormGroup.get("rates")["controls"]);
    //for(let i of )

    this.newquoteFormGroup.get('client_id').setValue(
      this.clients[(this.newquoteFormGroup.get('client').value)].id
    );
    for (let i = 0; i < this.rateFormGroup.get("rates")["controls"].length; i++)
    {
      console.log(i);
      this.changeRate(i);
    }

  }
  changeRate(i)
  {
    console.log(this.newquoteFormGroup.get('client').value);
    if( this.newquoteFormGroup.get('client').value !== '' )
    {
        if( this.rateFormGroup.get("rates")['controls'][i].get("rate").value && this.rateFormGroup.get("rates")['controls'][i].get("quantity").value )
        {
            this.rateFormGroup?.get('rates')['controls'][i].controls.client_total_rate.setValue(  this.calculateClientPrice(i) );
            this.rateFormGroup?.get('rates')['controls'][i].controls.books_total_rate.setValue(  this.calculateBooksPrice(i) );
        }
    }
  }

  calculateClientPrice(i :number) {
    return this.rateFormGroup.get("rates")['controls'][i].get("rate").value *  this.rateFormGroup.get("rates")['controls'][i].get("quantity").value ;
  }

  calculateBooksPrice(i :number) {
    if(this.app.loaded && this.clients )
    return this.rateFormGroup.get("rates")['controls'][i].get("rate").value *  this.rateFormGroup.get("rates")['controls'][i].get("quantity").value * this.app.basic?.tipo_de_cambio[this.clients[(this.newquoteFormGroup.get('client').value??0)]?.currency_id];
    else return 0;
  }

  updateEmailList(): void
  {
    this.apiService.getData('email/list',{}).subscribe(list =>
      {
        this.emailList = list
        //console.log(list);
      });
  }
  addStopFormGroup(): FormGroup {
    return this.fb.group({
        type: [2,[Validators.required]],
        date: '',
        time: '',
        site: ['', [Validators.required]],
        site_id: ['', [Validators.required]],
        zip: ['', [Validators.required]]
      });
    }

    clearSite( i:number) :void {
      this.stopsFormGroup.get("stops")['controls'][i].get("site").setValue('');
      this.stopsFormGroup.get("stops")['controls'][i].get("site_id").setValue('');
    }
    zipOnFocus(i : number) :void {



         this.filteredOptions = this.stopsFormGroup.get("stops")['controls'][i].get("zip").valueChanges.pipe(
        //                         //this.stopsFormGroup.get("stops")[i].get('zip').valueChanges.pipe(
         startWith(''),
         debounceTime(400),
         distinctUntilChanged(),
         switchMap(val  =>
            this.filter(val || '')
           )
        );

  }

  addStopClick(): void {
    (<FormArray>this.stopsFormGroup.get("stops")).push(
      this.addStopFormGroup()
    );

    this.stopsCount = (<FormArray>this.stopsFormGroup.get("stops")).length;


  }
  removeStopClick(i: number){

    const aFormArray = this.stopsFormGroup.controls.stops as FormArray;

    //const aFormArray = aFormGroup.controls.products as FormArray;
    aFormArray.removeAt(i);
    this.stopsCount = (<FormArray>this.stopsFormGroup.get("stops")).length;

  }
  openNewSite(i:number,name)
  {
    let dialogParams = {
      width: 'calc( 100% - 50px )',
      height: 'calc( 100% - 50px )',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: name
    };
    const dialogRef = this.dialog.open(NewsitedialogComponent, dialogParams);

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        this.stopsFormGroup.get("stops")['controls'][i].get("site").setValue(result.name);
        this.stopsFormGroup.get("stops")['controls'][i].get("site_id").setValue(result.id);
        this.stopsFormGroup.get("stops")['controls'][i].get("zip").setValue(result.city +' '+result.state);
      }


    });
  }


  openSiteDialog(i: number) {

    const dialogRef = this.dialog.open(SitedialogComponent,this.dialogParams);

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        if(result['create_new'])
        {
          this.openNewSite(i,result['name']);
        }

        else
        {
          this.stopsFormGroup.get("stops")['controls'][i].get("site").setValue(result.name);
          this.stopsFormGroup.get("stops")['controls'][i].get("site_id").setValue(result.id);
          this.stopsFormGroup.get("stops")['controls'][i].get("zip").setValue(result.city +' '+result.state);
        }
      }
    });
  }

  getClients(): void {
    this.apiService.getData('clients/list').subscribe(clients => this.clients = clients.data);
  }

  addPOFormGroup(): FormGroup {
    return this.fb.group({
        po: '',
        description: '',
        quantity: ['', [Validators.required]],
        po_units_id: [1, [Validators.required]],
        weight: ['', [Validators.required]],
        po_weight_id: [1, [Validators.required]],
        length: ['40', ],
        width: ['48', ],
        height: ['', ]

      });
  }

  addPOClick(): void {
    (<FormArray>this.poFormGroup.get("pos")).push(
      this.addPOFormGroup()
    );

  }
  removePOClick(i: number){
    const aFormArray = this.poFormGroup.controls.pos as FormArray;
    aFormArray.removeAt(i);
  }


  addRateFormGroup(): FormGroup {
    return this.fb.group({
      description: 1,
      rate: ['', [Validators.required]],
      unit_type: [1, [Validators.required]],
      quantity: ['1', [Validators.required]],
      client_total_rate: ['', [Validators.required]],
      books_total_rate: ['', [Validators.required]],
      });
  }

  addRateClick(): void {
    (<FormArray>this.rateFormGroup.get("rates")).push(
      this.addRateFormGroup()
    );

  }
  removeRateClick(i: number){
    const aFormArray = this.rateFormGroup.controls.rates as FormArray;
    aFormArray.removeAt(i);
  }



}
