import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Observable, of, Subscription, empty } from 'rxjs';
import { tap, startWith, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { asyncValidator } from '../helpers/custom-async-validator';
import { ApiService } from '../services/api.service';
import {Router} from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AppConstants } from '../structures/appconstants';
import { AppComponent } from '../app.component';
//import { formatDate } from "@angular/common";
import { DatePipe } from '@angular/common'
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';


//private app: AppComponent
@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})

export class PaymentsComponent implements OnInit {

  dataSource;
  constructor(
    private apiService: ApiService,
    private router: Router,
    private app: AppComponent,
    public datepipe: DatePipe,
    private sanitizer: DomSanitizer,
    fb: FormBuilder

  )
  {

  }

  ngOnInit(): void {
    this.getRows();
  }

  getRows(): void
  {
    this.apiService.getData( 'accounting/payments/list',{} )
      .subscribe(param =>
        {
          //if(this.dataSource)
            this.dataSource  = param.data;
          
        });

  }


}
