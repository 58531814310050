<mat-vertical-stepper linear="false" #stepper>
  <mat-step [stepControl]="newquoteFormGroup" [editable]="!formSubmitted">
    <form [formGroup]="newquoteFormGroup">
      <ng-template matStepLabel>{{ 'interface.basic_info' | translate }}</ng-template>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
            <mat-label >{{ 'interface.client' | translate }}</mat-label>
            <mat-select formControlName="client" (selectionChange)="changeClient()">
              <mat-option *ngFor="let client of clients; let i = index" [value]="i">
                {{client.name | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
            <mat-label >{{ 'interface.mode' | translate }}</mat-label>
            <mat-select formControlName="mode">
              <mat-option *ngFor="let mode of modes" [value]="mode.value">
                {{mode.viewValue | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
            <mat-label >{{ 'interface.equipment_type' | translate }}</mat-label>
            <mat-select formControlName="equipment_type">
              <mat-option *ngFor="let equipment of equipment_types" [value]="equipment.value">
                {{equipment.viewValue | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>



        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
            <input matInput placeholder="{{'interface.client_ref' | translate }}" formControlName="client_ref">
          </mat-form-field>

          <mat-form-field fxFlex>
            <input matInput placeholder="{{'interface.description' | translate }}" formControlName="description">
          </mat-form-field>

        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
            <input matInput placeholder="{{'interface.temperature' | translate }}" formControlName="temperature">
          </mat-form-field>

        </div>

        <div>
          <button mat-raised-button matStepperNext color="primary">{{'interface.next' |translate}}</button>
        </div>
      </form>
    </mat-step>
    <mat-step [editable]="!formSubmitted" [stepControl]="stopsFormGroup" label="{{ 'interface.stops' | translate}}">
      <form [formGroup]="stopsFormGroup">

        <div formArrayName="stops" *ngFor="let stop of stopsFormGroup.get('stops')['controls']; let i = index">
          <div [formGroupName]="i" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">



          <mat-form-field fxFlex>

            <mat-label >{{ 'interface.type' | translate }}</mat-label>
            <mat-select formControlName="type">
              <mat-option *ngFor="let type of stop_types" [value]="type.value">
                {{type.viewValue | translate}}
              </mat-option>
            </mat-select>

          </mat-form-field>

          <mat-form-field fxFlex>
            <input readonly matInput placeholder="{{ 'interface.site' | translate }}" formControlName="site" (click)="openSiteDialog(i)">
          </mat-form-field>
          <input type="hidden" formControlName="site_id">

          <mat-form-field fxFlex>
            <input type="text" placeholder="{{ 'interface.zip' | translate }}" aria-label="Number" matInput formControlName="zip" [matAutocomplete]="auto_i" (focus)="zipOnFocus(i)">
            <mat-autocomplete #auto_i="matAutocomplete" (optionSelected)='clearSite(i)' [displayWith]="displayFn">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>


          <mat-form-field fxFlex>
            <mat-label>Date</mat-label>
            <input matInput [matDatepicker]="date" formControlName="date">
            <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
            <mat-datepicker #date></mat-datepicker>

          </mat-form-field>


          <mat-form-field fxFlex>
            <mat-label>Time</mat-label>
            <input matInput type="time" formControlName="time">
          </mat-form-field>





          <div fxFlex ="40px">
            <button mat-icon-button  aria-label="remove" matTooltip="{{'interface.remove' | translate}}" (click)="removeStopClick(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>


          </div>
        </div>

        <div class='bottom_bar'>

          <button mat-raised-button matStepperPrevious>{{'interface.back' |translate}}</button>
          <button mat-raised-button matStepperNext color="primary">{{'interface.next' |translate}}</button>

            <span class="spacer"></span>

          <button mat-icon-button  aria-label="add" matTooltip="{{'interface.add' | translate}}" (click)="addStopClick()">
            <mat-icon>add</mat-icon>
          </button>
        </div>


      </form>
    </mat-step>
    <mat-step [editable]="!formSubmitted" [stepControl]="poFormGroup" label="POs">
      <form [formGroup]="poFormGroup">
        <div class="scrollable">
          <div formArrayName="pos" *ngFor="let pos of poFormGroup.get('pos')['controls']; let i = index">
            <div [formGroupName]="i" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">

            <mat-form-field fxFlex>
              <input matInput placeholder="{{'interface.po' | translate }}" formControlName="po">
            </mat-form-field>

            <mat-form-field fxFlex>
              <input matInput placeholder="{{'interface.description' | translate }}" formControlName="description">
            </mat-form-field>

            <mat-form-field fxFlex>
              <input matInput placeholder="{{'interface.quantity' | translate }}" formControlName="quantity" (change)='changePO(i)'>
            </mat-form-field>

            <mat-form-field fxFlex>
              <mat-label >{{ 'interface.units' | translate }}</mat-label>
              <mat-select formControlName="po_units_id">
                <mat-option *ngFor="let unit of po_units_id" [value]="unit.value">
                  {{unit.viewValue | translate}}
                </mat-option>
              </mat-select>

            </mat-form-field>

            <mat-form-field fxFlex>
              <input matInput placeholder="{{'interface.weight' | translate }}" formControlName="weight" (change)='changePO(i)'>
            </mat-form-field>

            <mat-form-field fxFlex>
              <mat-label >{{ 'interface.units' | translate }}</mat-label>
              <mat-select formControlName="po_weight_id" (selectionChange)='changePO(i)'>
                <mat-option *ngFor="let unit of po_weight_id" [value]="unit.value">
                  {{unit.viewValue | translate}}
                </mat-option>
              </mat-select>

            </mat-form-field>



            <mat-form-field fxFlex>
              <input matInput placeholder="{{'interface.length' | translate }}" formControlName="length" (change)='changePO(i)'>
            </mat-form-field>

            <mat-form-field fxFlex>
              <input matInput placeholder="{{'interface.width' | translate }}" formControlName="width" (change)='changePO(i)'>
            </mat-form-field>

            <mat-form-field fxFlex>
              <input matInput placeholder="{{'interface.height' | translate }}" formControlName="height" (change)='changePO(i)'>
            </mat-form-field>

            <mat-form-field fxFlex>
              <input matInput placeholder="{{'interface.nmfc' | translate }}" formControlName="nmfc">
            </mat-form-field>

            <mat-form-field fxFlex>
              <input matInput placeholder="{{'interface.class' | translate }}" formControlName="class">
            </mat-form-field>

            <mat-form-field fxFlex>
              <input matInput placeholder="{{'interface.subclass' | translate }}" formControlName="subclass">
            </mat-form-field>

            <section class="checkbox_class" fxFlex>
            <mat-checkbox  formControlName="stackable">
               stackable
            </mat-checkbox>
            </section>


            <div fxFlex ="40px">
              <button mat-icon-button  aria-label="remove" matTooltip="{{'interface.remove' | translate}}" (click)="removePOClick(i)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>

          </div>
        </div>
      </div>
        <div class='bottom_bar'>

          <button mat-raised-button matStepperPrevious>{{'interface.back' |translate}}</button>
          <button mat-raised-button matStepperNext color="primary">{{'interface.next' |translate}}</button>

            <span class="spacer"></span>

          <button mat-icon-button  aria-label="add" matTooltip="{{'interface.add' | translate}}" (click)="addPOClick()">
            <mat-icon>add</mat-icon>
          </button>
        </div>

      </form>
    </mat-step>

    <mat-step [editable]="!formSubmitted" [stepControl]="poFormGroup" label="Email">

      <form [formGroup]="emailFormGroup">


        <button mat-raised-button (click)='updateEmailList()'>{{'interface.threads' |translate}}</button>

        <label id="example-radio-group-label">Select Email Thread</label>

        <mat-radio-group
          formControlName="emailThread"
          aria-labelledby="example-radio-group-label"
          class="example-radio-group">


          <mat-radio-button class="example-radio-button" *ngFor="let thread of emailList" value="{{thread.threadId}}" (change)="emailSelected($event,thread)" >

            <mat-card>
              <mat-card-header>
                <mat-card-title>{{thread.subject}}</mat-card-title>
                <mat-card-subtitle>
                  <li *ngFor="let from of thread.from">{{from}}</li>
                </mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                {{thread.snippet}}
              </mat-card-content>

            </mat-card>


          </mat-radio-button>
        </mat-radio-group>


        <div class='bottom_bar'>

          <button mat-raised-button matStepperPrevious>{{'interface.back' |translate}}</button>
          <button mat-raised-button matStepperNext color="primary">{{'interface.next' |translate}}</button>

            <span class="spacer"></span>

        </div>

      </form>
    </mat-step>

    <mat-step [editable]="!formSubmitted" [stepControl]="rateFormGroup" label="{{ 'interface.rates' | translate}}">
      <form [formGroup]="rateFormGroup">



        <div formArrayName="rates" *ngFor="let rate of rateFormGroup.get('rates')['controls']; let i = index">
          <div [formGroupName]="i" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">



          <mat-form-field fxFlex>
            <mat-label >{{ 'interface.description' | translate }}</mat-label>
            <mat-select formControlName="description">
              <mat-option *ngFor="let et of expense_types" [value]="et.value">
                {{et.viewValue | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex>
            <input matInput placeholder="{{'interface.rate' | translate }}" formControlName="rate" (change)='changeRate(i)'>
          </mat-form-field>
          <mat-form-field fxFlex>
            <mat-label >{{ 'interface.unit_type' | translate }}</mat-label>
            <mat-select formControlName="unit_type">
              <mat-option *ngFor="let ut of unit_type" [value]="ut.value">
                {{ut.viewValue | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex>
            <input matInput placeholder="{{'interface.quantity' | translate }}" formControlName="quantity" (change)='changeRate(i)'>
          </mat-form-field>

          <mat-form-field fxFlex>
            <input matInput placeholder="{{('interface.total' | translate) + ' ' + ((newquoteFormGroup.get('client').value !== '' ) ? (clients[newquoteFormGroup.get('client').value]).currency_name: '')}} " formControlName="client_total_rate" >
          </mat-form-field>

          <mat-form-field fxFlex>
            <!-- ('interface.books_total_rate' | translate) -->
            <input matInput placeholder="{{ ('interface.total' | translate) + ' ' + (app.basic ? app.basic.company_currency.name:'')}}" formControlName="books_total_rate" readonly>
          </mat-form-field>

          <div fxFlex ="40px">
            <button mat-icon-button  aria-label="remove" matTooltip="{{'interface.remove' | translate}}" (click)="removeRateClick(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>


        </div>


        <div class='bottom_bar'>

          <button mat-raised-button matStepperPrevious>{{'interface.back' |translate}}</button>
          <button mat-raised-button matStepperNext color="primary">{{'interface.next' |translate}}</button>



            <span class="spacer"></span>

          <button mat-icon-button  aria-label="add" matTooltip="{{'interface.add' | translate}}" (click)="addRateClick()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </form>
  </mat-step>

  <mat-step [editable]="!formSubmitted" [stepControl]="finalFormGroup"  label="{{ 'interface.summary' | translate}}">
    <form [formGroup]="finalFormGroup">


  </form>

  <div class='bottom_bar'>



    <button mat-raised-button matStepperPrevious>{{'interface.back' |translate}}</button>
    <button (click)="submit2()" mat-raised-button color="primary">{{'interface.send' |translate}}</button>
    <!-- [disabled]="(!newquoteFormGroup.valid) || (!stopsFormGroup.valid) || (!poFormGroup.valid) || (!rateFormGroup.valid) ||(!finalFormGroup.valid || (!(stopsCount>=2)))" -->

      <span class="spacer"></span>

  </div>
</mat-step>



</mat-vertical-stepper>
