import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Observable, of, Subscription, empty } from 'rxjs';
import { tap, startWith, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { asyncValidator } from '../helpers/custom-async-validator';
import { ApiService } from '../services/api.service';
import { MatTableDataSource } from '@angular/material/table';
import {Router} from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppConstants } from '../structures/appconstants';
import { AppComponent } from '../app.component';
//import { formatDate } from "@angular/common";
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-facturas',
  templateUrl: './facturas.component.html',
  styleUrls: ['../shared/panel.component.css']
})

export class FacturasComponent implements OnInit {

  loaded: boolean

  filterFormGroup :FormGroup;
  facturasFormGroup :FormGroup;
  filter = {};
  selectedFacturas = {};
  checkedList:any[] = [];


  displayedColumns: string[] = ['check','select','contract_id','amount_paid_books','fecha_emision','nit_proveedor','razon_social','nro_factura','nro_autorizacion','importe_total_compra','importe_no_sujeto_a_credito_fiscal','subtotal','descuentos_bonificaciones','importe_base_para_credito_fiscal','credito_fiscal','codigo_de_control','tipo_de_compra_name'];
  displayedColumnsSelected: string[] = ['contract_id','amount_paid_books','fecha_emision','nit_proveedor','razon_social','nro_factura','nro_autorizacion','importe_total_compra','importe_no_sujeto_a_credito_fiscal','subtotal','descuentos_bonificaciones','importe_base_para_credito_fiscal','credito_fiscal','codigo_de_control','tipo_de_compra_name'];
  displayedColumnsFooter :  string[] = ['importe_total_compra','importe_no_sujeto_a_credito_fiscal','subtotal','descuentos_bonificaciones','importe_base_para_credito_fiscal','credito_fiscal'];
  dataSource : MatTableDataSource<any>;
  dataSourceSelected : MatTableDataSource<any>;

  accountFilteredOptions: Observable<any[]>;


  formEntry: FormGroup;

  ngOnInit(): void {
    this.getRows();
  }



  constructor(
    private apiService: ApiService,
    private router: Router,
    private app: AppComponent,
    public datepipe: DatePipe,
    fb: FormBuilder

  )
  {
    this.loaded = false;
    this.dataSourceSelected = new MatTableDataSource<any>();

    this.formEntry = fb.group({
      account: [
        '',
        [Validators.required]
      ],
      total: [
        this.totalFacturas(),
        [Validators.required]
      ],
      currency_id: [
        '',
        [Validators.required]
      ],
      total_books: [
        this.totalBooks(),
        [Validators.required]
      ],
      payment_number: [
        '',
        [Validators.required]
      ],
      date: [
        '',
        [Validators.required]
      ],
      file: [
        '',
        [Validators.required]
      ]

    });
    this.accountFilteredOptions = this.formEntry.get("account").valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(value =>
         this.accountFilter(typeof value === 'string' ? value : value.name || '')
        )
      //map(value => typeof value === 'string' ? value : value.name)
      //map(name => name ? this._filter(name) : this.options.slice())
     )


    this.filterFormGroup = fb.group({
      pickup_start: ['',[]],
      pickup_end: ['',[]],
      delivery_start: ['',[]],
      delivery_end: ['',[]]
    });
  }

  getCurrencies()
  {
    return AppConstants.currency_id;
  }


  accountFilter(val: string): Observable<any[]> {
    // call the service which makes the http-request
    return this.apiService.getData('accounts/filter_list',{query: val})
      .pipe(
      //   map(response => response.filter(option => {
      //     return option.name.toLowerCase().indexOf(val.toLowerCase()) === 0
    //     }))
      )
  }


  applyFilter(): void {
    this.filter = this.filterFormGroup.value;
    this.getRows();
  }

  displayFn(option): string {
    return option.name;//option && option.name ? option.name : '';
  }

  resetFilter(): void {
    this.filter = {};
    this.filterFormGroup.reset();
    this.getRows();
  }


  rowClick( row, event ) {
  if (( event.metaKey ) || (event.ctrlKey))
  {
    var win = window.open('/accounting/factura/'+ row.id , '_blank');
    if (win) {
        win.focus();
    } else {
        alert('Please allow popups for this website');
    }
  }
    else
//    OperationComponent.set_row(row.id);//.selected_row = row.id;
    this.router.navigate(['/accounting/factura/' + row.id  ]);

  }

  getRows(): void {
    this.apiService.getData( 'accounting/facturas/list',this.filter )
      .subscribe(param =>
        {
          if(this.dataSource)
            this.dataSource.data  = param;
          else
            this.dataSource = new MatTableDataSource<any>(param)
          this.loaded = true;
        });

  }


  selectedFiles = null;
  selectFile(event) {
    this.selectedFiles = event.target.files;
  }

  public toFormData( formValue ) {
  const formData = new FormData();

  for ( const key of Object.keys(formValue.controls) ) {

    const value = formValue.controls[key].value;
    if( key == 'date')
    {
      const  latest_date =this.datepipe.transform(value, 'yyyy-MM-dd');
      formData.append(key, latest_date);
    }
    else if( key == 'account')
    {
      formData.append(key, value.id);
    }
    else
      formData.append(key, value);
  }

  return formData;
}

recordPayment() :void{
  //if (this.formEntry.valid)
  {
    // the handler, e.g. if user presses "upload"-button
    //const file: FileInput = this.formEntry.get('file2').value;
    //const file = file_form.files[0]; // in case user didn't selected multiple files

    //const formData = new FormData();
    const formData = this.toFormData(this.formEntry)
    //formData.append('file', file,file.name); // attach blob to formdata / preparing the request





    //data['invoices'] = [];
   for(let row of this.dataSourceSelected.data)
   {
       //data['invoices'].push(row.id);
       formData.append('invoice[]', row.id);
   }



  this.apiService.getData( 'accounting/payments_bolivia/store', formData )
    .subscribe(
      result => {
      //this.getContract();
    });
  }

}




  //@ViewChild('tableSelected',{static:true}) tableElement: MatTableDataSource<any>;

  onCheckboxChange(option,id,event) {
    console.log(event);
    const data = this.dataSourceSelected.data;

     if(event.checked) {
       //this.dataSourceSelected.data[option] = this.dataSource.data[option];
      // data[option] = this.dataSource.data[option];
       data.push( this.dataSource.data[option] );
       //this.checkedList[option] = (data.length-1);
     } else {
       //const index: number = this.checkedList.indexOf(option);
       for(var i = 0; i < data.length; i++) {
         if(data[i].id == id)
         {
          data.splice(i,1);
          break;
         }
       }

    //    if (index !== -1) {
          //this.checkedList.splice(index, 1);
      //    this.dataSourceSelected.data.splice(option,1);
          //data.splice(this.checkedList[option],1);
          //this.checkedList.splice(option,1);
        //}
      }
      this.dataSourceSelected.data = data;

      this.formEntry.controls['total'].setValue(this.totalFacturas());
      this.formEntry.controls['total_books'].setValue( this.totalBooks());
    //  this.tableElement.renderRows();

  //console.log(this.checkedList);
  }


  updateCurrency()
  {
    console.log('updateCurrency');
    this.formEntry.controls['total_books'].setValue( this.totalBooks());

  }

  totalFacturas()
  {
    //return this.dataSource.data.reduce((accum, curr) => accum += curr.revenue - curr.costs, 0);
    return this.dataSourceSelected.filteredData.reduce((accum:number, curr) => accum += Number(curr.importe_total_compra), 0);
    //.filteredData.map(t => t.duration).reduce((acc, value) => acc + value, 0)

  }

  totalBooks()
  {
    //return this.dataSource.data.reduce((accum, curr) => accum += curr.revenue - curr.costs, 0);
    //console.log(this.formEntry.get('total').value);
    return this.dataSourceSelected.filteredData.reduce((accum:number, curr) => accum += Number(curr.importe_total_compra), 0) * this.app.getBasic()?.tipo_de_cambio[this.formEntry.controls.currency_id.value];
    //.filteredData.map(t => t.duration).reduce((acc, value) => acc + value, 0)

  }

  totalCol(col)
  {
    //return this.dataSource.data.reduce((accum, curr) => accum += curr.revenue - curr.costs, 0);
    if( this.displayedColumnsFooter.indexOf(col) != -1)
    return this.dataSourceSelected.filteredData.reduce((accum:number, curr) => accum += Number(curr[col]), 0);
    else
      return null;
    //.filteredData.map(t => t.duration).reduce((acc, value) => acc + value, 0)

  }


}
