import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ApiService } from '../services/api.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppConstants } from '../structures/appconstants';
import { AppComponent } from '../app.component';
import { MatTableDataSource } from '@angular/material/table';
import { EditableTableComponent } from '../editableTable/editableTable.component';
import { EditableFormComponent } from '../editableForm/editableForm.component';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-prospect',
  templateUrl: './prospect.component.html',
  styleUrls:  ['./prospect.component.css']
})
export class ProspectComponent implements OnInit {
  @Input() id: number;

  //@ViewChild('invoicesEditableTableId') invoicesEditableTable:EditableTableComponent;
  //@ViewChild('budgetsEditableTableId') budgetsEditableTable:EditableTableComponent;

  data : any;
  loaded: boolean;
  constructor(
    private apiService: ApiService,
    private app: AppComponent,
    public translate: TranslateService,
    fb: FormBuilder,
  ) {

    this.loaded = false;

  }

  ngOnInit(): void {

  }

  ngOnChanges(changes) {
    this.getSales();
  }

  getSales(): void {
    this.loaded = false;
    const id = +this.id;
    if(!this.id) return;
    this.apiService.getData('prospects/prospect_detail/view/'+ id )
      .subscribe(detailed_operation =>
        {
          this.data = detailed_operation.data;
          this.loaded = true;
        });
  }
}
