<div class="panel_div">
<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      Filter
    </mat-panel-title>
    <mat-panel-description>
      This is a summary of the content
    </mat-panel-description>
  </mat-expansion-panel-header>



  <form [formGroup]="filterFormGroup">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
      <mat-form-field fxFlex>
        <mat-label >{{ 'interface.client' | translate }}</mat-label>
        <mat-select formControlName="client">
          <mat-option *ngFor="let client of clients" [value]="client.id">
            {{client.name | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'interface.pickup_date_range' | translate}}</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate formControlName="pickup_start" placeholder="Start date">
          <input matEndDate formControlName="pickup_end" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>


      <mat-form-field fxFlex>
        <mat-label>{{ 'interface.delivery_date_range' | translate}}</mat-label>
        <mat-date-range-input [rangePicker]="picker2">
          <input matStartDate formControlName="delivery_start" placeholder="Start date">
          <input matEndDate formControlName="delivery_end" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-date-range-picker #picker2></mat-date-range-picker>
      </mat-form-field>


    </div>

    <button mat-raised-button color="primary" class="btn-block" (click)="applyFilter()" [disabled]="!filterFormGroup.valid">{{ 'interface.apply' | translate}}</button>
    <button mat-raised-button color="warn" class="btn-block" (click)="resetFilter()">{{ 'interface.reset' | translate}}</button>

  </form>

</mat-expansion-panel>


<mat-tab-group (selectedTabChange)="tabClick($event)">
  <mat-tab *ngFor="let tab of tabs" label="{{'interface.'+tab.name | translate}}">
    <ng-container *ngTemplateOutlet="tabContent"></ng-container>
  </mat-tab>

</mat-tab-group>

<ng-template #tabContent >
  <table mat-table [dataSource]="dataSource" class='operations_table'>

    <ng-container matColumnDef="select" >
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row"> <button mat-stroked-button  (click)="rowClick(row, $event)" >{{ 'interface.go' | translate }}</button> </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>


    <ng-container *ngFor="let col of displayedColumns | slice:1" matColumnDef="{{col}}">
      <div *ngIf="col=='pickups'||col=='deliveries'||col=='pickups_dates'||col=='deliveries_dates'; else elseBlock1">

      <th mat-header-cell *matHeaderCellDef> {{'interface.' + col | translate}} </th>
      <td mat-cell *matCellDef="let element">

          <div *ngIf="col=='pickups'||col=='deliveries'">
              <mat-list role="list" *ngFor="let stop of element[col]">
                {{stop.site_name}}<br/> {{stop.city_name}}, {{stop.state}}
              </mat-list>
          </div>
          <div *ngIf="col=='pickups_dates'||col=='deliveries_dates'">
              <mat-list role="list" *ngFor="let stop of element[col=='pickups_dates'?'pickups':'deliveries']">
                {{stop.appointment_from_date}}
              </mat-list>
          </div>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
        </div>



        <ng-template #elseBlock1>
          <ng-container *ngIf="col == 'gm';else elseBlock2">

          <th mat-header-cell *matHeaderCellDef> {{'interface.' + col | translate}} </th>
          <td mat-cell *matCellDef="let element">
          {{element['revenue']-element['costs']}}
          </td>

          <td mat-footer-cell *matFooterCellDef> {{totalGM()}} </td>

          </ng-container>
        </ng-template>


        <ng-template #elseBlock2>

          <th mat-header-cell *matHeaderCellDef> {{'interface.' + col | translate}} </th>
          <td mat-cell *matCellDef="let element">
          {{element[col]}}
          </td>

          <td mat-footer-cell *matFooterCellDef></td>
        </ng-template>

      </ng-container>





    <!-- <ng-container matColumnDef="pickups">
      <th mat-header-cell *matHeaderCellDef> Pickups </th>
      <td mat-cell *matCellDef="let element">
        <mat-list role="list" *ngFor="let pickup of element.pickups">
          {{pickup.site_name}}<br/> {{pickup.city_name}},{{pickup.state}}
        </mat-list>
      </td>
    </ng-container>

    <ng-container matColumnDef="pickups_dates">
      <th mat-header-cell *matHeaderCellDef> Pickups Dates </th>
      <td mat-cell *matCellDef="let element">
        <mat-list role="list" *ngFor="let pickup of element.pickups">
          {{pickup.departure_date}}
        </mat-list>
      </td>
    </ng-container>

    <ng-container matColumnDef="deliveries">
      <th mat-header-cell *matHeaderCellDef> Deliveries </th>
      <td mat-cell *matCellDef="let element">
        <mat-list role="list" *ngFor="let delivery of element.deliveries">
          {{delivery.site_name}}<br/> {{delivery.city_name}},{{delivery.state}}
        </mat-list>
      </td>
    </ng-container>

    <ng-container matColumnDef="deliveries_dates">
      <th mat-header-cell *matHeaderCellDef> Pickups Dates </th>
      <td mat-cell *matCellDef="let element">
        <mat-list role="list" *ngFor="let delivery of element.deliveries">
          {{delivery.departure_date}}
        </mat-list>
      </td>
    </ng-container> -->


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>

  </table>
</ng-template>
</div>
