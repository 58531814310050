import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Observable, of, Subscription, empty } from 'rxjs';
import { tap, startWith, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { asyncValidator } from '../helpers/custom-async-validator';
import { ApiService } from '../services/api.service';
import { MatTableDataSource } from '@angular/material/table';
import {Router} from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AppConstants } from '../structures/appconstants';
import { AppComponent } from '../app.component';
//import { formatDate } from "@angular/common";
import { DatePipe } from '@angular/common'
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import {MatSort} from '@angular/material/sort';
import {MatSidenav} from '@angular/material/sidenav';


//private app: AppComponent
@Component({
  selector: 'app-commissions',
  templateUrl: './commissions.component.html',
  styleUrls: ['../shared/panel.component.css']
})

export class CommissionsComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild(MatSort) sort: MatSort;
  selected_row : number;
  loaded: boolean =true;
  colFilter = {};
  commissionsFormGroup :FormGroup;
  selectedFacturas = {};
  checkedList:any[] = [];
  accountFilteredOptions: Observable<any[]>;

  filterValues = {
    status_name: '',
    service_id: '',
  };


  displayedColumns: string[] = ['check','select','service_id'];
  displayedColumnsSort: string[] = ['sort_check','sort_select','sort_service_id'];
  displayedColumnsSelected: string[] = ['service_id'];
  displayedColumnsFooter :  string[] = [];
  dataSource : MatTableDataSource<any>;
  dataSourceSelected : MatTableDataSource<any>;




  ngOnInit(): void {
    for (let i of Object.keys(this.colFilter))
    {

      this.colFilter[i].valueChanges
       .pipe(
         debounceTime(1000), // Waiting for 1 sec while you are typing
          distinctUntilChanged() // Prevents the emitting if the 'start' value and the 'end' value are the same
       )
      .subscribe(
        value => {
            this.filterValues[i] = value;
          this.dataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    }
  }



  constructor(
    private apiService: ApiService,
    private app: AppComponent,
    public datepipe: DatePipe,
    private sanitizer: DomSanitizer,
    fb: FormBuilder
  )
  {
    this.dataSource = new MatTableDataSource<any>();
    this.dataSourceSelected = new MatTableDataSource<any>();

    this.getRows();

    for (let i of Object.keys(this.filterValues)) {
      this.colFilter[i] = new FormControl('');
    }





  }


  rowClick( row, event ) {

    this.selected_row = row.id;
    this.sidenav.open();

  }

  public toFormData( formValue ) {
  const formData = new FormData();

  for ( const key of Object.keys(formValue.controls) ) {

    const value = formValue.controls[key].value;
    if( key == 'date')
    {
      const  latest_date =this.datepipe.transform(value, 'yyyy-MM-dd');
      formData.append(key, latest_date);
    }
    else if( key == 'account')
    {
      formData.append(key, value.id);
    }
    else
      formData.append(key, value);
  }

  return formData;
}

toggleChangeStatus(event)
{

}

  // Payment
  recordPayment() :void{
    //if (this.formEntry.valid)
    {
      // the handler, e.g. if user presses "upload"-button
      //const file: FileInput = this.formEntry.get('file2').value;
      //const file = file_form.files[0]; // in case user didn't selected multiple files

      //const formData = new FormData();
      //const formData = this.toFormData(this.formEntry)
      //formData.append('file', file,file.name); // attach blob to formdata / preparing the request





      //data['invoices'] = [];
     for(let row of this.dataSourceSelected.data)
     {
         //data['invoices'].push(row.id);
      //   formData.append('invoice[]', row.id);
     }



    // this.apiService.getData( 'accounting//store', formData )
    //   .subscribe(
    //     result => {
    //     //this.getContract();
    //   });
     }

  }


  getRows(): void {
    this.apiService.getData( 'accounting/commissions/list',{} )
      .subscribe(param =>
        {
          if(this.dataSource)
          {
            this.dataSource.data  = param.data;
            console.log('alreayd created');
          }
          else
          {
            this.dataSource = new MatTableDataSource<any>(param.data)
            console.log('new created');
          }

          //this.loaded = true;

          this.dataSource.filterPredicate = this.createFilter();

          this.filterValues['status_name'] = 'pending';
          this.dataSource.filter = JSON.stringify(this.filterValues);

          this.dataSource.sortData = this.createSort();
          this.dataSource.sort = this.sort;

        });

  }



  createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function(data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      let properties = Object.keys(searchTerms);

      for (let i of properties)
      {
        if((searchTerms[i] =='')|| searchTerms[i] == null)
        {
          continue;
        }
        else if( (i == 'status_name') )
        {
            if(searchTerms['status_name'].indexOf(data['status_name']) == -1)
              return false;
        }
        else
        {
          if(data[i]?.toString().toLowerCase().indexOf(searchTerms[i]) === -1 )
          {
            return false;
          }
        }
      }
      return true;
    }
    return filterFunction;
  }

  //@ViewChild('tableSelected',{static:true}) tableElement: MatTableDataSource<any>;

  onCheckboxChange(option,id,event) {
    console.log(event);
    const data = this.dataSourceSelected.data;

     if(event.checked)
     {
       for(var i = 0; i < this.dataSource.filteredData.length; i++) {
         if( this.dataSource.filteredData[i].id == id)
         {
          // this.dataSource.filteredData[i].isChecked = true;
          data.push( this.dataSource.filteredData[i] );
          //data.splice(i,1);
          break;
         }
       }

//       data.push( this.dataSource.filteredData[option] );

  //console.log( this.dataSource.filteredData[option].id );




     }
     else
     {
       for(var i = 0; i < data.length; i++) {
         if(data[i].id == id)
         {
          data.splice(i,1);
          break;
         }
       }

    //    if (index !== -1) {
          //this.checkedList.splice(index, 1);
      //    this.dataSourceSelected.data.splice(option,1);
          //data.splice(this.checkedList[option],1);
          //this.checkedList.splice(option,1);
        //}
      }
      this.dataSourceSelected.data = data;
      // this.formEntry.controls['total'].setValue(this.totalFacturas());
      // this.formEntry.controls['total_books'].setValue( this.totalBooks());
      // console.log('update');


  //console.log(this.checkedList);
  }



  // totalFacturas()
  // {
  //   //return this.dataSource.data.reduce((accum, curr) => accum += curr.revenue - curr.costs, 0);
  //   return this.dataSourceSelected.filteredData.reduce((accum:number, curr) => accum += Number(curr.total_vendor), 0);
  //   //.filteredData.map(t => t.duration).reduce((acc, value) => acc + value, 0)
  //
  // }
  //
  // totalBooks()
  // {
  //   //return this.dataSource.data.reduce((accum, curr) => accum += curr.revenue - curr.costs, 0);
  //   //console.log(this.formEntry.get('total').value);
  //
  //   //return this.dataSourceSelected.filteredData.reduce((accum:number, curr) => accum += Number(curr.total_vendor), 0) * this.app.getBasic()?.tipo_de_cambio[this.formEntry.controls.currency_id.value];
  //   return this.dataSourceSelected.filteredData.reduce((accum:number, curr) => accum += Number(curr.total_vendor), 0) ;
  //
  //   //.filteredData.map(t => t.duration).reduce((acc, value) => acc + value, 0)
  //
  // }
  //
  // totalCol(col)
  // {
  //   //return this.dataSource.data.reduce((accum, curr) => accum += curr.revenue - curr.costs, 0);
  //
  //
  //   switch( col)
  //   {
  //     case 'amount_paid_books':
  //     case 'subtotal_vendor':
  //     case 'hst_vendor':
  //     case 'total_vendor':
  //     case 'total_books':
  //       let total = this.dataSourceSelected.filteredData.reduce((accum:number, curr) => accum += Number(curr[col]), 0);
  //       return parseFloat(total).toFixed(2)
  //
  //     //case 'contract_id':
  //     //case 'invoice_number':
  //     case 'legal_name':
  //       let cont = this.dataSourceSelected.filteredData.reduce((accum, curr) => accum += '20' + curr['contract_id']+' ', '');
  //       let inv = this.dataSourceSelected.filteredData.reduce((accum, curr) => accum += curr['invoice_number']+' ', '');
  //       let ln = this.dataSourceSelected.data[0]?.legal_name;
  //       return  cont + ' ' + ln +' ' + inv;
  //     default:
  //       return null;
  //   }
  //   // if( this.displayedColumnsFooter.indexOf(col) != -1)
  //   //
  //   // else
  //   //   return null;
  //   // //.filteredData.map(t => t.duration).reduce((acc, value) => acc + value, 0)
  //
  // }


  createSort(): (data: any[], sort: MatSort) => any[]
  {
     let sortData = function(data: any[], sort: MatSort) : any[]{
     console.log('sorting');
     console.log(data);
     console.log(sort);
    var active = sort.active;
    const direction = sort.direction;
    if (!active || direction == '') { return data; }

    active = sort.active.slice(5);
    return data.sort((a, b) => {
      //console.log(a, ' vs ', b);

      let valueA = null;// = this.sortingDataAccessor(a, active);
      let valueB = null;// = this.sortingDataAccessor(b, active);

      switch( active)
      {
        default:
          valueA = this.sortingDataAccessor(a, active);
          valueB = this.sortingDataAccessor(b, active);
        break;

      }


      // If there are data in the column that can be converted to a number,
      // it must be ensured that the rest of the data
      // is of the same type so as not to order incorrectly.
      const valueAType = typeof valueA;
      const valueBType = typeof valueB;

      if (valueAType !== valueBType) {
        if (valueAType === 'number') { valueA += ''; }
        if (valueBType === 'number') { valueB += ''; }
      }

      // If both valueA and valueB exist (truthy), then compare the two. Otherwise, check if
      // one value exists while the other doesn't. In this case, existing value should come last.
      // This avoids inconsistent results when comparing values to undefined/null.
      // If neither value exists, return 0 (equal).
      let comparatorResult = 0;
      if (valueA != null && valueB != null) {
        // Check if one value is greater than the other; if equal, comparatorResult should remain 0.
        if (valueA > valueB) {
          comparatorResult = 1;
        } else if (valueA < valueB) {
          comparatorResult = -1;
        }
      } else if (valueA != null) {
        comparatorResult = 1;
      } else if (valueB != null) {
        comparatorResult = -1;
      }

      return comparatorResult * (direction == 'asc' ? 1 : -1);
    });
  }
  return sortData;
  }
}
