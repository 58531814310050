import {Component, Input,ElementRef, ViewChild, OnInit} from '@angular/core';
import { Observable, of, Subscription, empty } from 'rxjs';
import { tap, startWith, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
//import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { MatTableDataSource, MatTable } from '@angular/material/table';

//import { MatTable } from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import { AppConstants } from '../structures/appconstants';
import { DatePipe } from '@angular/common'

import {MatSort} from '@angular/material/sort';
import { NgForm, FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-editable-table-filter',
  templateUrl: './editableTableFilter.component.html',
  styleUrls: ['./editableTableFilter.component.css']
})

export class EditableTableFilterComponent implements OnInit {


  dataSource : MatTableDataSource<any>;
  colFilter;
  filterValues;
  @ViewChild('templateForm') templateForm: NgForm;
  @ViewChild(MatSort) sort: MatSort;

  filteredOptions: Observable<any[]>;
  @Input() headers;
  headers_sort;
  @Input() data: any;
  // @Input() selectList: string[];
  @Input() updateUrl: string;
  @Input() newUrl: string;
  @Input() removeUrl: string;
  @Input() blockEdit: string = "";
  //@Input() checkMarkCallback: (args: any) => void = function(){ alert('not defined');};
  @Input() fieldChangeCallback: (args: any) => void = function(){ alert('not defined');};

  // @Input() required: string[];
  // @Input() number: string[];
  // @Input() checkList: string[];
  // @Input() date: string[] = [];
  // @Input() static_field: string[] = [];

  @Input() popup: (args: any) => void = function(){ alert('not defined');};
  headers_array:string[] =[ ];
  headers_array_sort:string[] =[ ];
  //@ViewChild('editable_table') table: MatTable<any>;
  @ViewChild('editable_table') table: MatTable<any>;

  constructor(
    private apiService: ApiService,
    public datepipe: DatePipe
  )
  {

    this.backup = new Array();
  }

  ngOnChanges(changes) {
    if(this.dataSource)
      this.dataSource.data  = this.data;
    else
    {
      this.dataSource = new MatTableDataSource<any>()
      this.dataSource.data  = this.data;
    }


    this.dataSource.sort = this.sort;
    this.dataSource.sortData = this.createSort();


  }

  log( l)
  {
    console.log(l);
  }
  // selectRow()
  // {
  //
  //   this.checkMarkCallback( this.data );
  // }

  ngOnInit() {

    // if(this.dataSource)
    //   this.dataSource.data  = this.data;
    // else

      //this.table.renderRows();

    this.colFilter = {};
    this.filterValues = {};
    // if(this.selectList)
    //   for( let s of this.selectList)
    //   {
    //     console.log(s);
    //     this.data[s]['select'] = true;
    //   }
    for(let h of this.headers)
    {
      this.headers_array.push(h.name);
      this.headers_array_sort.push('sort_' + h.name);
      this.colFilter[h.name] = new FormControl('');
      this.filterValues[h.name] = '';
      this.dataSource.filterPredicate = this.createFilter();
      this.dataSource.sort = this.sort;
      this.dataSource.sortData = this.createSort();
      this.colFilter[h.name].valueChanges
       .pipe(
         debounceTime(1000), // Waiting for 1 sec while you are typing
          distinctUntilChanged() // Prevents the emitting if the 'start' value and the 'end' value are the same
       )
      .subscribe(
        value => {
          // if((i == 'pickups_dates_start') || (i == 'pickups_dates_end') || (i == 'deliveries_dates_start') || (i == 'deliveries_dates_end'))
          //   this.filterValues[i] = this.datepipe.transform(value, 'yyyy-MM-dd');
          // else
            this.filterValues[h.name] = value;
            this.dataSource.filter = JSON.stringify(this.filterValues);

        }
      );
    }
    this.headers_array.push('edit-save');
    this.headers_array.push('remove-cancel');

    this.headers_array_sort.push('sort_edit-save');
    this.headers_array_sort.push('sort_remove-cancel');



  }


  updateDependents( d , a ) {
    let bool_first = false;
    for(let i = 0; i<this.dataSource.data.length; i++)
    {
      if(this.data[i].editable && (!bool_first) )
      {

        for( let j=0; j< d.length ; j++)
          this.data[i][d[j].name] = d[j].value;
        this.data[i]['additional']=a;
        bool_first = true;
      }
      else
        this.data[i].additional = null;
    }

  }






  backup : any[];
  makeEditable( element)
  {
    element.editable = true;
    for(let e of this.headers)
    {
      this.backup[e] = element[e];
    }
  }

  cancelEditable( element)
  {
    if(!element.id)
    {
      const data = this.dataSource.data;
      data.splice(this.dataSource.data.indexOf(element),1);
      this.dataSource.data = data;
    }
    else
    {


      for(let e of this.headers)
      {
        element[e] = this.backup[e];

      }
      element.editable = false;
    }

  }

  newEditable()
  {
    //this.newLine = true;

    let new_element = {};
    for(let h of this.headers)
    {
      switch(h.type)
      {
         case 'date': {
            new_element[h.name] = new Date();
            break;
         }
         case 'autocomplete': {
            console.log(h.default);
            new_element[h.name] = h.default;
            break;
         }
         default: {
           if(h.default)
              new_element[h.name] = h.default;
           else
              new_element[h.name] = "";

           //if(h.lineChange)

            break;
         }
      }


      //if( this.myValidation.indexOf(e) != -1)
       //new_element[e]
    }
    new_element['editable'] = true;
    //new_element.new_line = true;



    const data = this.dataSource.data;
    data.push(new_element);
    this.dataSource.data = data;

    //this.dataSource.data.data = this.dataSource.data.data;
    // console.log(this.myValidation);
    // console.log(this.data);
    // console.log(this.headers);
  }

  fieldChange(element,header)
  {
    if(header.fieldChange)
      this.fieldChangeCallback(element);
  }




  public templateToFormData( object ) {
    const formData = new FormData();
    for ( const key of Object.keys(object) )
    {
      if (Array.isArray(object[key]))
      {
        for ( let a of object[key] )
          formData.append(key+'[]', a);
      }
      else
      {
        if( object[key]=== null)
          formData.append(key, '');
        else if( object[key]=== false)
          formData.append(key, '0');
        // else if( key == 'date')
        // {
        //     const  latest_date =this.datepipe.transform(value, 'yyyy-MM-dd');
        //     formData.append(key, latest_date);
        // }
        // else if( key =='aut')
        //   formData.append(key, object[key]);
        else
          formData.append(key, object[key]);
      }
    }
    return formData;
  }

  saveEditable( element)
  {
    element.updating = true;

    for(let h of this.headers)
    {
      if(h.type == 'date')
        element[h.name] = this.datepipe.transform(element[h.name], 'yyyy-MM-dd');
      else if((h.type == 'autocomplete') || h.type == 'dialog')
      {
        if( element[h.name] )
          element[h.name+'_id'] = element[h.name].id;
        else
          element[h.name+'_id'] = null;
      }

    }

    const formData = this.templateToFormData(element);

    if(element.id)
    {
      this.apiService.getData( this.updateUrl, formData )
        .subscribe(
          result => {
            if(!result['success'])
            {
              this.cancelEditable(element);

            }

            if(result['file_id'])
              element.file = result['file_id'];
            element.updating = false;
            element.editable = false;
          });
    }
    else
    {
      this.apiService.getData( this.newUrl, formData )
        .subscribe(
          result => {
            if(!result['success'])
            {

            }
            else
            {
              if(result['file_id'])
                element.file = result['file_id'];
              element.id = result['id'];
              element.editable = false;
            }
            element.updating = false;


          });

    }
  }

  rowValid(index)
  {
    for(let h of this.headers)
    {
      if( h.required )
      {
        if( this.data[index][h.name] === '' || this.data[index][h.name] === null )
        {
        //  console.log('not valid'+ this.data[index][h.name] + 'field' +h.name)
          return false;
        }
      }
      if( h.number)
      {
        if(this.data[index][h.name])
          if (isNaN(+this.data[index][h.name]))
            return false;

      }
    }

    return true;

  }
  removeEditable( element)
  {
    if(! confirm("Are you sure to delete"))
      return;
    element.updating = true;

    if(element.id)
    {
      this.apiService.getData( this.removeUrl, element )
        .subscribe(
          result => {
            if(result['success'])
            {
              let i = this.dataSource.data.indexOf(element);
              if( i != -1 )
              {
                const data = this.dataSource.data;
                data.splice(i,1);
                this.dataSource.data = data;
              }
              else
                console.log('not found');

              element.updating = false;
              element.editable = false;
            }
          });
    }
  }

  getStructure( name )
  {
    if(name)
    return AppConstants[name];
    else
    console.log('error getStructure name:' + name);
  }

  getStructureValue( name, id )
  {
    if(name && id)
    {
      return AppConstants[name].filter(x => x.value == id)[0]?.viewValue;
      // if(AppConstants[name][id-1])
      //   return AppConstants[name][id-1].viewValue;
      // else
      //   console.log('error getStructureValue name:' + name + ' id:'+id );
    }
    //else
    console.log('error getStructureValue name:' + name + ' id:'+id );
  }

  autocompleteOnFocus(header_name, h_i) :void
  {
    this.filteredOptions = this.templateForm.control.get(header_name).valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(val  =>
          this.filter(val || '',h_i)
      )
    );
  }

  filter(val, h_i): Observable<any[]>
  {
    return this.apiService.getData(this.headers[h_i].url,{query: val})
      .pipe(
      )
  }

  displayProperty(value) {
    if (value) {
      return value.name;
    }
  }
  local_popup(element)
  {
    console.log('debug here');
    //element.callback(element);//openCarrierDialog.bind(this)
    //this.popup(element);
  }

  optionSelected(event)
  {
    event.option.value.id;
  }


  createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function(data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      let properties = Object.keys(searchTerms);

      for (let i of properties) {
        if((searchTerms[i] =='')|| searchTerms[i] == null){
          continue;
        }
        else if( (i == 'carrier') )
        {
          let result = false;

            if(data[i].name?.toString().toLowerCase().indexOf(searchTerms[i].toLowerCase())  !== -1 ) result = true;
          if(!result) return false;
        }

        else
        {
          if( (!data[i]) || (data[i]?.toString().toLowerCase().indexOf(searchTerms[i].toLowerCase())  === -1 ))
          {
            return false;
          }
        }
      }
      return true;
    }
    return filterFunction;
  }
  createSort(): (data: any[], sort: MatSort) => any[] {
    let sortData = function(data: any[], sort: MatSort) : any[]{

      //console.log('sort');
    var active = sort.active;
    const direction = sort.direction;
    if (!active || direction == '') { return data; }

    active = sort.active.slice(5);
    return data.sort((a, b) => {
      //console.log(a, ' vs ', b);

      let valueA = null;// = this.sortingDataAccessor(a, active);
      let valueB = null;// = this.sortingDataAccessor(b, active);

      //console.log(active);
      switch( active)
      {
        case 'carrier':
          valueA = a.carrier.name;
          valueB = b.carrier.name;
          break;
        default:
          valueA = this.sortingDataAccessor(a, active);
          valueB = this.sortingDataAccessor(b, active);
        break;

      }

      //DEFAULT:
      // If there are data in the column that can be converted to a number,
      // it must be ensured that the rest of the data
      // is of the same type so as not to order incorrectly.
      const valueAType = typeof valueA;
      const valueBType = typeof valueB;

      if (valueAType !== valueBType) {
        if (valueAType === 'number') { valueA += ''; }
        if (valueBType === 'number') { valueB += ''; }
      }

      // If both valueA and valueB exist (truthy), then compare the two. Otherwise, check if
      // one value exists while the other doesn't. In this case, existing value should come last.
      // This avoids inconsistent results when comparing values to undefined/null.
      // If neither value exists, return 0 (equal).
      let comparatorResult = 0;
      if (valueA != null && valueB != null) {
        // Check if one value is greater than the other; if equal, comparatorResult should remain 0.
        if (valueA > valueB) {
          comparatorResult = 1;
        } else if (valueA < valueB) {
          comparatorResult = -1;
        }
      } else if (valueA != null) {
        comparatorResult = 1;
      } else if (valueB != null) {
        comparatorResult = -1;
      }

      return comparatorResult * (direction == 'asc' ? 1 : -1);
    });
  }
  return sortData;
  }
}
