<div *ngIf="loaded" fxLayout="column" class="test">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fx-layout-align="end">
    <div fxFlex>
      <h1>{{ data.name }}</h1>
    </div>
    <div fxFlex >
      <h1></h1>
      <h1></h1>
      <h3>

      </h3>
    </div>
  </div>

  <mat-toolbar>
  </mat-toolbar>
  <mat-tab-group mat-align-tabs="start">
    <mat-tab label="Info">
      <mat-card class="card">
        <mat-card-content>
          <app-editable-form
           [headers]="[{name:'name'},{name:'address_line'},{name:'zip', type:'autocomplete', url:'shared/zip'}]"
           [data]='data'
           updateUrl="{{'sites/site_address/modify/'+data.id}}">
          </app-editable-form>
        </mat-card-content>
      </mat-card>



      <mat-card class="card">
        <mat-card-subtitle>
          <div>
          <mat-checkbox [(ngModel)]="data.pending" (change)="pendingChange()"  [disabled]="detail_disabled">Site Details Pending (New Site)</mat-checkbox>
          </div>
        </mat-card-subtitle>
        <mat-divider></mat-divider>
        <mat-card-content>
          <app-editable-form *ngIf="!data.pending"
           [headers]="[{name:'shipping_hours'},{name:'lt_skids_no_appointment'},{name:'appointment',type:'checkList'},{name:'tailgate',type:'checkList'},{name:'inside_delivery',type:'checkList'},{name:'limited_access',type:'checkList'}]"
           [data]='data'
           updateUrl="{{'sites/site_accesorial/modify/'+data.id}}">
          </app-editable-form>
        </mat-card-content>
      </mat-card>




    </mat-tab>

      <mat-tab label="Notes">
        <app-editable-table [headers]="[ {name:'date', type:'static_field'},{name:'include_confirmation', type:'checkList'},{name: 'status'}]"
        [data]='data.notes'
        updateUrl="{{'site/notes/update/'+data.id}}"
        newUrl="{{'site/notes/store/'+data.id}}"
        removeUrl="{{'site/notes/remove/'+data.id}}">
        </app-editable-table>
      </mat-tab>

      <mat-tab label="Contacts">
        <app-editable-table [headers]="[ {name:'name',required:true},{name:'phone'},{name: 'email'},{name: 'shipper',type:'checkList'},{name: 'receiver',type:'checkList'},{name: 'customer',type:'checkList'},{name: 'accounting',type:'checkList'},{name: 'dispatch',type:'checkList'},{name: 'tracing',type:'checkList'},{name: 'bol',type:'checkList'}]"
        [data]='data.contacts'
        updateUrl="{{'site/contact/update/'+data.id}}"
        newUrl="{{'site/contact/store/'+data.id}}"
        removeUrl="{{'site/contact/remove/'+data.id}}">
        </app-editable-table>
      </mat-tab>

      <mat-tab *ngIf="data.vendor" label="Insurance">
        <app-editable-table [headers]="[ {name:'name'},{name:'date',type:'date'},{name: 'file', type:'file', icon:'insert_drive_file', callback:this.fileCallback,required:true}]"
        [data]='data.vendor.documents'
        updateUrl="{{'vendors/files/update/'+data.vendor.id}}"
        >
        </app-editable-table>

        <iframe *ngIf="this.file"  style="width:100%; height:calc( 100vh)" [src]="this.tryDoctype"></iframe>
      </mat-tab>

      <mat-tab *ngIf="data.vendor" label="Banking">
        <app-editable-table [headers]="[ {name:'currency'},{name:'bank_number'},{name:'routing'},{name:'account'},{name: 'file', type:'file', icon:'insert_drive_file', callback:this.fileCallback,required:true}]"
        [data]='data.vendor.banking'
        updateUrl="{{'vendors/banking/update/'+data.vendor.id}}"
        newUrl="{{'vendors/banking/store/'+data.vendor.id}}"
        removeUrl="{{'vendors/banking/remove/'+data.vendor.id}}"
        >
        </app-editable-table>

        <iframe *ngIf="this.file"  style="width:100%; height:calc( 100vh)" [src]="this.tryDoctype"></iframe>
      </mat-tab>

      <mat-tab label="Accounts">
      </mat-tab>

    </mat-tab-group>
</div>
<div *ngIf="!loaded">
  Loading...
</div>
