import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators, AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';



//import { FormGroup, , FormBuilder, Validators, , EmailValidator,  }
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AngularMaterialModule } from '../angular-material.module';
import { ApiService } from '../services/api.service';
import { AppConstants } from '../structures/appconstants';

import { tap, startWith, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { asyncValidator } from '../helpers/custom-async-validator';
import { Observable, of, Subscription, empty } from 'rxjs';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';

import { MatDialog } from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import { SitedialogComponent } from '../vendordialog/sitedialog.component';
import { NewsitedialogComponent } from '../vendordialog/newsitedialog.component';

import { AppComponent } from '../app.component';

@Component({
  selector: 'app-newquote',
  templateUrl: './newquote.component.html',
  styleUrls: ['./newoperation.component.css']
})
export class NewquoteComponent implements OnInit {

  // Variables
  threadId = null;
  messageId = null;
  subject = null;
  results = [];
  loading: boolean;
  quote_created:boolean = false;
  errors: boolean;
  newquoteFormGroup: FormGroup;
  finalFormGroup: FormGroup;
  poFormGroup: FormGroup;
  stopsFormGroup: FormGroup;
  stopsCount = 0;
  //stopsFormGroup: FormArray;
  rateFormGroup: FormGroup;
  emailFormGroup: FormGroup;
  clients;
  emailList;
  formSubmitted: boolean;
  filteredOptions: Observable<any[]>;

  basic:any;
//  filteredOptions;

  private dialogParams = {
    width: 'calc( 100% - 50px )',
    height: 'calc( 100% - 50px )',
    maxWidth: '100vw',
    maxHeight: '100vh',
  };

 //emailThread: string;// = null;


  constructor(
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private router: Router,
    private authService: AuthService,
    private apiService : ApiService,
    public dialog: MatDialog,
    public translate: TranslateService,
    public app: AppComponent
  ) {

    this.formSubmitted = false;
    this.basic = app.getBasic();

    this.emailFormGroup = fb.group({
      emailThread:[
        '',
        []
      ]
    });
    this.stopsFormGroup = fb.group({
      stops: fb.array([
        this.fb.group({
            date:'',
            time:'',
            type: 1,
            site: '',
            site_id: '',
            blind_site: '',
            blind_site_id: '',
            zip: '',
            zip_id: ''
          }, { validator: atLeastOne(Validators.required, ['site_id','zip']) })
          ,
          this.fb.group({
              date:'',
              time:'',
              type: 2,
              site: '',
              site_id: '',
              blind_site: '',
              blind_site_id: '',
              zip: '',
              zip_id: ''
            }, { validator: atLeastOne(Validators.required, ['site_id','zip']) })
            ])
    });
    this.stopsCount = 2;

    // console.log (this.stopsFormGroup.get("stops")['controls'][0].get("type").value);
    // this.stopsFormGroup.get("stops")['controls'][1].get("type").setValue(2);



    this.newquoteFormGroup = fb.group({
      template: [
        '',
        []
      ],
      client: [
        '',
        [Validators.required]
      ],
      client_id: [
        '',
        [Validators.required]
      ],

      mode: [
        1,
        [Validators.required]
      ],
      equipment_type: [
        1,
        [Validators.required]
      ],
      client_ref: [
        '',
        []
      ],
      description: [
        '',
        []
      ],
      temperature: [
        '',
        []
      ]
    });

    this.finalFormGroup = fb.group({
      number_of_copies: [
        '1',
        [Validators.required]
      ]
    });



    this.poFormGroup = fb.group({ // make a nested group
        pos: fb.array([this.addPOFormGroup()])
      });

      this.rateFormGroup  =fb.group({ // make a nested group
        rates:
        fb.array(
           [
        //     this.addRateFormGroup()
           ])
      });


  }

  //this.filteredOptions =



  // onChangeClient(value){
  //
  // }

  filter(val): Observable<any[]> {
    // call the service which makes the http-request
    return this.apiService.getData('shared/zip',{query: val})
      .pipe(
      //   map(response => response.filter(option => {
      //     return option.name.toLowerCase().indexOf(val.toLowerCase()) === 0
    //     }))
      )
  }

  get modes() {return AppConstants.modes;}
  get equipment_types() {return AppConstants.equipment_types;}

  get pos_units() { return AppConstants.pos_units;}

  get po_units_id() { return AppConstants.po_units_id;}
  get po_weight_id() { return AppConstants.po_weight_id;}



  get expense_types() { return AppConstants.expense_type_id ;}

  get unit_type() { return AppConstants.unit_type_id;}

  get stop_types() {return AppConstants.stop_type_id;}

  submit22()
  {
    // newquoteFormGroup: FormGroup;
    // poFormGroup: FormGroup;
    // stopsFormGroup: FormGroup;
    // rateFormGroup: FormGroup;
    // emailFormGroup: FormGroup;
    //
    let data = { ...this.newquoteFormGroup.value, ...this.poFormGroup.value, ...this.stopsFormGroup.value, ...this.rateFormGroup.value, ...this.emailFormGroup.value, ... this.finalFormGroup.value};

    //data.zip_id = data.zip.id;
    //console.log(data);
    //const id = +this.route.snapshot.paramMap.get('id');

    this.apiService.getData('quotes/quote/store', data ).subscribe(res =>
    {
        if ( res['success'] )
        {
            this.results = [ ...this.results, ...res.ids];
        }
        console.log(res);
        console.log(res['success']);

    });
    //console.log(data);
  }

  submit2() :void
  {
    let data = { ...this.newquoteFormGroup.value, ...this.poFormGroup.value, ...this.stopsFormGroup.value, ...this.rateFormGroup.value, ...this.emailFormGroup.value, ... this.finalFormGroup.value,threadId:this.threadId, messageId:this.messageId, subject: this.subject};

    //data.zip_id = data.zip.id;
    //console.log(data);
    //const id = +this.route.snapshot.paramMap.get('id');

    this.apiService.getData('quotes/quote/store', data ).subscribe(result =>
    {
        if ( result['success'] )
        {
            this.quote_created = true;
            //this.results = [ ...this.results, ...res.ids];
            //if(!this.id) return;
            const id = +result['quote'].data.id;

            if(!data['template'] )
            this.apiService.getBlob( 'pages/quotestart/pdf/'+id )
              .subscribe(response =>
                {
                  var blob = response;
                  var fileURL = URL.createObjectURL(blob);
                  var documentData = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);


                  let url = 'pages/quotestart/email/' +id;
                  let filename = 'Q'+ result['quote'].data.quote_id +'.pdf';
                  let subject = 'Q'+ result['quote'].data.quote_id + ' ';

                  for( var i = 0; i<result['quote'].data.stops.length; i++)
                  {
                    subject = subject + result['quote'].data.stops[i].city_name+', ' + result['quote'].data.stops[i].state + ' -';
                  }
                  subject = subject + result['quote'].data.mode_name;
                  let message = '';//this.translate.instant('dialogs.lc_email');


                  let preview = ""
                  let c = [];

                  for(var i= 0; i <result['quote'].data.client_contact.length; i++)
                  {
                    if( result['quote'].data.client_contact[i].customer )
                      c.push(result['quote'].data.client_contact[i]);
                  }

                  this.app.setSidenav(null,c, subject ,message, documentData, filename, url);
                  this.app.getSidenav().open();

                });


        }
        //console.log(res);
        //console.log(res['success']);

    });

  }


  ngOnInit(): void {
    this.getClients();
    this.updateEmailList();

    // this.newquoteFormGroup.controls.clients.valueChanges
    // .subscribe(
    //   value => {
    //     this.newquote
    //
    //   });
    //
    //
  }

  changeClient()
  {
    //console.log('change_client');
    //console.log(this.rateFormGroup.get("rates")["controls"]);
    //for(let i of )

    this.newquoteFormGroup.get('client_id').setValue(
      this.clients[(this.newquoteFormGroup.get('client').value)].id
    );
    for (let i = 0; i < this.rateFormGroup.get("rates")["controls"].length; i++)
    {
      console.log(i);
      this.changeRate(i);
    }

  }
  changePO(i)
  {
    let length = this.poFormGroup?.get('pos')['controls'][i].controls.length.value;
    let width = this.poFormGroup?.get('pos')['controls'][i].controls.width.value;
    let height = this.poFormGroup?.get('pos')['controls'][i].controls.height.value;
    let number = this.poFormGroup?.get('pos')['controls'][i].controls.quantity.value;
    let po_weight_id = this.poFormGroup?.get('pos')['controls'][i].controls.po_weight_id.value;
    let weight = this.poFormGroup?.get('pos')['controls'][i].controls.weight.value;
    let vclass = "";
    let subclass= "";

    if( length && width && height && number && po_weight_id && weight)
    {

      //this.rateFormGroup?.get('rates')['controls'][i].controls.client_total_rate.setValue(  this.calculateClientPrice(i) );
        let volume = length * width * height / 1728;
        let density = (weight/number)/volume;

        if(po_weight_id == 2)
          density *= 2.20462;

        console.log(density);
  //
  // Sub 1 Less than 1 ................ 400
  // Sub 2 1 but less than 2......... 300
  // Sub 3 2 but less than 4......... 250
  // Sub 4 4 but less than 6......... 175
  // Sub 5 6 but less than 8......... 125
  // Sub 6 8 but less than 10....... 100
  // Sub 7 10 but less than 12..... 92.5
  // Sub 8 12 but less than 15..... 85
  // Sub 9 15 but less than 22.5.. 70
  // Sub 10 22.5 but less than 30.. 65
  // Sub 11 30 or greater .............. 60
      if((density<1 ) )
      {
        vclass = "400";
        subclass = "1";
      }
      else if((density >= 1)  && (density < 2 ))
      {
        vclass = "300";
        subclass = "2";
      }
      else if((density >= 2)  && (density < 4 ))
      {
        vclass = "250";
        subclass = "3";
      }
      else if((density >= 4)  && (density < 6 ))
      {
        vclass = "175";
        subclass = "4";
      }
      else if((density >= 6)  && (density < 8 ))
      {
        vclass = "125";
        subclass = "5";
      }
      else if((density >= 8)  && (density < 10 ))
      {
        vclass = "100";
        subclass = "6";
      }
      else if((density >= 10)  && (density < 12 ))
      {
        vclass = "92.5";
        subclass = "7";
      }
      else if((density >= 12)  && (density < 15 ))
      {
        vclass = "85";
        subclass = "8";
      }
      else if((density >= 15)  && (density < 22.5 ))
      {
        vclass = "70";
        subclass = "9";
      }
      else if((density >= 22.5)  && (density < 30 ))
      {
        vclass = "65";
        subclass = "10";
      }
      else if((density >= 30))
      {
        vclass = "60";
        subclass = "11";
      }

    }
    this.poFormGroup?.get('pos')['controls'][i].controls.class.setValue(vclass);
    this.poFormGroup?.get('pos')['controls'][i].controls.subclass.setValue(subclass);


  }

  changeRate(i)
  {
    console.log(this.newquoteFormGroup.get('client').value);
    if( this.newquoteFormGroup.get('client').value !== '' )
    {
        if( this.rateFormGroup.get("rates")['controls'][i].get("rate").value && this.rateFormGroup.get("rates")['controls'][i].get("quantity").value )
        {
            this.rateFormGroup?.get('rates')['controls'][i].controls.client_total_rate.setValue(  this.calculateClientPrice(i) );
            this.rateFormGroup?.get('rates')['controls'][i].controls.books_total_rate.setValue(  this.calculateBooksPrice(i) );
        }
    }
  }

  calculateClientPrice(i :number) {
    return this.rateFormGroup.get("rates")['controls'][i].get("rate").value *  this.rateFormGroup.get("rates")['controls'][i].get("quantity").value ;
  }

  calculateBooksPrice(i :number) {
    if(this.app.loaded && this.clients )
    return this.rateFormGroup.get("rates")['controls'][i].get("rate").value *  this.rateFormGroup.get("rates")['controls'][i].get("quantity").value * this.app.basic?.tipo_de_cambio[this.clients[(this.newquoteFormGroup.get('client').value??0)]?.currency_id];
    else return 0;
  }


  emailSelected(event, thread)
  {
    if(event.source.checked)
    {
        this.threadId = thread.threadId;
        this.messageId = thread['Message-ID'];
        this.subject = thread['subject'];
    }

  }

  updateEmailList(): void
  {
    this.apiService.getData('email/list',{}).subscribe(list =>
      {
        this.emailList = list
        //console.log(list);
      });
  }
  addStopFormGroup(): FormGroup {
    return this.fb.group({
        type: [2,[Validators.required]],
        date: '',
        time: '',
        site: ['', [Validators.required]],
        site_id: '',
        blind_site: '',
        blind_site_id: '',
        zip: '',
        zip_id: ''
      }, { validator: atLeastOne(Validators.required, ['site_id','zip']) });
    }

    clearSite( i:number) :void {
      this.stopsFormGroup.get("stops")['controls'][i].get("site").setValue('');
      this.stopsFormGroup.get("stops")['controls'][i].get("site_id").setValue('');
      this.stopsFormGroup.get("stops")['controls'][i].get("zip_id").setValue(this.stopsFormGroup.get("stops")['controls'][i].get("zip").value.id);
    }
    zipOnFocus(i : number) :void {



         this.filteredOptions = this.stopsFormGroup.get("stops")['controls'][i].get("zip").valueChanges.pipe(
        //                         //this.stopsFormGroup.get("stops")[i].get('zip').valueChanges.pipe(
         startWith(''),
         debounceTime(400),
         distinctUntilChanged(),
         switchMap(val  =>
            this.filter(val || '')
           )
        );

  }

  addStopClick(): void {
    (<FormArray>this.stopsFormGroup.get("stops")).push(
      this.addStopFormGroup()
    );

    this.stopsCount = (<FormArray>this.stopsFormGroup.get("stops")).length;


  }
  removeStopClick(i: number){

    const aFormArray = this.stopsFormGroup.controls.stops as FormArray;

    //const aFormArray = aFormGroup.controls.products as FormArray;
    aFormArray.removeAt(i);
    this.stopsCount = (<FormArray>this.stopsFormGroup.get("stops")).length;

  }
  openNewSite(i:number,name)
  {
    let dialogParams = {
      width: 'calc( 100% - 50px )',
      height: 'calc( 100% - 50px )',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: name
    };
    const dialogRef = this.dialog.open(NewsitedialogComponent, dialogParams);

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        this.stopsFormGroup.get("stops")['controls'][i].get("site").setValue(result.name);
        this.stopsFormGroup.get("stops")['controls'][i].get("site_id").setValue(result.id);
        this.stopsFormGroup.get("stops")['controls'][i].get("zip").setValue(result.city +' '+result.state);
      }


    });
  }


  openSiteDialog(i: number) {

    const dialogRef = this.dialog.open(SitedialogComponent,this.dialogParams);

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        if(result['create_new'])
        {
          this.openNewSite(i,result['name']);
        }

        else
        {
          this.stopsFormGroup.get("stops")['controls'][i].get("site").setValue(result.name);
          this.stopsFormGroup.get("stops")['controls'][i].get("site_id").setValue(result.id);
          this.stopsFormGroup.get("stops")['controls'][i].get("zip").setValue(result.city +' '+result.state);
        }
      }
    });
  }


  openNewBlindSite(i:number,name)
  {
    let dialogParams = {
      width: 'calc( 100% - 50px )',
      height: 'calc( 100% - 50px )',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: name
    };
    const dialogRef = this.dialog.open(NewsitedialogComponent, dialogParams);

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        this.stopsFormGroup.get("stops")['controls'][i].get("blind_site").setValue(result.name);
        this.stopsFormGroup.get("stops")['controls'][i].get("blind_site_id").setValue(result.id);
      }


    });
  }


  openBlindSiteDialog(i: number) {

    const dialogRef = this.dialog.open(SitedialogComponent,this.dialogParams);

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        if(result['create_new'])
        {
          this.openNewBlindSite(i,result['name']);
        }

        else
        {
          this.stopsFormGroup.get("stops")['controls'][i].get("blind_site").setValue(result.name);
          this.stopsFormGroup.get("stops")['controls'][i].get("blind_site_id").setValue(result.id);
        }
      }
    });
  }

  getClients(): void {
    this.apiService.getData('clients/list').subscribe(clients => this.clients = clients.data);
  }

  addPOFormGroup(): FormGroup {
    return this.fb.group({
        po: '',
        description: '',
        quantity: ['', [Validators.required]],
        po_units_id: [1, [Validators.required]],
        weight: ['', [Validators.required]],
        po_weight_id: [1, [Validators.required]],
        stackable: '',
        length: ['48', ],
        width: ['40', ],
        height: ['', ],
        nmfc: '',
        class: '',
        subclass: ''

      });
  }

  addPOClick(): void {
    (<FormArray>this.poFormGroup.get("pos")).push(
      this.addPOFormGroup()
    );

  }
  removePOClick(i: number){
    const aFormArray = this.poFormGroup.controls.pos as FormArray;
    aFormArray.removeAt(i);
  }


  addRateFormGroup(): FormGroup {
    return this.fb.group({
      description: 1,
      rate: ['', [Validators.required]],
      unit_type: [1, [Validators.required]],
      quantity: ['1', [Validators.required]],
      client_total_rate: ['', [Validators.required]],
      books_total_rate: ['', [Validators.required]],
      });
  }

  addRateClick(): void {
    (<FormArray>this.rateFormGroup.get("rates")).push(
      this.addRateFormGroup()
    );

  }
  removeRateClick(i: number){
    const aFormArray = this.rateFormGroup.controls.rates as FormArray;
    aFormArray.removeAt(i);
  }


  displayFn(option): string {
    return option.name;//option && option.name ? option.name : '';
  }



}





  export const atLeastOne = (validator: ValidatorFn, controls:string[] = null) => (
    group: FormGroup,
  ): ValidationErrors | null => {
    if(!controls){
      controls = Object.keys(group.controls)
    }

    const hasAtLeastOne = group && group.controls && controls
      .some(k => !validator(group.controls[k]));

    return hasAtLeastOne ? null : {
      atLeastOne: true,
    };
  };
