import { Component, OnInit, Input, ViewChild } from '@angular/core';
//import {Component, Input,ElementRef, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ApiService } from '../services/api.service';
import { PdfService } from '../services/pdf.service';
import { AppComponent } from '../app.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { VendordialogComponent } from '../vendordialog/vendordialog.component';
import { DriverdialogComponent } from '../vendordialog/driverdialog.component';
import { NewvendordialogComponent } from '../vendordialog/newvendordialog.component';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import { DatePipe } from '@angular/common'
import { AppConstants } from '../structures/appconstants';
import {FormControl} from '@angular/forms';
import { EditableTableComponent } from '../editableTable/editableTable.component';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from "html-to-pdfmake";

@Component({
  selector: 'app-contracttoolstabs',
  templateUrl: './contracttoolstabs.component.html',
  styleUrls: ['./contract.component.css']
})
export class ContracttoolstabsComponent implements OnInit {

    @Input() privilegios: any;
    @Input() contract: any;
  //  @Input() service_id: any;
    @Input() service_contacts: any;
    //@Input() documents: any;

    @ViewChild('invoicesContractEditableTableId') invoicesEditableTable:EditableTableComponent;
    @ViewChild('budgetsContractEditableTableId') budgetsEditableTable:EditableTableComponent;

    displayedColumnsStops: string[] = ['stop_type_name', 'site_name', 'city_name', 'state','date'];

    forms= {};

    data = {
  inputDateHiredLabel: "Date Hired",
  inputDateHiredData: new Date("03/01/2008")

    }


// ngOnChanges(changes) {
//   for(let i=0; i<this.contract.stops.length;i++)
//   {
//     this.forms[i] = {};
//     this.forms[i]['appointment_from_date']= new FormControl( new Date(this.contract.stops[i].appointment_from_date) );
//   }
//   console.log(this.forms);
// }


    form: FormGroup;
    offset;
    loaded: boolean;
    errors: boolean;
    tryDoctype: any;
  constructor(
    fb: FormBuilder,
    private apiService: ApiService,
    private pdfService: PdfService,
    private route: ActivatedRoute,
    private location: Location,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public translate: TranslateService,
    private app: AppComponent,
    public datepipe: DatePipe,

  ) {

    this.offset = new Date().getTimezoneOffset();///60*1000;
    //if(this.offset > 0)
      //this.offset = '+'+this.offset;
    this.loaded = false;
    this.form = fb.group({
      vendor_id: [
        '',
        [Validators.required]
      ],
      vendor: [
        '',
        [Validators.required]
      ],
      pro: [
         '',
         []
       ],
       quote_id: [
          '',
          []
        ],
      // driver: [
      //   '',
      //   []
      // ],
      // placa: [
      //   '',
      //   []
      // ],
      rate: [
        '',
        []
      ],
      currency: [
        '3',
        [Validators.required]
      ],
    });


  }

  private dialogParams = {
    width: 'calc( 100% - 50px )',
    height: 'calc( 100% - 50px )',
    maxWidth: '100vw',
    maxHeight: '100vh',
  };
  public vendor = { name: null, id :null};
  public driver = { name: null, id :null};

  public currencies = [
    {value: '1', viewValue: 'interface.bolivianos'},
    {value: '2', viewValue: 'interface.dolares_americanos'},
    {value: '3', viewValue: 'interface.dolares_canadienses'}
  ];

  openDialogVendor()
  {
    const dialogRef = this.dialog.open(VendordialogComponent, this.dialogParams);
    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        if(result == 'create_new')
        {
          this.openNewVendor();
        }
        else
        {
          this.form.controls['vendor'].setValue(result.name);
          this.form.controls['vendor_id'].setValue(result.id);
        }
      }
    });
  }

  getDate( date_string:string)
  {
    if(!date_string) return null;
     const date = new Date(date_string);
     date.setDate(date.getDate() + 1);

     return date.toISOString();

    //const date = new Date(date_string);
    //return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));


    //const dateObj = new Date(date_string);
    //return dateObj.setMinutes((dateObj.getMinutes() + dateObj.getTimezoneOffset()));
    //const date = new Date(date_string);

    //if (this.offset < 0)
      date.setMinutes(720);

      //console.log('test');
    //return date;//.toLocaleDateString();
    //return date_string;




  }
  openDialogDriver() {

    const dialogRef = this.dialog.open(DriverdialogComponent,this.dialogParams);

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        if(result == 'create_new')
        {
            alert('create_carrier');
        }
        else
        {
          this.form.controls['driver'].setValue(result.name + ' ' + result.lastname);
          this.form.controls['driver_id'].setValue(result.id);
        }
      }
    });
  }






  changeConfirmed(stop)
  {
    stop.confirmed_disabled = true;

    let data = {line: stop.id, value:stop.appointment_confirmed,name:'appointment_confirmed'}
    this.apiService.getData( 'contracts/stops/update/'+this.contract.id, data )
      .subscribe(
        result => {
          if(result['success'])
            stop.confirmed_disabled = false;
      });
  }



  changeRef(contract,stop,event,field:string)
  {

 console.log(event);
    // if( event.targetElement )
    // {
    // if (! event.targetElement.classList.contains('updating'))
    //   event.targetElement.classList.add('updating');
    // }
    // else if(event.target.classList)
    // {
    // if (! event.target.classList.contains('updating'))
    //   event.target.classList.add('updating');
    // }
    // else
      event.classList.add('updating');


    let data = {line: stop, value:event.value??'null',name:field}


    if(field.includes('date'))
      data['value'] = this.datepipe.transform(event.value, 'yyyy-MM-dd');

    this.apiService.getData( 'contracts/stops/update/'+contract, data )
      .subscribe(
        result => {
          if(result['update'])
            {
              console.log('update row');
              this.contract = result['contract'];
            }
          if(result['success'])
            event.classList.remove('updating');

      });
  }


timeClear(contract,stop,field:string,event)
{
  if(event.target.value == "")
  {
    event.target.classList.add('updating');

    let data = {line: stop, value:null,name:field}

    this.apiService.getData( 'contracts/stops/update/'+contract, data )
      .subscribe(
        result => {

          event.target.classList.remove('updating');
      });

  }
}
  changeRefTime(contract,stop,event,field:string,val)
  {

  console.log(event);
    // if( event.targetElement )
    // {
    // if (! event.targetElement.classList.contains('updating'))
    //   event.targetElement.classList.add('updating');
    // }
    // else if(event.target.classList)
    // {
    // if (! event.target.classList.contains('updating'))
    //   event.target.classList.add('updating');
    // }
    // else
      event.classList.add('updating');


    let data = {line: stop, value:val??'null',name:field}

    this.apiService.getData( 'contracts/stops/update/'+contract, data )
      .subscribe(
        result => {
            if(result['success'])
              event.classList.remove('updating');
      });
  }


  openNewVendor()
  {
    const dialogRef = this.dialog.open(NewvendordialogComponent, this.dialogParams);

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        this.vendor = result;
        this.form.controls['vendor'] = result;
        console.log(result);
      }


    });
  }


  ngOnInit(): void {
    //this.getContract();
  }

  get controls() {
    return this.form.controls;
  }


  // ASSIGN VENDOR
  assignvendor() :void{
    if (this.form.valid)
    {

    const id = this.contract.id;
    this.apiService.getData( 'contracts/vendor/store/'+id, this.form.value )
      .subscribe(
        result => {
          this.contract = result;//getContract();
      });
    }

  }


  //cancel
  cancel() :void{

    if(confirm(this.translate.instant('dialogs.cancel_contract')))
    {
      console.log(this);
      const id = +this.contract.id//this.route.snapshot.paramMap.get('id');

      this.apiService.getData( 'contracts/vendor/remove/'+id, {} )
        .subscribe(
          result => {
          this.contract = result;//getContract();
        });
    }
  }







  editSite(id) :void
  {
    this.app.setSiteSide(id);
    this.app.getSiteSide().open();
  }


  print_bol() :void
  {
    const id = +this.contract.id;//route.snapshot.paramMap.get('id');
    this.apiService.getBlob( 'pages/bol/pdf/'+id )
      .subscribe(response =>
        {
          var blob = response;
          var fileURL = URL.createObjectURL(blob);
          var documentData = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
        //  this.tryDoctype = documentData;

          let url = 'pages/bol/email/' +id;
          let filename = 'BOL V20'+ this.contract.contract_id  +'.pdf';
          let subject = 'V20'+ this.contract.contract_id + ' ';
          for( var i = 0; i<this.contract.stops.length; i++)
          {
            subject = subject + this.contract.stops[i].city_name+', ' + this.contract.stops[i].state + ' -';
          }
          subject = subject + this.contract.mode_name;
          let message = this.translate.instant('dialogs.lc_email');



          let preview = ""
          let c = [];

          for(var i= 0; i <this.service_contacts.length; i++)
          {
            if( this.service_contacts[i].bol )
              c.push(this.service_contacts[i]);
          }
          this.app.setSidenav(null,c, subject ,message, documentData, filename, url);
          this.app.getSidenav().open();

        });
  }


  print_label() :void
  {
    const id = +this.contract.id;//route.snapshot.paramMap.get('id');
    this.apiService.getBlob( 'pages/label/pdf/'+id )
      .subscribe(response =>
        {
          var blob = response;
          var fileURL = URL.createObjectURL(blob);
          var documentData = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
        //  this.tryDoctype = documentData;

          let url = 'pages/label/email/' +id;
          let filename = 'Label '+ this.contract.contract_id  +'.pdf';
          let subject = 'Label '+ this.contract.contract_id ;
          for( var i = 0; i<this.contract.stops.length; i++)
          {
            subject = subject + this.contract.stops[i].state + '-';
          }
          subject = subject + this.contract.vendor_name;
          let message = this.translate.instant('dialogs.lc_email');



          let preview = ""
          let c = [];

          console.log(this.service_contacts);
          for(var i= 0; i <this.service_contacts.length; i++)
          {
            if( this.service_contacts[i].bol )
              c.push(this.service_contacts[i]);
          }
          this.app.setSidenav(null,c, subject ,message, documentData, filename, url);
          this.app.getSidenav().open();

        });
  }




  siteEmail(stop)
  {
    let url = 'pages/stops/email/'+stop.id;
    // if (stop.stop_type_id == 1)
    //   url1 = 'pages/pickup/html/' + this.contract.id;
    // else if (stop.stop_type_id == 2)
    //   url1 = 'pages/delivery/html/' + this.contract.id;
    //
    this.apiService.getData('pages/stops/html/' + stop.id )
      .subscribe(response =>
      {
         //console.log(response);
          let subject = 'V20'+ this.contract.contract_id;

          if (stop.stop_type_id == 1)
          {
            subject = subject +  ' Pickup Scheduled: ' +stop.ref_number;

          }
          else if (stop.stop_type_id == 2)
          {
            subject = subject +  ' Delivery Scheduled: ' +stop.ref_number;
          }

          //
          for( var i = 0; i<this.contract.stops.length; i++)
          {
             subject = subject + this.contract.stops[i].city_name+', ' + this.contract.stops[i].state + ' - ';
          }
          subject = subject + this.contract.vendor_name;
          let message = this.translate.instant('dialogs.lc_email');


          let preview = ""
          let c = [];

          for(var i= 0; i <stop.site_contacts.length; i++)
          {
            if (stop.stop_type_id == 1)
            {
              if( stop.site_contacts[i].shipper )
              c.push(stop.site_contacts[i]);
            }
            else
            {
              if( stop.site_contacts[i].receiver )
              c.push(stop.site_contacts[i]);
            }

          }

          console.log(response['view']);

          this.app.setSidenav(null,c, subject ,response['view'], null, null, url);
          this.app.getSidenav().open();
      });

      console.log(stop);


  }
  //print contract
  print_contract() :void
  {
    const id = +this.contract.id;//route.snapshot.paramMap.get('id');
    this.apiService.getBlob( 'pages/contract_dispatch/pdf/'+id )
      .subscribe(response =>
        {
          var blob = response;
          var fileURL = URL.createObjectURL(blob);
          var documentData = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
        //  this.tryDoctype = documentData;

          let url = 'pages/contract_dispatch/email/' +id;
          let filename = 'LC 20'+ this.contract.contract_id +'.pdf';
          let subject = 'LC 20'+ this.contract.contract_id + ' ';

          for( var i = 0; i<this.contract.stops.length; i++)
          {
            subject = subject + this.contract.stops[i].city_name+', ' + this.contract.stops[i].state + ' -';
          }
          subject = subject + this.contract.vendor_name;
          let message = this.translate.instant('dialogs.lc_email');


          let preview = ""
          let c = [];

          for(var i= 0; i <this.contract.vendor_contacts.length; i++)
          {
            if( this.contract.vendor_contacts[i].dispatch )
              c.push(this.contract.vendor_contacts[i]);
          }

          this.app.setSidenav(null,c, subject ,message, documentData, filename, url);
          this.app.getSidenav().open();

        });



    // let docDefinition = {
    //     header: 'C#Corner PDF Header',
    //     content: htmlToPdfmake("<div> <h1>My title</h1><p>This is a sentence with a <strong>bold word</strong>, <em>one in italic</em>,and <u>one with underline</u>. And finally <a href>a link</a>.</p></div>")
    //   };
    //
    // var blob = pdfMake.createPdf(docDefinition);
    // var callback = function(url) {
    //   var documentData = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    //
    // }
    // blob.getDataUrl(callback.bind(this));





    // var fileURL = URL.createObjectURL(blob);
    // var documentData = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);


    ///this.app.setSideBlob( this.pdfService.createPdf() );




  }

  // getContract(): void {
  //   //const id = +this.route.snapshot.paramMap.get('id');
  //   const id = this.contract.id;
  //   this.apiService.getData( 'contracts/contract_detail/'+id )
  //     .subscribe(param => {
  //       this.contract = param.data;
  //       this.views = param.views;
  //       this.loaded = true;
  //     });
  // }



  openBlog( id:number, event)
  {
    //event.target.classList.add('active');
    this.apiService.getBlob( 'documents/view/'+id )
      .subscribe(response =>
        {
          var blob = response;
          //var contentType = response.headers["content-type"];
          //let blob = new Blob([res.error.text.blob()], { type: 'application/pdf' })

          //var blob2 = new Blob([blob], { type: 'application/pdf', title: 'my title ' })


          var fileURL = URL.createObjectURL(blob);
          var documentData = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
          this.tryDoctype = documentData;
          //var blob_iframe = document.querySelector('#blob-src-test');
          //blob_iframe.src = documentData;


          //this.tryDoctype = fileURL;
          //window.open(fileURL);
        });



  }

  poChange(po,contract_id)
  {

    //event.target.classList.add('updating');
    po.disabled = true;
    this.apiService.getData( 'contracts/po/update/'+ contract_id, {line:po.id,marked:po.marked} )
      .subscribe(
        result => {
          if(result['success'])
          {
            po.disabled = false;
            //this.cancelEditable(element);
            //event.target.classList.remove('updating');
          }


        });
  }
  getStructureValue( name, id )
  {
    return AppConstants[name][id-1].viewValue;
  }

  getStructure( name )
  {
    return AppConstants[name];
  }

  invoiceSelected = ( data ): void => {}
  budgetSelected = ( data ): void => {
    let total =0;
    let budgets = [];
    for(let i =0; i<data.length;i++)
    {
      if(data[i].marked)
      {
        total += parseFloat(data[i].rate);
        budgets.push( data[i].id );
      }
    }
    //this.sub_total = total;


    var update = [];

    if( total )
    {
      update.push({name:'sub_total_books', value: total});
      update.push({name:'hst_books', value: 0});
      update.push({name:'total_books', value: total});
    }
    else
    {
      update.push({name:'sub_total_books', value: ''});
      update.push({name:'hst_books', value: ''});
      update.push({name:'total_books', value: ''});
    }
    console.log('about to call view child');
    this.invoicesEditableTable.updateDependents(update,budgets);
    //callback code here
  }


  lineChange = ( element ): void => {

    element.rate =element.unit_price * element.quantity;
  }


  fileCallback = ( element ): void =>
  {
  //https://tms.venkor.ca/pages/invoice/pdf/49/
    this.apiService.getBlob( 'documents/contract_invoices/'+element.id )
      .subscribe(response =>
        {
          var blob = response;
          var fileURL = URL.createObjectURL(blob);
          var documentData = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);

          let url = null;//'pages/invoices/email/' + element.id;
          let filename = null;//'filename';
          let subject = null;//'Invoice V';

          let message = this.translate.instant('dialogs.lc_email');
          let preview = ""

          let c = [];

          //this.app.setSidenav(this.contract, c, subject ,message, documentData, filename, url);
          this.app.setSidenavD(documentData,"");
          this.app.getSidenav().open();
        });

  }

  fileCallbackDocuments = ( element ): void =>
  {
      this.apiService.getBlob( 'documents/contract_documents/'+element.id )
      .subscribe(response =>
        {
          var blob = response;
          var fileURL = URL.createObjectURL(blob);
          var documentData = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);

          let url = null;//'pages/invoices/email/' + element.id;
          let filename = null;//'filename';
          let subject = null;//'Invoice V';

          let message = this.translate.instant('dialogs.lc_email');
          let preview = ""

          let c = [];

          //this.app.setSidenav(this.contract, c, subject ,message, documentData, filename, url);
          this.app.setSidenavD(documentData,"");
          this.app.getSidenav().open();
        });

  }

}
