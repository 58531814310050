<mat-card class="example-card  hide-print">
  <mat-card-header>
    <mat-card-title>Profit and Loss</mat-card-title>
    <mat-card-subtitle>Options</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="filterFormGroup" (submit)="getProfitandloss()">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fx-layout-align="end">
        <mat-form-field>
          <mat-label>Up to date</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate placeholder="Start date" formControlName="date_from">
            <input matEndDate placeholder="End date" formControlName="date_to">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>



      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fx-layout-align="end">
        <button mat-raised-button color="primary" type="submit">Generate Report</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<div  #print_content class="print_content" *ngIf="loaded">
  <div style="text-align: center;">Venkor Inc</div>
  <div style="text-align: center;">PROFIT AND LOSS</div>
  <div style="text-align: center;">{{data.date_from | date:'MMM d, y'}} - {{data.date_to | date:'MMM d, y'}}</div>




  <ng-template #treeView let-name="name" let-gifi="gifi" let-children="children" let-saldo="saldo">
    <tr><td>{{name}}  {{show_gifi?gifi:''}}</td>
      <td class='second_column'>
        <ng-container *ngIf="children === null">
          {{saldo | number : '1.2-2'}}
        </ng-container>
      </td>
    </tr>
    <ng-container *ngIf="children?.length > 0">
      <tr>
        <td colspan="2">
          <table>
            <ng-container *ngFor="let child of children">
              <ng-container *ngIf="child.saldo !=0" >
                <ng-container *ngTemplateOutlet="treeView;  context:child"></ng-container>
              </ng-container>
            </ng-container>
          </table>
        </td>
      </tr>
      <tr><td><strong>Total {{name}} {{show_gifi?gifi:''}}</strong></td> <td class="second_column"><strong>{{saldo | number : '1.2-2'}}</strong></td></tr>
    </ng-container>
  </ng-template>


<table>
<ng-container *ngTemplateOutlet="treeView;context:data.revenues"></ng-container>
<tr><td colspan="2"></td></tr>
<ng-container *ngTemplateOutlet="treeView;context:data.expenses"></ng-container>
<tr><td>PROFIT</td><td  class="second_column">{{data.revenues.saldo - data.expenses.saldo | number : '1.2-2'}}</td></tr>
</table>
</div>
