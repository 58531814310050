import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { Hero } from '../slidenav/hero';
import { HEROES } from '../slidenav/mok-hero'

@Injectable({
  providedIn: 'root'
})
export class SlidenavService {

  constructor(
  private http: HttpClient,
  ) { }

  private heroesUrl = 'api/heroes';  // URL to web api
  
  /** GET heroes from the server */
  getHeroes(): Observable<Hero[]> {
    return this.http.get<Hero[]>(this.heroesUrl)
  }

}
