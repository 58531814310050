import {Component, Input,ElementRef, ViewChild, OnInit, AfterViewInit} from '@angular/core';
import { ApiService } from '../services/api.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { AppConstants } from '../structures/appconstants';

@Component({
  selector: 'app-profitandloss',
  templateUrl: './profitandloss.component.html',
  styleUrls: ['./profitandloss.component.css']
})

export class ProfitandlossComponent implements OnInit
{
  show_gifi: boolean = false;
  test;
  loaded:boolean = false;
  data;
  filterFormGroup :FormGroup;
  filter = {};

  ngOnInit()
  {

  }

  constructor( private apiService: ApiService, fb: FormBuilder,public datepipe: DatePipe)
  {
    this.filterFormGroup = fb.group({
      date_from: [
        new Date('8/1/2020'),
        []
      ],
      date_to: [
        new Date('7/31/2021'),
        []
      ]
    });

  }

  public templateToFormData( object ) {
    console.log(object);
    const formData = new FormData();
    for ( const key of Object.keys(object) )
    {
      if (Array.isArray(object[key]))
      {
        for ( let a of object[key] )
          formData.append(key+'[]', a);
      }
      else
      {
        if( object[key]=== null)
          formData.append(key, '');
        else if( object[key]=== false)
          formData.append(key, '0');
        else if( (key == 'date_from') || (key == 'date_to'))
        {
          const  latest_date =this.datepipe.transform(object[key], 'yyyy-MM-dd');
          formData.append(key, latest_date);
        }
        else
          formData.append(key, object[key]);
      }
    }
    return formData;
  }

  indent(account)
  {
    let i = account['code'].match(/\./g).length;
    var tab = '&emsp;';
    return tab.repeat(i);
  }
  getProfitandloss()
  {
    const formData = this.templateToFormData(this.filterFormGroup.value);
    this.apiService.getData( 'statements/profitandloss', formData)
      .subscribe(param =>
        {
          this.loaded = true;
          this.data = param;
        });

  }
  log( l)
  {
    console.log(l);
  }
}
