<mat-toolbar>
<div *ngIf="contract.status_name=='booked' || contract.status_name=='dispatched'">
    <button mat-icon-button aria-label="cancel" matTooltip="{{'interface.cancel' | translate}}" (click)="cancel()">
    <mat-icon>cancel</mat-icon>
  </button>
</div>
<div *ngIf="contract.status_name=='booked'">
  <button  *ngIf="privilegios.modificar" mat-icon-button  aria-label="dispatch" matTooltip="{{'interface.dispatch' | translate}}">
    <mat-icon>send</mat-icon>
  </button>
</div>


<div *ngIf="contract.status_name != 'pending'">
  <button mat-icon-button  aria-label="print" matTooltip="{{'interface.print' | translate}}" (click)="print_contract()">
    <mat-icon>print</mat-icon>
  </button>

  <button   mat-icon-button  aria-label="bol" matTooltip="BOL"  (click)="print_bol()">
    <mat-icon>web</mat-icon>
  </button>

  <button   mat-icon-button  aria-label="bol" matTooltip="BOL"  (click)="print_label()">
    <mat-icon>label</mat-icon>
  </button>
  </div>

  <span class="spacer"></span>
  <span>{{'interface.' + contract.status_name | translate}}</span>
</mat-toolbar>





<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fx-layout-align="end">
  <div fxFlex>
    <div><strong>{{ contract.vendor_name | translate | uppercase }}</strong>
      <button  mat-icon-button  matTooltip="Detail"  (click)="editSite(contract.site_id)">
        <mat-icon>web</mat-icon>
      </button>
    </div>

    <ng-container *ngFor="let budget of contract.contract_budgets; last as isLast">
      {{budget.rate}}
      <span *ngIf="!isLast"> | </span>
    </ng-container>


  </div>
  <div fxFlex >
    <div class="right_align">{{ 'interface.contract' | translate | uppercase }} {{ contract.contract_id }}</div>
    <div class='grayed right_align'>
      <ng-container *ngFor="let stop of contract.stops; last as isLast">
        {{stop.city_name}}, {{stop.state}}
        <span *ngIf="!isLast"> | </span>
      </ng-container>
    </div>
  </div>


</div>

<mat-tab-group mat-align-tabs="start">
  <mat-tab label="{{ 'interface.stops' | translate }}">

    <mat-card class='stop_card' *ngFor='let stop of contract.stops;  let i = index'>
      <mat-card-header class="stop-mat-card-header">

            <mat-card-subtitle class="black_background" fxLayout>
              {{stop.stop_type_name | uppercase}} <span class="spacer"></span> <span>{{stop.appointment?'by appointment':'No appointment required'}}</span>
            </mat-card-subtitle>
            <mat-card-title  fxLayout class="stop_name">
                  {{stop.site_name}}
                  <button  mat-icon-button   matTooltip="Site"  (click)="editSite(stop.site_id)">
                    <mat-icon>web</mat-icon>
                  </button>

                  <button  mat-icon-button  matTooltip="Email"  (click)="siteEmail(stop)">
                    <mat-icon>email</mat-icon>
                  </button>

                  <mat-checkbox [(ngModel)]="stop.appointment_confirmed" (change)="changeConfirmed(stop)" [disabled]="stop.confirmed_disabled" fxFlex>

                      Appt Confirmed
                  </mat-checkbox>





            </mat-card-title>
            <mat-card-subtitle>{{stop.address}}</mat-card-subtitle>
            <mat-card-subtitle>{{stop.city_name}}, {{stop.state}} {{stop.zip_code}}</mat-card-subtitle>


      </mat-card-header>
      <mat-card-content>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
              <mat-label>Stop Ref. Number</mat-label>
            <input matInput value="{{stop.ref_number}}" (change)="changeRef(contract.id,stop.id,$event.target,'ref_number')">
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
            <mat-label>ETA</mat-label>
            <input matInput [matDatepicker]="pickerETA" value="{{stop.eta_date}}" (dateChange)="changeRef(contract.id,stop.id,$event.targetElement,'eta_date')" >
            <mat-datepicker-toggle matSuffix [for]="pickerETA"></mat-datepicker-toggle>
            <mat-datepicker #pickerETA>
            </mat-datepicker>
          </mat-form-field>

          <mat-form-field fxFlex>
            <mat-label>Time</mat-label>
            <input matInput #timeETA1 [ngxTimepicker]="pickerETAT1" [value]="stop.eta_time?.slice(0,-3)" (change)="timeClear(contract.id,stop.id,'eta_time',$event)">
            <ngx-material-timepicker #pickerETAT1 (timeSet)="changeRefTime(contract.id,stop.id,timeETA1,'eta_time',$event)"></ngx-material-timepicker>
          </mat-form-field>
        </div>



        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
            <mat-label>Scheduled From</mat-label>
            <input matInput [matDatepicker]="picker" value="{{stop.appointment_from_date}}" (dateChange)="changeRef(contract.id,stop.id,$event.targetElement,'appointment_from_date')" >
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker>
            </mat-datepicker>
          </mat-form-field>




          <mat-form-field fxFlex>
            <mat-label>Time</mat-label>
            <input matInput #time1 [ngxTimepicker]="pickerT1" [value]="stop.appointment_from_time?.slice(0,-3)" (change)="timeClear(contract.id,stop.id,'appointment_from_time',$event)">
            <ngx-material-timepicker #pickerT1 (timeSet)="changeRefTime(contract.id,stop.id,time1,'appointment_from_time',$event)"></ngx-material-timepicker>
          </mat-form-field>


        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">

          <mat-form-field fxFlex>
            <mat-label>Scheduled To</mat-label>
            <input matInput [matDatepicker]="picker4" value="{{stop.appointment_to_date}}" (dateChange)="changeRef(contract.id,stop.id,$event.targetElement,'appointment_to_date')">
            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
            <mat-datepicker #picker4></mat-datepicker>
          </mat-form-field>

          <mat-form-field fxFlex>
            <mat-label>Time</mat-label>
            <input matInput #time2  [ngxTimepicker]="pickerT2"  [value]="stop.appointment_to_time?.slice(0,-3)" (change)="timeClear(contract.id,stop.id,'appointment_to_time',$event)">
            <ngx-material-timepicker #pickerT2 (timeSet)="changeRefTime(contract.id,stop.id,time2,'appointment_to_time',$event)" ></ngx-material-timepicker>
          </mat-form-field>
        </div>




<!--  -->
<ng-container *ngIf="!(stop.stop_type_id==1 && contract.status_name=='pending') && !(stop.stop_type_id==2 && (contract.status_name=='pending' || contract.status_name=='booked' || contract.status_name=='dispatched'))">

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" >
          <mat-form-field fxFlex>
            <mat-label>Arrival</mat-label>
            <input matInput [matDatepicker]="picker2" value="{{stop.arrival_date}}" (dateChange)="changeRef(contract.id,stop.id,$event.targetElement,'arrival_date')" >
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>




          <mat-form-field fxFlex>
            <mat-label>Time</mat-label>
            <input #time3 matInput [ngxTimepicker]="pickerT3" [value]="stop.arrival_time?.slice(0,-3)" (change)="timeClear(contract.id,stop.id,'arrival_time',$event)">
            <ngx-material-timepicker #pickerT3 (timeSet)="changeRefTime(contract.id,stop.id,time3,'arrival_time',$event)" >
            </ngx-material-timepicker>
          </mat-form-field>



        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
            <mat-label>Departure</mat-label>
            <input matInput [matDatepicker]="picker3" value="{{stop.departure_date}}" (dateChange)="changeRef(contract.id,stop.id,$event.targetElement,'departure_date')">
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
          </mat-form-field>

          <mat-form-field fxFlex>
            <mat-label>Time</mat-label>
            <input #time4 matInput [ngxTimepicker]="pickerT4" [value]="stop.departure_time?.slice(0,-3)" (change)="timeClear(contract.id,stop.id,'departure_time',$event)">
            <ngx-material-timepicker #pickerT4  (timeSet)="changeRefTime(contract.id,stop.id,time4,'departure_time',$event)"></ngx-material-timepicker>
          </mat-form-field>
        </div>

</ng-container>


        <div class="table_div">
          <div class="row_div">
              <div class="th_div">
              </div>

              <div class="th_div">
                PO
              </div>

              <div class="th_div">
                Description
              </div>

              <div class="th_div">
                Quantity
              </div>

              <div class="th_div">
                Units
              </div>

              <div class="th_div">
                Weight
              </div>

              <div class="th_div">
                Units
              </div>

              <div class="th_div">
                Length
              </div>

              <div class="th_div">
                Width
              </div>


              <div class="th_div">
                Height
              </div>



          </div>
          <div class="row_div" *ngFor="let po of stop.stoppos; let j=index">
              <div class="cell_div">
                <mat-checkbox [(ngModel)]="po.marked" (change)="poChange(po,contract.id)"  [disabled]="po.disabled"></mat-checkbox>
              </div>
              <div class="cell_div">
                {{po.po.name}}
              </div>
              <div class="cell_div">
                {{po.po.description}}
              </div>
              <div class="cell_div">
                {{po.po.number}}
              </div>

              <div class="cell_div">
                {{getStructureValue('po_units_id',po.po.po_units_id)}}
              </div>

              <div class="cell_div">
                {{po.po.weight}}
              </div>

              <div class="cell_div">
                {{getStructureValue('po_weight_id',po.po.po_weight_id)}}
              </div>


              <div class="cell_div">
                {{po.po.length}}
              </div>

              <div class="cell_div">
                {{po.po.width}}
              </div>


              <div class="cell_div">
                {{po.po.height}}
              </div>


          </div>
        </div>


      </mat-card-content>

      <!-- <mat-card-actions>
        <button mat-button>LIKE</button>
        <button mat-button>SHARE</button>
      </mat-card-actions> -->
    </mat-card>


  </mat-tab>

  <mat-tab label="Edit Stops">
    <app-editable-table
    [headers]="[ {name:'service_stop_id',type:'selectListCustom'},{name:'stop_type_id',type:'selectList'}, {name:'zip', type:'autocomplete', url:'shared/zip'}]"
    [data]='contract.stops'
    updateUrl="{{'contracts/editstops/update/'+contract.id}}"
    newUrl="{{'contracts/editstops/store/'+contract.id}}"
    removeUrl="{{'contracts/editstops/remove/'+contract.id}}"
    >
    </app-editable-table>
  </mat-tab>
  <mat-tab label="{{ 'interface.contract' | translate }}" >
      <!-- NEW VENDOR -->
      <div *ngIf="contract.status_name =='pending'; else elseBlockBooked">
        <form [formGroup]="form" (submit)="assignvendor()" fxLayout="column">

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex>
              <input readonly matInput placeholder="{{ 'interface.carrier' | translate }}" formControlName="vendor" (click)="openDialogVendor()">
              <input type="hidden" formControlName="vendor_id">
              <!-- type="hidden" -->
            </mat-form-field>

            <mat-form-field fxFlex>
              <input matInput placeholder="Carrier PRO" formControlName="pro">
            </mat-form-field>

            <mat-form-field fxFlex>
              <input matInput placeholder="Carrier Quote ID" formControlName="quote_id">
            </mat-form-field>
          </div>

          <!-- <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex>
              <input readonly matInput placeholder="{{ 'interface.driver' | translate }}" formControlName="driver" (click)="openDialogDriver()">
              <input formControlName="driver_id">
            </mat-form-field>
            <mat-form-field fxFlex>
              <input matInput placeholder="{{ 'interface.placa' | translate }}" formControlName="placa">
            </mat-form-field>
          </div> -->
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex>
              <input matInput placeholder="{{ 'interface.subtotal' | translate }}" formControlName="rate">
            </mat-form-field>
            <mat-form-field fxFlex>
              <mat-label>{{ 'interface.currency' | translate }}</mat-label>
              <mat-select formControlName="currency">
                <mat-option *ngFor="let currency of currencies" [value]="currency.value">
                  {{currency.viewValue | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>






            <button mat-stroked-button class="btn-block" type="submit" [disabled]="!form.valid">{{ 'interface.assign_vendor' | translate}}</button>
        </form>
      </div>

      <ng-template #elseBlockBooked>
        <app-editable-form
           [headers]="[{name:'pro'},{name:'quote_id'},{name:'trailer'}]"
           [data]='contract.details'
           updateUrl="{{'contracts/contract_detail/modify/'+contract.id}}">
         </app-editable-form>


      </ng-template>

  <!-- NEW VENDOR -->
  </mat-tab>
  <mat-tab label="{{ 'interface.notes' | translate }}"></mat-tab>


  <mat-tab  label="Costs">

    <mat-card>
      <mat-card-header>
        <mat-card-subtitle>Budgets</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <app-editable-table #budgetsContractEditableTable
        [headers]="[ {name:'invoice',type:'checkMarkEdit',callback:this.budgetSelected},{name:'expense_type_id',type:'selectList',default:1}, {name:'unit_price',fieldChange:true},{name:'unit_type_id',type:'selectList',default:1},{name:'quantity',fieldChange:true},{name:'rate', readonly:true}]"
        [data]='contract.contract_budgets'
        updateUrl="{{'contracts/budgets/update/'+contract.id}}"
        newUrl="{{'contracts/budgets/store/'+contract.id}}"
        removeUrl="{{'contracts/budgets/remove/'+contract.id}}"
        blockEdit="invoice"
        [fieldChangeCallback]="lineChange"
        >
        </app-editable-table>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-header>
        <mat-card-subtitle>Invoices</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <app-editable-table #invoicesContractEditableTableId
         [headers]="[{name:'payment',type:'checkMarkEdit',callback:this.invoiceSelected},{name:'factoring',type:'autocomplete',url:'accounting/factoring/filter_list'},{name:'date',required:true,type:'date'},{name:'invoice_number',required:true},{name:'sub_total_books',required:true,readonly:true},{name:'hst_books',required:true,readonly:true},{name:'total_books',required:true,readonly:true},{name:'file',type:'file',icon:'insert_drive_file',callback:this.fileCallback,required:true}]"
         [data]='contract.invoices'
         updateUrl="{{'contracts/invoices/update/'+contract.id}}"
         newUrl="{{'contracts/invoices/store/'+contract.id}}"
         removeUrl="{{'contracts/invoices/remove/'+contract.id}}"></app-editable-table>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-header>
        <mat-card-subtitle>Payments</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <app-editable-table #paymentsContractEditableTable
        [headers]="[ {name:'date',type:'date'},{name:'payment_type_id',type:'selectList',default:1}, {name:'source_id',type:'autocomplete',url:'accounts/filter_list',default:{id: 68, name: 'RBC Chequing CAD'}},{name:'nro'},{name:'total_books'}]"
        [data]='contract.payments'
        updateUrl="{{'contracts/payments/update/'+contract.id}}"
        newUrl="{{'contracts/payments/store/'+contract.id}}"
        removeUrl="{{'contracts/payments/remove/'+contract.id}}"
        blockEdit="invoice"
        [fieldChangeCallback]="lineChange"
        >
        </app-editable-table>






      </mat-card-content>
    </mat-card>


  </mat-tab>
  <mat-tab label="{{ 'interface.documents' | translate }}">
        <mat-card>
          <mat-card-header>
            <mat-card-subtitle>Documents Visible to Customer</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <app-editable-table
            [headers]="[ {name:'contract_document_type_id',type:'selectList'}, {name:'file',type:'file',icon:'insert_drive_file',callback:this.fileCallbackDocuments,required:true}]"
            [data]='contract.contract_documents'
            updateUrl="{{'contracts/documents/update/'+contract.id}}"
            newUrl="{{'contracts/documents/store/'+contract.id}}"
            removeUrl="{{'contracts/documents/remove/'+contract.id}}"
            >
            </app-editable-table>
          </mat-card-content>
        </mat-card>
  </mat-tab>

</mat-tab-group>
