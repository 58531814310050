<div  *ngIf="loaded" fxLayout="column" class="test">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fx-layout-align="end">
    <div fxFlex>
      <h1>{{ 'interface.contract' | translate | uppercase }} {{ data.detail.contract_id }}</h1>
      <h1>{{ data.detail.vendor_name | translate | uppercase }}</h1>
      <h3>{{ data.detail.driver_name | translate}} | {{ data.detail.placa | translate}}</h3>

    </div>
    <div fxFlex >
      <h1>{{data.detail.client_name}}</h1>
      <h1>{{data.detail.client_ref}}</h1>
      <h3>
        <ng-container *ngFor="let stop of data.detail.stops; last as isLast">
          {{stop.city_name}}, {{stop.state}}
          <span *ngIf="!isLast"> | </span>
        </ng-container>
      </h3>
    </div>
  </div>

<app-contracttoolstabs [views]="data.views" [contract]="data.detail" [documents]="data.views.contractsdocumentos.models"></app-contracttoolstabs>

</div>
