import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { DetailedOperation } from '../structures/detailed_operation';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppConstants } from '../structures/appconstants';
import { AppComponent } from '../app.component';
import {CdkDragDrop, moveItemInArray, transferArrayItem, CdkDropList} from '@angular/cdk/drag-drop';
//import { MatTable, MatTableDataSource } from '@angular/material';
import { MatTableDataSource } from '@angular/material/table';
import {TranslateService} from '@ngx-translate/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';


@Component({
  selector: 'app-operation',
  templateUrl: './operation.component.html',
  styleUrls: ['./operation.component.css']
})
export class OperationComponent implements OnInit {

  data : any;
  privilegios : any;
  loaded: boolean;
  is_editting_details :boolean;
  descriptionFormGroup: FormGroup;

  displayedColumnsStops: string[] = ['stop_type_name', 'site_name', 'city_name', 'state'];
  //dataSourceStops = ELEMENT_DATA;


  constructor(
    private apiService: ApiService,
    private sanitizer: DomSanitizer,
    private app: AppComponent,
    private route: ActivatedRoute,
    private location: Location,
    public translate: TranslateService,
    fb: FormBuilder,

  ) {


    this.is_editting_details = false;
    this.loaded = false;
    this.descriptionFormGroup = fb.group({
      description: [
        '',
        []
      ],
      client_ref: [
        '',
        []
      ],
      temperature: [
        '',
        []
      ],
      mode_id: [
        '',
        []
      ]
    });


  }

  ngOnInit(): void {
    this.getOperation();
  }

  getOperation(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.apiService.getData('services/service_detail/view/'+ id )
      .subscribe(detailed_operation =>
        {

      //    detailed_operation.detail.contracts[0].stops[0]['appointment_from_date'] = new Date("03/01/2008");//new Date(detailed_operation.detail.contracts[0].stops[0].appointment_from_date);
        //  detailed_operation.detail.contracts[0].stops[1]['appointment_from_date'] =  new Date("03/01/2008");//Date(detailed_operation.detail.contracts[0].stops[1].appointment_from_date);

          this.data = detailed_operation.data;
          this.privilegios = detailed_operation.privilegios;
          console.log(detailed_operation);
      //    this.data.detail.contracts[0].stops[0]['appointment_from_date'] = new Date("03/01/2008");//new Date(detailed_operation.detail.contracts[0].stops[0].appointment_from_date);
    //      this.data.detail.contracts[0].stops[1]['appointment_from_date'] =  new Date("03/01/2008");//Date(detailed_operation.detail.contracts[0].stops[1].appointment_from_date);



          this.loaded = true;

        });
  }
  // makeEditable( element)
  // {
  //   element.editable = true;
  //   element.name_bk = element.name;
  //   element.description_bk = element.description;
  //   element.quantity_bk = element.quantity;
  //   element.weight_bk = element.weight;
  //   element.units_bk = element.units;
  //   element.length_bk = element.length;
  //   element.width_bk = element.width;
  //   element.height_bk = element.height;
  // }
  //
  // cancelEditable( element)
  // {
  //   element.name = element.name_bk;
  //   element.description = element.description_bk;
  //   element.quantity = element.quantity_bk;
  //   element.weight = element.weight_bk;
  //   element.units = element.units_bk;
  //   element.length = element.length_bk;
  //   element.width = element.width_bk;
  //   element.height = element.height_bk;
  //
  //   element.editable = false;
  //
  // }

  getModes()
  {
    return AppConstants.modes;
  }


  resetDescription(){
      this.descriptionFormGroup.reset( this.data.detail);
      this.is_editting_details = false;
  }

  saveDescription()
  {
    const id = +this.route.snapshot.paramMap.get('id');
    this.apiService.getData('services/service_detail/modify/'+ id, this.descriptionFormGroup.value ).subscribe(detailed_operation =>
        {
          for(var i in detailed_operation){
             this.data.detail[i]= detailed_operation[i];
          }
          this.is_editting_details = false;
        });
  }

  //print contract
  print_contract( contract ) :void{
    this.apiService.getBlob( 'pages/contract_dispatch/pdf/'+contract.id )
      .subscribe(response =>
        {
          var blob = response;
          var fileURL = URL.createObjectURL(blob);
          var documentData = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);

          let url = 'pages/contract_dispatch/email/' +contract.id;
          let filename = 'LC'+ contract.contract_id +'.pdf';
          let subject = 'LC '+ contract.contract_id + ' ';
          for( var i = 0; i<contract.stops.length; i++)
          {
            subject = subject + contract.stops[i].state + '-';
          }
          subject = subject + contract.vendor_name;
          let message = this.translate.instant('dialogs.lc_email');
          let preview = ""
  //        this.app.setSidenav(contract, subject ,message, documentData, filename, url);
    //      this.app.getSidenav().open();
        });

  }

}
