<h2 mat-dialog-title>New Site</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="siteFormGroup">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
      <mat-form-field fxFlex>
        <input matInput placeholder="Site Name" formControlName="site_name">
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
      <mat-form-field fxFlex>
        <input matInput placeholder="Street Address" formControlName="street_address">
      </mat-form-field>

      <mat-form-field fxFlex>
        <input type="text" placeholder="{{ 'interface.zip' | translate }}" aria-label="Number" matInput formControlName="zip" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayProperty" [optionSelected]="optionSelected">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
      <mat-form-field fxFlex>
        <mat-label>Shipping Hours</mat-label>
        <input matInput placeholder="" formControlName="shipping_hours">
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
      <mat-checkbox formControlName="appointment">
        Appointment Required
      </mat-checkbox>
    </div>


    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
      <mat-checkbox formControlName="tailgate">
        Tailgate Required
      </mat-checkbox>
    </div>
  </form>


  <form [formGroup]="contactsFormGroup">

    <div formArrayName="contact" *ngFor="let contact of contactsFormGroup.get('contact')['controls']; let i = index">
      <div [formGroupName]="i" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">

      <mat-form-field fxFlex>
        <input matInput placeholder="{{'interface.name' | translate }}" formControlName="contact_name">
      </mat-form-field>

      <mat-form-field fxFlex>
        <input matInput placeholder="{{'interface.phone' | translate }}" formControlName="phone">
      </mat-form-field>

      <mat-form-field fxFlex>
        <input matInput placeholder="{{'interface.email' | translate }}" formControlName="email">
      </mat-form-field>

      <mat-form-field fxFlex>
        <mat-label>Type</mat-label>
        <mat-select formControlName="type" multiple>
          <mat-option *ngFor="let topping of contactType" [value]="topping">{{topping}}</mat-option>
        </mat-select>
      </mat-form-field>


      <div fxFlex ="40px">
        <button mat-icon-button  aria-label="remove" matTooltip="{{'interface.remove' | translate}}" (click)="removeContactClick(i)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>

    </div>
  </div>
    <div class='bottom_bar'>


      <button mat-icon-button  aria-label="add" matTooltip="{{'interface.add' | translate}}" (click)="addContactClick()">
        <mat-icon>add</mat-icon>
      </button>
    </div>


  <div *ngIf="!formSubmitted">
      <button mat-raised-button (click)="submit()" color="primary">Submit</button>
  </div>

  </form>

</mat-dialog-content>
