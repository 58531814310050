<div class="panel_div">


  <div class="example-table-container mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" class='operations_table example-table'>

    <ng-container matColumnDef="select" >
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row"> <button mat-stroked-button  (click)="rowClick(row, $event)" >{{ 'interface.go' | translate }}</button> </td>
    </ng-container>


    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> {{'interface.name'| translate}}
        <mat-form-field class="filter" floatLabel="never">
            <input matInput  [formControl]="colFilter['name']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
      {{ element['name'] | translate}}
      </td>
    </ng-container>

    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef> {{'interface.address' | translate}}
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['address']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
      {{ element['address'] }}
      </td>
    </ng-container>

    <ng-container matColumnDef="city">
      <th mat-header-cell *matHeaderCellDef> {{'interface.city' | translate}}
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['city']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
      {{ element['city']}}
      </td>
    </ng-container>

    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef> {{'interface.state' | translate}}
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['state']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
      {{ element['state']}}
      </td>
    </ng-container>


    <ng-container matColumnDef="zip">
      <th mat-header-cell *matHeaderCellDef> {{'interface.zip' | translate}}
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['zip']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
      {{ element['zip']}}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


  </table>
  </div>

</div>
