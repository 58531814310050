<div *ngIf="loaded">
<mat-sidenav-container class="example-container" (backdropClick)="sidenav.close()" >
  <mat-sidenav #sidenav (keydown.escape)="sidenav.close()" disableClose position="end" class="side_bar">
    <app-quotesnm [id]="selected_row"></app-quotesnm>
    &nbsp;
  </mat-sidenav>

  <mat-sidenav-content>

<div class="panel_div">

<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="0px" fxLayoutGap.lt-md="0px" fx-layout-align="end">
    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" multiple (change)="toggleChangeService($event)" [(ngModel)]="tabsSelected">
      <mat-button-toggle *ngFor="let tab of tabs" value="{{tab.name}}">{{'interface.'+tab.name | translate}}</mat-button-toggle>
    </mat-button-toggle-group>

    <span class="spacer"></span>


    <span class="spacer"></span>

    <button mat-button (click)="getRows()">Update</button>
    <button mat-button (click)="resetFilter()">Reset Filter</button>
</div>


  <div class="example-table-container mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" class='operations_table example-table' matSort #matsort>

    <ng-container matColumnDef="select" >
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <button mat-icon-button mat-stroked-button color="primary" (click)="rowClick(row, $event)" >
          <mat-icon>menu</mat-icon>
        </button>
      </td>
      <td mat-footer-cell *matFooterCellDef>  </td>
    </ng-container>


    <ng-container matColumnDef="status_name">
      <th mat-header-cell *matHeaderCellDef>
        <!-- <mat-form-field class="filter" floatLabel="never">
            <input matInput >
        </mat-form-field> -->
      </th>
      <td mat-cell *matCellDef="let element">
      {{'interface.'+ element['status_name'] | translate}}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="quote_id">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['quote_id']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
      {{ element['quote_id'] }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>


    <ng-container matColumnDef="carrier_coordinator_name">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['carrier_coordinator_name']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
      {{ element['carrier_coordinator_name'] }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="account_manager_name">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['account_manager_name']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
      {{ element['account_manager_name'] }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>


    <ng-container matColumnDef="client_name">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['client_name']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
      {{ element['client_name']}}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>


    <ng-container matColumnDef="client_ref">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['client_ref']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
      {{ element['client_ref']}}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>


    <ng-container matColumnDef="pickups">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['pickups']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">

          <mat-list role="list" *ngFor="let stop of element['pickups']">
            {{stop.city_name}}, {{stop.state}} | {{stop.site_name}}
            <!-- {{stop.site_name}}<br/> {{stop.city_name}}, {{stop.state}} -->
          </mat-list>

      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>



    <ng-container matColumnDef="deliveries">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['deliveries']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
          <mat-list role="list" *ngFor="let stop of element['deliveries']">
            <!-- {{stop.site_name}}<br/> {{stop.city_name}}, {{stop.state}} -->
            {{stop.city_name}}, {{stop.state}} | {{stop.site_name}}
          </mat-list>


      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>



    <ng-container matColumnDef="mode_name">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['mode_name']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
      {{ element['mode_name']}}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="skids">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['skids']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
      {{ element['skids']}}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="weight">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['weight']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
      {{ element['weight']}}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>



    <ng-container matColumnDef="revenue">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [formControl]="colFilter['revenue']" >
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
      {{ element['revenue'] | number:'.2'}}
      </td>
      <td mat-footer-cell *matFooterCellDef> {{totalRev() | number:'.2'}} </td>
    </ng-container>


        <!-- sort -->

    <ng-container matColumnDef="sort_select" >
      <th mat-header-cell *matHeaderCellDef></th>
    </ng-container>

    <ng-container matColumnDef="sort_status_name">
      <th mat-header-cell *matHeaderCellDef> {{'interface.status'| translate}}
      </th>
    </ng-container>

    <ng-container matColumnDef="sort_quote_id">
      <th mat-header-cell *matHeaderCellDef> {{'interface.service' | translate}}
      </th>
    </ng-container>

    <ng-container matColumnDef="sort_carrier_coordinator_name">
      <th mat-header-cell *matHeaderCellDef>Carrier Coordinator
      </th>
    </ng-container>

    <ng-container matColumnDef="sort_client_name">
      <th mat-header-cell *matHeaderCellDef> {{'interface.client' | translate}}
      </th>
    </ng-container>

    <ng-container matColumnDef="sort_account_manager_name">
      <th mat-header-cell *matHeaderCellDef>Carrier Coordinator
      </th>
    </ng-container>


    <ng-container matColumnDef="sort_client_ref">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'interface.client_ref' | translate}}
      </th>
    </ng-container>


    <ng-container matColumnDef="sort_pickups">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'interface.pickups' | translate}}
      </th>
    </ng-container>


    <ng-container matColumnDef="sort_deliveries">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'interface.deliveries' | translate}}
      </th>
    </ng-container>

  <ng-container matColumnDef="sort_mode_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'interface.mode_name' | translate}}
      </th>
    </ng-container>

    <ng-container matColumnDef="sort_skids">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'interface.skids' | translate}}
      </th>
    </ng-container>

    <ng-container matColumnDef="sort_weight">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'interface.mode_name' | translate}}
      </th>
    </ng-container>

    <ng-container matColumnDef="sort_revenue">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'interface.revenue' | translate}}
      </th>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="displayedColumnsSort; sticky: true"></tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>

  </table>
  </div>

</div>

</mat-sidenav-content>
</mat-sidenav-container>
</div>
<div *ngIf="!loaded">
  Loading...
</div>
