//import { Component, OnInit,ViewChild } from '@angular/core';
import {Component, Input,ElementRef, ViewChild, OnInit} from '@angular/core';
//import { Operation } from './operation';
//import { Tab } from './tab';
import { ApiService } from '../services/api.service';
import { MatTableDataSource } from '@angular/material/table';
//import { AngularMaterialModule } from '../angular-material.module'
import {Router} from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import {MatSidenav} from '@angular/material/sidenav';
import { AppConstants } from '../structures/appconstants';

@Component({
  selector: 'app-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.css']
})

export class VendorsComponent implements OnInit {

  loaded:boolean;
  vendors;
  selected_row : number;
  filterFormGroup :FormGroup;
  filter = {};

  colFilter;

  displayedColumns: string[] = ['select','name','address','city','state','zip'];

  filterValues = {
    name: '',
    address: '',
    city: '',
    state: '',
    zip: ''
  };

  dataSource : MatTableDataSource<any>;

  ngOnInit(): void
  {
    for (let i of Object.keys(this.colFilter))
    {

      this.colFilter[i].valueChanges
       .pipe(
         debounceTime(1000), // Waiting for 1 sec while you are typing
          distinctUntilChanged() // Prevents the emitting if the 'start' value and the 'end' value are the same
       )
      .subscribe(
        value => {
          this.filterValues[i] = value;
          this.dataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    }
  }

  constructor( private apiService: ApiService, private router: Router, fb: FormBuilder,public datepipe: DatePipe)
  {
    this.loaded = false;
    this.getRows();
    this.colFilter = {};

    let properties = Object.keys(this.filterValues);
    for (let i of properties) {
      this.colFilter[i] = new FormControl('');
    }



  }

  createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function(data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      let properties = Object.keys(searchTerms);

      for (let i of properties) {
        searchTerms[i] = searchTerms[i].toLowerCase();
        if((searchTerms[i] =='')|| searchTerms[i] == null){
          continue;

        }


        if(data[i]?.toString().toLowerCase().indexOf(searchTerms[i]) === -1 )
        {
          return false;
        }

      }
      return true;
    }
    return filterFunction;
  }

  rowClick( row, event )
  {
    if (( event.metaKey ) || (event.ctrlKey))
    {
      var win = window.open('/vendor_detail/'+ row.id , '_blank');
      if (win) {
          win.focus();
      } else {
          alert('Please allow popups for this website');
      }
    }
    else
    {
      this.router.navigate(['/vendor_detail/' + row.id  ]);
    }
  }

  getRows(): void
  {
    this.apiService.getData( 'vendors/list' )
      .subscribe(param =>
      {
        if(this.dataSource)
          this.dataSource.data  = param.data;
        else
          this.dataSource = new MatTableDataSource<any>(param.data)
        this.loaded = true;
        this.dataSource.filterPredicate = this.createFilter();
      });

  }
}
