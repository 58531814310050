<div *ngIf="value.type == 'email'">
<mat-toolbar>

  <button mat-icon-button  aria-label="send" matTooltip="{{'interface.print' | translate}}" (click)="emailDialogToggle()">
    <mat-icon>send</mat-icon>
  </button>



  <span class="spacer"></span>
  <span></span>

</mat-toolbar>


<div *ngIf='email_dialog' class="email_dialog">
<form [formGroup]="rulesForm" (ngSubmit)="sendEmail()">
	<mat-form-field appearance="outline">
    <mat-label>{{'interface.to' | translate }}</mat-label>
		<mat-chip-list #chipList formArrayName="emails" >
			<mat-chip [color]="(item.invalid)?'warn':''" selected *ngFor="let item of emailList; let i=index" [selectable]="true" [removable]="removable"
			 (removed)="removeEmail(item)" required name="chips">{{item.value}}
				<mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
			</mat-chip>
			<input matInput [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes " [matChipInputAddOnBlur]="true"
			 (matChipInputTokenEnd)="add($event) " />

		</mat-chip-list>
	</mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{'interface.subject' | translate }}</mat-label>
			<input matInput formControlName="subject">
	</mat-form-field>

  <span>{{'interface.message' | translate }}</span>

    <div [innerHTML]="value.message"></div>



  <button  type="submit" [disabled]="!rulesForm.valid || sending" mat-raised-button color="primary">{{'interface.send' | translate }}</button>
</form>
</div>
</div>
<div *ngIf="value.type == 'document'">
{{value.message}}
</div>
<iframe *ngIf="this.value.tryDoctype"  height="600" [src]="this.value.tryDoctype"></iframe>
