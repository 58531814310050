import {Component, Input,ElementRef, ViewChild, OnInit} from '@angular/core';
import { Observable, of, Subscription, empty } from 'rxjs';
import { tap, startWith, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
//import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { MatTable } from '@angular/material/table';
//import { MatTable } from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import { AppConstants } from '../structures/appconstants';
import { DatePipe } from '@angular/common'
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-notes',
  templateUrl: './editableNotes.component.html',
  styleUrls: ['./editableNotes.component.css']
})

export class EditableNotesComponent implements OnInit {
  //@ViewChild('templateForm') templateForm: NgForm;
  text:string = "";
  @Input() data: any;
  @Input() updateUrl: string =null;
  @Input() newUrl: string =null;
  @Input() removeUrl: string =null;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;


  constructor(
    private apiService: ApiService,
    public datepipe: DatePipe
  )
  {
  
  }

  ngOnInit() {
    this.scrollToBottom();
  }

  removeNote(element)
  {
      if(! confirm("Are you sure to delete"))
        return;


        this.apiService.getData( this.removeUrl, element  )
          .subscribe(
            result => {
              if(result['success'])
              {
                this.data.splice(this.data.indexOf(element),1);
              }
            });

  }
  scrollToBottom(): void {
        try {
            this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
        } catch(err) { }
    }


  newNote(event) {
    const text = this.text;
    this.text ="";
    this.apiService.getData( this.newUrl, {status: text } )
      .subscribe(
          result => {
            if(!result['success'])
            {

            }
            else
            {
              this.data.push({user_name: result['user_name'], date: result['date'], status: text, id:result['id']});
              setTimeout(() => {
                this.scrollToBottom();
              });

            }
        });
    }
}
