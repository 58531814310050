import {Component, Input,ElementRef, ViewChild, OnInit, AfterViewInit} from '@angular/core';
import { ApiService } from '../services/api.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { AppConstants } from '../structures/appconstants';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from "html-to-pdfmake";


@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.css']
})

export class BalanceComponent implements OnInit
{
  @ViewChild('print_content') content: ElementRef;
  test;
  loaded:boolean = false;
  data;
  filterFormGroup :FormGroup;
  filter = {};

  ngOnInit(): void
  {

  }

  constructor( private apiService: ApiService, fb: FormBuilder,public datepipe: DatePipe)
  {
    this.test = {name:'asse',children:'child'};
    this.filterFormGroup = fb.group({
      date: [
        new Date('7/31/2021'),
        []
      ]
    });

  }

  public templateToFormData( object ) {
    console.log(object);
    const formData = new FormData();
    for ( const key of Object.keys(object) )
    {
      if (Array.isArray(object[key]))
      {
        for ( let a of object[key] )
          formData.append(key+'[]', a);
      }
      else
      {
        if( object[key]=== null)
          formData.append(key, '');
        else if( object[key]=== false)
          formData.append(key, '0');
        else if( key == 'date')
        {
          const  latest_date =this.datepipe.transform(object[key], 'yyyy-MM-dd');
          formData.append(key, latest_date);
        }
        else
          formData.append(key, object[key]);
      }
    }
    return formData;
  }

  indent(account)
  {
    let i = account['code'].match(/\./g).length;
    var tab = '&emsp;';
    return tab.repeat(i);
  }
  getBalance()
  {
    const formData = this.templateToFormData(this.filterFormGroup.value);
    this.apiService.getData( 'statements/balance', formData)
      .subscribe(param =>
        {



          this.data = param;
          this.data.equity.children.push({ children: null, gifi: '3680', name:'Period Results', saldo: this.data.revenues.saldo - this.data.expenses.saldo - this.data.expenses_after_taxes.saldo});
          this.data.equity.saldo += this.data.revenues.saldo - this.data.expenses.saldo  - this.data.expenses_after_taxes.saldo;
          this.loaded = true;
        });

  }
  log( l)
  {
    console.log(l);
  }

  generatePDF()
  {
    const content = this.content.nativeElement;
    let rcontent = htmlToPdfmake(content.innerHTML);

    // rcontent[0].table["headerRows"] = 4;
    // rcontent[0].table["widths"] = ['auto', 'auto', 250, '*', 'auto', 'auto'];

    let dd = {
    footer: function (currentPage, pageCount) {
      if(pageCount == currentPage)
        return '';
      return {
                text: "Page " + currentPage.toString() + ' of ' + pageCount,
                alignment: 'right',
                style: 'text',
                margin: [0, 0, 40, 0]
      };
    },
    pageSize: 'LETTER',
    //pageOrientation: 'landscape',
    content: rcontent,
    styles: {
      text_center:
      {
        alignment: 'center'
      },
      'html-td':
      {
        fontSize: 6.5,
      },
      'html-th':
      {
        fontSize: 6.5,
      },
    }

    };
    pdfMake.createPdf(dd).print();
  }


}
