import {HttpClient} from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef, Optional, Inject} from '@angular/core';
import { ApiService } from '../services/api.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { AppConstants } from '../structures/appconstants';
import { Observable, of, Subscription, empty } from 'rxjs';
import { tap, startWith, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { asyncValidator } from '../helpers/custom-async-validator';
import { AngularMaterialModule } from '../angular-material.module';


import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import htmlToPdfmake from "html-to-pdfmake";


@Component({
  selector: 'app-newsitedialog',
  templateUrl: './newsitedialog.component.html',
  styleUrls: ['./vendordialog.component.css']
})
export class NewsitedialogComponent implements OnInit {

  public name: string;
  contactType= ['shipper','receiver','customer','accounting','dispatch','tracing','bol'];
  filteredOptions: Observable<any[]>;
  formSubmitted = false;
  // Variables
  loading: boolean;
  errors: boolean;
  siteFormGroup: FormGroup;
  contactsFormGroup: FormGroup;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<NewsitedialogComponent >,
    private apiService : ApiService
  )
  {

    this.contactsFormGroup = fb.group({ // make a nested group
        contact: fb.array([this.addContactsFormGroup()])
      });



    this.siteFormGroup = fb.group({
      site_name: [
        data,
        [Validators.required]
      ],
      street_address: [
        '',
        [Validators.required]
      ],
      zip: [
        '',
        [Validators.required]
      ],
      shipping_hours: [
        'details pending',
        [Validators.required]
      ],
      appointment: [
        true,
        [Validators.required]
      ],
      tailgate: [
        false,
        [Validators.required]
      ]

    });




    this.filteredOptions = this.siteFormGroup.get("zip").valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(val =>
         this.filter(val || '')
        )
     )


  }
  optionSelected(event)
  {
    event.option.id;
  }

  filter(val: string): Observable<any[]> {
   // call the service which makes the http-request
   return this.apiService.getData('shared/zip',{query: val})
     .pipe(
     )
  }

  addContactsFormGroup(): FormGroup {
    return this.fb.group({
        contact_name: ['', [Validators.required]],
        phone: '',
        email: ['', [Validators.required]],
        type: ''

      });
  }

  displayProperty(value) {
    if (value) {
      return value.name;
    }
  }

  addContactClick(): void {
    (<FormArray>this.contactsFormGroup.get("contact")).push(
      this.addContactsFormGroup()
    );

  }

  removeContactClick(i: number){
    const aFormArray = this.contactsFormGroup.controls.contact as FormArray;
    aFormArray.removeAt(i);
  }

  submit()
  {
    let result = [];
    result['name'] = 'name';
    result['id'] = 10;
    result['city'] = 'city';
    result['state'] = 'state';

    this.formSubmitted = true;

    let data = { ...this.contactsFormGroup.value, ...this.siteFormGroup.value };

    data['zip_id'] = data['zip'].id;

    this.apiService.getData('sites/site_detail/store', data ).subscribe(res =>
    {
        if ( res['success'] )
        {
            //this.router.navigate(['/operation_detail/' + res['id']  ]);
            //let results = [ ...this.results, ...res.ids];
            this.dialogRef.close(res);
        }


    });




  }
  ngOnInit(): void { }


}
