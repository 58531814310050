import {Component, Input,ElementRef, ViewChild, OnInit, AfterViewInit} from '@angular/core';
import { ApiService } from '../services/api.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { AppConstants } from '../structures/appconstants';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./statements.component.css']
})

export class VoucherComponent implements OnInit
{
  test;
  loaded:boolean = false;
  data;
  filterFormGroup :FormGroup;
  filter = {};

  ngOnInit(): void
  {
    this.getVoucher()
  }

  constructor( private apiService: ApiService, fb: FormBuilder,public datepipe: DatePipe,private route: ActivatedRoute)
  {
    this.filterFormGroup = fb.group({

    });

  }

  deleteVoucher()
  {
    if( confirm("Are you sure to delete ") )
    {
      const id = +this.route.snapshot.paramMap.get('id');
      this.apiService.getData( 'accounting/voucher/remove', {id:id} )
        .subscribe(param =>
        {
            this.data = {};
            this.loaded = false;
        });

    }
  }
  public templateToFormData( object ) {
    console.log(object);
    const formData = new FormData();
    for ( const key of Object.keys(object) )
    {
      if (Array.isArray(object[key]))
      {
        for ( let a of object[key] )
          formData.append(key+'[]', a);
      }
      else
      {
        if( object[key]=== null)
          formData.append(key, '');
        else if( object[key]=== false)
          formData.append(key, '0');
        else if( key == 'date')
        {
          const  latest_date =this.datepipe.transform(object[key], 'yyyy-MM-dd');
          formData.append(key, latest_date);
        }
        else
          formData.append(key, object[key]);
      }
    }
    return formData;
  }


  getVoucher()
  {
    const id = +this.route.snapshot.paramMap.get('id');
    this.apiService.getData( 'accounting/voucher/view/'+ id )
      .subscribe(param =>
        {
          this.data = param.data;
          this.loaded = true;
        });

  }
}
