<mat-card class="example-card hide-print">
  <mat-card-header>
    <mat-card-title>Balace Sheet</mat-card-title>
    <mat-card-subtitle>Options</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="filterFormGroup" (submit)="getBalance()">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fx-layout-align="end">
        <mat-form-field>
          <mat-label>Up to date</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="date">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fx-layout-align="end">
        <button mat-raised-button color="primary" type="submit">Generate Report</button>
      </div>



      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fx-layout-align="end">
      <button mat-raised-button color="accent"(click)="generatePDF()">Print Report
        <mat-icon aria-hidden="false" aria-label="">print</mat-icon>
      </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>



<div  #print_content class="print_content" *ngIf="loaded">
  <div style="text-align: center;">Venkor Inc</div>
  <div style="text-align: center;">BALANCE SHEET</div>
  <div style="text-align: center;">As of {{data.date | date:'MMM d, y'}}</div>



  <ng-template #treeView let-name="name" let-gifi="gifi" let-children="children" let-saldo="saldo">
    <tr><td>{{name}} {{gifi}} </td>
      <td class='second_column'>
        <ng-container *ngIf="children === null">
          {{saldo | number :'1.2-2'}}
        </ng-container>
      </td>
    </tr>
    <ng-container *ngIf="children?.length > 0">
      <tr>
        <td colspan="2">
          <table>
            <ng-container *ngFor="let child of children">
              <ng-container *ngIf="child.saldo !=0" >
                <ng-container *ngTemplateOutlet="treeView;  context:child"></ng-container>
              </ng-container>
            </ng-container>
          </table>
        </td>
      </tr>
      <tr><td><strong>Total {{name}} {{gifi}}</strong></td> <td  class='second_column'><strong>{{saldo | number :'1.2-2'}}</strong></td></tr>
    </ng-container>
  </ng-template>


<table data-pdfmake="{&quot;widths&quot;:[&quot;*&quot;,&quot;*&quot;],&quot;layout&quot;:&quot;noBorders&quot;}">
<ng-container *ngTemplateOutlet="treeView;context:data.assets"></ng-container>
<tr><td colspan="2"></td></tr>


<tr>
  <td>Liabilities and Equity</td><td class='second_column'></td>
</tr>
<tr><td colspan="2">
<table data-pdfmake="{&quot;widths&quot;:[&quot;*&quot;,&quot;*&quot;],&quot;layout&quot;:&quot;noBorders&quot;}">
  <ng-container *ngTemplateOutlet="treeView;context:data.liabilities"></ng-container>
  <ng-container *ngTemplateOutlet="treeView;context:data.equity"></ng-container>
</table>
</td></tr>
  <tr><td>
    <strong>Total Liabilities and Equity</strong>
    </td>
    <td class='second_column'>
      {{data.liabilities.saldo + data.equity.saldo | number:'1.2-2'}}
    </td>
  </tr>
</table>

</div>

A: {{data.assets.saldo | number:'1.2-2'}} 
L+E: {{data.liabilities.saldo + data.equity.saldo | number:'1.2-2'}}
