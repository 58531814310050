import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AngularMaterialModule } from '../angular-material.module';
import { ApiService } from '../services/api.service';
import { tap, startWith, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { Observable, of, Subscription, empty } from 'rxjs';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import htmlToPdfmake from "html-to-pdfmake";


@Component({
  selector: 'app-login',
  templateUrl: './newvendor.component.html',
  styleUrls: ['./newvendor.component.scss']
})
export class NewVendorComponent implements OnInit {

  @ViewChild('htmlData') htmlData:ElementRef;
filteredOptions: Observable<any[]>;
filteredOptions2: Observable<any[]>;
  // public openPDF():void {
  //   let DATA = this.htmlData.nativeElement;
  //   let doc = new jsPDF('p','pt', 'a4');
  //   doc.fromHTML(DATA.innerHTML,15,15);
  //   doc.output('dataurlnewwindow');
  // }





  generatePDF() {

    var DateObj = new Date();


    //DATE
    const month = DateObj.toLocaleString('en-us', { month: 'long' }); /* June */
    const day = ('0' + DateObj.getDate()).slice(-2);
    const year = DateObj.getFullYear();

    // Carrier
    const legal_name = this.companyFormGroup.controls['legal_name'].value;
    const mc = this.companyFormGroup.controls['mc_number'].value;
    const dot= this.companyFormGroup.controls['us_dot'].value;
    const address= this.companyFormGroup.controls['street_address'].value + ' '+ this.companyFormGroup.controls['zip'].value

    const fullname= this.subscriberFormGroup.controls['name_subscriber'].value;
    const title= this.subscriberFormGroup.controls['title_subscriber'].value;



    let dd = {
    footer: function (currentPage, pageCount) {
      if(pageCount == currentPage)
        return '';
      return {
                text: "Initials______(Broker) ________(Carrier)    Page " + currentPage.toString() + ' of ' + pageCount,
                alignment: 'right',
                style: 'text',
                margin: [0, 0, 40, 0]
      };
    },
    pageSize: 'LETTER',
    content: [
      {
        text: 'BROKER CARRIER AGREEMENT',
        style: 'header'
      },
      {
        text: 'This Agreement is entered into this '+day+' day of '+month+', '+ year+', by and between VENKOR INC, a Registered Property Broker, Lic. No.: USDOT 3473199 /  MC-1137427  having its principal facility at 902-15 Eva Rd, Etobicoke, On M9C 4W3 (hereinafter “BROKER”), and '+legal_name+', located in '+address+', a Registered Motor Carrier, Permit/Certificate No. USDOT '+dot+' / '+mc+' (hereinafter "CARRIER"); collectively, the "Parties".  ("Registered" means operated under authority issued by the Federal Motor Carrier Safety Administration (or its predecessors) within the U.S. Department of Transportation, and under authority issued by all applicable regulatory authorities in Canada to carry the property of another person or company by commercial motor vehicle for compensation). \n\n ',
        style: 'text'
      },
      {
        text: '1. 	CARRIER REPRESENTS AND WARRANTS THAT IT:',
        style: 'subheader'
      },
      {
        text: `A. 	Is a Registered Motor Carrier of Property authorized to provide transportation of property under contracts with shippers and receivers and/or brokers of general commodities.

B. 	Shall transport the property, under its own operating authority and subject to the terms of this Agreement;

C. 	Makes the representations herein for the purpose of inducing BROKER to enter into this Agreement.

D. 	Agrees that a Shipper’s insertion of BROKER’s name as the carrier on a bill of lading shall be for the Shipper’s convenience only and shall not change BROKER’s status as a property broker nor CARRIER’s status as a motor carrier. BROKER is not a motor carrier and assumes no motor carrier responsibility for cargo loss and damage in the event that the National Motor Freight Traffic Association (NMFTA) (effective in August 2016), form of bill of lading is utilized.

E. 	Will not re-broker, co-broker, subcontract, assign, interline, or transfer the transportation of shipments hereunder to any other persons or entity conducting business under a different operating authority, without prior written consent of BROKER. If CARRIER breaches this provision, among all other remedies (whether at equity or in law), BROKER shall have the right of paying the monies it owes CARRIER directly to the delivering carrier, in lieu of payment to CARRIER. Upon BROKER’s payment to delivering carrier, CARRIER shall not be released from any liability to BROKER under this Agreement or otherwise, including any claims under MAP-21 (49 U.S.C. § 13901 et seq.). In addition to the indemnity obligation in Par 1.H, CARRIER will be liable for consequential damages for violation of this provision.

F. 	(i) Is in, and shall maintain compliance during the term of this Agreement, with all applicable federal, provincial (or state) and local laws relating to the provision of its services including, but not limited to:  transportation of Dangerous Goods (or Hazardous Materials), (including the licensing and training of drivers) to the extent that any shipments hereunder constitute Dangerous Goods (or Hazardous Materials); security regulations; customs regulations; owner/operator lease regulations; loading and securement of freight regulations; implementation and maintenance of driver safety regulations including, but not limited to, hiring, controlled substances and alcohol testing, and hours of service regulations; sanitation, temperature, and contamination requirements for transporting food, perishable, and other products, qualification and licensing and training of drivers; implementation and maintenance of equipment safety regulations; maintenance and control of the means and method of transportation including, but not limited to, performance of its drivers; all applicable insurance laws and regulations including but not limited to workers’ compensation. CARRIER agrees to provide proof of compliance upon request.

	(ii) Is solely responsible for any and all management, governing, discipline, direction and control of its employees, owner/operators, and equipment with respect to operating within all applicable federal and provincial (or state) legal and regulatory requirements to ensure the safe operation of CARRIERS vehicles, drivers and facilities. CARRIER and BROKER agree that safe and legal operation of the CARRIER and its drivers shall completely and without question govern and supersede any service requests, demands, preferences, instructions, and information from BROKER or BROKER’s customer with respect to any shipment at any time.

G. 	CARRIER will notify BROKER immediately if any Operating Authority is revoked, suspended or rendered inactive for any reason; and/or if it is sold, or if there is a change in control of ownership, and/or any insurance required hereunder is threatened to be or is terminated, cancelled, suspended, or revoked for any reason.

H. CARRIER shall defend, indemnify and hold BROKER and its shipper customer harmless from any claims, actions or damages, arising out of its performance under this Agreement, including cargo loss and damage, theft, delay, damage to property, and personal injury or death. BROKER shall not be liable to the CARRIER for any claims, actions or damages due to the negligence or intentional act of the CARRIER, or the shipper. The obligation to defend shall include all costs of defense as they accrue.

I. 	Does not have an “Unsatisfactory” safety rating issued by the Federal Motor Carrier Safety Administration (FMCSA), U.S. Department of Transportation, or any provincial regulatory authority and will notify BROKER in writing immediately if its safety rating is changed to “Unsatisfactory” or “Conditional”.

J.	Authorizes BROKER to invoice CARRIER’s freight charges to shipper, consignee, or third parties responsible for payment.

K. 	Has investigated, monitors, and agrees to conduct business hereunder based on the credit-worthiness of BROKER and is granting BROKER credit terms accordingly.

L.	For the benefit of the BROKER and any and all shippers, consignors, consignees, receivers, and any other parties with any interest to the transportation of the property. CARRIER warrants that: To the extent that any shipments subject to this Agreement are transported within the State of California, all equipment including but not limited to: semi-trailers, containers, truck vans, shipping containers and railcars, and Semi-Tractors that haul them under this Agreement are in compliance with (i) the California Air Resources  Board (ARB) Heavy-Duty Vehicle Greenhouse Gas (Tractor-Trailer GHG) Emission Reduction Regulations, and ( ii) all refrigerated equipment utilized within the state are in full compliance with the California Air Resources Board (ARB) Transport Refrigerated Unit (TRU)  Airborne Toxic Control Measure (ATCM) , and in-use regulations, and (iii) the California Air Resources  Board (ARB) Truck and Bus Regulation or On-Road Heavy-Duty Diesel Vehicles (In-Use) Regulation. CARRIER shall defend, indemnify, hold harmless and be liable to BROKER and any and all shippers, consignors, consignees, receivers, and any other parties with any interest to the transportation of the property for any penalties, or any other liability, imposed on the same, or assumed by BROKER due to penalties imposed on BROKERS customer(s) because of CARRIER\'s use of non-compliant equipment.`,
        style: 'text'
      },

      {
        text: '2. 	BROKER RESPONSIBILITIES: ',
        style: 'subheader'
      },
      {
        text: `A. 	SHIPMENTS, BILLING & RATES:  BROKER shall offer CARRIER at least one (1) loads/shipments annually. BROKER shall inform CARRIER of (i) place of origin and destination of all shipments; and (ii) if applicable, any special shipping and handling instructions, special equipment requirements, or value of shipments in excess of the amount specified in Par. 3D below, of which BROKER has been timely notified.

B. 	BROKER agrees to conduct all billing services to shippers, consignees, or other party responsible for payment.  CARRIER shall invoice BROKER for its (CARRIER’s) charges, as mutually agreed in writing, by fax, or by electronic means, contained in BROKER’s Load Confirmation Sheet(s) / dispatch sheets incorporated herein by this reference.  Additional rates for truckload or LTL shipments, or modifications or amendments of the above rates, or additional rates, may be established to meet changing market conditions, shipper requirements, BROKER requirements, and/or specific shipping schedules as mutually agreed upon, and shall be confirmed in writing (or by fax or email) by both Parties. Any such additional, modified, or amended rates, changes in rates shall automatically be incorporated herein by this reference.

C. 	RATES:  Additionally, any rates, which may be verbally agreed upon, shall be deemed confirmed in writing where CARRIER has billed the agreed rate and BROKER has paid it. All written confirmations of rates, including confirmations by billing and payment, shall be incorporated herein by this reference. Rates or charges, including but not limited to stop-offs, detention, loading or unloading, fuel surcharges, or other accessorial charges, tariff rates, released rates or values, or tariff rules or circulars, shall only be valid when their terms are specifically agreed to in a writing signed by both Parties.

D. 	PAYMENT:
	(i) The Parties agree that BROKER is the sole party responsible for payment of CARRIER's charges. Failure of BROKER to collect payment from its customer shall not exonerate BROKER of its obligation to pay CARRIER. CARRIER herby waives the requirement under any applicable statute or regulation for BROKER to maintain a trust account or be subject to any trust obligations in respect of moneys owed to CARRIER hereunder. BROKER agrees to pay CARRIER's invoice within thirty (30) days of receipt of the bill of lading or proof of delivery, provided CARRIER is not in default under the terms of this Agreement. If BROKER has not paid CARRIER's invoice as agreed, and CARRIER has complied with the terms of this Agreement, CARRIER may seek payment from the Shipper or other party responsible for payment after giving BROKER twenty (20) business days advance written notice. CARRIER shall not seek payment from Shipper, consignees, or third parties, if they can prove payment to BROKER.
	(ii) Payment and other disputes are subject to the terms of Par. 4D, which provides in part that prevailing parties are entitled to recovery of costs, expenses and reasonable attorney fees.

E. 	If applicable, BROKER will notify CARRIER immediately if its Operating Authority is revoked, suspended or rendered inactive for any reason; and/or if it is sold, or if there is a change in control of ownership, and/or any insurance required hereunder is threatened to be or is terminated, cancelled, suspended, or revoked for any reason.

F. 	BROKER’s responsibility is limited to arranging for, but not actually performing, transportation of a shipper’s freight.
`,
        style: 'text'
      },
      {
        text: '3.	 CARRIER RESPONSIBILITIES: ',
        style: 'subheader'
      },
      {
        text: `A.	EQUIPMENT: Subject to its representations and warranties in Paragraph 1 above, CARRIER agrees to provide the necessary equipment and qualified personnel for completion of the transportation services required for BROKER and/or its customers. CARRIER will not supply equipment that has been used to transport hazardous wastes, solid or liquid. CARRIER will furnish equipment for transporting cargo which is sanitary, and free of any contamination, suitable for the particular commodity being transported and which will not cause in whole or in part adulteration of the commodity. CARRIER agrees that all shipments will be transported and delivered with reasonable dispatch, or as otherwise agreed in writing.

B. 	BILLS OF LADING: CARRIER shall sign a bill of lading produced by shipper, or CARRIER shall issue a Uniform Bil of Lading for the property it receives for transportation under this Agreement. Unless otherwise agreed in writing, CARRIER shall become fully responsible/liable for the freight when it takes/receives possession thereof, and the trailer(s) is loaded, regardless of whether a bill of lading has been issued, and/or signed, and/or delivered to CARRIER, and which responsibility/liability shall continue until delivery of the shipment to the consignee and the consignee signs the bill of lading or delivery receipt. The foregoing sentence is not intended to limit or waive the application of the law related to concealed damages. Any terms of the bill of lading (including but not limited to payment and credit terms, released rates or released value) inconsistent with the terms of this Agreement shall be ineffective.  Failure to issue a bill of lading, or sign a bill of lading acknowledging receipt of the cargo, by CARRIER, shall not affect the liability of CARRIER.

C. 	LOSS & DAMAGE CLAIMS:
	(i)    CARRIER shall comply with 49 C.F.R. §370.1 et seq. and any amendments and/or any other applicable regulations adopted by the Federal Motor Carrier Safety Administration, U.S. Department of Transportation, or any applicable state or provincial regulatory agency, for processing all loss and damage claims and salvage. CARRIER agrees that food that has been transported or offered for transport under conditions that are not in compliance with Shipper's or BROKER'S instructions, as provided to CARRIER by Shipper or BROKER, will be conclusively determined to be "adulterated" within the meaning of the Federal Food, Drug and Cosmetic Act, 21 U.S.C. § 342 (i). CARRIER understands and agrees that adulterated shipments may be refused by the consignee or receiver, at destination without diminishing or affecting CARRIER'S liability in the event of a cargo claim.  CARRIER shall not sell, salvage or attempt to sell or salvage any goods without the BROKER's express written permission; and
	(ii)   CARRIER’s liability for any cargo damage, loss, or theft from any cause shall be determined under the Carmack Amendment, 49 U.S.C. §14706 as governing shipments according to its terms, and in respect of shipments originating in Canada under the uniform bill of lading in effect in the province of Canada where the CARRIER issues a bill of lading.  It is agreed that in respect of shipments from a Canadian origin that the BROKER on behalf of the shipper is deemed to have declared the full value of the shipment for the carriage on the bill of lading, and in this regard the CARRIER shall have full liability for cargo damage, loss or theft and CARRIER waives the provisions of clauses 9 and 10 of the uniform bill of lading in effect in the province of origin.
	(iii) 	Special Damages: CARRIER’s indemnification liability (Par 1.H) for freight loss and damage claims under this sub-par C (ii) shall include legal fees which shall constitute special damages, the risk of which is expressly assumed by CARRIER, and which shall not be limited by any liability of CARRIER under sub par (ii) above.
 	(iv)   Except as provided in Par 1.E above, neither Party shall be liable to the other for consequential damages without prior written notification of the risk of loss and its approximate financial amount, and agreement to assume such responsibility in writing.
	(v)    Notwithstanding the terms of 49 CFR 370.9, CARRIER shall pay, decline or make settlement offer in writing on all cargo loss or damage claims within 10 days of receipt of the claim. Failure of CARRIER to pay, decline or offer settlement within this 10 day period shall be deemed admission by CARRIER of full liability for the amount claimed and a material breach of this Agreement.

D.	INSURANCE: CARRIER shall furnish BROKER with Certificate(s) of Insurance, or insurance policies providing thirty (30) days advance written notice of cancellation or termination, and unless otherwise agreed, subject to the following minimum limits:  Public (General) liability $1,000,000.00; motor vehicle (including hired and non-owned vehicles), property damage, and personal injury liability $1,000,000.00, per occurrence ( $2,000,000 if transporting hazardous materials and/or dangerous goods including environmental damages due to release or discharge of hazardous substances or $5,000,000 USD if required by the D.O.T. under 49 CFR Part 387 for the transportation of certain hazardous substances) covering all vehicles used by CARRIER in performing the services set forth in this Agreement; cargo damage/loss, $150,000.00; workers’ compensation with limits required by law.  Except for the higher coverage limits which may be specified above, the insurance policies shall comply with minimum requirements of the Federal Motor Carrier Safety Administration and any other applicable federal, state or provincial regulatory agency. Nothing in this Agreement shall be construed to avoid or limit CARRIER’s liability due to any policy limits or exclusion or deductible in any insurance policy.

E.	ASSIGNMENT OF RIGHTS: CARRIER automatically assigns to BROKER all its rights to collect freight charges from Shipper or any responsible third party on receipt of payment of its freight charges from BROKER.

F.	CARRIER assumes full responsibility and liability for payment of the following items: All applicable federal, state or provincial, and local payroll taxes, taxes for unemployment insurance, old age pensions, workers’ compensation, social security, with respect to persons engaged in the performance of its transportation services hereunder.  BROKER shall not be liable for any of the payroll-related tax obligations specified above and CARRIER shall indemnify, defend, and hold BROKER harmless from any claim or liability imposed or asserted against BROKER for any such obligations.
`,
        style: 'text'
      },
      {
        text: '4. 	MISCELLANEOUS: ',
        style: 'subheader'
      },
      {
        text: `A. 	INDEPENDENT CONTRACTOR: The relationship of the Parties to each other shall at all times be that of independent contractors. None of the terms of this Agreement, or any act or omission of either Party shall be construed for any purpose to express or imply a joint venture, partnership, principal/agent, fiduciary, or employer/employee relationship between the Parties. Each Party shall provide sole supervisions and shall have exclusive control over the actions and operations of its employees, and agents used to perform its services hereunder. Neither Party has any right to control, discipline or direct the performance of any employees, or agents of the other Party. Neither Party shall represent to any party that it is anything other than an independent contractor in its relationship to the other Party.

B. 	NON-EXCLUSIVE AGREEMENT: CARRIER and BROKER acknowledge and agree that this contract does not bind the respective Parties to exclusive services to each other. Either party may enter into similar agreements with other carriers, brokers, or freight forwarders.

C.	WAIVER OF PROVISIONS:
	(i)     Failure of either Party to enforce a breach or waiver of any provision or term of this Agreement shall not be deemed to constitute a waiver of any subsequent failure or breach, and shall not affect or limit the right of either Party to thereafter enforce such a term or provision.
	(ii)    This Agreement is for specified services pursuant to 49 U.S.C. §14101(b), where applicable. To the extent that terms and conditions herein are inconsistent with Part (b), Subtitle IV, of Title 49 U.S.C. (ICC Termination Act of 1995), the Parties expressly waive any or all rights and remedies they may have under the Act.

D.	DISPUTES: In the event of a dispute arising out of this Agreement, including but not limited to Federal, State or Provincial statutory claims, the Party's sole recourse (except as provided below) shall be to arbitration. Arbitration Proceedings shall be conducted under the rules of the ADR Institue of Ontario (ADR), upon mutual agreement of the Parties, or if no agreement, then at BROKER’s sole discretion. Arbitration proceedings shall be started within eighteen (18) months from the date of delivery or scheduled date of delivery of the freight, whichever is later. Upon agreement of the Parties, arbitration proceedings may be conducted outside of the administrative control of the ADR. The decision of the arbitrators shall be binding and final and the award of the arbitrator may be entered as judgment in any court of competent jurisdiction. The rationale and reasoning of the decision of arbitrator(s) shall be fully explained in a written opinion, including findings of fact and conclusions of law. The prevailing party shall be entitled to recovery of costs, expenses and reasonable attorney fees as well as those incurred in any action for injunctive relief, or in the event further legal action is taken to enforce the award of arbitrators. Arbitration proceedings shall be conducted at the office of the ADR nearest the offices of the BROKER or at such other place as mutually agreed upon in writing, or if no agreement then at the selection of the BROKER or as directed by the acting arbitration association. Provided, however, either Party may apply to a court of competent jurisdiction for injunctive relief. Venue and controlling law for any such action shall be Ontario. The arbitration provisions of this paragraph shall not apply to enforcement of the award of arbitration.

E. 	NO BACK SOLICITATION:
	(i)    Unless otherwise agreed in writing, CARRIER shall not knowingly solicit freight shipments (or accept shipments) for a period of 24 month(s) following termination of this agreement for any reason, from any shipper, consignor, consignee, or other customer of BROKER, when such shipments of shipper customers were first tendered to CARRIER by BROKER.

	(ii)    In the event of breach of this provision, BROKER shall be entitled, for a period of 36 months following delivery of the last shipment transported by CARRIER under this Agreement, to a commission of twenty-five percent (25%) of the gross transportation revenue (as evidenced by freight bills) received by CARRIER for the transportation of said freight as liquidated damages. Additionally, BROKER may seek injunctive relief and in the event it is successful, CARRIER shall be liable for all costs and expenses incurred by BROKER, including, but not limited to, reasonable attorney's fees.

F. 	CONFIDENTIALITY:
	(i)   In addition to Confidential Information protected by law, statutory or otherwise, the Parties agree that all of their financial information and that of their customers, including but not limited to freight and brokerage rates, amounts received for brokerage services, amounts of freight charges collected, freight volume requirements, as well as personal customer information, customer shipping or other logistics requirements shared or learned between the Parties and their customers, shall be treated as Confidential, and shall not be disclosed or used for any reason without prior written consent.
	(ii)    In the event of violation of this Confidentiality paragraph, the Parties agree that the remedy at law, including monetary damages, may be inadequate and that the Parties shall be entitled, in addition to any other remedy they may have, to an injunction restraining the violating Party from further violation of this Agreement in which case the non-prevailing Party shall be liable for all costs and expenses incurred, including but not limited to reasonable attorney’s fees.
G. The limitations of liability for cargo loss and damage as well as other liabilities, arising out of the transportation of shipments, which originate outside the United States of America, may be subject to the laws of the country of origination.

H. 	MODIFICATION OF AGREEMENT: This Agreement and Exhibit A et. seq. attached may not be amended, except by mutual written agreement, or the procedures set forth above (Pars 2.B and 2.C).
	(i)    Should CARRIER modify any provision of this agreement, whether in handwritten form, modified text or otherwise, such amendment shall not be effective, unless BROKER has initialed such change in close proximity thereto evidencing BROKER’s specific acceptance of such modification.
	(ii)    Additionally, the provisions of this Agreement shall be deemed to supersede and shall prevail over any conflicting terms set forth in any load confirmation, rate confirmation, dispatch sheet or other document pertaining to this Agreement, whether any such document was signed prior to, contemporaneously with or subsequent to execution of this Agreement.

I. 	NOTICES:
	(i)    All notices provided or required by this Agreement, shall be made in writing and delivered, return receipt requested, to the addresses shown herein with postage prepaid; or by confirmed (electronically acknowledged on paper) fax, or by email with electronic receipt.
	(ii)    The Parties shall promptly notify each other of any claim that is asserted against either of them by anyone arising out of the Parties performance of this Agreement.
	(iii)    Notices sent as required hereunder, to the addresses shown in this Agreement shall be deemed sent to the correct address, unless the Parties are notified in writing of any changes in address.

J. 	CONTRACT TERM: The term of this Agreement shall be one (1) year from the date hereof and thereafter it shall automatically be renewed for successive one (1) year periods, unless terminated, upon thirty (30) day's prior written notice, with or without cause, by either Party at any time, including the initial term. In the event of termination of this Agreement for any reason, the Parties shall be obligated to complete performance of any work in progress in accordance with the terms of this Agreement.

K. 	SEVERANCE: SURVIVAL: In the event any of the terms of this Agreement are determined to be invalid or unenforceable, no other terms shall be affected and the unaffected terms shall remain valid and enforceable as written. The representations, rights and obligations of the parties hereunder shall survive termination of this Agreement for any reason.

L. 	COUNTERPARTS: This Agreement may be executed in any number of counterparts each of which shall be deemed to be a duplicate original hereof.

M.	FORCE MAJEURE.  In the event that either Party is prevented from performing its obligations under this Agreement because of an occurrence beyond its control and arising without its fault or negligence, including war, riots, rebellion, acts of lawful authorities, fire, strikes, lockouts or other labor disputes, such failures to perform (except for any payments due hereunder) shall be excused for the duration of such occurrence. Economic hardships, including, but not limited to, recession and depression, shall not constitute Force Majeure events.

N. 	ENTIRE AGREEMENT:  Unless otherwise agreed in writing, this Agreement contains the entire understanding of the Parties and supersedes all verbal or written prior agreements, arrangements, and understandings of the Parties relating to the subject matter stated herein, whether any such document was signed prior to, contemporaneously with or subsequent to execution of this Agreement. The Parties further intend that this Agreement constitutes the complete and exclusive statement of its terms, and that no extrinsic evidence may be introduced to reform this Agreement in any judicial or arbitration proceeding involving this Agreement.

IN WITNESS WHEREOF, we have signed this Agreement the date and year first shown above.
`,
        style: 'text'
      },
      {
        text: 'It is possible to apply multiple styles, by passing an array. This paragraph uses two styles: quote and small. When multiple styles are provided, they are evaluated in the specified order which is important in case they define the same properties',
        style: ['quote', 'small']
      },
      {
  			columns: [
  			{
          text: 'BROKER',
          style: 'subheader'
    		},
        {
          text: 'CARRIER',
          style: 'subheader'
  			},
        ]
      },
      {
        columns: [
  			{
          text: '\nPrinted Full Name and Title: \nGabriela Antelo, Vicepresident\n\n\n____________________________________________\nAuthorized Signature',
          style: 'text'
    		},
        {
          text: '\nPrinted Full Name and Title:\n'+fullname +', '+title+'\n\n\n____________________________________________\nAuthorized Signature\n\n____________________________________________\nDate',
          style: 'text'
  			},
  			]
		  },
    ],
    styles: {
      header: {
        fontSize: 12,
        bold: true,
        alignment: 'center'
      },
      text: {
        fontSize: 9,
        alignment: 'justify'
      },
      subheader: {
        fontSize: 9,
        bold: true,
        margin: [0, 5, 0, 0]
      },
      quote: {
        italics: true
      },
      small: {
        fontSize: 8
      }
    }

  };

  let docDefinition = {
      header: 'C#Corner PDF Header',
      content: htmlToPdfmake("<div> <h1>My title</h1><p>This is a sentence with a <strong>bold word</strong>, <em>one in italic</em>,and <u>one with underline</u>. And finally <a href>a link</a>.</p></div>")
    };

    //pdfMake.createPdf(dd).open();
    pdfMake.createPdf(dd).print();
  }

  // Variables
  loading: boolean;
  errors: boolean;
//  agreement;


MCFormGroup: FormGroup;
companyFormGroup: FormGroup;
contactsFormGroup: FormGroup;
subscriberFormGroup: FormGroup;
insuranceFormGroup: FormGroup;

  constructor(
    fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private apiService : ApiService
  ) {



    this.MCFormGroup = fb.group({
      mc_number: [
        '',
        [Validators.required]
      ]
    });


    this.companyFormGroup = fb.group({
      legal_name: [
        '',
        [Validators.required]
      ],
      dba: [
        '',
        []
      ],
      street_address: [
        '',
        [Validators.required]
      ],
      zip: [
        '',
        [Validators.required]
      ],
      street_address_mailing: [
        '',
        []
      ],
      zip_mailing: [
        '',
        []
      ]
    });

    this.insuranceFormGroup = fb.group({
      commercial_liability: [
        '',
        [Validators.required]
      ],
      commercial_expiry: [
        '',
        [Validators.required]
      ],
      automobile_liability: [
        '',
        [Validators.required]
      ],
      automobile_expiry: [
        '',
        [Validators.required]
      ],
      cargo_liability: [
        '',
        [Validators.required]
      ],
      cargo_expiry: [
        '',
        [Validators.required]
      ]
    });


    this.contactsFormGroup = fb.group({
      name_dispatch: [
        '',
        [Validators.required]
      ],
      phone_dispatch: [
        '',
        [Validators.required]
      ],
      email_dispatch: [
        '',
        [Validators.required]
      ],
      name_afterhours: [
        '',
        [Validators.required]
      ],
      phone_afterhours: [
        '',
        [Validators.required]
      ],
      email_afterhours: [
        '',
        [Validators.required]
      ],
      name_accounting: [
        '',
        [Validators.required]
      ],
      phone_accounting: [
        '',
        [Validators.required]
      ],
      email_accounting: [
        '',
        [Validators.required]
      ]
    });

    this.subscriberFormGroup = fb.group({
      name_subscriber: [
        '',
        [Validators.required]
      ],
      title_subscriber: [
        '',
        [Validators.required]
      ]
    });

    this.filteredOptions = this.companyFormGroup.get("zip").valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(val =>
         this.filter(val || '')
        )
     )


     this.filteredOptions2 = this.companyFormGroup.get("zip_mailing").valueChanges.pipe(
       startWith(''),
       debounceTime(400),
       distinctUntilChanged(),
       switchMap(val =>
          this.filter(val || '')
         )
      )

  }

  filter(val: string): Observable<any[]> {
   // call the service which makes the http-request
   return this.apiService.getData('shared/zip',{query: val})
     .pipe(
     )
  }

  formSubmitted = false;
  submit()
  {
    this.formSubmitted = true;


    let data = { ...this.MCFormGroup.value, ...this.companyFormGroup.value, ...this.insuranceFormGroup.value, ...this.contactsFormGroup.value, ...this.subscriberFormGroup.value};

    this.apiService.getData( 'vendors/new/store/', data )
      .subscribe(
        result => {
          //this.contract = result;//getContract();
      });

  }
  ngOnInit(): void { }

  /**
   * Login the user based on the form values
   */

  /**
   * Getter for the form controls
   */
  // get controls() {
  //   return this.form.controls;
  // }

}
