import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ApiService } from '../services/api.service';
import { DetailedOperation } from '../structures/detailed_operation';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppConstants } from '../structures/appconstants';
import { AppComponent } from '../app.component';
import {CdkDragDrop, moveItemInArray, transferArrayItem, CdkDropList} from '@angular/cdk/drag-drop';
//import { MatTable, MatTableDataSource } from '@angular/material';
import { MatTableDataSource } from '@angular/material/table';
//import { EditableTableComponent } from '../editableTable/EditableTable.component';
import { EditableTableComponent } from '../editableTable/editableTable.component';
import {TranslateService} from '@ngx-translate/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';

import { CarrierdialogComponent } from '../vendordialog/carrierdialog.component';
import { NewcarrierdialogComponent } from '../vendordialog/newcarrierdialog.component';
import { EditcarrierdialogComponent } from '../vendordialog/editcarrierdialog.component';
import { NewsitedialogComponent } from '../vendordialog/newsitedialog.component';
import { SitedialogComponent } from '../vendordialog/sitedialog.component';

@Component({
  selector: 'app-operationm',
  templateUrl: './operationm.component.html',
  styleUrls: ['./operationm.component.css']
})
export class OperationmComponent implements OnInit {
  @Input() id: number;

  @ViewChild('invoicesEditableTableId') invoicesEditableTable:EditableTableComponent;
  @ViewChild('budgetsEditableTableId') budgetsEditableTable:EditableTableComponent;

  data : any;
  sub_total=0;
  privilegios : any;
  loaded: boolean;
  is_editting_details :boolean;
  descriptionFormGroup: FormGroup;
  //invoice_headers = [{name:'date',required:true,type:'date'},{name:'invoice_number',type:'static_field'},{name:'sub_total_books',required:true,readonly:true},{name:'hst_books',required:true,readonly:true},{name:'total_books',required:true,readonly:true},{name:'file',type:'button',icon:'insert_drive_file',callback:this.fileCallback}];

  displayedColumnsStops: string[] = ['stop_type_name', 'site_name', 'city_name', 'state'];
  //dataSourceStops = ELEMENT_DATA;


  constructor(
    private apiService: ApiService,
    private sanitizer: DomSanitizer,
    private app: AppComponent,
    private location: Location,
    public translate: TranslateService,
    public dialog: MatDialog,
    fb: FormBuilder,

  ) {


    this.is_editting_details = false;
    this.loaded = false;
    this.descriptionFormGroup = fb.group({
      description: [
        '',
        []
      ],
      client_ref: [
        '',
        []
      ],
      temperature: [
        '',
        []
      ],
      mode_id: [
        '',
        []
      ]
    });


  }

  private dialogParams = {
    width: 'calc( 100% - 50px )',
    height: 'calc( 100% - 50px )',
    maxWidth: '100vw',
    maxHeight: '100vh',
  };

  ngOnInit(): void {

  }

  ngOnChanges(changes) {
    this.getOperation();
  }


  send_completed()
  {
    const id = +this.id;
    if(!this.id) return;
    this.apiService.getData('services/status/update/'+ id ,{param:'send_completed'})
      .subscribe(results =>
        {

          if(results['success'] )
          {
            this.data.status_name = 'q_completed';
            this.data.status = -11;
            console.log('completed');
          }
        });

  }

  send_pending()
  {
    const id = +this.id;
    if(!this.id) return;
    this.apiService.getData('services/status/update/'+ id ,{param:'send_pending'})
      .subscribe(results =>
        {

          if(results['success'] )
          {
            this.data.status_name = 'q_pending';
            this.data.status = -10;
            console.log('completed');
          }
        });

  }

  send_customer()
  {
    const id = +this.id;
    if(!this.id) return;
    this.apiService.getData('services/status/update/'+ id ,{param:'send_customer'})
      .subscribe(results =>
        {

          if(results['success'] )
          {
            this.data.status_name = 'q_sent';
            this.data.status = -12;
          }
        });

  }

  send_new_op()
  {
    const id = +this.id;
    if(!this.id) return;
    this.apiService.getData('services/status/update/'+ id ,{param:'send_new_op'})
      .subscribe(results =>
        {

          if(results['success'] )
          {
            this.data.status_name = 'pending';
            this.data.status = 1;
          }
        });

  }

  back_completed()
  {
    const id = +this.id;
    if(!this.id) return;
    this.apiService.getData('services/status/update/'+ id ,{param:'back_completed'})
      .subscribe(results =>
        {

          if(results['success'] )
          {
            this.data.status_name = 'q_completed';
            this.data.status = -11;
          }
        });

  }


  cancel()
  {
    if(confirm(this.translate.instant('Cancel Operation?')))
    {
      const id = +this.id;
      if(!this.id) return;
      this.apiService.getData('services/service/remove/'+ id )
        .subscribe(results =>
          {
            if(results['success'] == "true")
            {
              this.data.status_name = 'cancelled';
              this.data.status = 9;
            }
          });

    }
  }


  EditCarrierRow(element)
  {

    let dialogParams = {
      width: 'calc( 100% - 50px )',
      height: 'calc( 100% - 50px )',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: element.carrier
    };

    //return this.openEditCarrier(element.carrier,element);
    const dialogRef = this.dialog.open(EditcarrierdialogComponent, dialogParams);

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        //element.carrier = [];
        element.carrier.id = result.id;
        element.carrier.name = result.name;
        element.carrier.phone = result.phone;
        element.carrier.emails = result.emails;
        console.log(element);

      }


    });
  }


  sendEmailCarrier(element)
  {
      const id = +this.data.id
      //this.apiService.getBlob( 'emaisl/quote_request/pdf/'+id )
      //  .subscribe(response =>
        //  {
            // var blob = response;
            // var fileURL = URL.createObjectURL(blob);
            // var documentData = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
          //  this.tryDoctype = documentData;

            let url = 'pages/bol/email/' +id;
            //let filename = 'BOL V20'+ this.contract.contract_id  +'.pdf';
            let subject = 'V20';//+ this.contract.contract_id + ' ';
            // for( var i = 0; i<this.contract.stops.length; i++)
            // {
            //   subject = subject + this.contract.stops[i].city_name+', ' + this.contract.stops[i].state + ' -';
            // }
            // subject = subject + this.contract.mode_name;
             let message = this.translate.instant('dialogs.lc_email');



            let preview = ""
            let c = [];

            // for(var i= 0; i <this.service_contacts.length; i++)
            // {
            //   if( this.service_contacts[i].bol )
            //     c.push(this.service_contacts[i]);
            // }
            this.app.setSidenav(null,c, subject ,message, null, null, url);
            this.app.getSidenav().open();

      //    });





    // this.apiService.getData('quotes/carrier_email/email/' + element.id , this.rulesForm.value ).subscribe(res =>
    // {
    //   console.log(res);
    //   if(res.success == true)
    //     this.value.sidenav.close();
    //     this.sending = false;
    // });
  }
  hold()
  {
    if(confirm(this.translate.instant('Place on hold')))
    {
      const id = +this.id;
      if(!this.id) return;
      this.apiService.getData('services/status/update/'+ id ,{param:'on_hold'})
        .subscribe(results =>
          {
            if(results['success'])
            {
              this.data.status_name = 'onhold';
              this.data.status = 7;
            }
          });

    }
  }

  remove_hold()
  {
    if(confirm(this.translate.instant('Remove hold?')))
    {
      const id = +this.id;
      if(!this.id) return;
      this.apiService.getData('services/status/update/'+ id ,{param:'remove_hold'})
        .subscribe(results =>
          {
            if(results['success'])
            {
              this.data.status_name = 'pending';
              this.data.status = 1;
            }
          });

    }
  }


  getOperation(): void {
    this.loaded = false;
    const id = +this.id;
    if(!this.id) return;
    this.apiService.getData('services/service_detail/view/'+ id )
      .subscribe(detailed_operation =>
        {

          for(let contract of detailed_operation.data.contracts)
          {
            for(let stop of contract.stops)
            {
              stop.service_stop_id_list.push({value:-10,viewValue:'New Stop'});
            }
          }

          this.data = detailed_operation.data;
          this.privilegios = detailed_operation.privilegios;




          this.loaded = true;

        });
  }
  // makeEditable( element)
  // {
  //   element.editable = true;
  //   element.name_bk = element.name;
  //   element.description_bk = element.description;
  //   element.quantity_bk = element.quantity;
  //   element.weight_bk = element.weight;
  //   element.units_bk = element.units;
  //   element.length_bk = element.length;
  //   element.width_bk = element.width;
  //   element.height_bk = element.height;
  // }
  //
  // cancelEditable( element)
  // {
  //   element.name = element.name_bk;
  //   element.description = element.description_bk;
  //   element.quantity = element.quantity_bk;
  //   element.weight = element.weight_bk;
  //   element.units = element.units_bk;
  //   element.length = element.length_bk;
  //   element.width = element.width_bk;
  //   element.height = element.height_bk;
  //
  //   element.editable = false;
  //
  // }

  getModes()
  {
    return AppConstants.modes;
  }

  editSite(id) :void
  {
    this.app.setSiteSide(id);
    this.app.getSiteSide().open();
  }

  fileCallback = ( element ): void =>
  {
  //https://tms.venkor.ca/pages/invoice/pdf/49/
    this.apiService.getBlob( 'pages/invoices/pdf/'+element.id )
      .subscribe(response =>
        {
          var blob = response;
          var fileURL = URL.createObjectURL(blob);
          var documentData = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);

          let url = 'pages/invoices/email/' + element.id;
          let filename = 'V'+ this.data.service_id +'.pdf';
          let subject = 'Invoice V'+ this.data.service_id;

          let message = ""
          let preview = ""

          let c = [];

          for(var i= 0; i <this.data.client_contact.length; i++)
          {
            if( (this.data.client_contact[i].accounting) && (this.data.client_contact[i].email) )
              c.push(this.data.client_contact[i]);
          }


          this.app.setSidenav(this.getOperation.bind(this), c, subject ,message, documentData, filename, url);
          this.app.getSidenav().open();
        });

  }

  budgetSelected = ( data ): void => {
    console.log('budgetSelected');
    let total =0;
    let budgets = [];
    for(let i =0; i<data.length;i++)
    {
      if(data[i].marked)
      {
        total += parseFloat(data[i].rate);
        budgets.push( data[i].id );
      }
    }
    //this.sub_total = total;


    var update = [];

    if( total )
    {
      let hst = total*this.getHstRatio();


      // this.invoice_headers[3].dependent_val = total;
      // this.invoice_headers[4].dependent_val = hst;
      // this.invoice_headers[5].dependent_val = total+hst;
      update.push({name:'sub_total_books', value: total});
      update.push({name:'hst_books', value: hst});
      update.push({name:'total_books', value: total+hst});
    }
    else
    {
      update.push({name:'sub_total_books', value: ''});
      update.push({name:'hst_books', value: ''});
      update.push({name:'total_books', value: ''});
    }
    console.log('about to call view child');
    this.invoicesEditableTable.updateDependents(update,budgets);
    //callback code here
  }


  lineChange = ( element ): void => {

    element.rate =element.unit_price * element.quantity;
  }

  getHstRatio()
  {
    console.log( 'hst');
    console.log( this.data);
    if((this.data.stops[0].country =='Canada') && (this.data.stops[this.data.stops.length-1].country =='Canada'))
    {
      if((this.data.stops[0].state =='QC') && (this.data.stops[this.data.stops.length-1].state =='QC'))
        return .14975;

      if( (this.data.stops[this.data.stops.length-1].state =='AB') ||
      (this.data.stops[this.data.stops.length-1].state =='BC') ||
      (this.data.stops[this.data.stops.length-1].state =='MB') ||
      (this.data.stops[this.data.stops.length-1].state =='SK') ||
      (this.data.stops[this.data.stops.length-1].state =='NWT') ||
      (this.data.stops[this.data.stops.length-1].state =='QC') )

        return 0.05;


      if( (this.data.stops[this.data.stops.length-1].state =='ON'))
        return 0.13;

      if( (this.data.stops[this.data.stops.length-1].state =='NS') ||
        (this.data.stops[this.data.stops.length-1].state =='PEI') ||
        (this.data.stops[this.data.stops.length-1].state =='NB') ||
        (this.data.stops[this.data.stops.length-1].state =='NL') )


        return 0.15;

        alert('HST NOT FOUND, DO NOT SEND INVOICE');
        return 0;
    }
    else
    {
      return 0;
    }

  }



  bill()
  {
    // const id = +this.id;//route.snapshot.paramMap.get('id');
    // this.apiService.getBlob( 'pages/bill/pdf/'+id )
    //   .subscribe(response =>
    //     {
    //       var blob = response;
    //       var fileURL = URL.createObjectURL(blob);
    //       var documentData = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
    //     //  this.tryDoctype = documentData;
    //
    //       let url = 'pages/bill/email/' +id;
    //       let filename = 'V'+ this.service_id +(this.contract.sub_unit!=1?'-'+this.contract.sub_unit:'') +'.pdf';
    //       let subject = 'BOL'+ this.service_id +(this.contract.sub_unit!=1?'-'+this.contract.sub_unit:'');
    //       for( var i = 0; i<this.contract.stops.length; i++)
    //       {
    //         subject = subject + this.contract.stops[i].state + '-';
    //       }
    //       subject = subject + this.contract.vendor_name;
    //       let message = this.translate.instant('dialogs.lc_email');
    //
    //
    //
    //       let preview = ""
    //       let c = [];
    //
    //       console.log(this.service_contacts);
    //       for(var i= 0; i <this.service_contacts.length; i++)
    //       {
    //         if( this.service_contacts[i].bol )
    //           c.push(this.service_contacts[i]);
    //       }
    //       this.app.setSidenav(this.contract,c, subject ,message, documentData, filename, url);
    //       this.app.getSidenav().open();
    //
    //     });
  }
  resetDescription(){
      this.descriptionFormGroup.reset( this.data.detail);
      this.is_editting_details = false;
  }

  saveDescription()
  {
    const id = +this.id;
    this.apiService.getData('services/service_detail/modify/'+ id, this.descriptionFormGroup.value ).subscribe(detailed_operation =>
        {
          for(var i in detailed_operation){
             this.data.detail[i]= detailed_operation[i];
          }
          this.is_editting_details = false;
        });
  }

  //print contract
  print_contract( contract ) :void{
    this.apiService.getBlob( 'pages/contract_dispatch/pdf/'+contract.id )
      .subscribe(response =>
        {
          var blob = response;
          var fileURL = URL.createObjectURL(blob);
          var documentData = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);

          let url = 'pages/contract_dispatch/email/' +contract.id;
          let filename = 'LC'+ contract.contract_id +'.pdf';
          let subject = 'LC '+ contract.contract_id + ' ';
          for( var i = 0; i<contract.stops.length; i++)
          {
            subject = subject + contract.stops[i].state + '-';
          }
          subject = subject + contract.vendor_name;
          let message = this.translate.instant('dialogs.lc_email');
          let preview = ""
          this.app.setSidenav(null, contract.vendor.site.contacts, subject ,message, documentData, filename, url);
          this.app.getSidenav().open();
        });

  }



  openNewSite(name,element)
  {
    let dialogParams = {
      width: 'calc( 100% - 50px )',
      height: 'calc( 100% - 50px )',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: name
    };
    const dialogRef = this.dialog.open(NewsitedialogComponent, dialogParams);

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        element.site.name = result.name;
        element.site.id = result.id;
        element.zip_name = result.city +', '+result.state +', '+result.zip_code;
      }


    });
  }

  openNewBlindSite(name,element)
  {
    let dialogParams = {
      width: 'calc( 100% - 50px )',
      height: 'calc( 100% - 50px )',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: name
    };
    const dialogRef = this.dialog.open(NewsitedialogComponent, dialogParams);

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        element.blind_site.name = result.name;
        element.blind_site.id = result.id;
        //element.zip_name = result.city +', '+result.state +', '+result.zip_code;
      }


    });
  }

  site_clear(element)
  {
    element.site.name = '';
    element.site.id = null;
  }

  blind_site_clear(element)
  {
    element.blind_site.name = '';
    element.blind_site.id = null;
  }


  openEditCarrier(row,element)
  {
    let dialogParams = {
      width: 'calc( 100% - 50px )',
      height: 'calc( 100% - 50px )',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: row
    };
    const dialogRef = this.dialog.open(EditcarrierdialogComponent, dialogParams);

    dialogRef.afterClosed().subscribe(result => {
      //if(result)
      {
        this.openCarrierDialog(element);
        // element.site.name = result.name;
        // element.site.id = result.id;
        // element.zip_name = result.city +', '+result.state +', '+result.zip_code;
      }


    });
  }




  openNewCarrier(name,element)
  {
    let dialogParams = {
      width: 'calc( 100% - 50px )',
      height: 'calc( 100% - 50px )',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: name
    };
    const dialogRef = this.dialog.open(NewcarrierdialogComponent, dialogParams);

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        element.carrier = [];
        element.carrier.id = result.id;
        element.carrier.name = result.name;
        element.carrier.phone = result.phone;
        element.carrier.emails = result.emails;

      }


    });
  }

  find_carriers()
  {
    const id = +this.id;
    this.apiService.getData('services/search_carriers/update/'+ id ).subscribe(detailed_operation =>
    {
        for(var i in detailed_operation){
          this.data.detail[i]= detailed_operation[i];
        }
          //this.is_editting_details = false;
    });

  }


  openSiteDialog(element) {

    const dialogRef = this.dialog.open(SitedialogComponent,this.dialogParams);

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        if(result['create_new'])
        {
          this.openNewSite(result['name'],element);
        }
        else
        {

          element.site.name = result.name;
          element.site.id = result.id;
          element.zip_name = result.city +', '+result.state +', '+result.zip_code;

          //element.zip.id = result.zip.id;
        }
      }
    });
    console.log(element);
    return (element);
  }

  openBlindSiteDialog(element) {

    const dialogRef = this.dialog.open(SitedialogComponent,this.dialogParams);

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        if(result['create_new'])
        {
          this.openNewBlindSite(result['name'],element);
        }
        else
        {

          element.blind_site.name = result.name;
          element.blind_site.id = result.id;
          //element.zip_name = result.city +', '+result.state +', '+result.zip_code;

          //element.zip.id = result.zip.id;
        }
      }
    });
    console.log(element);
    return (element);
  }

  lineChangePO = ( element ): void => {
    if(element.length && element.width && element.height && element.number && element.po_weight_id  && element.weight)
    {
        let volume = element.length * element.width * element.height / 1728;
        let density = (element.weight/element.number)/volume;

        if(element.po_weight_id == 2)
          density *= 2.20462;

        console.log(density);
//
// Sub 1 Less than 1 ................ 400
// Sub 2 1 but less than 2......... 300
// Sub 3 2 but less than 4......... 250
// Sub 4 4 but less than 6......... 175
// Sub 5 6 but less than 8......... 125
// Sub 6 8 but less than 10....... 100
// Sub 7 10 but less than 12..... 92.5
// Sub 8 12 but less than 15..... 85
// Sub 9 15 but less than 22.5.. 70
// Sub 10 22.5 but less than 30.. 65
// Sub 11 30 or greater .............. 60
      if((density<1 ) )
      {
        element.class = "400";
        element.subclass = "1";
      }
      else if((density >= 1)  && (density < 2 ))
      {
        element.class = "300";
        element.subclass = "2";
      }
      else if((density >= 2)  && (density < 4 ))
      {
        element.class = "250";
        element.subclass = "3";
      }
      else if((density >= 4)  && (density < 6 ))
      {
        element.class = "175";
        element.subclass = "4";
      }
      else if((density >= 6)  && (density < 8 ))
      {
        element.class = "125";
        element.subclass = "5";
      }
      else if((density >= 8)  && (density < 10 ))
      {
        element.class = "100";
        element.subclass = "6";
      }
      else if((density >= 10)  && (density < 12 ))
      {
        element.class = "92.5";
        element.subclass = "7";
      }
      else if((density >= 12)  && (density < 15 ))
      {
        element.class = "85";
        element.subclass = "8";
      }
      else if((density >= 15)  && (density < 22.5 ))
      {
        element.class = "70";
        element.subclass = "9";
      }
      else if((density >= 22.5)  && (density < 30 ))
      {
        element.class = "65";
        element.subclass = "10";
      }
      else if((density >= 30))
      {
        element.class = "60";
        element.subclass = "11";
      }

    }
    else
    {
      element.class = "";
      element.subclass = "";
    }

  }



  print_bol() :void
  {
    const id = +this.data.id;//route.snapshot.paramMap.get('id');
    this.apiService.getBlob( 'pages/service_bol/pdf/'+id )
      .subscribe(response =>
        {
          var blob = response;
          var fileURL = URL.createObjectURL(blob);
          var documentData = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);


          let url = 'pages/service_bol/email/' +id;
          let filename = 'BOL V20'+ this.data.service_id  +'.pdf';
          let subject = this.data.service_id + ' ';
          for( var i = 0; i<this.data.stops.length; i++)
          {
            subject = subject + this.data.stops[i].city_name+', ' + this.data.stops[i].state + ' -';
          }
          subject = subject + this.data.mode_name;
          let message = this.translate.instant('dialogs.lc_email');



          let preview = ""
          let c = [];

          for(var i= 0; i <this.data.client_contact.length; i++)
          {
            if( this.data.client_contact[i].bol )
              c.push(this.data.client_contact[i]);
          }
          this.app.setSidenav(null,c, subject ,message, documentData, filename, url);
          this.app.getSidenav().open();



        });
  }




  openCarrierDialog(element) {

    const dialogRef = this.dialog.open(CarrierdialogComponent,this.dialogParams);

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        if(result['create_new'])
        {
          this.openNewCarrier(result['name'],element);
        }

        else if(result['edit_carrier'])
        {
          this.openEditCarrier(result['row'],element);
        }
        else
        {
          console.log(element);

          element.carrier = [];
          element.carrier.id = result.id;
          element.carrier.name = result.name;
          element.carrier.phone = result.phone;
          element.carrier.emails = result.emails;
          console.log(element);


        }
      }
    });
    console.log(element);
    //return (element);
  }

  update_budgets()
  {
    const id = +this.data.id;
    //const id = 512;

    this.apiService.getBlob( 'pages/serviceupdate/pdf/'+id )
      .subscribe(response =>
        {
          var blob = response;
          var fileURL = URL.createObjectURL(blob);
          var documentData = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);


          let url = 'pages/serviceupdate/email/' +id;
          let filename = 'V'+ this.data.service_id +'.pdf';
          let subject = 'V'+ this.data.service_id + ' ';

          for( var i = 0; i<this.data.stops.length; i++)
          {
            subject = subject + this.data.stops[i].city_name+', ' + this.data.stops[i].state + ' -';
          }
          subject = subject + this.data.mode_name;
          let message = '';//this.translate.instant('dialogs.lc_email');


          let preview = ""
          let c = [];

          for(var i= 0; i <this.data.client_contact.length; i++)
          {
            if( this.data.client_contact[i].customer )
              c.push(this.data.client_contact[i]);
          }

          this.app.setSidenav(null,c, subject ,message, documentData, filename, url);
          this.app.getSidenav().open();

        });



  }
  getStructureValue( name, id )
  {
    if(name && id)
    {
      return AppConstants[name].filter(x => x.value == id)[0]?.viewValue;
      // if(AppConstants[name][id-1])
      //   return AppConstants[name][id-1].viewValue;
      // else
      //   console.log('error getStructureValue name:' + name + ' id:'+id );
    }
  //  else
    console.log('error getStructureValue name:' + name + ' id:'+id );
  }


  getRevenueTotal()
  {
    return this.data?.service_budgets?.reduce((accumulator:number, current) => accumulator + Number(current.rate), +0);
  }

  getCostTotal()
  {
    return this.data?.contracts?.reduce(( a:number, contract) => a + Number( contract.contract_budgets?.reduce((accumulator:number, current) => accumulator + Number(current.rate), +0)) ,+0);

    //.contract_budgets?.reduce((accumulator:number, current) => accumulator + Number(current.rate), +0);
  }

}
