import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from './message.service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap, delay} from 'rxjs/operators';
import {
  AsyncValidatorFn,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';
import {MatSnackBar, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ApiService {


  apiUrl = 'https://api.venkor.ca/api/';





  constructor(
    private http: HttpClient,
    public _snackBar: MatSnackBar
  ) { }

  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.log( 'hola');
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
    //  this.log(`${operation} failed: ${error.message}`);


      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getData( path, params = null ): Observable<any> {

    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        //'Content-Type': 'application/json',
        //'Content-Type': 'false',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      })
    };


    console.log('getDatacalled');
    if(params)
      return this.http.post<any>(this.apiUrl+path, params, options);
    else
      return this.http.get<any>(this.apiUrl+path, options);
  }

  getBlob( path ) : Observable<any> {
    const optionsBlog = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      })
      ,
      responseType: 'blob'  as 'json'
    };


    return this.http.get<any>(this.apiUrl+path, optionsBlog);
  }

verticalPosition: MatSnackBarVerticalPosition = 'top';

  public checkValidation(value: string, code:string) {
    // simulate http.get()
    // console.log('checking');
    // if(value == 'test')
    // {
    //   this._snackBar.open(code , 'OK', {
    //     //duration: 2000,
    //     verticalPosition: this.verticalPosition
    //   });
    // }
    //

    return this.getData('validate/'+code, {'value':value });

  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    //this.messageService.add(`HeroService: ${message}`);
  }
}
