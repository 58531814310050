import { Component, OnInit} from '@angular/core';
import { Observable, of, Subscription, empty } from 'rxjs';
import { tap, startWith, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['../shared/panel.component.css']
})

export class TrackingComponent implements OnInit {

  tracking_id:string ="a";
  ngOnInit(): void {
    this.getData();

  }

  getData()
  {
    var id = +this.route.snapshot.paramMap.get('id');

    this.apiService.getData('access/tracking/' + id)
      .subscribe(response =>
        {
          this.tracking_id = response;
    //      console.log(response);

        });


  }



  constructor(
    private apiService: ApiService,
    public datepipe: DatePipe,
    private route: ActivatedRoute
  )
  {

  }


}
