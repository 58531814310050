<div *ngIf="loaded" fxLayout="column" class="test">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fx-layout-align="end">
    <div fxFlex>
      <h1>{{ data.name }}</h1>
    </div>
    <div fxFlex >
    </div>
  </div>


  <mat-card>
    <mat-card-header>
      <mat-card-subtitle>Description</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>

        <app-editable-form
         [headers]="[{name:'name'},{name:'description'},{name:'website'},{name:'goal',type:'date'},{name:'account_manager_id',type:'selectList'},{name:'tabs_prospects_id',type:'selectList'}]"
         [data]='data'
         updateUrl="{{'prospects/prospect_detail/modify/'+data.id}}">
       </app-editable-form>
  </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-subtitle>Contacts</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <app-editable-table
      [headers]="[ {name:'name'},{name:'phone'}, {name:'email'},{name:'note'}]"
      [data]='data.contacts'
      updateUrl="{{'prospects/contacts/update/'+data.id}}"
      newUrl="{{'prospects/contacts/store/'+data.id}}"
      removeUrl="{{'prospects/contacts/remove/'+data.id}}">
      </app-editable-table>
    </mat-card-content>
  </mat-card>


  <mat-card>
    <mat-card-header>
      <mat-card-subtitle>Calls</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <app-editable-table
      [headers]="[ {name:'date',type:'date',readonly:'true',update:'true'},{name:'caller',update:'true',readonly:'true'}, {name:'notes'},{name:'call_outcome_id',type:'selectList'},{name:'next_objective'},{name:'next_date', type:'date'}]"
      [data]='data.calls'
      updateUrl="{{'prospects/calls/update/'+data.id}}"
      newUrl="{{'prospects/calls/store/'+data.id}}"
      removeUrl="{{'prospects/calls/remove/'+data.id}}"
      >
      </app-editable-table>
    </mat-card-content>
  </mat-card>


</div>

<div *ngIf="!loaded">
  Loading...
</div>
